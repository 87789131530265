import React from 'react';
import icons from '../../assets/icons';
import './styles.scss';

const EmptyState = ({ icon, title, details }) => (
  <div className="empty-state">
    <img src={icons[icon]} alt="Empty State"/>
    <div className="text-container">
      <h2>{title}</h2>
      <p>{details}</p>
    </div>
  </div>
);

export default EmptyState;