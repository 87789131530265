import React, { useEffect, useState } from 'react';
import components from '../../components';
import reportUtils from '../../utils/report';
import projectUtils from '../../utils/project';
import utils from '../../utils';

const { CustomDropdown, CustomReport, QuickReport } = components;

const GenerateReportButton = ({
  project,
  report
}) => {
  const [customReport, setCustomReport] = useState(report);
  const [modals, setModals] = useState({
    isCustomReportOpen: false,
    isQuickReportOpen: false
  });

  useEffect(() => {
    setCustomReport(report);
  }, [report]);

  const toggleReportModal = reportOption => {
    if (reportOption === 'quick') {
      const state = !modals.isQuickReportOpen;
      setModals({ ...modals, isQuickReportOpen: state });
    } else if (reportOption === 'custom') {
      const state = !modals.isCustomReportOpen;
      setModals({ ...modals, isCustomReportOpen: state });
    }
  };

  const onReportOptionSelected = reportOption => {
    if (reportOption === 'pdf') {
      window.print();
    } else {
      toggleReportModal(reportOption);
    }
  };

  const onCustomReportChange = newCustomReport => {
    const newReport = utils.deepClone(newCustomReport);
    const { frequency, seletedDay, customDay, fields } = newCustomReport;

    // Set day to send when report has custom day selected
    if (frequency === 'monthly') {
      if (!seletedDay || seletedDay === 'custom') {
        if (customDay && customDay[0] !== '') {
          newReport.daysToSend = customDay;
        }
      }
    }

    // When a field with name speed appears this should be transformed to: speed_{speedUnit}
    newReport.fields = fields.map(field => {
      if (field === 'speed') {
        const speedUnit = project.preferences.speedUnit;
        const speedUnitObj = projectUtils.getSpeedUnitOption(speedUnit);
        const speedUnitShortName = String(speedUnitObj.shortName).replace('/', '');
        return `speed_${speedUnitShortName === 'ms' ? 'mps' : speedUnitShortName}`;
      }
      return field;
    });

    setCustomReport(newReport);

    setModals({
      ...modals,
      isCustomReportOpen: false,
      isQuickReportOpen: true
    });
  };

  return (
    <div className="report-dropdown">
      <CustomDropdown
        staticTitle={'Generate Report'}
        items={reportUtils.reportTypes}
        valueField={'id'}
        displayField={'name'}
        direction="end"
        handleSelection={(e) => onReportOptionSelected(e.target.value)}
        outline={false} />
      <CustomReport
        classes={'custom-report'}
        report={customReport}
        onSubmit={onCustomReportChange}
        onClose={() => toggleReportModal('custom')}
        isOpen={modals.isCustomReportOpen} />
      <QuickReport
        classes={'quick-report'}
        project={project}
        report={customReport}
        onClose={() => {
          setCustomReport(report);
          toggleReportModal('quick');
        }}
        isOpen={modals.isQuickReportOpen} />
    </div>
  );
};

export default GenerateReportButton;