import React, { useState } from 'react';

import ReportsContainer from '../../containers/reports';
import components from '../../components';
import ReloadButton from '../../components/reloadButton';
import TimezoneInfo from '../../components/timezoneInfo';

import './styles.scss';

const { Header, ProjectSelector, NotificationTools } = components;

const ReportsScreen = () => {
  const [lastUpdated, setLastUpdated] = useState('');

  return (
    <div className="d-flex flex-column flex-fill">
      <Header
        title={'Management'} icon={'settingsDark'}
        filters={
          <div className="d-flex">
            <ProjectSelector/>
          </div>
        }
        actions={[
          { type: "reactComponent", value: <TimezoneInfo key="timezone-info" /> },
          {
            type: "reactComponent",
            value: <ReloadButton
              key="reload-btn"
              contentName="Report list"
              lastUpdatedCB={setLastUpdated} />
          },
          { type: "reactComponent", value: <NotificationTools key="notification-btn" /> }
        ]} />
      <ReportsContainer lastUpdated={lastUpdated}/>
    </div>
  )

};

export default ReportsScreen;