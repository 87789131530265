import React, {useState} from 'react';
import icons from '../../assets/icons';
import './styles.scss';

const InfoSection = ({ title, content, onClickCB }) => {
  const [infoOpen, setInfoOpen] = useState(false);

  return (
    <div
      className={`info-section-component ${infoOpen ? 'open' : ''}`}
      onClick={() => {
        if (onClickCB) {
          onClickCB(!infoOpen);
        }

        setInfoOpen(!infoOpen);
      }}>
      <div className="info-header">
        <div className="d-flex">
          <img src={icons.info} alt="info icon"/>
          <h3>{title}</h3>
        </div>
        <img className="arrow-icon" src={icons.angleDown} alt="arrow icon"/>
      </div>
      <div className="info-body">
        {content}
      </div>
    </div>
  );
};
export default InfoSection;