import types from '../types.js';

const initialState = {
  companies: [],
  selectedCompany: null
};

const companyReducer = (state = initialState, action) => {
  const {
    LOAD_COMPANIES,
    SELECT_COMPANY
  } = types;

  switch (action.type) {
    case LOAD_COMPANIES:
      return {
        ...state,
        ...{
          companies: action.payload
        }
      };
    case SELECT_COMPANY:
      return {
        ...state,
        ...{
          selectedCompany: { ...action.payload }
        }
      };
    default:
      return state;
  }
};

export default companyReducer;