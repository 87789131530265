import { solutionsMeta } from "../solutions";

const defaultWidgets = () => {
  const widgets = {};
  return widgets;
};

const getCustomWidgets = (preferences) => {
  if (preferences && preferences.customWidgets) {
    return preferences.customWidgets;
  }

  return defaultWidgets();
};

const getDefaultWidgets = (selectedSolution) => {
  const widgets = solutionsMeta
    .filter(solution => solution.id === selectedSolution);
  return widgets;
};

export {
  getCustomWidgets,
  getDefaultWidgets
};