const minValidation = (value, min, customMessage) => {
  if (typeof value === 'string') {
    if (value !== '' && value.length < min) {
      return { success: false, message: customMessage ? customMessage : `Needs to have at least ${min} characters` };
    }
  } else {
    if (value < min) {
      return { success: false, message: customMessage ? customMessage : `Needs to be higher than ${min}` };
    }
  }
  return { success: true };
};

const maxValidation = (value, max, customMessage) => {
  if (typeof value === 'string') {
    if (value.length > max) {
      return {
        success: false,
        message: customMessage ? customMessage : `Needs to have a maximum of ${max} characters`
      };
    }
  } else {
    if (value > max) {
      return { success: false, message: customMessage ? customMessage : `Needs to be lower than ${max}` };
    }
  }
  return { success: true };
};

const requiredValidation = (value, target, customMessage) => {
  if (value === '') {
    return { success: false, message: customMessage ? customMessage : `This value is required` };
  }
  return { success: true };
};

const uniqueValidation = (value, existingNames, customMessage) => {
  if (value !== '' && existingNames.indexOf(value) > -1) {
    return { success: false, message: customMessage ? customMessage : `This value is already being used` };
  }
  return { success: true };
};

const emailValidation = (value, target, customMessage) => {
  const mailRegex = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(value);
  if (value !== '' && !mailRegex) {
    return { success: false, message: customMessage ? customMessage : `Invalid email address` };
  }
  return { success: true };
};

const equalsValidation = (value, target, customMessage) => {
  if (value !== target) {
    return { success: false, message: customMessage ? customMessage : `Values should match.` };
  }
  return { success: true };
};

const differentValidation = (value, target, customMessage) => {
  if (value === target) {
    return { success: false, message: customMessage ? customMessage : `Values shouldn't match.` };
  }
  return { success: true };
};

const alfanumericValidation = (value, target, customMessage) => {
  const validatorRegex = /^[\w-]*$/;
  if (value !== '' && !validatorRegex.test(value)) {
    return { success: false, message: customMessage ? customMessage : `Values don't match.` };
  }
  return { success: true };
};

const minTimeValidation = (value, min, customMessage) => {
  if (value < min) {
    return { success: false, message: customMessage ? customMessage : `Needs to be higher than ${min}` };
  }
  return { success: true };
};

const maxTimeValidation = (value, max, customMessage) => {
  if (value > max) {
    return { success: false, message: customMessage ? customMessage : `Needs to be less than ${max}` };
  }
  return { success: true };
};

const valid = {
  min: minValidation,
  max: maxValidation,
  alfanumeric: alfanumericValidation,
  required: requiredValidation,
  unique: uniqueValidation,
  email: emailValidation,
  equals: equalsValidation,
  different: differentValidation,
  minTime: minTimeValidation,
  maxTime: maxTimeValidation
};

function fieldValidation(value, validations) {
  /* Expected Format:
    validations = [
      {
        key: required,
        value: true
      },
      {
        key: min,
        value: 0
      },
    ]
  */
  const errors = [];

  validations.forEach(validate => {
    const result = valid[validate.key](value, validate.value, validate.customMessage);
    if (!result.success) errors.push(result.message);
  });

  return errors;
}

export default fieldValidation;
