import React, { useEffect, useRef, useState } from 'react';

import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { useDebouncedCallback } from 'use-debounce';
import { Button } from 'reactstrap';
import SearchBox from '../searchBox';
import icons from '../../assets/icons';
import Dropdown from './dropdown';
import paginationUtils from '../../utils/pagination';

import './styles.scss';

const InfinityLoader = ({
  selected,
  options,
  onSelect,
  onLoading,
  onOpenChange,
  label,
  disabled,
  leftAlign,
  openUp,
  customWidth,
  totalSize = 99999,
  outline = true
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [items, setItems] = useState([]);
  const [nextPageToken, setNextPageToken] = useState('');
  const [searchingBy, setSearchingBy] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const infiniteLoaderRef = useRef(null);
  const itemStatusMap = useRef({});
  const LOADING = 1;
  const LOADED = 2;
  const BATCH_SIZE = 40;

  useEffect(() => {
    setItems(options);
  }, [options]);

  useEffect(() => {
    setValue(selected);
    setSearchingBy('');
  }, [selected]);

  const toggleOpen = () => {
    if (onOpenChange) {
      onOpenChange(!isOpen);
    }
		setIsOpen(!isOpen);
  };

  const handleSearch = useDebouncedCallback((term) => {
    // Clean up items cache and status everytime searchingBy changes
    setNextPageToken('');
    setSearchingBy(term);
    if (infiniteLoaderRef.current) {
      infiniteLoaderRef.current.resetloadMoreItemsCache();
    }
    itemStatusMap.current = {};

    paginationUtils.setItemsStatus(0, BATCH_SIZE, itemStatusMap.current, LOADING);
    setIsLoading(true);

    // Perform fetch on parent component
    onLoading('', term).then(newPageToken => {
      paginationUtils.setItemsStatus(0, BATCH_SIZE, itemStatusMap.current, LOADED);
      setIsLoading(false);
      setNextPageToken(newPageToken);
    });
  }, 300);

  const handleSelection = (data) => {
    // Close menu and update component render
    toggleOpen();
    setValue(data);
    setSearchingBy('');
		onSelect(data.value);
  };

  const loadMoreItems = (startIndex, stopIndex) => {
    paginationUtils.setItemsStatus(startIndex, stopIndex, itemStatusMap.current, LOADING);
    setIsLoading(true);

    // Perform fetch on parent component
    onLoading(nextPageToken, searchingBy).then(newPageToken => {
      paginationUtils.setItemsStatus(startIndex, stopIndex, itemStatusMap.current, LOADED);
      setIsLoading(false);
      setNextPageToken(newPageToken);
    });
  };

  const renderLabel = () => {
    return (label && label.length > 0)
      ? <div className="dropdown-label">{ label }</div>
      : null;
  };

  const renderRow = ({index, style}) => {
    if (itemStatusMap.current[index] !== LOADED) {
      return <div className="empty-message">Loading...</div>
    }
    return (
      <div className={`dropdown-search-list-item`} style={style} onClick={() => handleSelection(items[index])} >
        {items[index].label}
      </div>
    )};

  const renderEmptyMessage = () => {
    if (items.length > 0) {
      return null;
    }

    return (
      <div className="empty-message">No options</div>
    )
  };

  const renderLoading = () => {
    if (!isLoading) {
      return <div className="empty-message" />;
    }

    return (
      <div className="empty-message">
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
      </div>
    )
  };

  const renderValue = (value) => {
    if (!value) return value;
    return value.length > 30 ? `${value.slice(0, 22)}...${value.slice(-5)}` : value;
  };

  return (
    <div className={`dropdown-search ${outline ? "outline": ""} ${leftAlign ? "left-align": ""} ${openUp ? "open-up" : ""}`}>
      { renderLabel() }
      <Dropdown
        customWidth={customWidth}
        isOpen={isOpen}
        onClose={toggleOpen}
        target={
          <Button onClick={toggleOpen} active={isOpen} disabled={disabled}>
            <span className="current-value" alt={value ? value.label : ''}>
              { value ? renderValue(value.label) : 'Select' }
            </span>
            <img src={icons.angleDown} className="arrow-down" alt="arrow-down"/>
          </Button>
        } >
        <SearchBox
          onChange={handleSearch}
          placeholder="Search..."
          className="outline dropdown-list-search-box"
          autoFocus/>
        <InfiniteLoader
          ref={infiniteLoaderRef}
          isItemLoaded={params => paginationUtils.isRowLoaded(params, itemStatusMap.current)}
          itemCount={totalSize}
          minimumBatchSize={BATCH_SIZE}
          threshold={5}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered, ref }) => (
            <List
              ref={ref}
              className={`dropdown-search-list ${items.length === 0 ? 'empty' : ''}`}
              height={200}
              itemCount={items.length}
              itemSize={48}
              onItemsRendered={onItemsRendered}
              width={customWidth ? customWidth : 332}
            >
              {renderRow}
            </List>
          )}
        </InfiniteLoader>
        { renderLoading() }
        { renderEmptyMessage() }
      </Dropdown>
    </div>
  );
};

export default InfinityLoader;