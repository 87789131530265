import React, { useState } from 'react';
import CustomInput from '../../../components/customInput';
import utils from '../../../utils/site';

const Integrations = ({ site, updateSite }) => {
  const [mappings, setMappings] = useState(utils.populateMappingsFields(site));

  const updateMappingField = (field, value) => {
    const newMappings = { ...mappings, [field]: value };
    setMappings(newMappings);
    updateSite({ ...site, mappings: newMappings });
  };

  return (
    <>
      <h4 className="section-label">Configure site integration</h4>
      {site.siteGroup.integration && (
        <div className="row integration-fields">
          {site.siteGroup.integration.mappingMetadataFields.map((field, index) => {
            const fieldName = field.replaceAll(' ', '_').toLowerCase();
            return (
              <div className="form-group col-6" key={fieldName}>
                <label>{field}</label>
                  <CustomInput
                  id={`${fieldName}-${index}`}
                  placeholder={field}
                  isEditable={true}
                  value={mappings[fieldName]}
                  onChange={e => updateMappingField(fieldName, e.target.value)}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Integrations;
