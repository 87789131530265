import React from 'react';
import { isSameDay } from 'date-fns';

import calendarUtils from '../../utils/calendar';

import './styles.scss';

const DatePresets = ({ startDate, endDate, onDatesChange }) => {
  return (
    <div className="presets-list">
      {calendarUtils.presetsOptions.map(({ text, start, end }) => {
        const isSelected = isSameDay(start, startDate) && isSameDay(end, endDate);
        return (
          <button
            key={text}
            type="button"
            className={`PresetDateRangePicker_button ${isSelected ? 'PresetDateRangePicker_button__selected' : ''}`}
            onClick={() => onDatesChange([start, end])}
          >
            {text}
          </button>
        );
      })}
    </div>
  );
};

export default DatePresets;
