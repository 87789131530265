import React, { useEffect, useState } from 'react';
import images from '../../assets/images';
import './styles.scss';

const CheckBoxCard = ({ value, text, image, onChange, disabled, defaultState }) => {
  const [selected, setSelected] = useState(defaultState);

  useEffect(() => {
    setSelected(defaultState);
  }, [defaultState]);

  const handleClick = () => {
    setSelected(!selected);
    onChange(value);
  };

  const statClasses = `${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`;
  return (
    <div
      onClick={handleClick}
      className={`checkbox-card ${statClasses}`}>
      <div className="image-container">
        <img src={images[image]} alt={image} />
      </div>
      <div className="title-container">
        <p>{text}</p>
      </div>
    </div>
  );
};

export default CheckBoxCard;