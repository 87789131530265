import React from 'react';
import steps from './siteSteps/index.js';
import siteUtils from '../../utils/site';

const { Settings, Solutions, Locations, Devices, Integrations } = steps;

const siteSteps = (site, setSite, companyId) => {
  const siteSteps = [
    {
      title: 'Settings',
      required: true,
      content: <Settings site={site} updateSite={setSite} />,
      validation: () => {
        return site ? !siteUtils.hasErrorsCheck(site, 'settings') : false;
      }
    },
    {
      title: 'Solution',
      required: true,
      content: <Solutions site={site} updateSite={setSite} />,
      validation: () => {
        return site ? !siteUtils.hasErrorsCheck(site, 'solution') : false;
      }
    },
    {
      title: 'Location',
      required: true,
      content: <Locations site={site} updateSite={setSite} />,
      validation: () => {
        return site ? !siteUtils.hasErrorsCheck(site, 'location') : false;
      }
    },
    {
      title: 'Devices',
      modified: () => (site.devices !== undefined ? site.devices.length !== 0 : false),
      content: <Devices companyId={companyId} site={site} updateSite={setSite} />
    }
  ];
  if (site.siteGroup && site.siteGroup.integration) {
    siteSteps.push({
      title: `Integration: ${site.siteGroup.integration.cmsClient}`,
      content: <Integrations site={site} updateSite={setSite} />
    });
  }
  return siteSteps;
};

export default siteSteps;
