import React from 'react'
import { UncontrolledTooltip } from 'reactstrap';
import icons from '../../assets/icons';
import './styles.scss'

const IconTooltip = ({
  id,
  icon,
  tooltipText,
  placement = 'left',
  height = 20,
  width = 20
  }) => {

  return (
  <span>
    <img id={`icon-${id}`} className="icon-tooltip" src={icons[icon]} alt="Info icon" height={height} width={width}/>
    <UncontrolledTooltip placement={placement} target={`#icon-${id}`}>
      {tooltipText}
    </UncontrolledTooltip>
  </span>
  )
}

export default IconTooltip;