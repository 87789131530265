import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import utils from '../../utils';
import icons from '../../assets/icons';
import './styles.scss';

class MultisectionDropdown extends Component {
  constructor (props) {
    super(props);
    this.state = {
      iconSelected: '',
      selectedIndex: 0,
      selectedSection: 0,
      dropdownOpen: false
    };
  }

  componentDidMount() {
    const { items, valueField, selected, multipleSections, selectedItem } = this.props;

    if (selected) {
      let itemFound;
      if (valueField) {
        itemFound = items.findIndex((item) => item[valueField].toUpperCase() === selected.toUpperCase());
      } else {
        itemFound = items.findIndex((item) => item.toUpperCase() === selected.toUpperCase());
      }
      this.setState({ selectedIndex: itemFound });
    }

    if (!multipleSections) {
      const { iconSelected } = this.state;
      if (selectedItem) {
        this.setState({ iconSelected: valueField ? selectedItem[valueField].toUpperCase() : selectedItem.toUpperCase() })
      } else if (items[0] && iconSelected === '') {
        this.setState({
          iconSelected: valueField ? items[0][valueField].toUpperCase() : items[0].toUpperCase()
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { items, valueField, selected } = this.props;

    if (items.length === 0) return;

    if (prevProps.selected !== selected) {
      let itemFound;
      if (valueField) {
        itemFound = items.findIndex((item) => item[valueField].toUpperCase() === selected.toUpperCase());
      } else {
        itemFound = items.findIndex((item) => item.toUpperCase() === selected.toUpperCase());
      }

      if (itemFound === -1) {
        itemFound = 0;
      }

      this.setState({
        selectedIndex: itemFound,
        iconSelected: valueField ? items[itemFound][valueField].toUpperCase() : items[0].toUpperCase()
      });
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  handleSelection = (event, index, section) => {
    const { displayField, multipleSections } = this.props;
    if (multipleSections) {
      this.setState({
        iconSelected: displayField ? event[displayField] : event,
        selectedIndex: index,
        selectedSection: section
      });
      this.props.handleSelection(event, index);
    } else {
      this.setState({
        iconSelected: event.target.value.toUpperCase(),
        selectedIndex: index
      });
      this.props.handleSelection(event, index);
    }

  }

  hasLabel = () => {
    const { label } = this.props;
    return (label && label.length > 0) ? true : false;
  }

  renderSections = () => {
    const { items, selectedItem, valueField, displayField } = this.props;
    const currentItem = selectedItem ? selectedItem : null;

    return (<DropdownMenu>
      { items &&
        items.map((section, sectionIndex) => (
          <div className="dropdown-submenu" key={`category_${sectionIndex}_${section}`}>
            <span className="dropdown-header" key={section.category}>
              { utils.capitalize(section.category) }
            </span>
            {
              section.values.map((item, valIndex) => {
                let active = false;
                if (currentItem) {
                  active = valueField ? currentItem[valueField] === item[valueField] : currentItem === item;
                }
                return (
                <DropdownItem
                key={`button_${sectionIndex}_${valIndex}_${item}`}
                  onClick={() => this.handleSelection(item, valIndex, sectionIndex)}
                  active={active}>
                  { displayField ? item[displayField] : item }
                </DropdownItem>
              )})
            }
          </div>
        ))
      }
    </DropdownMenu>);
  }

  renderItems = () => {
    const { items, selectedItem, valueField, displayField, selected } = this.props;

    // Prevent selected index to be out of bound
    let selIndex = this.state.selectedIndex >= 0 ? this.state.selectedIndex : 0;
    selIndex = (selIndex >= items.length) ? 0 : selIndex;
    const firstItem = items ? items[selIndex] : null;
    const currentItem = selectedItem ? selectedItem : firstItem;

    return (<DropdownMenu>
      {
        items && items.map((item, index) => {
          // Test value field to try to get current selected value
          const currentSelectedValue = (valueField
            ? currentItem[valueField] === item[valueField]
            : currentItem === item
          );

          // Active state should rely on selected items
          const isActive = (selected || selectedItem) && currentSelectedValue;
          return (
            <DropdownItem
              key={index}
              value={valueField ? item[valueField] : item}
              onClick={event => this.handleSelection(event, index)}
              active={isActive}>
              { displayField ? item[displayField] : item }
            </DropdownItem>
          )
        })
      }
    </DropdownMenu>);
  }

  render () {
    const { items,
      selectedItem,
      displayField,
      hasIcon,
      label,
      disabled,
      multipleSections,
      nullable,
      staticTitle,
      outline = true
    } = this.props;
    const { iconSelected, selectedIndex, dropdownOpen } = this.state;
    const firstItem = items ? items[selectedIndex] : null;

    let currentItem = selectedItem ? selectedItem : firstItem;
    if (nullable) {
      currentItem = selectedItem ? selectedItem : null;
    }

    let currentLabel = '--';
    if (currentItem) {
      currentLabel = (displayField ? currentItem[displayField] : currentItem);
    }

    return (
      <div className={`custom-dropdown ${outline ? "outline" : ""}`}>
        { this.hasLabel() && <div className="dropdown-label">{ label }</div> }
        <Dropdown
          disabled={disabled}
          className={`dropdown-list-container ${disabled ? "disabled" : ""}`}
          isOpen={dropdownOpen}
          toggle={this.toggle}>
          <DropdownToggle className="dropdown-list">
            <div className="current-value">
              { hasIcon &&
                <img src={icons[iconSelected]} alt="" height="25"/>
              }
              <span>{ staticTitle ? staticTitle : currentLabel }</span>
            </div>
            <img src={icons.angleDown} className="arrow-down" alt="arrow-down"/>
          </DropdownToggle>
          { multipleSections ? this.renderSections() : this.renderItems() }
        </Dropdown>
      </div>
    );
  }
}

export default MultisectionDropdown;