import config from '../../config';
import API from '../api';

//#region Types
/**
 * @typedef DeviceComment
 * @type {Object}
 * @property {string} createTime - Creation time of the comment.
 * @property {string} id - ID of the comment.
 * @property {string} text - Text of the comment.
 */

/**
 * @typedef DeviceState
 * @type {Object}
 * @property {string} admproviderVersion - ADM provider version.
 * @property {string} malosVersion - Malos version.
 * @property {boolean} managed - Indicates if the device is managed.
 * @property {string} platform - Platform of the device.
 * @property {string} status - Status of the device.
 * @property {string} updateTime - Last update time of the device state.
 */

/**
 * @typedef DeviceConfiguration
 * @type {Object}
 * @property {string} companyId - Company ID associated with the configuration.
 * @property {Object} customConfig - Custom configuration for the device.
 * @property {Object} defaultConfig - Default configuration for the device.
 * @property {string} defaultSolutionConfigId - Default solution configuration ID.
 * @property {string} deviceId - Device ID associated with the configuration.
 * @property {string} name - Name of the configuration.
 * @property {Object} solutionConfig - Solution configuration for the device.
 */

/**
 * @typedef Device
 * @type {Object}
 * @property {boolean} archived - Indicates if the device is archived.
 * @property {string[]} attachedSites - Array of attached sites.
 * @property {string} companyId - Company ID associated with the device.
 * @property {string} createTime - Creation time of the device.
 * @property {string} creatorId - Creator ID of the device.
 * @property {DeviceComment[]} deviceComments - Array of comments associated with the device.
 * @property {string} displayName - Display name of the device.
 * @property {boolean} enableAutoUpdate - Indicates if auto-update is enabled.
 * @property {boolean} hasWifi - Indicates if the device has WiFi.
 * @property {string} heatmapImageUrl - URL of the heatmap image.
 * @property {string} id - ID of the device.
 * @property {("INTEGRATION_NAME_UNSPECIFIED" | "BRIGHTAUTHORFACEV0" | "AYUDAFACEV1" | "BROADSIGNFACEV1" | "SIGNAGELIVEFACEV1" | "BRIGHTAUTHORFACEV1" | "BROADSIGNVEHICLEV1" | "SCALAFACEV0" | "HIVESTACKFACEV1"[])} integrations - Array of enabled integrations for the device.
 * @property {Object.<string, string>} labels - Labels associated with the device.
 * @property {string} name - Name of the device.
 * @property {string} projectId - Project ID associated with the device.
 * @property {string} provisioningToken - Provisioning token for the device.
 * @property {boolean} provisioningTokenWasUsed - Indicates if the provisioning token was used.
 * @property {"SOLUTION_NAME_UNSPECIFIED" | "VEHICLERECOGNITIONV1" | "FACEV2" | "VEHICLECROWD" | "CROWDV3" | "VEHICLEDETECTIONV1"} solution - Solution associated with the device.
 * @property {DeviceState} state - State of the device.
 * @property {string[]} tags - Tags associated with the device.
 * @property {string} updateTime - Last update time of the device.
 */

//#endregion

//#region CREATE
/**
 * Creates a new device.
 * @param {string} cid - Company ID.
 * @param {Device} device - Device to be created.
 * @returns {Device} Created device.
 */
const createDevice = async (cid, device) => {
  await API.refreshTokenWhenNeeded();
  const url = `${config.coreApi.baseUrl}/companies/${cid}/devices`;
  return fetch(url, {
    method: 'POST',
    headers: API.headers,
    body: JSON.stringify(device)
  }).then(res => res.json());
}

/**
 * Creates a new device comment.
 * @param {string} cid - Company ID.
 * @param {string} did - Device ID.
 * @param {string} text - Comment to be created.
 * @returns {Device} Created device.
 */
const createDeviceComment = async (cid, did, text) => {
  await API.refreshTokenWhenNeeded();
  const url = `${config.coreApi.baseUrl}/companies/${cid}/devices/${did}/comments`;
  return fetch(url, {
    method: 'POST',
    headers: API.headers,
    body: JSON.stringify({comment: {text}})
  }).then(res => res.json());
}

/**
 * Archives a single device.
 * @param {string} cid - Company ID.
 * @param {string} did - Device ID.
 * @returns {Device} Archived device.
 */
const archiveDevice = async (cid, did) => {
  await API.refreshTokenWhenNeeded();
  const url = `${config.coreApi.baseUrl}/companies/${cid}/devices/${did}:archive`;
  return fetch(url, {
    method: 'POST',
    headers: API.headers
  }).then(res => res.json());
}

/**
 * Dearchives a single device.
 * @param {string} cid - Company ID.
 * @param {string} did - Device ID.
 * @returns {Device} Dearchived device.
 */
const dearchiveDevice = async (cid, did) => {
  await API.refreshTokenWhenNeeded();
  const url = `${config.coreApi.baseUrl}/companies/${cid}/devices/${did}:dearchive`;
  return fetch(url, {
    method: 'POST',
    headers: API.headers
  }).then(res => res.json());
}
//#endregion

//#region READ
/**
 * List existing devices.
 * @param {string} cid - Company ID.
 * @param {string} page - page token (first page is defined as empty string).
 * @param {string} filter - Filter expression.
 * @param {string} orderBy - Order expression.
 * @returns {Device[]} devices
 */
const getDevices = async (cid, page = '', filter = '', orderBy = 'displayName, id') => {
  await API.refreshTokenWhenNeeded();
  const url = `${config.coreApi.baseUrl}/companies/${cid}/devices?pageSize=40&pageToken=${page}&filter=${filter}&orderBy=${orderBy}`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
}

/**
 * Gets a single device.
 * @param {string} cid - Company ID.
 * @param {string} did - Device ID.
 * @param {string} page - page token (first page is defined as empty string).
 * @param {string} filter - Filter expression.
 * @param {string} orderBy - Order expression.
 * @returns {Device} device
 */
const getDevice = async (cid, did, page = '', filter = '', orderBy = 'displayName, id') => {
  await API.refreshTokenWhenNeeded();
  const url = `${config.coreApi.baseUrl}/companies/${cid}/devices/${did}?pageSize=40&pageToken=${page}&filter=${filter}&orderBy=${orderBy}`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
}

/**
 * Gets the device's config.
 * @param {string} cid - Company ID.
 * @param {string} did - Device ID.
 * @param {string} customHeaders - custom API headers in case of impersonating user
 * @returns {DeviceConfiguration} device configuration.
 */
const getDeviceConfig = async (cid, did, customHeaders) => {
  const requestHeader = customHeaders ? customHeaders : API.headers;
  await API.refreshTokenWhenNeeded();
  const url = `${config.coreApi.baseUrl}/companies/${cid}/devices/${did}/configuration`;
  return fetch(url, {
    headers: requestHeader,
    method: 'GET'
  }).then(res => API.generateResponse(res));
}

/**
 * @param {string} cid - Company ID.
 * @param {string} did - Device ID. 
 * @param {('start'|'stop'|'restart'|'preview')} command - command sent to API.
 * @returns {{}}
 */
const sendCommand = async (cid, did, command) => {
  await API.refreshTokenWhenNeeded();

  const commandsMapping = {
    restart: 'restartDetection',
    start: 'startDetection',
    stop: 'stopDetection',
    preview: 'getPreviewImage'
  }

  const url = `${config.coreApi.baseUrl}/companies/${cid}/devices/${did}:${commandsMapping[command]}`;
  
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  })
  .then(res => API.generateResponse(res))
  .then(res => {
    if (res.status && (res.status === 'failed' || res.status === 'timeout')) {
      throw res.answer;
    }
    return res;
  });
}
//#endregion

//#region UPDATE
/**
 * Provisions a single device
 * @param {string} token - provisioning Token.
 * @returns {{credentials: {certificatePem: string, privateKey: string, publicKey: string}}} devices
 */
const provisionDevice = async (token) => {
  await API.refreshTokenWhenNeeded();
  const url = `/companies/-/devices:provision`;
  return fetch(url, {
    headers: API.headers,
    method: 'PATCH',
    body: JSON.stringify({ provisioningToken: token })
  }).then(res => API.generateResponse(res));
}

/**
 * Updates a device.
 * @param {string} cid - Company ID.
 * @param {string} device - Device being updated.
 * @param {string[]} fields - Company fields being updated. Comma separated.
 * @returns {Device} Device updated.
 */
const updateDevice = async (cid, device, fields) => {
  await API.refreshTokenWhenNeeded();
  
  const paths = `updateMask=${fields.join(',')}`;
  const body = {};

  fields.forEach(field => {
    body[field] = device[field];
  });
  
  const url = `${config.coreApi.baseUrl}/companies/${cid}/devices/${device.id}?${paths}`;

  return fetch(url, {
    method: 'PATCH',
    headers: API.headers,
    body: JSON.stringify(body)
  }).then(res => res.json());
}

/**
 * Updates the device's config.
 * @param {string} cid - Company ID.
 * @param {string} did - Device ID.
 * @param {string} configutarion - Device configuration.
 * @param {string[]} fields - Company fields being updated. Comma separated.
 * @returns {Device} Device updated.
 */
const updateDeviceConfig = async (cid, did, configuration, fields) => {
  await API.refreshTokenWhenNeeded();
  
  const paths = `updateMask=${fields.join(',')}`;
  const body = {};
  
  fields.forEach(field => {
    body[field] = configuration[field];
  });

  const url = `${config.coreApi.baseUrl}/companies/${cid}/devices/${did}/configuration?${paths}`;

  return fetch(url, {
    method: 'PATCH',
    headers: API.headers,
    body: JSON.stringify(body)
  }).then(res => res.json());
}
//#endregion

//#region DELETE
/**
 * Deletes a single device.
 * @param {string} cid - Company ID.
 * @param {string} did - Device ID.
 * @returns {{}}
 */
const deleteDevice = async (cid, did) => {
  await API.refreshTokenWhenNeeded();
  const url = `${config.coreApi.baseUrl}/companies/${cid}/devices/${did}`;
  return fetch(url, {
    headers: API.headers,
    method: 'DELETE'
  }).then(res => API.generateResponse(res));
}

/**
 * Deletes a device comment.
 * @param {string} cid - Company ID.
 * @param {string} did - Device ID.
 * @param {string} cmid - Comment ID.
 * @returns {{}}
 */
const deleteDeviceComment = async (cid, did,cmid) => {
  await API.refreshTokenWhenNeeded();
  const url = `${config.coreApi.baseUrl}/companies/${cid}/devices/${did}/comments/${cmid}`;
  return fetch(url, {
    headers: API.headers,
    method: 'DELETE'
  }).then(res => API.generateResponse(res));
}
//#endregion

export { 
  createDevice,
  createDeviceComment,
  archiveDevice,
  dearchiveDevice,
  getDevices,
  getDevice,
  getDeviceConfig,
  sendCommand,
  provisionDevice,
  updateDevice,
  updateDeviceConfig,
  deleteDevice,
  deleteDeviceComment
}