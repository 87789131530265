import React from 'react';

import DatePresets from './datePresets';
import CalendarFilters from './calendarFilters';
import PanelControl from './panelControl';

import './styles.scss';

const CalendarInfo = ({
  startTime,
  endTime,
  startDate,
  endDate,
  weekDays,
  isWeekDaysEnabled,
  isTimeEnabled,
  applyChanges,
  setStartTime,
  setEndTime,
  setTimeEnabled,
  errors,
  setErrors,
  setWeekDays,
  setWeekDaysEnabled,
  setCalendarOpen,
  onDatesChange
}) => {
  return (
    <div className="info-panel">
      <DatePresets startDate={startDate} endDate={endDate} onDatesChange={onDatesChange} />
      <div className="calendar-footer">
        <CalendarFilters
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          setEndTime={setEndTime}
          isTimeEnabled={isTimeEnabled}
          setTimeEnabled={setTimeEnabled}
          errors={errors}
          setErrors={setErrors}
          weekDays={weekDays}
          setWeekDays={setWeekDays}
          isWeekDaysEnabled={isWeekDaysEnabled}
          setWeekDaysEnabled={setWeekDaysEnabled}
        />
        <PanelControl
          startTime={startTime}
          endTime={endTime}
          startDate={startDate}
          endDate={endDate}
          weekDays={weekDays}
          isWeekDaysEnabled={isWeekDaysEnabled}
          isTimeEnabled={isTimeEnabled}
          setCalendarOpen={setCalendarOpen}
          applyChanges={applyChanges}
        />
      </div>
    </div>
  );
};

export default CalendarInfo;
