import React, {useState, useEffect} from 'react';
import CustomTag from '../customTag';

import './styles.scss';

const ChipTags = ({tags, isEditable, onChange}) => {
  const [currentTags, setCurrentTags] = useState(tags);
  const [newTag, setNewTag] = useState('');

  useEffect(() => {
    setCurrentTags(tags);
  }, [tags]);

  const onTagClose = (tag) => {
    const newTags = currentTags.filter(currentTag => currentTag.value !== tag.value);
    setCurrentTags(newTags);
    onChange(newTags.map(currentTag => currentTag.value));
  };

  //Create a new tag using space
  const updateNewTag = (value) => {
    let newTagCopy = value;
    setNewTag(newTagCopy);

    if (newTagCopy.includes(' ')) {
      //Check if its a new tag and clear input for a new tag
      if (newTagCopy.trim().length > 0) {
        const newTags = [...currentTags, {value: newTagCopy.trim()}];
        setCurrentTags(newTags);
        setNewTag('');
        onChange(newTags.map(tag => tag.value));
      }
      //Force empty string if starts with space
      else setNewTag('');
    }
  };

  //Create a new tag using Enter
  const createNewTag = (e) => {
    if (e.key === 'Enter' && newTag.length > 0 ) {
      const newTags = [...currentTags, {value: newTag}];
      setCurrentTags(newTags);
      onChange(newTags.map(tag => tag.value));
      setNewTag('');
    }
  }

  return (
    <div className={`chiptags${isEditable ? '' : ' disabled'}`}>
      {tags && currentTags.map((tag,index) => {
        return <CustomTag
          key={`tag-${index}`}
          label={tag.label}
          value={tag.value}
          onClose={onTagClose}
          disabled={!isEditable}
        />
      })}
      {isEditable &&
        <input
          type='text'
          onChange={e => updateNewTag(e.target.value)}
          onKeyDown={createNewTag}
          placeholder='Add...'
          value={newTag}
        />}
    </div>
  );
}

export default ChipTags;