import React from 'react';
import CustomCheckBox from '../customCheckBox';

import './styles.scss';

export default function Tbody({
  page,
  actionButtons,

  hasActionButtons,
  hasBulkActionButtons,
  isSelected,
  loading,
  rowOnFocus,
  hasInteractiveRows,

  setRowOnFocus,
  getTableBodyProps,
  prepareRow,
  onItemSelected,
  toggleAllRowsSelected,
  toggleSelection
}) {
  const getTrClasses = (row) => {
    return `${hasInteractiveRows ? 'interactive-row' : ''} ${row.id === rowOnFocus ? 'selected' : ''}`;
  };

  return (
    <tbody {...getTableBodyProps()}>
      { loading
        ? (
          <tr className="loading-message">
            <td>
              <span>Loading...</span>
            </td>
          </tr>
        ) : (
          page.map((row, indexRow) => {
            prepareRow(row);
            return (
              <tr
                key={`tr-${indexRow}`}
                {...row.getRowProps()}
                className={getTrClasses(row)}
                onClick={() => {
                  if (!row.isSelected) {
                    onItemSelected(row.original);
                    toggleAllRowsSelected(false);
                    row.toggleRowSelected(true);
                    setRowOnFocus(row.id);
                  }
                }}>
                { row.cells.map((cell, indexCell) => (
                  <td key={`td-${indexCell}`} {...cell.getCellProps()}>
                    <div className={`${indexCell === 0 ? 'cell-wrapper' : ''}`}>
                      { (hasBulkActionButtons && indexCell === 0) &&
                        <CustomCheckBox
                          selected={isSelected(row.id) ? 'all' : 'none'}
                          onClick={(value) => toggleSelection(row.id, value)} />
                      }
                      { cell.render('Cell') }
                    </div>
                  </td>
                )) }
                { hasActionButtons &&
                  <td className="action-buttons">
                    { actionButtons(row.original) }
                  </td>
                }
              </tr>
            );
          })
        )
      }
    </tbody>
  );
}