import API from '../../services/api';

export const discoverUserPermission = async (user, project) => {
  const result = await Promise.all([
    API.testPermission('users', user.id, constraints.support),
    API.testPermission('projects', project.id, constraints.dashboard),
    API.testPermission('projects', project.id, constraints.advertiser)
  ]);

  const adminPermissions = result[0];
  const dashboardPermissions = result[1];
  const advertiserPermissions = result[2];

  let permissions = {};

  if (adminPermissions.allAllowed) {
    permissions = { support: true };
  } else if (!dashboardPermissions.allAllowed && advertiserPermissions.allAllowed) {
    permissions = { advertiser: true };
  } else {
    permissions = { dashboard: true };
  }

  return { ...permissions };
};

export const constraints = {
  dashboard: 'devicemanagementapi.projects.update',
  support: 'authapi.accounts.impersonate',
  advertiser: [
    'iam.permissions.get',
    'iam.permissions.list',
    'iam.roles.get',
    'iam.roles.list',
    'iam.policies.get',
    'queryapi.datasets.queryTable',
    'queryapi.datasets.listTables',
    'queryapi.datasets.getTableSchema',
    'queryapi.datasets.getTableReport',
    'queryapi.jobs.create',
    'queryapi.jobs.get',
    'queryapi.jobs.getResults',
    'queryapi.jobs.exportResults',
    'queryapi.datasets.create',
    'authapi.accounts.update',
    'authapi.accounts.show',
    'iam.policies.getResources',
    'iam.ancestors.listResource',
    'queryapi.datasets.getTableData',
    'queryapi.datasets.listTableReport',
    'queryapi.datasets.pauseTableReport',
    'queryapi.datasets.restartTableReport',
    'queryapi.datasets.cancelTableReport',
    'cmsintegrationsapi.integrations.list',
    'cmsintegrationsapi.reports.create',
    'devicemanagementapi.devices.list'
  ]
};

export const roles = [
  { id: 'admobilize.dashboardDataViewer', name: 'Data Viewer' },
  { id: 'admobilize.dashboardUser', name: 'Dashboard User' }
];

export const getRoleName = role => {
  const roleFound = roles.find(innerRole => innerRole.id === role);
  return roleFound ? roleFound.name : 'unknown';
};
