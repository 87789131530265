import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import store from './redux/store';

import * as Sentry from '@sentry/browser';

import App from './App';
import config from './config';

import { defineCustomElements, applyPolyfills } from '@admobilize/widgets/loader';
import * as serviceWorker from './serviceWorker';

const { hostname } = window.location;
const isLocalEnv = hostname === 'localhost' || hostname === '127.0.0.1';

// Setup Monitoring for production env only
if (process.env.REACT_APP_STAGE === 'prod' && !isLocalEnv) {
  Sentry.init({
    dsn: config.sentry.dsn,
    release: `${config.appVersion} #${config.commitHash}`
  });
}

createRoot(document.getElementById('root')).render(
  <HashRouter>
    <Provider store={store}>
      <App isLocalEnv={isLocalEnv} />
    </Provider>
  </HashRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
applyPolyfills().then(() => {
  defineCustomElements(window);
});

serviceWorker.unregister();
