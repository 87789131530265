import React, { useState, useEffect, useCallback } from 'react';
import CustomInput from '../customInput';
import CustomDropdown from '../customDropdown';
import utils from "../../utils";

const ZoneInput = ({ zone, updateZone, namesUsed, roisName, onChange }) => {

  const [x, setX] = useState("");
  const [y, setY] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [name, setName] = useState("");
  const [rotation, setRotation] = useState("");
  const [real_height, setRealHeight] = useState("");
  const [roiRef, setRoiRef] = useState("");
  const [errors, setErrors] = useState({});

  const hasChanged = useCallback(onChange);

  useEffect(() => {
    if (zone) {
      setInitValues(zone);
      zone.on('dragend transformend', () => {
        setInitValues(zone);
        hasChanged();
      });
    }
  }, [zone, hasChanged]);


  const setInitValues = (zone) => {
    const heightFinal = (zone.height() * zone.scaleY());
    const widthFinal = (zone.width() * zone.scaleX());

    setX(parseInt(zone.x()));
    setY(parseInt(zone.y()));
    setWidth(parseInt(widthFinal));
    setHeight(parseInt(heightFinal));
    setRealHeight(zone.real_height);
    setName(zone.name());
    setRoiRef(zone.roi_ref);
    setRotation(zone.rotation());
  }

  const updateVariable = (value, key, cb) => {
    if(value !== "" && key === 'real_height') {
      value = parseFloat(value);
    } else if (value !== "" && key !== 'name' && key !== 'roi_ref') {
      value = parseInt(value);
    }
    cb(value);
    let newValue = {
      oldName: zone.name()
    };
    const validations = {
      'name': [{ key: 'required', value: true }, { key: 'unique', value: namesUsed }],
      'x': [{ key: 'required', value: true }, { key: 'min', value: 0 }],
      'y': [{ key: 'required', value: true }, { key: 'min', value: 0 }],
      'width': [{ key: 'required', value: true }, { key: 'min', value: 0 }],
      'height': [{ key: 'required', value: true }, { key: 'min', value: 0 }],
      'rotation': [{ key: 'required', value: true }, { key: 'min', value: 0 }],
      'real_height': [{ key: 'required', value: true }, { key: 'min', value: 0 }, { key: 'max', value: 1 }],
      'roi_ref': [{ key: 'required', value: true }],
    }

    const errorFound = utils.fieldValidation(value, validations[key]);
    if (errorFound.length === 0) {
      newValue[key] = value;
      updateZone(newValue);
    }
    setErrors({ ...errors, [key]: errorFound.join('. ') })
  };

  return (
    <div id='zone-settings-form'>
      <div className="row">
        <h3 className="section-header">Zone Settings</h3>
      </div>
      <div className="zone-form-container">
        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <label>Name</label>
              <CustomInput
                id="zone-name"
                type="text"
                value={name}
                isEditable={true}
                onChange={e => updateVariable(e.target.value, 'name', setName)}
                errorMessage={errors['name']} />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label>X</label>
              <CustomInput
                id="zone-x"
                type="number"
                value={x}
                isEditable={true}
                onChange={e => updateVariable(e.target.value, 'x', setX)}
                errorMessage={errors['x']} />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label>Y</label>
              <CustomInput
                id="zone-y"
                type="number"
                value={y}
                isEditable={true}
                onChange={e => updateVariable(e.target.value, 'y', setY)}
                errorMessage={errors['y']} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label>Width</label>
              <CustomInput
                id="zone-width"
                type="number"
                value={width}
                isEditable={true}
                onChange={e => updateVariable(e.target.value, 'width', setWidth)}
                errorMessage={errors['width']} />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>Height</label>
              <CustomInput
                id="zone-height"
                type="number"
                value={height}
                isEditable={false}
                onChange={e => updateVariable(e.target.value, 'height', setHeight)}
                errorMessage={errors['height']} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <label>Rotation</label>
              <CustomInput
                id="zone-rotation"
                type="number"
                value={rotation}
                isEditable={true}
                onChange={e => updateVariable(e.target.value, 'rotation', setRotation)}
                errorMessage={errors['rotation']} />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label>Real Height</label>
              <CustomInput
                id="zone-real-height"
                type="number"
                value={real_height}
                step="0.1"
                min="0"
                max="1"
                isEditable={true}
                onChange={e => updateVariable(e.target.value, 'real_height', setRealHeight)}
                errorMessage={errors['real_height']} />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label>ROI</label>
              { roiRef && <CustomDropdown
                items={roisName}
                handleSelection={event => updateVariable(event.target.value, 'roi_ref', setRoiRef)}
                selectedItem={roiRef}
                valueField={'name'}
                displayField={'name'} />
                }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ZoneInput;
