import type from '../types';

const initialState = {
  user: null,
  loading: true,
  featureFlags: {},
  alert: {
    visible: false,
    message: "We couldn't load your devices. Please refresh the page.",
    type: 'error',
    color: 'danger',
    duration: 0,
    callToAction: null,
    hideType: false
  }
};

const dashboardReducer = (state = initialState, action) => {
  const {
    SET_USER,
    SET_ALERT,
    SET_LOADING,
    UPDATE_USER,
    UPDATE_USER_PREFERENCES,
    SET_FEATURE_FLAGS,
    DISMISS_ALERT,
    CLEAR_USER
  } = type;

  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...{
          user: action.payload
        }
      };
    case UPDATE_USER:
      return {...state, ...{
        user: action.payload
      }};
    case UPDATE_USER_PREFERENCES:
      return {
        ...state,
        ...{ user: {
          ...state.user,
          preferences: action.payload
        }}
      };
    case SET_ALERT:
      return {
        ...state,
        ...{
          alert: action.payload
        }
      };
    case DISMISS_ALERT:
      return {
        ...state,
        ...{
          alert: { ...state.alert, visible: false }
        }
      };
    case SET_LOADING:
      return {
        ...state,
        ...{
          loading: action.payload
        }
      };
    case CLEAR_USER:
      return {
        ...state,
        ...{
          user: null
        }
      };
    case SET_FEATURE_FLAGS:
      return {
        ...state,
        ...{
          featureFlags: action.payload
        }
      };

    default:
      return state;
  }
};

export default dashboardReducer;
