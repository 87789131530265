import config from '../../config';
import API from '../api';

//#region Types
/**
 * @typedef Credential
 * @type {Object}
 * @property {string} companyId - ID of the company associated with the credential.
 * @property {string} createTime - Creation time of the credential.
 * @property {string} creatorId - ID of the creator of the credential.
 * @property {Object} credential - Credential details.
 * @property {string} credential.clientSpecificCredentials - Client-specific credentials.
 * @property {string} displayName - Display name of the credential.
 * @property {string} id - ID of the credential.
 * @property {string} name - Name of the credential.
 * @property {number} numMappings - Number of mappings associated with the credential.
 * @property {string} projectId - ID of the project associated with the credential.
 * @property {string} updateTime - Update time of the credential.
 */

/**
 * @typedef Integration
 * @type {Object}
 * @property {string} cmsClient - CMS client associated with the integration.
 * @property {string} id - ID of the integration.
 * @property {string} name - Name of the integration.
 * @property {string} playlogsTableId - ID of the playlogs table associated with the integration.
 * @property {string} reportTableId - ID of the report table associated with the integration.
 * @property {string} sourceTableId - ID of the source table associated with the integration.
 * @property {string} mappingPlayerIdFmt - Format for mapping player IDs.
 * @property {string[]} mappingMetadataFields - Metadata fields used in mapping.
 * @property {string[]} credentialFields - Fields required for credentials.
 * @property {string} createTime - Creation time of the integration.
 * @property {string} updateTime - Update time of the integration.
 */

//#endregion

/**
 * Lists existing CMS credentials.
 * @param {string} cid - Company ID.
 * @param {string} page - page token (first page is defined as empty string).
 * @param {string} filter - Filter expression.
 * @param {string} orderBy - Order expression.
 * @returns {{credentials: Credential[], nextPageToken: string}} List of sites.
 */
const getCredentials = async (cid,page = '', filter = '', pageSize = 40, orderBy = 'displayName, id') => {
  await API.refreshTokenWhenNeeded();
  const url = `${config.coreApi.baseUrl}companies/${cid}/cmscredentials?pageSize=${pageSize}&pageToken=${page}&filter=${filter}&orderBy=${orderBy}`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
}

/**
 * Lists existing CMS integrations.
 * @param {string} page - page token (first page is defined as empty string).
 * @param {string} filter - Filter expression.
 * @param {string} orderBy - Order expression.
 * @returns {{integrations: Integration[], nextPageToken: string}} List of integrations.
*/
const getIntegrations = async (page = '', filter = '', pageSize = 40, orderBy = 'cmsClient, id') => {
  await API.refreshTokenWhenNeeded();
  const url = `${config.coreApi.baseUrl}/cmsintegrations?pageSize=${pageSize}&pageToken=${page}&filter=${filter}&orderBy=${orderBy}`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
}

/**
 * 
 * @param {string} cid - Company dd
 * @param {Credential} credential - credential to be created
 * @returns {Credential} created credential
 */
const createCredential = async (cid, credential) => {
  await API.refreshTokenWhenNeeded();
  const url = `${config.coreApi.baseUrl}/companies/${cid}/cmscredentials`;
  return fetch(url, {
    method: 'POST',
    headers: API.headers,
    body: JSON.stringify(credential)
  }).then(res => res.json());
}

export {
  getCredentials,
  getIntegrations,
  createCredential
}