import React from 'react';
import IconButton from '../iconButton';

const ActionButtons = ({ buttons }) => (
  <>
    {buttons &&
      buttons.map(button => (
        <IconButton
          key={button.key}
          id={button.id}
          icon={button.icon}
          imgClasses={button.imgClasses}
          btnClasses={`outline`}
          onPress={button.onPress}
          tooltip={button.tooltip}
          disabled={button.disabled}
          loading={button.loading}
        />
      ))}
  </>
);

export default ActionButtons;
