import React, { useState, useEffect } from 'react';
import utils from '../../../utils';
import CustomInput from '../../../components/customInput';

const Details = ({ project, updateProject }) => {
  const [displayName, setDisplayName] = useState(project.displayName ? project.displayName : '');
  const [errors, setErrors] = useState({});

  // Validate fields
  useEffect(() => {
    const validation = {
      'project-display-name': [
        { key: 'required', value: true },
        { key: 'min', value: 4 }
      ]
    };

    const errorList = {
      'project-display-name': utils.fieldValidation(displayName, validation['project-display-name']).join(', ')
    };
    setErrors(errorList);
    updateProject({ ...project, errors: { ...project.errors, ...errorList }, displayName: displayName });
  }, [displayName]);

  return (
    <>
      <div className="form-group">
        <label>Project Name*</label>
        <CustomInput
          id="project-name"
          type="text"
          value={displayName}
          isEditable={true}
          onChange={e => {
            setDisplayName(e.target.value);
          }}
          autoFocus={true}
          errorMessage={errors['project-display-name']}
        />
      </div>
    </>
  );
};

export default Details;
