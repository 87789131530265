import Footer from '../../components/footer';
import React from 'react';
import './styles.scss';

const Authentication = ({ content }) => (
  <div className="d-flex min-vh-100 align-items-center" id="login-screen">
    <div className="left-area col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-0 min-vh-100 ">
      <div className="d-flex align-items-center min-vh-100 text-center justify-content-around flex-column">
        <div className="logo"></div>
        <div className="form-box">
          {content}
        </div>
        <div className="text-center">
          <Footer />
        </div>
      </div>
    </div>
    <div className="right-area col-md-6 d-xl-flex d-lg-flex d-md-flex d-flex d-sm-none d-none min-vh-100 p-0">
      <div className="d-flex min-vh-100 justify-content-center flex-column right-container">
        <h1 className="display-3 font-weight-bold text-white">Welcome</h1>
        <p className="caption text-white"></p>
      </div>
    </div>
  </div>
);

export default Authentication;
