import React, { useState, useEffect } from 'react';

import { useTable, useSortBy, useRowSelect, usePagination } from 'react-table';

import Thead from './thead';
import Tbody from './tbody';
import Footer from '../footer';
import Pagination from './pagination';

import './styles.scss';

export default function Table({
  columns,
  data,
  dataLabel,
  fetchData,
  pageCount,
  itemsCount,
  initialSortColumn,
  isItemDetailsClosed,
  onItemSelected, // TODO: rename to onItemFocus ?
  onDataSelected,
  searchBy,
  filterBy,
  loading,
  bulkActionButtons,
  actionButtons,
  navigation,
  hasInteractiveRows,
  solution,
  resetOnChange
}) {
  const {
    getTableProps,
    getTableBodyProps,
    toggleAllRowsSelected,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    // Get the state from the instance
    state: { pageIndex, pageSize, sortBy }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 15,
        sortBy: [{ id: initialSortColumn }]
      }, // Pass our hoisted table state
      manualPagination: true,
      pageCount,
      manualSortBy: true,
      autoResetSelectedRows: false,
      getRowId: (originalRow, relativeIndex) => (originalRow.id ? originalRow.id : relativeIndex)
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  const [rowOnFocus, setRowOnFocus] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);

  const activePage = pageIndex + 1;
  const inferiorLimit = activePage === 1 ? 1 : (activePage - 1) * pageSize + 1;
  const superiorLimit = (activePage - 1) * pageSize + pageSize;

  const hasBulkActionButtons = !!bulkActionButtons;
  const hasActionButtons = !!actionButtons;
  const hasNavigation = !!navigation;

  // Always return to the first page on filter and search
  useEffect(() => {
    gotoPage(0);
  }, [searchBy, filterBy, gotoPage, resetOnChange]);

  // Listen for changes on pagination and use the state to fetch new data
  useEffect(() => {
    fetchData({ pageIndex, pageSize, sortBy });
  }, [fetchData, pageIndex, pageSize, sortBy]);

  // Listen for changes on Item Details component to reset selected rows
  useEffect(() => {
    if (isItemDetailsClosed) {
      toggleAllRowsSelected(false);
      setRowOnFocus('');
    }
  }, [toggleAllRowsSelected, isItemDetailsClosed]);

  // Send list os selected items for parent component
  useEffect(() => {
    if (selectedRows && onDataSelected) {
      const selectedData = selectedRows.map(row => data.find(item => item.id === row));
      onDataSelected(selectedData);
    }
  }, [selectedRows]);

  // TODO: export to a common file
  const isSelected = columnId => {
    return selectedRows.some(column => column === columnId);
  };

  // TODO: export to a common file
  const toggleSelection = (id, remove) => {
    if (remove) {
      const newColumns = selectedRows.filter(column => column !== id);
      setSelectedRows([...newColumns]);
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  // TODO: export to a common file
  const selectAll = value => {
    // Select all rows
    if (value) {
      setSelectedRows(data.map(row => row.id));
    } else {
      // Deselect all rows
      setSelectedRows([]);
    }
  };
  const tableClasses = [
    'react-table',
    hasActionButtons ? ' has-action-buttons' : '',
    hasNavigation ? ' has-navigation' : '',
    isItemDetailsClosed ? '' : ' slim-table',
    solution ? ` ${solution}` : ''
  ];

  // Render the UI for your table
  return (
    <div className="d-flex flex-column">
      <div className={tableClasses.join('')}>
        <table {...getTableProps()}>
          <Thead
            headerGroups={headerGroups}
            hasBulkActionButtons={hasBulkActionButtons}
            hasActionButtons={hasActionButtons}
            selectedRowsCount={selectedRows.length}
            dataCount={data.length}
            selectAll={selectAll}
            bulkActionButtons={bulkActionButtons}
            navigation={navigation}
          />
          <Tbody
            page={page}
            actionButtons={actionButtons}
            hasActionButtons={hasActionButtons}
            hasBulkActionButtons={hasBulkActionButtons}
            isSelected={isSelected}
            loading={loading}
            hasInteractiveRows={hasInteractiveRows}
            rowOnFocus={rowOnFocus}
            setRowOnFocus={setRowOnFocus}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            onItemSelected={onItemSelected}
            toggleAllRowsSelected={toggleAllRowsSelected}
            toggleSelection={toggleSelection}
          />
        </table>
      </div>
      <div className="pagination-wrapper">
        <Footer />
        <Pagination
          dataLabel={dataLabel}
          itemsCount={itemsCount}
          inferiorLimit={inferiorLimit}
          superiorLimit={superiorLimit}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </div>
    </div>
  );
}
