import React from 'react';
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from 'react-window-infinite-loader';
import DeviceItem from './deviceItem';
import EmptyState from '../emptyState';
import paginationUtils from '../../utils/pagination';

const ITEM_HEIGHT = 40;
const LIST_HEIGHT = window.innerHeight - 485;
const BATCH_SIZE = 40;

const DeviceList = ({ devices, selectedDevice, customWidth, onSelect, loadMoreItems, itemStatusMap, infiniteLoaderRef }) => {
  // useEffect(() => {
  //   scrollSelectedDeviceIntoView();
  // }, [devices, deviceId, selectedDevice]);

  // /*
  //   Returns if current device is being viewed on current scroll position
  // */
  // const isDeviceBeingViewed = (scrollTop, deviceIndex) => {
  //   const rowsOnScreen = HEIGHT / ITEM_HEIGHT;

  //   return ((scrollTop + rowsOnScreen) >= deviceIndex && scrollTop <= deviceIndex );
  // };

  // /*
  //   Scroll the list of devices, until the selected is on view
  // */
  // const scrollSelectedDeviceIntoView = () => {
  //   const listRefFound = listRef && listRef.current;

  //   if (listRefFound) {
  //     const deviceIndex = devices.findIndex(dev => dev.id === selectedDevice.id);

  //     // Get amount of scroll units from top to current position
  //     const scrollTop = listRef.current.state.scrollTop/100;

  //     if (!isDeviceBeingViewed(scrollTop, deviceIndex)) {
  //       listRef.current.scrollToItem(deviceIndex, "center");
  //     }
  //   }
  // };

  const renderRow = ({index, style}) => (
    <DeviceItem
      device={devices[index]}
      style={{
        ...style,
        top: style.top + 8,
        height: style.height - 8
      }}
      selectedDevice={selectedDevice}
      onSelect={onSelect} />
  );

  if (devices.length === 0) {
    return (
      <div className="device-list-container">
        <EmptyState icon="device" title="No device found" details="If you do expect results for current filters and seach, please contact support."/>
      </div>
    );
  }

  return (
    <div className="device-list-container">
      <InfiniteLoader
        ref={infiniteLoaderRef}
        isItemLoaded={params => paginationUtils.isRowLoaded(params, itemStatusMap.current)}
        itemCount={99999}
        minimumBatchSize={BATCH_SIZE}
        threshold={5}
        loadMoreItems={loadMoreItems} >
          {({ onItemsRendered, ref }) => (
            <List
              ref={ref}
              onItemsRendered={onItemsRendered}
              className={`device-list ${devices.length === 0 ? 'empty' : ''}`}
              height={LIST_HEIGHT}
              itemCount={devices.length}
              itemSize={ITEM_HEIGHT}
              width={customWidth ? customWidth : "100%"}>
                {renderRow}
            </List>
          )}
      </InfiniteLoader>
    </div>
  );
};

export default DeviceList;