import React, { Component } from 'react';

import CustomDropdown from '../customDropdown';
import CustomInput from '../customInput';
import Timezone from '../timezone';

import preferenceUtils from '../../utils/project/preferences';
import utils from '../../utils';
import './styles.scss';

const { generateAlert, fieldValidation } = utils;

class ProjectSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preferences: props.preferences,
      isEditable: false
    };

    // Input validations
    this.validations = {
      maxSessionTime: [
        { key: 'required', value: true },
        { key: 'min', value: 0 }
      ],
      maxDwellTime: [
        { key: 'required', value: true },
        { key: 'min', value: 0 }
      ],
      viewThreshold: [
        { key: 'required', value: true },
        { key: 'min', value: 0.1 }
      ]
    };

    this.speedUnitOptions = preferenceUtils.projectPreferences.speedUnitOptions;
  }

  componentDidUpdate(prevProps) {
    // Store current settings state for future comparison
    if (prevProps.preferences !== this.props.preferences) {
      this.setState({ preferences: this.props.preferences });
    }
  }

  // Change view for inputs from read to edit mode
  changeEditModeState = () => {
    this.setState({ isEditable: !this.state.isEditable });
  };

  onSpeedUnitChange = event => {
    this.setState({
      preferences: {
        ...this.state.preferences,
        speedUnit: event.target.value
      }
    });
  };

  onTimezoneChange = timezone => {
    this.setState({
      preferences: {
        ...this.state.preferences,
        timezone: timezone
      }
    });
  };

  // Update UI for changes on any field
  onFieldChange = (fieldPath, value) => {
    const { preferences } = this.state;
    let newSettings = {};

    const fields = fieldPath.split('.');
    const option = fields[0];
    const field = fields[1];

    // Set empty string if there's no data in any input
    value = !Number.isNaN(value) ? value : '';

    if (fields.length === 2) {
      const newValue = { ...preferences[option], [field]: value };
      newSettings = { ...preferences, [option]: newValue };
    } else {
      newSettings = { ...preferences, [option]: value };
    }

    this.setState({ preferences: { ...newSettings } });
  };

  // Trigger data update request and update UI from edit to read mode
  saveChanges = errors => {
    if (!utils.hasErrorsCheck(errors)) {
      const { preferences } = this.state;

      if (this.props.preferences !== preferences) {
        this.props.onChange(preferences);
      }

      this.changeEditModeState();
    } else {
      this.props.setAlert(generateAlert('Something is wrong. Please check the fields.', 'error'));
    }
  };

  cancelChanges = () => {
    const { preferences } = this.props;
    this.setState({ isEditable: false, preferences });
  };

  render() {
    const { preferences, isEditable } = this.state;

    if (Object.keys(preferences).length > 0) {
      const { maxSessionTime, maxDwellTime, viewThreshold, speedUnit } = preferences;
      const errors = {
        'max-session-time': fieldValidation(maxSessionTime, this.validations['maxSessionTime']).join(', '),
        'max-attention-time': fieldValidation(maxDwellTime, this.validations['maxDwellTime']).join(', '),
        'view-threshold': fieldValidation(viewThreshold, this.validations['viewThreshold']).join(', ')
      };

      return (
        <div className="settings-control">
          <div className="button-container">
            <a className="btn btn-secondary btn-sm" href={`/#projects/${this.props.project}`}>
              Go to Project Page
            </a>
            {isEditable ? (
              <>
                <button className="btn btn-success btn-sm mr-3" onClick={() => this.saveChanges(errors)}>
                  <i className="uil uil-check" /> Apply
                </button>
                <button className="btn btn-danger btn-sm" onClick={this.cancelChanges}>
                  <i className="uil uil-times" /> Cancel
                </button>
              </>
            ) : (
              <button className="btn btn-primary btn-sm" onClick={this.changeEditModeState}>
                <i className="uil uil-pen" /> Edit Preferences
              </button>
            )}
          </div>

          <div className="preference-control row">
            <div className="form-group col-12">
              <label>Timezone</label>
              <Timezone
                disabled={!isEditable}
                leftAlign
                selected={{ label: preferences.timezone, value: preferences.timezone }}
                onSelect={this.onTimezoneChange}
              />
            </div>

            <div className="form-group col-12">
              <label>Speed Unit</label>
              <CustomDropdown
                selectedItem={speedUnit}
                items={this.speedUnitOptions}
                handleSelection={this.onSpeedUnitChange}
                valueField={'id'}
                displayField={'name'}
                disabled={!isEditable}
              />
            </div>

            <div className="form-group col-12">
              <label>
                View Threshold <small>(sec)</small>
              </label>
              <CustomInput
                id="view-threshold"
                type="number"
                value={viewThreshold}
                errorMessage={errors['view-threshold']}
                onChange={e => this.onFieldChange('viewThreshold', parseFloat(e.target.value))}
                isEditable={isEditable}
                min={0.1}
              />
            </div>

            <div className="form-group col-6">
              <label>
                Max Session Time <small>(sec)</small>
              </label>
              <CustomInput
                id="max-session-time"
                type="number"
                value={maxSessionTime}
                errorMessage={errors['max-session-time']}
                onChange={e => this.onFieldChange('maxSessionTime', parseFloat(e.target.value))}
                isEditable={isEditable}
                min={0}
              />
            </div>
            <div className="form-group col-6">
              <label>
                Max Attention Time <small>(sec)</small>
              </label>
              <CustomInput
                id="max-attention-time"
                type="number"
                value={maxDwellTime}
                errorMessage={errors['max-attention-time']}
                onChange={e => this.onFieldChange('maxDwellTime', parseFloat(e.target.value))}
                isEditable={isEditable}
                min={0}
              />
            </div>
          </div>
        </div>
      );
    }

    return <div className="settings-loading">Loading...</div>;
  }
}

export default ProjectSettings;
