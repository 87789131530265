import {
  getCompanies,
  getCompany,
  getCompanyPolicy,
  updateCompany,
  setCompanyPolicy,
  deleteCompany
} from "./company";
import {
  createSite,
  getSites,
  getSite,
  getSiteTypes,
  updateSite,
  updateAttachedDevices,
  deleteSite
} from "./sites"
import {
  createSiteGroup,
  getSiteGroupsByCompany,
  getSiteGroups,
  getSiteGroup,
  getVersion,
  getDatasources,
  getSiteGroupStats,
  getPolicy,
  setPolicy,
  updateSiteGroup,
  deleteSiteGroup
} from "./sitegroups"
import {
  getCredentials,
  getIntegrations,
  createCredential
} from './cms'
import { getTemplates } from './template'
import { 
  createDevice,
  createDeviceComment,
  getDevices,
  getDevice,
  archiveDevice,
  dearchiveDevice,
  getDeviceConfig,
  sendCommand,
  provisionDevice,
  updateDevice,
  updateDeviceConfig,
  deleteDevice,
  deleteDeviceComment
} from "./devices";

export default {
  getCompanies,
  getCompany,
  getCompanyPolicy,
  updateCompany,
  setCompanyPolicy,
  deleteCompany,
  createSite,
  getSites,
  getSite,
  getSiteTypes,
  updateSite,
  updateAttachedDevices,
  deleteSite,
  createSiteGroup,
  getSiteGroupsByCompany,
  getSiteGroups,
  getSiteGroup,
  getVersion,
  getDatasources,
  getSiteGroupStats,
  getPolicy,
  setPolicy,
  updateSiteGroup,
  deleteSiteGroup,
  getCredentials,
  getIntegrations,
  createCredential,
  getTemplates,
  createDevice,
  createDeviceComment,
  archiveDevice,
  dearchiveDevice,
  getDevices,
  getDevice,
  getDeviceConfig,
  sendCommand,
  provisionDevice,
  updateDevice,
  updateDeviceConfig,
  deleteDevice,
  deleteDeviceComment
}
