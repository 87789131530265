import React from 'react';
import './styles.scss';

const EditButton = ({
  saveChanges,
  cancelChanges,
  errors,
  isEditable,
  renderSmallButton,
  changeEditModeState
}) => {
  return (
    <div className={`edit-button-container${renderSmallButton ? ' small' : ''}`}>
      { isEditable
      ? (
          <>
            { !renderSmallButton &&<div className="button-hint">You can save or revert your changes.</div> }
            <div>
              <button className="btn btn-success btn-slim mr-3" onClick={() => saveChanges(errors)}>
                <i className="uil uil-check" /> Apply
              </button>
              <button className="btn btn-danger btn-slim" onClick={() => cancelChanges()}>
                <i className="uil uil-times" /> Cancel
              </button>
            </div>
          </>
        )
      : (
        <>
          { !renderSmallButton && <div className="button-hint">Click on <strong>Edit</strong> to change values and then save them.</div>}
          <button className="btn btn-primary btn-slim" onClick={() => changeEditModeState(!isEditable)}>
            <i className="uil uil-pen" /> Edit
          </button>
        </>
        )
      }
    </div>
  );
};

export default EditButton;