import DeviceCommands from './deviceCommands';
import JsonEditor from './JsonEditor';
import Details from './details';
import './styles.scss';

const deviceDetails = {
  DeviceCommands,
  JsonEditor,
  Details
};

export default deviceDetails;