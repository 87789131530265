import React, { useState, useEffect } from 'react';
import CustomDropdown from '../customDropdown';
import CustomInput from '../customInput';
import IntervalSelector from '../dateTimeFilterBar/intervalSelector';
import utils from '../../utils';
import reportUtils from '../../utils/report';
import calendar from '../../utils/calendar';

const dayOptions = {
  weekly: [
    { value: 'sunday', label: 'Sunday' },
    { value: 'monday', label: 'Monday' },
    { value: 'tuesday', label: 'Tuesday' },
    { value: 'wednesday', label: 'Wednesday' },
    { value: 'thursday', label: 'Thursday' },
    { value: 'friday', label: 'Friday' },
    { value: 'saturday', label: 'Saturday' }
  ],
  monthly: [
    { value: '1', label: 'First Day' },
    { value: '31', label: 'Last Day' },
    { value: 'custom', label: 'Custom' }
  ]
};

const ReportSettings = ({ report, onChange, solution, frequencies, hideFields, isEditable, customIntervals }) => {
  const [newReport, setNewReport] = useState({});
  const [format, setFormat] = useState(report.format);
  const [frequency, setFrequency] = useState(report.frequency);
  const [interval, setInterval] = useState(report.interval);
  const [selectedDay, setSelectedDay] = useState(report.selectedDay);
  const [customDay, setCustomDay] = useState(report.daysToSend);
  const [errors, setErrors] = useState({});

  // Initialize values, old CDM
  useEffect(() => {
    /**
     * Load selectedDay based on report specifications
     *
     * Check if selectedDay is first, last or custom day.
     * P.S: Mostly used on <ReportContainer/> to load saved reports
     */
    const chosenDay = reportUtils.loadSelectedDay(report.frequency, report.daysToSend);
    const newCustomDay = chosenDay === 'custom' && report.daysToSend[0] ? report.daysToSend : report.customDay;

    // Select one of the options on Select Day dropdown
    // based on frequency and daysToSend
    setFormat(report.format);
    setFrequency(report.frequency);
    setInterval(report.interval);
    setSelectedDay(chosenDay);
    setCustomDay(newCustomDay);

    const updatedReport = {
      ...report,

      format: report.format,
      frequency: report.frequency,
      interval: report.interval,

      // Not sent to the api
      selectedDay: chosenDay,
      customDay: newCustomDay
    };

    setNewReport({ ...updatedReport });
  }, [report]);

  useEffect(() => {
    let day = '';

    if (customDay && customDay[0] !== '') {
      day = parseInt(customDay[0]);
    }

    setErrors(getErrors(day));
  }, [customDay]);

  const getErrors = day => ({
    'days-input': utils
      .fieldValidation(day, [
        { key: 'required', value: true },
        { key: 'min', value: 1 },
        { key: 'max', value: 31 }
      ])
      .join(', ')
  });

  const updateField = (field, value, callback) => {
    let updatedReport = { ...newReport, [field]: value };

    // Parse daysToSend values based on frequency and selected day dropdowns
    updatedReport = parseDaysToSend(field, value, updatedReport);

    // Update report reference and perform callbacks
    setNewReport(updatedReport);
    callback(value); // Callback function will be one of the set state functions.
    onChange({ ...updatedReport });
  };

  const parseDaysToSend = (field, value, newReport) => {
    // Update daysToSend when changing frequency
    if (field === 'frequency') {
      switch (value) {
        case 'daily':
          newReport.daysToSend = [''];
          break;
        case 'weekly':
          // select first day of the week
          newReport.daysToSend = ['sunday'];
          setSelectedDay('sunday');
          break;
        case 'monthly':
          // select first day of the month
          newReport.daysToSend = ['1'];
          setSelectedDay('1');
          break;
        default:
          break;
      }
    } else if (field === 'selectedDay') {
      if (value !== 'custom') {
        // Set first day and last day options
        newReport.daysToSend = [value];
        newReport.errors = {};
      } else {
        // Set a value different from First and Last day
        newReport.daysToSend = ['2'];
        newReport.customDay = ['2'];
      }
    } else if (field === 'customDay') {
      // Keep daysToSend empty to prevent selectedDay to change
      newReport.daysToSend = [''];
      newReport.errors = getErrors(value);
    }

    return newReport;
  };

  const getReportGroupingOptions = () => {
    const options = [{ value: 'default', label: 'Default' }];
    if (solution.id === 'facev2') {
      options.push({ value: 'deviceGroup', label: 'By Device' });
    }
    return options;
  };

  return (
    <>
      <div className={`form-group ${hideFields ? 'hidden' : ''}`}>
        <label>Interval</label>
        <IntervalSelector
          disabled={!isEditable}
          customIntervals={customIntervals}
          intervalSelected={interval}
          onIntervalSelected={intervalId => {
            updateField('interval', calendar.getIntervalById(intervalId, customIntervals), setInterval);
          }}
          outline
        />
      </div>

      <div className={`form-group ${hideFields ? 'hidden' : ''}`}>
        <label>Grouping</label>
        <CustomDropdown
          selectedItem={format}
          disabled={!isEditable}
          valueField={'value'}
          displayField={'label'}
          handleSelection={event => {
            const { value } = event.target;
            updateField('format', value, setFormat);
          }}
          items={getReportGroupingOptions()}
        />
      </div>

      <div className={`form-group ${hideFields ? 'hidden' : ''}`}>
        <label>Frequency</label>
        <CustomDropdown
          selectedItem={frequency}
          disabled={!isEditable}
          valueField={'value'}
          displayField={'label'}
          handleSelection={event => {
            const { value } = event.target;
            updateField('frequency', value, setFrequency);
          }}
          items={frequencies}
        />
      </div>

      {frequency && frequency !== 'daily' && frequency !== 'single' && (
        <>
          <div className={`form-group ${hideFields ? 'hidden' : ''}`}>
            <label>Select a day</label>
            <CustomDropdown
              id="days-input"
              selectedItem={selectedDay}
              disabled={!isEditable}
              valueField={'value'}
              displayField={'label'}
              handleSelection={event => {
                const { value } = event.target;
                updateField('selectedDay', value, setSelectedDay);
              }}
              items={dayOptions[frequency]}
            />
          </div>

          {selectedDay === 'custom' && (
            <div className={`form-group ${hideFields ? 'hidden' : ''}`}>
              <label>Choose a custom day</label>
              <CustomInput
                id="days-to-send-input"
                type="number"
                min={1}
                max={31}
                value={customDay}
                onChange={event => {
                  const { value } = event.target;
                  updateField('customDay', [value], setCustomDay);
                }}
                errorMessage={errors['days-input']}
                isEditable={isEditable}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ReportSettings;
