import React, { useEffect, useState } from 'react';
import Card from '../../../components/card';
import icons from '../../../assets/icons';
import './styles.scss';

const SiteGroupCard = ({ siteGroup, selectedSiteGroup, ownersMap, onSelect }) => {
  const [owner, setOwner] = useState('--');
  const displayName = siteGroup.displayName ? siteGroup.displayName : '--';

  const company = siteGroup.companyDisplayName ? siteGroup.companyDisplayName : '--';
  const hasIntegration = siteGroup.enabledIntegrations.length > 0;
  const count = siteGroup.sitesCount > 0 ? siteGroup.sitesCount : siteGroup.devicesCount;
  let footerLabel = siteGroup.sitesCount > 0 ? 'site' : 'device';

  if (siteGroup.devicesCount === 0) {
    footerLabel = 'site';
  }

  useEffect(() => {
    setOwner(ownersMap[siteGroup.ownerId] ? ownersMap[siteGroup.ownerId].email : siteGroup.ownerId);
  }, [ownersMap]);

  return (
    <Card
      classes={selectedSiteGroup && siteGroup.id === selectedSiteGroup.id ? 'selected' : ''}
      header={
        <div>
          <h3>{displayName}</h3>
        </div>
      }
      body={
        <div className="d-flex flex-column">
          <h4 id={`site-group-owner${owner}`}>
            <b>Owner:</b> {owner}
          </h4>
          <h4 id={`site-group-company-${company}`}>
            <b>Company:</b> {company}
          </h4>
        </div>
      }
      footer={
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <img className={'blue-icon'} src={icons.projectOutline} alt="projects" />
            <h5>{`${count} ${count > 1 ? `${footerLabel}s` : footerLabel}`}</h5>
          </div>
          { hasIntegration && (
            <div className="d-flex align-items-center">
              <img className={'blue-icon'} src={icons.integrations} alt="integrations" />
              <h5>CMS integration</h5>
            </div>
          )}
        </div>
      }
      onPress={() => {
        onSelect(siteGroup);
      }}
    />
  );
};

export default SiteGroupCard;
