import React, { useEffect, useState } from 'react';

import DropdownSearch from '../dropdownSearch';
import timezones from '../../utils/timezones';

import './styles.scss';

const Timezone = ({
  selected,
  onSelect,
  label,
  disabled,
  leftAlign,
  openUp,
  customWidth,
  outline
}) => {
  const [selectedTimezone, setSelectedTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

  useEffect(() => {
    const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setSelectedTimezone(selected ? selected : { value: defaultTimezone, label: defaultTimezone });
  }, [selected]);


  return (
    <DropdownSearch
      selected={selectedTimezone}
      options={timezones.map(item => ({ label: item, value: item }))}
      onSelect={onSelect}
      label={label}
      disabled={disabled}
      leftAlign={leftAlign}
      openUp={openUp}
      customWidth={customWidth}
      outline={outline} />
  );
}

export default Timezone;
