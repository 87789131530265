import React from 'react';

const Dropdown = ({ children, isOpen, target, onClose, customWidth }) => {
  const menu = children => (
    <div className="react-selector__menu" style={{ width: customWidth ? customWidth : 332 }} >
      {children}
    </div>
  );

  const blanket = (onClick) => (
    <div className="react-select__blanket" onClick={onClick} />
  );

  return (
    <div className="react-select__dropdown">
      {target}
      {isOpen ? menu(children) : null}
      {isOpen ? blanket(onClose) : null}
    </div>
  );
}

export default Dropdown;