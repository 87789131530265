import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setAlert } from '../../redux/dashboard/actions';
import CustomTextArea from '../customTextArea';
import EditButton from '../editButton';
import Loading from '../loading';

import API from '../../services/api';
import utils from '../../utils';
import { getCustomConfig } from '../../utils/device/exportParse';
import './styles.scss';

const { generateAlert } = utils;

const JsonEditor = ({ device, rawConfig, currentPresetConfig, onConfigChanged }) => {
  const [newConfig, setNewConfig] = useState('');
  const [isEditable, setIsEditable] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setNewConfig(JSON.stringify(rawConfig, null, 2));
  }, [rawConfig]);

  const cancelChanges = () => {
    setNewConfig(JSON.stringify(rawConfig, null, 2));
    setIsEditable(false);
  };

  // Change view for inputs from read to edit mode
  const changeEditModeState = () => {
    setIsEditable(!isEditable);
  };

  const updateDeviceConfig = async () => {
    let updatedConfig = null;
    const config = JSON.parse(newConfig);

    // Diff between received config and changes made
    const customConfigChanges = getCustomConfig(currentPresetConfig, config);
    // Remove null and empty values from config
    const newCustomConfig = utils.removeNullValue(customConfigChanges);

    // Need to add back empty ROI node, if there was one, to prevent using default after merge on the backend.
    if (config.driver_config.config.rois && config.driver_config.config.rois.length === 0) {
      newCustomConfig.driver_config.config.rois = [];
    }

    updatedConfig = {
      name: device.name,
      customConfig: newCustomConfig
    };

    try {
      const updateResponse = await API.updateDeviceConfig(device.companyId, device.id, updatedConfig, ['customConfig']);

      onConfigChanged(updateResponse);
      dispatch(setAlert(utils.generateAlert("Device updated successfully!", 'success')));
    } catch (error) {
      console.error(error);
    }
  };

  // Trigger data update request and update UI from edit to read mode
  const saveChanges = () => {
    try {
      const parsedConfig = JSON.stringify(rawConfig, null, 2);
      if (newConfig !== parsedConfig) {
        updateDeviceConfig();
      }

      changeEditModeState();
    } catch (error) {
      dispatch(setAlert(generateAlert('Something went wrong! Please check out the logs.', 'error')));
      console.error(error);
    }
  };

  if (rawConfig) {
    return (
      <div className="section-body">
        <EditButton
          isEditable={isEditable}
          saveChanges={saveChanges}
          cancelChanges={cancelChanges}
          changeEditModeState={changeEditModeState}
        />
        <div className="form-group">
          <CustomTextArea
            id="support-only-device-settings"
            value={newConfig}
            onChange={e => setNewConfig(e.target.value)}
            isEditable={isEditable}
            rows={20}
          />
        </div>
      </div>
    );
  }

  return <Loading />;
}

export default JsonEditor;