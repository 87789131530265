import React, { Component } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import SearchBox from '../searchBox';
import CustomButton from '../customButton';
import CustomCheckBox from '../customCheckBox';

import './styles.scss';

class ItemSelector extends Component {
  constructor (props) {
    super(props);
    this.state = {
      enableApplyButton: false,
      selectedItemsCount: 0,

      filteredItems: [], // filtered/sorted results
      selectedItems: {}, // manually selected by the user
      snapshotItems: {}, // current state for sorted & filtered items

      sortBy: { value: 'name', desc: false },
      searchQuery: ''
    };
  }

  componentDidMount() {
    if (this.props.items.length > 0) {
      const sortedAndFilteredItems = this.applySortAndFilter();
      if (this.props.firstShouldBeSelected) {
        this.selectFirstItem(sortedAndFilteredItems);
      } else {
        this.selectAll(sortedAndFilteredItems, false);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.items !== prevProps.items) {
      if (this.props.items.length === 0) {
        this.setState({
          enableApplyButton: false,
          selectedItemsCount: 0,
          filteredItems: [],
          selectedItems: {},
          snapshotItems: {},
        });
      } else {
        this.populateItemsList();
      }
    }
  }

  /**
   * Do initial items list population using component props and intial values
   */
  populateItemsList = () => {
    const sortedAndFilteredItems = this.applySortAndFilter();

    // Everytime we change the item list the first element is selected
    if (this.props.firstShouldBeSelected) {
      this.selectFirstItem(sortedAndFilteredItems);
    } else {
      // Only select all items in case there was not selected items before
      if (Object.keys(this.state.selectedItems).length === 0) {
        this.selectAll(sortedAndFilteredItems, false);
      }

      // Create the current state list using before state (snapshotItems)
      // and the new state (sortedAndFilteredItems)
      this.createSnapshotList(sortedAndFilteredItems);
    }
  }

  /**
   * Set an item as selected, in the selectedItems list, based on his key
   * @param {String} key key that represents the item to be selected
   */
  select = (key) => {
    const { selectedItems, selectedItemsCount } = this.state;
    const newSelectedItems = {...selectedItems};

    newSelectedItems[key] = !newSelectedItems[key];
    const selectedCount = newSelectedItems[key] ? selectedItemsCount + 1 : selectedItemsCount - 1;
    const enableApplyButton = this.enableApplyButton(newSelectedItems);

    this.setState({
      enableApplyButton,
      selectedItems: newSelectedItems,
      selectedItemsCount: selectedCount
    });
  }

  /**
   * Toggle select state for all items in the list
   * @param {Object} items items that will be selected
   * @param {boolean} clearSelection case is true, unselect all the items
   */
  selectAll = (items, clearSelection) => {
    const selectedItems = {};

    items.forEach(key => {
      selectedItems[key.id] = clearSelection ? false : true;
    });

    const enableApplyButton = this.enableApplyButton(selectedItems);

    this.setState({
      selectedItems,
      enableApplyButton,
      selectedItemsCount: clearSelection ? 0 : items.length
    });
  }

  /**
   * From a given list, select the first item
   * @param {Array} filteredItems list that have items
   */
  selectFirstItem = (filteredItems) => {
    if (filteredItems.length > 0) {
      this.selectAll(filteredItems, true); // Restart selected list
      this.select(filteredItems[0].id); // Select only the first item
      this.applyItemsSelection(); // Update selection on container
    }
  }

  /**
   * Create a list that represents the current state of sorted & filtered items
   * @param {Array} items list of items for snapshot creation
   */
  createSnapshotList = (items) => {
    const { snapshotItems } = this.state;
    let newSnapshotItems = {};

    // Case snapshotItems is emapty create a new snapshot list (newSnapshotItems) with all the items
    if (Object.keys(snapshotItems).length === 0) {
      items.forEach((item) => {
        newSnapshotItems[item.id] = true;
      });
    } else {
      // Check if all the snapshotItems exists inside of items then remove the ones that aren't there
      Object.keys(snapshotItems).forEach((snapItem) => {
        const existsInCurrentSelection = items.some((item) => item.id === snapItem);
        if (!existsInCurrentSelection) {
          delete snapshotItems[snapItem];
        }
      });

      // Add all the snapshotItems in newSnapshotItems
      newSnapshotItems = { ...snapshotItems };

      // Add everything from items in newSnapshotItems, but as not selected
      items.forEach((item) => {
        if (!newSnapshotItems[item.id]) {
          newSnapshotItems[item.id] = false;
        }
      });
    }

    // The snapshot is the current state of the selectedItems list
    this.setState({
      enableApplyButton: false,
      selectedItems: newSnapshotItems,
      snapshotItems: newSnapshotItems
    });
  }

  /**
   * Sort a given list of items based on sortBy options
   * @param {Array} items list of items to be sorted
   */
  sort = (items) => {
    const { desc, value } = this.state.sortBy;
    const sortedItems = [...items];
    const order = desc ? -1 : 1;

    return sortedItems.sort((a, b) => {
      const aValue = a[value] || '';
      const bValue = b[value] || '';
      if (aValue.toLowerCase() < bValue.toLowerCase()) return (-1 * order);
      if (aValue.toLowerCase() > bValue.toLowerCase()) return (1 * order);
      return 0;
    });
  }

  /**
   * Switch sort state
   */
  toggleSort = () => {
    const { sortBy } = this.state;
    this.setState({
      sortBy: { ...sortBy, desc: !sortBy.desc }
    }, this.applySortAndFilter);
  }

  /**
   * Check if a search match exists for a specific field of a given item
   * @param {Object} item item to look for the data
   * @param {String} field field of the item
   * @param {String} query search query
   */
  findSearchMatch = (item, field, query) => {
    return item[field].toUpperCase().includes(query.toUpperCase());
  }

  /**
   * String search on items based on given fields list
   * @param {Array} items items to search
   * @param {Array} fields fields to look on each item during the search
   */
  search = (items, fields) => {
    const { searchQuery } = this.state;

    if (searchQuery !== '') {
      const matchedItems = items.filter(item => {
        let matchesFound = 0;

        // Tries to find a match using the search query for all
        // the given fields in an item
        fields.forEach(field => {
          if (this.findSearchMatch(item, field, searchQuery)) {
            matchesFound++;
          }
        });

        return matchesFound > 0;
      });

      return matchedItems;
    }

    return items;
  }

  /**
   * Execute Sort and Filter functions one after another
   */
  applySortAndFilter = () => {
    const { searchOptions, items } = this.props;
    const { selectedItems } = this.state;

    const sortedItems = this.sort(items);
    const filteredItems = this.search(sortedItems, searchOptions);
    const enableApplyButton = this.enableApplyButton(selectedItems);

    this.setState({ filteredItems, enableApplyButton });
    return filteredItems;
  }

  /**
   * Send selected items to parent component using
   * this.props.onItemSelected function
   */
  applyItemsSelection = () => {
    const { selectedItems, filteredItems } = this.state;

    // Generates an updated list of selected items to display
    const selectedItemsToDisplay = filteredItems.filter((item) => {
      return selectedItems[item.id] === true;
    });

    this.setState({
      enableApplyButton: false,
      snapshotItems: selectedItems
    });

    this.props.onItemSelected(selectedItemsToDisplay);
  }

  /**
   * Enable apply button to be clickable based on select changes on
   * selectedItems list in comparison with the snapshotItems list
   * @param {Object} selectedItems selected items
   */
  enableApplyButton = (selectedItems) => {
    const { snapshotItems } = this.state;

    const comparisonSnapshot = JSON.stringify(snapshotItems);
    const comparisonSelectedItems = JSON.stringify(selectedItems);

    return comparisonSnapshot !== comparisonSelectedItems;
  }

  render() {
    const { items, searchPlaceholder, icon } = this.props;
    const {
      selectedItems,
      enableApplyButton,
      selectedItemsCount,
      filteredItems,
      sortBy
    } = this.state;
    const filteredAndSelectedItems = filteredItems
      .filter((item) => selectedItems[item.id]);

    if (items.length === 0) {
      return (<span>No items to display</span>);
    }

    return (
      <div className='item-selector-container'>
        <SearchBox
          placeholder={searchPlaceholder}
          onChange={value => {
            this.setState({ searchQuery: value }, this.applySortAndFilter)}
          }/>
        <div className='item-selector-toolbar'>
          <div className='sort-button' onClick={this.toggleSort}>
            <i className={sortBy.desc ? 'uil uil-angle-up' : 'uil uil-angle-down'} />
            <label>Name</label>
          </div>
          <CustomCheckBox
            multiple
            label='Select All'
            onClick={(value) => this.selectAll(this.state.filteredItems, value)}
            totalItems={filteredItems.length}
            selectedItems={selectedItemsCount}
            filteredSelectedItems={filteredAndSelectedItems.length} />
        </div>
        <ul className='item-selector-list'>
          {
            filteredItems.map((item) => (
              <li
                key={item.id}
                id={item.id}
                onClick={() => this.select(item.id)}
                className={`d-flex align-items-center ${selectedItems[item.id] ? 'selected' : ''}`}>
                  { icon && <img src={ icon } alt=""/> }
                  <div>{ item.name }</div>
                  <UncontrolledTooltip placement="top" target={item.id}>
                    { item.name }
                  </UncontrolledTooltip>
              </li>
            ))
          }
        </ul>
        <CustomButton
          title={'Apply'}
          classes={'btn-primary btn-block'}
          handleClick={this.applyItemsSelection}
          disabled={!enableApplyButton || selectedItemsCount === 0} />
      </div>
    );
  }
}

export default ItemSelector;