import React from 'react';
import icons from '../../assets/icons';
import IconButton from '../iconButton';
import { format } from 'date-fns';
import './styles.scss';
const itemNotification = ({ notification, onArchive, onDelete, isBusy }) => {
  const createdAt = new Date(notification.createdAt);

  const renderStatus = () => {
    if (notification.priority === 'URGENT') {
      const statusLegends = {
        URGENT: {
          label: 'URGENT',
          style: 'danger'
        }
      };

      return (
        <span className={`status badge badge-pill badge-outline-${statusLegends[notification.priority].style}`}>
          {statusLegends[notification.priority].label}
        </span>
      );
    }
  };

  const getSrcIcon = ({ severity, type }) => {
    if (severity && type.id) {
      const typeId = type.id.toLowerCase();
      severity = severity.toLowerCase();
      if (icons[`${typeId}_${severity}`]) {
        return icons[`${typeId}_${severity}`];
      } else if (icons[`${typeId}_default`]) {
        return icons[`${typeId}_default`];
      }
    }
    if (severity && icons[`standard_${severity}`]) {
      return icons[`standard_${severity}`];
    }
    return icons.standard_default;
  };

  return (
    <div className="d-flex flex-row item-notification">
      <div>
        <div className={`icon-item-notification ${notification.severity ? notification.severity.toLowerCase() : ''}`}>
          <img alt="image-notification" src={getSrcIcon(notification)} />
        </div>
      </div>
      <div className="notification-info w-100">
        <div className="notification-header d-flex justify-content-between">
          <small className="notification-service">{notification.type ? notification.type.id : 'MANAGEMENT'}</small>
          <div className="d-flex">
            {renderStatus()}
            <div className="actions">
              {notification.archived ? (
                <IconButton
                  disabled={isBusy}
                  id="action-message"
                  icon="message"
                  onPress={() => onArchive([notification], false)}
                  tooltip="Send to Inbox"
                />
              ) : (
                <IconButton
                  disabled={isBusy}
                  id="action-archived"
                  icon="archive"
                  onPress={() => onArchive([notification], true)}
                  tooltip="Archive"
                />
              )}
              <IconButton id="action-remove" icon="del" onPress={() => onDelete([notification])} tooltip="Delete" />
            </div>
          </div>
        </div>
        <h5 className="notification-title">{notification.title}</h5>
        <div className="notification-description" dangerouslySetInnerHTML={{ __html: notification.message }}></div>
        <div className="notification-time">
          <i className="uil uil-clock-three"></i>
          <small>{format(createdAt, 'HH:mm - MM/dd/yyyy')}</small>
        </div>
      </div>
    </div>
  );
};

export default itemNotification;
