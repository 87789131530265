import React, { useState, useEffect } from 'react';
import components from '../../../components';
import EditButton from '../../../components/editButton';
import API from '../../../services/api';
import utils from '../../../utils';

import './styles.scss';

const { CustomInput, CustomConfirm } = components;

const Info = ({ selectedProject, editMode, setEditMode, updateProject, deleteProject, isAdmin }) => {
  const [loading, setLoading] = useState(false);
  const [showDeleteProjectDialog, setShowDeleteProjectDialog] = useState(false);
  const [owner, setOwner] = useState(selectedProject ? selectedProject.ownerId : '');
  const [project, setProject] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (selectedProject) {
      setProject({ ...selectedProject })
      if (selectedProject.ownerId) {
        setLoading(true);
        API.getAccountDetails([selectedProject.ownerId])
        .then(res => {
          setOwner(res[selectedProject.ownerId].email);
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
      } else {
        setOwner('');
      }
    }
    return () => true;
  }, [selectedProject]);

  // Validate fields
  useEffect(() => {
    const validation = {
      'displayName': [{ key: 'required', value: true }, { key: 'min', value: 4 }],
    };

    if (project) {
      setErrors({
        'displayName': utils.fieldValidation(project.displayName, validation['displayName']).join(', ')
      });
    }
  }, [project]);

  const changeEditModeState = () => {
    setEditMode({ editMode: !editMode });
  };

  const cancelChanges = () => {
    setEditMode(false);
    setProject({...selectedProject});
  };

  const saveChanges = () => {
    for (const key in errors) {
      if (errors[key].length > 0){
        return;
      }
    }
    updateProject(project);
    setEditMode(false);
  };

  return loading
  ? (<div>Loading...</div>)
  : (
    <div className="info-section">
      <EditButton
        isEditable={editMode}
        renderSmallButton
        saveChanges={saveChanges}
        changeEditModeState={changeEditModeState}
        cancelChanges={cancelChanges} />

      <div className="form-group">
        <label>Name</label>
        <CustomInput
          id="project-name"
          type="text"
          value={project.displayName}
          isEditable={editMode}
          errorMessage={errors['displayName']}
          isCopyButtonEnabled={true}
          onChange={e => setProject({ ...project, displayName: e.target.value })}
          autoFocus={true}
          />
      </div>
      {
        owner &&
        <div className="form-group">
          <label>Owner</label>
          <CustomInput
            id="project-owner"
            type="text"
            value={owner}
            isCopyButtonEnabled={true} />
        </div>
      }
      <div className="form-group">
        <label>Project ID</label>
        <CustomInput
          id="project-id"
          type="text"
          value={selectedProject.id}
          isCopyButtonEnabled={true} />
      </div>
      <div className="form-group">
        <label>Provisioning Token</label>
        <CustomInput
          id="project-token"
          type="text"
          className={"wrap-word"}
          value={selectedProject.provisioningToken}
          isCopyButtonEnabled={true} />
      </div>
      <div className="form-group">
        <label>Created at</label>
        <CustomInput
          id="project-created-at"
          type="text"
          className={"wrap-word"}
          value={new Date(selectedProject.createTime).toISOString().slice(0, 10)}/>
      </div>
      {
        isAdmin &&
        <button className="btn btn-danger" onClick={() => setShowDeleteProjectDialog(true)}>
          <i className="uil uil-trash" /> Delete this project
        </button>
      }
      <CustomConfirm
        messageType="deleteProject"
        messageContext={selectedProject}
        confirmCb={() => deleteProject(selectedProject)}
        cancelCb={() => setShowDeleteProjectDialog(false)}
        isOpen={showDeleteProjectDialog} />
    </div>
  );
};

export default Info;