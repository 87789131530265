import React from 'react';
import utils from '../../utils';
import Tags from '../tags';

const FilterTags = ({ items, counters, onClose }) => {
  if (items.length === 0) {
    return (
      <div className="multiple-filter-tags no-filters">
        <div className="no-filters-message">
          <span> No filters applied</span>
        </div>
      </div>
    );
  }

  return (
    <div className="multiple-filter-tags">
      {items.map(tag => {
        const value = counters[tag.key] ? `${tag.label} (${counters[tag.key]})` : tag.label;
        return (
          <Tags
            key={`tag-${utils.toCamelCase(tag.label)}`}
            value={value}
            hasCloseButton
            onClose={() => onClose(tag)}
          />
        );
      })}
    </div>
  );
};

export default FilterTags;
