const targetMapping = [
  { value: "0", label: "CPU" },
  { value: "1", label: "GPU (32)" },
  { value: "2", label: "GPU (16)" },
  { value: "3", label: "Movidius" }
];

const backendMapping = [
  { value: "0", label: "Default" },
  { value: "2", label: "Openvino" },
  { value: "3", label: "OpenCV" }
];

const getObjectFromValue = (value, array) => array.find(obj => obj.value === `${value}`);

const modelUtils = {
  targetMapping,
  backendMapping,
  getObjectFromValue
};

export default modelUtils;