import React, { useState } from 'react';

import containers from '../../containers';
import components from '../../components';
import ReloadButton from '../../components/reloadButton';
import TimezoneInfo from '../../components/timezoneInfo';

const { IntegrationsContainer } = containers;
const { Header, ProjectSelector, NotificationTools } = components;

const IntegrationsScreen = () => {
  const [lastUpdated, setLastUpdated] = useState('');

  return (
    <div className="d-flex flex-column flex-fill">
      <Header
        title={'Management'}
        filters={
          <div className="d-flex">
            <ProjectSelector/>
          </div>
        }
        icon={'settingsDark'}
        actions={[
          { type: "reactComponent", value: <TimezoneInfo key="timezone-info" /> },
          {
            type: "reactComponent",
            value: <ReloadButton
              key="reload-btn"
              contentName="Insights data"
              lastUpdatedCB={setLastUpdated} />
          },
          { type: "reactComponent", value: <NotificationTools key="notification-btn" /> }
        ]} />
      <IntegrationsContainer lastUpdated={lastUpdated}/>
    </div>
  );
}

export default IntegrationsScreen;