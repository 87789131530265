import React from 'react';
import { useSelector } from 'react-redux';
import CheckBoxCard from '../../../components/checkBoxCard';
import { getUser } from '../../../redux/dashboard/actions';
import { getDevices } from '../../../redux/devices/actions';

const WidgetGrid = ({widgets, onWidgetClick}) => {
  const user = useSelector(getUser);
  const devices = useSelector(getDevices);

  // Check user permission level
  const isAdmin = (user && (user.permissions['support'] || user.impersonator));
  // Check wifi devices
  const hasWifiTracker = devices.some(device => device.hasWifi);
  const hasHeatmapDevices = devices.some(device => device.heatmapImageUrl && device.heatmapImageUrl !== '');

  return (
    <div className="widget-options-container">
      { widgets.map(widget => {
        // Don't display support-only widgets if not admin
        if (!isAdmin && widget.onlyAdminEnabled) return null;

        // Don't display wifi widgets if doesn't have wifiTrack devices
        if (!hasWifiTracker && widget.wifiTracker) return null;

        // Don't display heatmap widgets if doesn't have devices with heatmap configured
        if (!hasHeatmapDevices && widget.display === 'heatmap') return null;

        return (
          <div key={widget.id}>
            <CheckBoxCard
              value={widget.id}
              image={widget.display}
              text={widget.title}
              onChange={onWidgetClick}
              defaultState={widget.selected} />
          </div>
        )
      })}
    </div>
  );
};

export default WidgetGrid;