import React, { useEffect, useState, useCallback } from 'react';
import CustomInput from '../customInput';
import utils from '../../utils';
import { useDispatch } from 'react-redux';
import types from '../../redux/types';

const PersonalInformation = ({ user, onChange }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [errors, setErrors] = useState({ name: [] });
  const [wasSubmitted, setWasSubmitted] = useState(false);

  const dispatch = useDispatch();

  const validateForm = useCallback(() => {
    const validations = {
      name: [{ key: 'required', value: true }],
    };

    let hasError = false;
    let newErrors = {
      name: utils.fieldValidation(name, validations['name'])
    };

    setErrors(newErrors);

    Object.keys(newErrors).forEach((key) => {
      if (newErrors[key].length > 0) {
        hasError = true;
      }
    });

    return hasError;
  }, [name]);

  useEffect(() => {
    if (user) {
      startUserData(user);
    }
  }, [user]);

  useEffect(() => {
    if (!wasSubmitted) {
      return;
    }
    validateForm();
  }, [validateForm, wasSubmitted]);

  const startUserData = (user) => {
    setName(user.name);
  }

  const saveChanges = () => {
    setWasSubmitted(true);
    if (!validateForm()) {
      setIsLoading(true);
      onChange({ displayName: name }, (err) => {
        setIsLoading(false);
        if (!err) {
          dispatch({ type: types.UPDATE_USER, payload: { ...user, name } });
          setIsEditable(false);
        }
      });
    }
  }

  const changeEditModeState = () => {
    setIsEditable(true);
  }

  const cancelChanges = () => {
    startUserData(user);
    setIsEditable(false);
  }

  return (
    <div className="section">
      <div className="section-title">
        Personal Information
      </div>
      <div className="section-content">
        <div className="buttons-container">
          { isEditable ?
            <>
              <button className="btn btn-success btn-sm" disabled={isLoading} onClick={() => saveChanges()}>
                <i className="uil uil-check"/> Apply
              </button>
              <button className="btn btn-danger btn-sm" disabled={isLoading} onClick={() => cancelChanges()}>
                <i className="uil uil-times"/> Cancel
              </button>
            </>
            :
            <button className="btn btn-primary btn-sm" onClick={() => changeEditModeState()}>
              <i className="uil uil-pen"/> Edit
            </button>
          }
        </div>
        <div className="row">
          <div className="col-7">
            <div className="form-group">
              <label>Name</label>
              <CustomInput
                id="profile-name"
                isEditable={isEditable}
                errorMessage={errors['name'].join(', ')}
                onChange={e => setName(e.target.value)}
                type="text"
                value={name} />
            </div>
            <div className="form-group">
              <label>Email</label>
              <CustomInput
                id="profile-email"
                type="text"
                value={user.email}
                isCopyButtonEnabled />
            </div>
          </div>
          <div className="col-5">
            <div className="form-group">
              <label>User Id</label>
              <div className="ellipsis">
                <CustomInput
                  id="profile-id"
                  type="text"
                  value={user.id}
                  isCopyButtonEnabled />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PersonalInformation;