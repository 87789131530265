import React from 'react';
import images from '../../assets/images';

import './styles.scss';

const Loading = ({ size }) => (
  <div className="component-loading">
    <img style={{width: `${size || 25}%`}} src={images.loading} alt={"loading"}/>
  </div>
);

export default Loading;