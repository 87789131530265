import React from 'react';
import ActionButtons from '../actionButtons';
import './style.scss';

const Comment = ({ comment, onDelete, onSolve }) => {
  return (
    <div className="comment-row">
      <div className="comment">
        <div className="comment-header">
          <div className="comment-date">{comment.date}</div>
          <div className="comment-sender">{comment.sender}</div>
        </div>
        <div className="comment-message">{comment.message}</div>
        <div className="comment-footer">
          <div className="comment-status">{comment.status}</div>
          <div className="comment-buttons">
            <ActionButtons
              buttons={[
                {
                  key: 'delete',
                  id: 'delete',
                  icon: 'trash',
                  tooltip: 'Delete the comment',
                  onPress: () => onDelete(comment)
                },
                {
                  key: 'resolve',
                  id: 'resolve',
                  icon: 'check',
                  tooltip: 'Resolve comment',
                  disabled: comment.status !== 'Unresolved',
                  onPress: () => onSolve(comment)
                }
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comment;
