import utils from '../../utils';

const initializeCredential = (credentialFields) => {
  const initVal = {};

  if (!credentialFields) {
    return {};
  }

  credentialFields.forEach(field => {
    initVal[field] = '';
  });

  return initVal;
};

const initializeMapping = (mappingMetadataFields) => {
  const initVal = {};
  mappingMetadataFields.forEach(field => {
    initVal[field] = '';
  });
  return initVal;
};

const getCredentialFieldName = (credential) => {
  return Object.keys(credential).find(key => key.includes('Credential'));
};

const getCredentials = (credential) => {
  const parsedCredentials = {};

  // Credential field name depends on the CMS name i.e. signageliveCredential, broadsignCredential etc
  const credentialsField = getCredentialFieldName(credential);
  const credentials = credential[credentialsField];

  // Scala Integration was made using script and doesn't have credentials
  // Next line is to prevent error
  if (!credentials) return null;

  const keys = Object.keys(credentials);
  const values = Object.values(credentials);

  // Also the fields come in camelCase, but they need to be mapped to snake_case
  const snakeKeys = keys.map(key => utils.camelToSnakeCase(key));

  snakeKeys.forEach((key, index) => {
    parsedCredentials[key] = values[index];
  });

  return parsedCredentials;
};

export {
  initializeCredential,
  initializeMapping,
  getCredentials,
  getCredentialFieldName
}