import React, { useState, useEffect } from 'react';

import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import Wizard from '../wizard';
import newReportSteps from './newReportSteps';

import './styles.scss';

const CustomReport = ({ report, classes, onSubmit, onClose, isOpen }) => {

  const [newReport, setNewReport] = useState({});

  useEffect(() => {
    if (report) {
      setNewReport({ ...report });
    }
  }, [report]);

  return (
    <Modal isOpen={isOpen} fade={false} toggle={onClose} className={classes} centered>
      <ModalHeader toggle={onClose} tag="div">
        <h5>Generate Analytics Report</h5>
        <div className="modal-subtitle">
          Define your report parameters and decide what e-mail should we sent it to
        </div>
      </ModalHeader>
      <ModalBody>
        <Wizard
          steps={newReportSteps(newReport, setNewReport)}
          onSubmit={() => onSubmit(newReport)}
          onCancel={onClose} />
      </ModalBody>
    </Modal>
  );
}

export default CustomReport;