import API from '../../services/api';
import { availableCMS } from '../../utils/solutions';
import dateTimeUtils from '../../utils/calendar';

export const generateReportObject = report => {
  let devices = [];
  let startDate = report.startDate;
  let endDate = '';
  const interval = String(report.interval.id).toUpperCase();

  // Recurring Report data
  if (report.frequency !== 'single') {
    devices = report.devices.map(device => device.id);
    // Fix timezone offset in datetime object
    endDate = dateTimeUtils.addTime(99, 'years');
  } else {
    // Single Report data
    devices = report.devices.map(device => ({
      deviceId: device.id,
      deviceName: device.name
    }));

    // Fix timezone offset in datetime object
    endDate = report.endDate;
  }
  return {
    email: report.email,
    startTime: startDate,
    endTime: endDate,
    filterByTime: report.filterByTime,
    filterByWeekday: report.filterByWeekday,
    timezone: report.timezone,
    epoch: interval, // Field expected on GenerateReport
    interval: interval, // Field expected on CreateReport (Recurring)
    fields: report.fields, // CSV columns to use on the Report
    format: report.format,
    frequency: report.frequency,
    daysToSend: report.daysToSend,
    advertisers: report.advertisers,
    medias: report.medias,
    devices
  };
};

export const submit = async (report, project) => {
  let data = generateReportObject(report);
  let table = report.solution.table;

  if (report.solution.cms && availableCMS[table]) {
    // Table name is available at 'table' field
    table = availableCMS[table].table;
  }

  // Should add speedUnit for VR, VD and VC
  if (report.solution.table === 'vehiclerecognitionv1') {
    // Vehicle products have this specific speedUnit field
    data.speedUnit = project.preferences.speedUnit;
  }

  // Add project preferences to report requests
  data.preferences = project.preferences;

  if (data.frequency === 'single') {
    // Single report
    return API.generateReport(project.id, table, JSON.stringify(data));
  }

  // Recurring Reports
  return API.createReport(project.id, table, JSON.stringify(data));
};
