import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import CustomInput from '../../components/customInput';
import CustomButton from '../../components/customButton';
import CustomCheckBox from '../../components/customCheckBox';

const ExpirationLicenseModal = ({ isOpen, toggleModal, onExpirationChange, license }) => {
  const [validUntil, setValidUntil] = useState(license.validUntil);
  const [demo, setDemo] = useState(license.demo);

  const closeModal = () => {
    setValidUntil(license.validUntil);
    toggleModal();
  };

  useEffect(() => {
    setValidUntil(license.validUntil);
    setDemo(license.demo);
  }, [license]);

  return (
    <Modal className="create-license-modal" isOpen={isOpen} fade={false} toggle={toggleModal} centered>
      <ModalHeader className="header-gray" toggle={toggleModal} tag="div">
        <h5>Set Expiration Date</h5>
        <div className="modal-subtitle">
          {`You are setting the expiration date of the license: ${license.id}`}
        </div>
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="row">
            <div className="form-group col-8">
              <label>Valid Until</label>
              <CustomInput
                id="lincense-valid-from"
                type="date"
                value={validUntil ? validUntil.split('T')[0] : ''}
                isEditable
                onChange={e => {
                  const { value } = e.target;
                  setValidUntil(value);
                }}
                autoFocus={true} />
            </div>

            <div className="form-group col-4">
              <label>Demo</label>
              <CustomCheckBox
                label={'Testing Period'}
                selected={demo ? 'all' : 'none'}
                onClick={val => setDemo(!val)}/>
            </div>
          </div>
        </form>
        <div className="button-container">
          <CustomButton
            title="Cancel"
            classes="btn-secondary"
            handleClick={closeModal} />
          <CustomButton
            disabled={(validUntil === license.validUntil && demo === license.demo)}
            title="Reassign License"
            classes="btn-primary"
            handleClick={() => onExpirationChange(validUntil, demo)} />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ExpirationLicenseModal;