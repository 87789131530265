import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getSelectedSolution, getSelectedProject } from '../../redux/projects/actions';
import { getUser, setAlert } from '../../redux/dashboard/actions';
import calendarUtils from '../../utils/calendar';
import API from '../../services/api';
import utils from '../../utils';
import { availableCMS } from '../../utils/solutions';
import { insightsTableHeaders, insightsTableData } from '../../utils/solutions';
import components from '../../components';
import icons from '../../assets/icons';
import './styles.scss';

const { Table, DateTimeFilterBar } = components;

const InsightsContainer = ({ lastUpdated }) => {
  const project = useSelector(getSelectedProject);
  const user = useSelector(getUser);
  const selectedSolution = useSelector(getSelectedSolution);

  const [campaignNavigation, setCampaignNavigation] = useState(null);
  const [data, setData] = useState([]); // Data returned by API
  const [items, setItems] = useState([]);
  const [itemsCount, setItemsCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState(calendarUtils.getDefaultDate('start'));
  const [endTime, setEndTime] = useState(calendarUtils.getDefaultDate('end'));
  const [filterByTime, setFilterByTime] = useState({});
  const [filterByWeekday, setFilterByWeekday] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const fetchIdRef = useRef(0);
  const dispatch = useDispatch();

  // Update after filters and redux changes
  useEffect(() => {
    if (user && selectedSolution && !loading) {
      const projectId = project ? project.id : '';
      const solution = selectedSolution.id;
      const table = selectedSolution.table;
      const body = generateRequestBody(selectedSolution.cms);

      setLoading(true);

      API.getTableData(projectId, table, body)
        .then(res => {
          const { rows } = res;
          const tableHeaderParams = {
            cmsSchema: availableCMS[selectedSolution.id],
            preferences: project.preferences,
            selectedCampaign: selectedCampaign
          };
          const columnsData = insightsTableHeaders[solution](tableHeaderParams);
          const data = insightsTableData[solution](rows);
          setColumns(columnsData);

          if (data && data.length > 0) {
            setData(data);
          } else {
            setData([]);
          }
        })
        .catch(err => {
          setData([]);
          dispatch(setAlert(utils.generateAlert('Something went wrong. Please try again.', 'error')));
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    user,
    project,
    selectedSolution,
    startTime,
    endTime,
    filterByTime,
    filterByWeekday,
    selectedCampaign,
    lastUpdated
  ]);

  useEffect(() => {
    // Reset selected campaign after changing project or solution
    setSelectedCampaign('');
    if (project && project.preferences) {
      setStartTime(calendarUtils.getDefaultDate('start'));
      setEndTime(calendarUtils.getDefaultDate('end'));
    }
  }, [project, selectedSolution]);

  useEffect(() => {
    if (selectedCampaign && selectedCampaign !== '') {
      setCampaignNavigation({ title: `Campaign: ${selectedCampaign}`, goBackButton: goBack });
    } else setCampaignNavigation(null);
  }, [selectedCampaign]);

  const generateRequestBody = isCms => {
    const body = {
      layout: 'deviceGroup',
      startTime: calendarUtils.replaceOffset(startTime, project.preferences.timezone),
      endTime: calendarUtils.replaceOffset(endTime, project.preferences.timezone),
      filterByTime,
      filterByWeekday,
      groupBy: ['deviceId'],
      timezone: project.preferences.timezone
    };

    if (isCms) {
      const { columns } = availableCMS[selectedSolution.id];

      if (selectedCampaign) {
        const filterBy = {
          column: columns.advertisers,
          value: selectedCampaign
        };

        return {
          ...body,
          filterBy,
          groupBy: [columns.medias, columns.sites]
        };
      }

      return {
        ...body,
        groupBy: [columns.advertisers, columns.sites]
      };
    }

    return body;
  };

  const handleRowClick = row => {
    if (selectedSolution.cms && !selectedCampaign) {
      const { columns } = availableCMS[selectedSolution.id];
      setSelectedCampaign(row[columns.advertisers]);
    }
  };

  const goBack = () => {
    setSelectedCampaign('');
  };

  const onPeriodSelected = payload => {
    const { startDate, endDate, filterByTime, filterByWeekday } = payload;
    setStartTime(startDate);
    setEndTime(endDate);
    setFilterByTime(filterByTime ? filterByTime : {});
    setFilterByWeekday(filterByWeekday ? filterByWeekday : []);
  };

  // This is called when the table needs new data
  const fetchData = useCallback(
    ({ pageIndex, pageSize, sortBy }) => {
      // Load table empty state
      if (data.length === 0) {
        setItems([]);
        setItemsCount(0);
        setPageCount(0);
        return;
      }

      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      // Generate sort config object
      const sortByQuery = sortBy[0];
      const sortByConfig = utils.generateSortByConfig(sortByQuery, 'deviceName', columns);

      // Perform data sort
      const items = utils.sort(data, sortByConfig);

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;

        // Retrieve fetched data
        setItems(items.slice(startRow, endRow));

        // Compute total number of items
        setItemsCount(items.length);

        // Compute total page count
        setPageCount(Math.ceil(items.length / pageSize));
      }
    },
    [data, columns]
  );

  if (!selectedSolution) {
    return (
      <div className="no-data-message">
        <h1>No data found</h1>
      </div>
    );
  }

  return (
    <div className="comparison-container">
      <div className="filter-bar d-flex">
        <div className="title">
          <img src={icons.insights} alt="Compare" />
          <h2>{selectedSolution.cms ? `CMS Insights` : `Insights`}</h2>
        </div>
        <DateTimeFilterBar hideIntervalSelector onPeriodSelected={onPeriodSelected} />
      </div>
      {loading || data.length > 0 ? (
        <Table
          columns={columns}
          data={items}
          fetchData={fetchData}
          loading={loading || !project}
          navigation={campaignNavigation}
          hasInteractiveRows={!selectedCampaign && selectedSolution.cms}
          pageCount={pageCount}
          itemsCount={itemsCount}
          onItemSelected={handleRowClick}
          solution={selectedSolution.id}
        />
      ) : (
        <div className="no-data-message">
          <h1>No data</h1>
        </div>
      )}
    </div>
  );
};

export default InsightsContainer;
