import React from 'react';
import icons from '../../assets/icons';
import { UncontrolledTooltip } from 'reactstrap';
import './styles.scss';

const IconButton = ({
  id,
  icon,
  imgClasses,
  btnClasses,
  onPress,
  tooltip,
  disabled,
  loading
}) => {
  return (
    <button
      id={id}
      disabled={disabled}
      className={`btn icon-button ${btnClasses}`}
      onClick={onPress}>
      {
        loading
          ? <span
              className="spinner-border spinner-border-sm"
              role="status" aria-hidden="true"/>
          : <img
              src={icons[icon]}
              alt={icon}
              className={imgClasses} />
      }
      { tooltip &&
        <UncontrolledTooltip placement="top" target={id}>
          { tooltip }
        </UncontrolledTooltip>
      }
    </button>
  )
};

export default IconButton;