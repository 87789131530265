import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { UncontrolledTooltip } from 'reactstrap';
import icons from '../../assets/icons';
import utils from '../../utils';

import './styles.scss';

const PresetCompareModal = ({
  title = "Are you sure you want to apply all the changes you have done?",
  subtitle = "Here is what will be changed.",
  isOpen,
  handleClose,
  configs,
  applyChanges
}) => {

  const renderStatus = (status) => {
    if (status && status !== 'equal') {
      const statusLegends = {
        'added': 'success',
        'removed': 'danger',
        'modified': 'info'
      };

      return (
        <span className={`status badge badge-pill badge-outline-${statusLegends[status]}`}>
          {status.toUpperCase()}
        </span>
      );
    }
  };

  const renderSubtitle = (text) => {
    return utils.capitalize(utils.splitCamelCase(text));
  };

  const renderValue = (value) => {
    if (value !== undefined) {
      return Array.isArray(value)
        ? JSON.stringify(value).replaceAll(',', ', ')
        : JSON.stringify(value);
    }
    return "-";
  };

  const renderSection = (sections) => {
    if (sections.length) {
      return sections.map((category) => (
        <div key={`category-${category.subtitle}`}>
          <div className="table-session-subtitle d-flex flex-fill row">
            <div className="col d-flex align-items-center">
              <div className="section-title">
                <h4>
                  {renderSubtitle(category.subtitle)}
                </h4>
              </div>
              {renderStatus(category.status)}
            </div>
          </div>
          <div className="table-session-body d-flex flex-fill flex-column">
            {
              category.values.map((row) => (
                <div key={`row-${row.title}`} className="d-flex flex-fill row table-row">
                  <div className="col">{row.title}</div>
                  <div className="col-3 text-center old-value">{renderValue(row.values.old)}</div>
                  <div className="col-3 text-center new-value"><b>{renderValue(row.values.new)}</b></div>
                </div>
              ))
            }
          </div>
        </div>
      ));
    }

    return (
      <div className="no-changes-session">No changes here</div>
    );
  }

  if (!configs || !configs.content || !isOpen) {
    return null;
  }

  return (
    <Modal size="lg" isOpen={isOpen} fade={false} toggle={handleClose} centered id="preset-modal">
      <ModalHeader className="header-preset" toggle={handleClose}>
        {title}
      </ModalHeader>
      <h5 className="subtitle-modal">{subtitle}</h5>
      <ModalBody>
        <div className="d-flex compare-object-table flex-column">
          <div className="table-title d-flex flex-fill row">
            <div className="justify-content-end d-flex flex-fill">
              <div className="col-3 d-flex justify-content-center align-items-center" id="old-solution-config">
                <span>{configs.old.title}</span>
                <UncontrolledTooltip placement="top" target="old-solution-config">
                  {configs.old.label}
                </UncontrolledTooltip>
                <img className="info-icon" src={icons.info} alt="info" />
              </div>
              <div className="col-3 d-flex justify-content-center align-items-center" id="now-solution-config">
                <span>{configs.new.title}</span>
                <UncontrolledTooltip placement="top" target="now-solution-config">
                  {configs.new.label}
                </UncontrolledTooltip>
                <img className="info-icon" src={icons.info} alt="info" />
              </div>
            </div>
          </div>
          <div>
            {
              configs.content.map(({ title, sections }, indexSection) => (
                <div key={indexSection} className="table-session d-flex flex-fill flex-column">
                  <div className="table-session-title d-flex flex-fill row">
                    <div className="col">
                      <h2 className="tab-label">
                        { title &&
                          utils.capitalize(title.toLowerCase())
                        }
                      </h2>
                    </div>
                  </div>
                  <div>
                    { renderSection(sections) }
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-fill justify-content-end">
          <div className="modal-buttons d-flex">
            <button type="button" className="btn btn-secondary outline" onClick={() => handleClose()}>Cancel</button>
            <button type="button" className="btn btn-primary ml-3" onClick={() => applyChanges()}>Apply</button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default PresetCompareModal;