import React, { useEffect, useState } from 'react';
import './styles.scss';
import VirtualRange from './virtualRange';

const CustomRangeInput = ({ id, value, minValue, maxValue, step, onChange, isEditable, suffix, valType }) => {
  const [position, setPosition] = useState(0);
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    updatePosition();
  }, [currentValue]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value, isEditable]);

  const updatePosition = () => {
    const currentPosition = (100 * (currentValue - minValue)) / (maxValue - minValue);
    setPosition(`${currentPosition}%`);
  };

  const handleChange = val => {
    setCurrentValue(val);

    // If input value expected is an int parse it before callback func
    if (valType === 'integer') {
      onChange(parseInt(val));
    } else {
      onChange(parseFloat(val));
    }
  };

  return (
    <div className="d-flex threshold-range">
      <div className="label">
        {currentValue}
        {suffix}
      </div>
      <VirtualRange disabled={!isEditable} position={position} />
      <input
        type="range"
        className="custom-range"
        min={minValue}
        max={maxValue}
        step={step || '1'}
        id={id}
        onChange={e => handleChange(e.target.value)}
        value={currentValue}
        disabled={!isEditable}
      />
    </div>
  );
};

export default CustomRangeInput;
