import React from 'react';
import utils from '../../utils';
import deviceTemplate from '../../utils/device/deviceTemplate';
import GeneralInput from '../generalInput';

import './styles.scss';

const getInputProps = (inputTemplate, inputValue, isEmpty, isEditable, handleChange) => {
  return {
    value: typeof inputValue !== 'undefined' ? inputValue : inputTemplate.value,
    classes: isEmpty,
    id: '',
    isEditable: inputTemplate.isEditable && isEditable,
    onChange: val => handleChange(inputTemplate.path, val),
    type: inputTemplate.type,
    isCopyButtonEnabled: inputTemplate.isCopyButtonEnabled,
    options: inputTemplate.options,
    valueField: inputTemplate.valueField,
    displayField: inputTemplate.displayField,
    label: inputTemplate.label,
    step: inputTemplate.step,
    minValue: inputTemplate.minValue,
    maxValue: inputTemplate.maxValue,
    suffix: inputTemplate.suffix,
    valType: inputTemplate.valType,
    showDate: inputTemplate.showDate,
    showTime: inputTemplate.showTime,
    isAdminOnly: inputTemplate.isAdminOnly
  };
};

const renderInput = (inputIndex, fieldKeys, inputTemplate, renderOnSidebar, inputProps) => {
  if (inputTemplate.type === 'models') {
    return (
      <div key={inputIndex} className={renderOnSidebar ? '' : 'col-12'}>
        {
          <div className="form-group">
            <GeneralInput
              props={{
                ...inputProps,
                id: `input-${fieldKeys[inputIndex]}-${inputIndex}`,
                renderOnSidebar: renderOnSidebar
              }}
            />
          </div>
        }
      </div>
    );
  }

  return (
    <div key={inputIndex} className={renderOnSidebar ? 'col-12' : 'col-6'}>
      {
        <div className="form-group">
          <label>{inputTemplate.label}</label>
          <GeneralInput props={{ ...inputProps, id: `input-${fieldKeys[inputIndex]}-${inputIndex}` }} />
        </div>
      }
    </div>
  );
};

const render = (device, fields, handleChange, isEditable, renderOnSidebar, isAdminUser) => {
  const inputTemplateList = fields ? Object.values(fields) : [];
  const fieldKeys = fields ? Object.keys(fields) : [];

  return inputTemplateList.map((inputTemplate, inputIndex) => {
    const inputValue = inputTemplate.path ? utils.get(device, inputTemplate.path) : undefined;
    const isEmpty = inputValue ? '' : 'is-empty';
    const inputProps = getInputProps(inputTemplate, inputValue, isEmpty, isEditable, handleChange);

    if (inputTemplate.isAdminOnly && !isAdminUser) {
      return null;
    }

    return renderInput(inputIndex, fieldKeys, inputTemplate, renderOnSidebar, inputProps);
  });
};

const DeviceInputs = ({
  device,
  sections,
  handleChange,
  isEditable,
  showSectionLabel,
  renderOnSidebar,
  isAdminUser
}) => {
  const solution = device.solution || device.solutionId;

  const advancedFields = deviceTemplate.advanced.fields[solution];
  const deviceTemp = { ...deviceTemplate, advanced: advancedFields };

  return sections.map((section, templateIndex) => {
    const tabLabel = deviceTemp[section].label;
    const sections = deviceTemp[section].fields;
    const sectionsKeys = sections ? Object.keys(sections) : [];
    const sectionsValues = sections ? Object.values(sections) : [];

    return (
      <div key={templateIndex} className="device-input-section">
        {showSectionLabel && <h2 className="tab-label">{tabLabel}</h2>}
        {sectionsKeys.map((sectionKey, sectionIndex) => {
          const sectionTitle = sectionsValues[sectionIndex].label;
          const sectionFields = sectionsValues[sectionIndex].fields;

          if (sectionsValues[sectionIndex].isAdminOnly && !isAdminUser) {
            return null;
          }

          return (
            <section key={sectionIndex} className="section-container">
              <div className="section-title">
                <h4>{sectionTitle}</h4>
              </div>
              <div className="row">
                {render(device, sectionFields, handleChange, isEditable, renderOnSidebar, isAdminUser)}
              </div>
            </section>
          );
        })}
      </div>
    );
  });
};

export default DeviceInputs;
