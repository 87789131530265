import React, { useEffect, useState } from 'react';

import { FixedSizeList as List } from "react-window";
import { Button } from 'reactstrap';
import SearchBox from '../searchBox';
import icons from '../../assets/icons';
import Dropdown from './dropdown';

import './styles.scss';

const DropdownSearch = ({
  selected,
  options,
  onSelect,
  label,
  disabled,
  leftAlign,
  openUp,
  customWidth,
  outline = true
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [items, setItems] = useState([]);
  const [searchingBy, setSearchingBy] = useState('');

  useEffect(() => {
    setItems(options);
  }, [options]);

  useEffect(() => {
    setValue(selected);
  }, [selected]);

  useEffect(() => {
    const filteredItems = options.filter(item => item.label.toUpperCase().includes(searchingBy.toUpperCase()));
    // Update items with searched items
    setItems(filteredItems);
  }, [searchingBy]);

  const toggleOpen = () => {
		setIsOpen(!isOpen);
  };

  const handleSelection = (data) => {
    // Close menu and update component render
    toggleOpen();
    setValue(data);
    setSearchingBy('');
		onSelect(data.value);
  };

  const renderLabel = () => {
    return (label && label.length > 0)
      ? <div className="dropdown-label">{ label }</div>
      : null;
  };

  const renderRow = ({index, style}) => (
    <div
      className={`dropdown-search-list-item`}
      style={style}
      onClick={() => handleSelection(items[index])}
      >
      {items[index].label}
    </div>
  );

  const renderEmptyMessage = () => {
    if (items.length > 0) {
      return null;
    }

    return (
      <div className="empty-message">No options</div>
    )
  }

  const renderValue = (value) => {
    if (!value) return value;
    return value.length > 30 ? `${value.slice(0, 22)}...${value.slice(-5)}` : value;
  };

  return (
    <div className={`dropdown-search ${outline ? "outline": ""} ${leftAlign ? "left-align": ""} ${openUp ? "open-up" : ""}`}>
      { renderLabel() }
      <Dropdown
        customWidth={customWidth}
        isOpen={isOpen}
        onClose={toggleOpen}
        target={
          <Button onClick={toggleOpen} active={isOpen} disabled={disabled}>
            <span className="current-value" alt={value ? value.label : ''}>
              { value ? renderValue(value.label) : 'Select' }
            </span>
            <img src={icons.angleDown} className="arrow-down" alt="arrow-down"/>
          </Button>
        } >
        <SearchBox
          onChange={setSearchingBy}
          placeholder="Search..."
          searchingBy={searchingBy}
          className="outline dropdown-list-search-box"
          autoFocus/>
        <List
          className={`dropdown-search-list ${items.length === 0 ? 'empty' : ''}`}
          height={200}
          itemCount={items.length}
          itemSize={48}
          width={customWidth ? customWidth : 332}>
            {renderRow}
        </List>
        {renderEmptyMessage()}
      </Dropdown>
    </div>
  );
};

export default DropdownSearch;