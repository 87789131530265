import utils from '..';

const speedUnitOptions = [
  {
    id: 'MILES_PER_HOUR',
    name: 'Miles/Hour',
    shortName: 'mph',
    conversionFactor: 2.237
  },
  {
    id: 'KM_PER_HOUR',
    name: 'Kilometers/Hour',
    shortName: 'km/h',
    conversionFactor: 3.6
  },
  {
    id: 'METERS_PER_SECOND',
    name: 'Meters/Second',
    shortName: 'm/s',
    conversionFactor: 1
  }
];

const projectPreferences = {
  enableAnomalyDetection: true,
  speedUnitOptions,
  speedUnit: speedUnitOptions[1],
  maxSessionTime: 120.0,
  maxDwellTime: 60.0,
  viewThreshold: 0.5,
  viewMetric: 'ATTENTION',
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  maxConfidenceAge: 0,
  maxConfidenceEmotion: 0,
  maxConfidenceGender: 0,
  minConfidenceAge: 0,
  minConfidenceEmotion: 0,
  minConfidenceGender: 0,
  vehicleMultiplier: {
    car: 2,
    motorcycle: 1,
    bus: 35,
    truck: 2
  }
};

const getPreferencesTemplate = () => {
  const newPreferences = utils.deepClone(projectPreferences);
  delete newPreferences.speedUnitOptions;
  newPreferences.speedUnit = newPreferences.speedUnit.coreId;
  return newPreferences;
};

export default {
  projectPreferences,
  getPreferencesTemplate
};
