import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import UserContainer from '../../containers/user';

const ManageUserModal = ({ isOpen, toggleModal }) => {
  return (
    <Modal size="lg" isOpen={isOpen} fade={false} toggle={toggleModal} centered>
      <ModalHeader className="header-gray" toggle={toggleModal} tag="div">
        <h5>Manage Users</h5>
      </ModalHeader>
      <ModalBody>
        <UserContainer />
      </ModalBody>
    </Modal>
  );
};

export default ManageUserModal;
