import React, { useState, useEffect } from 'react';
import { isAfter, parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { summary, analytics, management, advanced } from './navigationRoutes';
import UserDropdown from '../../components/userDropdown';
import { setAlert } from '../../redux/dashboard/actions';
import { getSelectedProject } from '../../redux/projects/actions';
import icons from '../../assets/icons';
import utils from '../../utils';
import API from '../../services/api';

import './styles.scss';

const routes = [summary(), analytics(), management(), advanced()];

const MainNavigation = ({
  location,
  history,
  user,
  hideNavigation,
  stopImpersonating,
  openImpersonateModal,
  featureFlags
}) => {
  const [expanded, setExpanded] = useState('');

  const selectedProject = useSelector(getSelectedProject);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedRoute();
  }, [location]);

  useEffect(() => {
    checkExpiredLicenses();
  }, [location.pathname, selectedProject]);

  const userOptions = [
    {
      name: 'Profile',
      icon: 'userDark',
      url: '/#/profile'
    },
    {
      name: 'Impersonate User',
      icon: 'impersonateEye',
      action: openImpersonateModal,
      isAdminOnly: true
    },
    {
      name: 'Logout',
      icon: 'logoutDark',
      url: '/#/logout'
    }
  ];

  const checkExpiredLicenses = () => {
    if (selectedProject && featureFlags['dashboard-expired-license-alert']) {
      API.getProjectStats(selectedProject.companyId, selectedProject.id)
        .then(response => {
          const hasExpired = response.licenseCountByStatus.expired > 0
          if (hasExpired) {
            dispatch(
              setAlert(
                utils.generateAlert(
                  'You have expired licenses in your project.',
                  'error',
                  -1,
                  'https://subscriptions.zoho.com/portal/admobilize/login',
                  true
                )
              )
            );
          }
        })
        .catch(console.error);
    }
  };

  const setSelectedRoute = () => {
    setExpanded('');
    // Open submenu in case the selected page is a subitem on page load
    routes.forEach(route => {
      if (route.subitems) {
        // Verifies if this is a selected subitem
        if (route.subitems.some(item => item.to === location.pathname)) {
          setExpanded(route.key);
        }
      }
    });
  };

  const handleNavClick = (route, event) => {
    if (event) {
      if (route.subitems) {
        // Disable default behaviour for click when a navbar item have subitems
        event.preventDefault();

        // Navigate imediatelly to first subitem
        const firstItem = route.subitems[0];
        setExpanded(firstItem.key);
        history.replace(firstItem.to);
      } else {
        history.replace(route.to);
      }
    }
  };

  const handleNavArrowClick = (route, event) => {
    if (event && route.subitems) {
      // Disable default behaviour for click when a navbar item have subitems
      event.preventDefault();

      if (expanded === route.key) {
        setExpanded('');
      } else {
        if (route.subitems) {
          setExpanded(route.key);
        }
      }
    } else {
      if (expanded !== '') {
        setExpanded('');
      }
    }
  };

  const isActive = (location, target) => {
    // Menu entries with subitems should have target (to) URL as #
    if (target === '#') {
      return false;
    }

    const homepageTarget = '/summary';
    const homepageOptions = ['/index.html', '/'];

    // The homepage can have URL variations like the ones listed obove
    if (target === homepageTarget && homepageOptions.includes(location.pathname)) {
      return true;
    } else if (location.pathname === target) {
      return true;
    }

    return false;
  };

  const renderNavOptions = items => {
    const isPowerUser = user && (user.permissions['support'] || user.impersonator);

    return (
      <ul className="d-flex flex-column flex-fill">
        {items.map(route => {
          if (route.isAdminOnly && !isPowerUser) return null;
          if (route.featureEnabled && !featureFlags[route.featureEnabled]) return null;
          const isExpanded = expanded === route.key;

          return (
            <li key={route.key} className={isExpanded ? 'active-submenu' : ''}>
              <div className={`menu-entry ${isActive(location, route.to) ? 'active' : ''}`}>
                <div className="menu-entry-label" onClick={event => handleNavClick(route, event)}>
                  <img src={icons[route.icon]} alt="" />
                  <span>{route.name}</span>
                </div>
                {route.subitems && (
                  <div className="submenu-indicator" onClick={event => handleNavArrowClick(route, event)}>
                    <div className={`expand-button ${isExpanded ? 'expanded' : ''}`}>
                      <img src={icons.angleDown} alt="" />
                    </div>
                  </div>
                )}
              </div>
              {isExpanded && route.subitems && renderNavOptions(route.subitems)}
            </li>
          );
        })}
      </ul>
    );
  };

  if (!user || hideNavigation) {
    return <div />;
  }

  return (
    <div className="sidebar-wrapper">
      <div className="sidebar-navigation">
        <a href="/" className="company-logo">
          {''}
        </a>
        {renderNavOptions(routes)}
        <UserDropdown stopImpersonating={stopImpersonating} user={user} items={userOptions} />
      </div>
    </div>
  );
};

export default MainNavigation;
