import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, Marker, Circle, Autocomplete } from '@react-google-maps/api';
import { Loader } from '@googlemaps/js-api-loader';
import CustomInput from '../../../components/customInput';
import getMapDefinition from './mapsDefinition';
import config from '../../../config';
import utils from '../../../utils/site'

const Locations = ({ site, updateSite }) => {
  const [center, setCenter] = useState(null);
  const [latitude, setLatitude] = useState(site.latitude ? site.latitude : '');
  const [longitude, setLongitude] = useState(site.longitude ? site.longitude : '');
  const [countryCode, setCountryCode] = useState(site.countryCode ? site.countryCode  : '')
  const [address, setAddress] = useState(site.address ? site.address : '');
  const [autocomplete, setAutocomplete] = useState(null);
  const [loader, setLoader] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [mapDefinition, setMapDefinition] = useState(getMapDefinition(center));

  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      setMapDefinition(getMapDefinition(center));
      if (site.latitude && site.longitude) {
        if(site.latitude !== '' && site.longitude !== ''){
          setCenter({ lat: site.latitude, lng: site.longitude });
        }
      } else {
        setCenter(mapDefinition.defaultCenter)
      }
      const localLoader = new Loader({
        apiKey: config.mapsApi.key,
        version: 'weekly',
        libraries: config.mapsApi.libraries
      });
      localLoader.load().then(() => setMapLoaded(true));
      setLoader(localLoader);
    }
    isMounted.current = true;
  }, []);

  useEffect(() => {
    if (center) {
      setLatitude(center.lat);
      setLongitude(center.lng);
      fetchLocation(center.lat, center.lng);
      setMapDefinition(getMapDefinition(center));
    }
  }, [center]);

  useEffect(() => {
    if (center) {
      updateSite({ ...site, latitude: center.lat, longitude: center.lng, address, countryCode });
    }
  }, [center, address]);

  const updateCenter = (value, coord) => {
    if (value) {
      if (!center) {
        const newCenter = { lat: 0, lng: 0 };
        newCenter[coord] = parseInt(value);
        setCenter(newCenter);
      } else {
        setCenter({ ...center, [coord]: parseInt(value) });
      }
    }
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const lat = autocomplete.getPlace().geometry.location.lat();
      const lng = autocomplete.getPlace().geometry.location.lng();
      setCenter({
        lat,
        lng
      });
      setAddress(autocomplete.getPlace().formatted_address);
    }
  };

  const handlePlusCode = address => {
    const plusCodeRegex = /^[A-Za-z0-9]{4}\+[A-Za-z0-9]{2}$/;
    const firstWord = address.split(' ')[0];
    const isPlusCode = plusCodeRegex.test(firstWord); //test if there is a plus code in front of address
    if (isPlusCode) {
      return address.substring(address.indexOf(' ') + 1);
    }
    return address;
  };

  const handleLocationName = addresses => {
    
    if(addresses.results.length > 0) {
      return handlePlusCode(addresses.results[0].formatted_address);
    }
    
    return null;
  };

  const fetchLocation = (lat, lng) => {
    if (lat && lng) {
      loader
        .load()
        .then(google => {
          const geocoder = new google.maps.Geocoder();
          const latlng = {
            lat: lat,
            lng: lng
          };

          geocoder
            .geocode({ location: latlng })
            .then(response => {
              const address = handleLocationName(response);
              if (address) {
                setAddress(address);
                setCountryCode(utils.getCountryCode(response.results))
              } else {
                console.error(response.error_message);
              }
            })
            .catch(error => console.error(error));
        })
        .catch(error => console.error(error));
    }
  };

  return (
    <>
      <h4 className="section-label">Select the location by typing the information or selecting on the map</h4>
      {mapLoaded && (
        <>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label className={`${address === '' ? 'input-error' : ''}`}>Address</label>
                <Autocomplete onLoad={setAutocomplete} onPlaceChanged={onPlaceChanged}>
                  <CustomInput
                    id={'address'}
                    placeholder={'Type an address'}
                    isEditable={true}
                    onChange={() => {}}
                    value={address}
                    icon={'location'}
                  />
                </Autocomplete>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label className={`${latitude && latitude !== '' ? '' : 'input-error'}`}>Latitude</label>
                <CustomInput
                  id={'latitude'}
                  placeholder={'e.g. 25.7914'}
                  isEditable={true}
                  value={latitude}
                  min={-90}
                  max={90}
                  type="number"
                  onChange={e => {
                    setLatitude(e.target.value);
                    updateCenter(e.target.value, 'lat');
                  }}
                  icon={'pin'}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label className={`${longitude && longitude !== '' ? '' : 'input-error'}`}>Longitude</label>
                <CustomInput
                  id={'longitude'}
                  placeholder={'e.g. -80.1389'}
                  isEditable={true}
                  value={longitude}
                  min={-180}
                  max={180}
                  type="number"
                  onChange={e => {
                    setLongitude(e.target.value);
                    updateCenter(e.target.value, 'lng');
                  }}
                  icon={'pin'}
                />
              </div>
            </div>
          </div>
          <GoogleMap
            zoom={mapDefinition.zoom}
            center={mapDefinition.center}
            options={{ disableDefaultUI: true, zoomControl: true }}
            mapContainerClassName="map-container"
            onClick={e => {
              setCenter({lat: e.latLng.lat(), lng: e.latLng.lng()})
            }}
          >
            <Marker position={mapDefinition.position}></Marker>
            <Circle center={mapDefinition.position} radius={site.radius} options={mapDefinition.circleOptions} />
          </GoogleMap>
        </>
      )}
    </>
  );
};

export default Locations;
