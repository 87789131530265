import utils from "..";

/**
 * Get customConfig by extracting solutionConfig (current interface values) from defaultConfig (preset template)
 * customConfig = solutionConfig - defaultConfig
 * @param {*} defaultConfig first object
 * @param {*} solutionConfig second object
 */
export const getCustomConfig = (defaultConfig, solutionConfig) => {
  let diff = {};
  for (let objectKey in solutionConfig) {
    if ("object" === typeof solutionConfig[objectKey] && !utils.isNullish(defaultConfig) && "object" === typeof defaultConfig && "object" === typeof defaultConfig[objectKey]) {
      if (Array.isArray(defaultConfig[objectKey]) || Array.isArray(solutionConfig[objectKey])) {
        if (Array.isArray(defaultConfig[objectKey]) && Array.isArray(solutionConfig[objectKey])) {
          if (!utils.compareArrays(defaultConfig[objectKey], solutionConfig[objectKey])) {
            diff[objectKey] = solutionConfig[objectKey];
          }
        } else {
          diff[objectKey] = solutionConfig[objectKey];
        }
      } else {
        diff[objectKey] = getCustomConfig(defaultConfig[objectKey], solutionConfig[objectKey]);
      }
    } else if (solutionConfig[objectKey] !== defaultConfig[objectKey]) {
      diff[objectKey] = solutionConfig[objectKey];
    }
  }
  return diff;
}