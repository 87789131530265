import React, { Component } from 'react';

import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { Alert } from 'reactstrap';
import {
  getUser,
  setUser,
  updateUser,
  getAlert,
  setAlert,
  getLoading,
  setLoading,
  getFeatureFlags
} from './redux/dashboard/actions';

import { selectSolution } from './redux/projects/actions';

// Monitoring
import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';
import hotjar from './utils/hotjar';

import screens from './screens';
import components from './components';

import utils from './utils';

import API from './services/api';
import config from './config';
import credentialsStore from './services/credentialsStore';

import './App.scss';
import icons from './assets/icons';
import ImpersonateFrame from './components/impersonateFrame';
import { getCustomWidgets } from './utils/user/preferences';

const { MainNavigation, Login, Logout, ForgotPassword, Signup } = components;

class App extends Component {
  constructor(props) {
    super(props);
    // this is used to hide the left navigation bar and to control auth redirection
    this.loginPaths = ['/login', '/forgot'];
    this.shortcutKeys = ['alt', 'i'];
    this.state = {
      impersonateModalOpened: false
    };
  }

  async componentDidMount() {
    this.loadWhitelabel();
    await this.checkCredentials(true);
  }

  // noinspection JSCheckFunctionSignatures
  async componentDidUpdate(prevProps) {
    const { pathname } = this.props.location;
    const { user } = this.props;

    if (user !== null && prevProps.user !== user) {
      this.setupMonitoringServices();
    }

    // When route has changed after logout
    if (prevProps.location.pathname !== pathname) {
      if (pathname === '/logout' || user === null) {
        this.props.setLoading(true);
        (await prevProps.location.pathname) === '/signup' ? this.checkCredentials(true) : this.checkCredentials();
      }
    }
  }

  setupMonitoringServices = () => {
    const { location, user } = this.props;

    // Setup Monitoring for production env only
    if (process.env.REACT_APP_STAGE === 'prod' && !this.props.isLocalEnv) {
      // Send user information to Sentry
      Sentry.configureScope(scope => {
        scope.setUser({
          id: user.id,
          email: user.email
        });
      });

      // Initialize Google Analytics
      ReactGA.initialize(config.googleAnalytics.trackingId);

      // Send user information to Google Analytics
      ReactGA.set({ dimension1: user.id });
      ReactGA.set({ dimension2: user.email });

      // Record a pageview for the given page
      ReactGA.pageview(location.pathname);

      // Initialize Hotjar and send user information
      hotjar.initialize(config.hotjar.hjid, config.hotjar.hjsv);
      hotjar.identify(user.id, { email: user.email });
    }
  };

  isAdMobilizeDashboard = () => {
    const hostname = window.location.hostname;
    return /localhost|admobilize/.test(hostname);
  };

  loadWhitelabel = () => {
    // Extract whitelabel from URL
    let hostname = window.location.hostname;

    if (this.isAdMobilizeDashboard()) {
      hostname = 'dashboard.admobilize.com';
      // Uncomment the following line to use the whitelabel
      // hostname = 'ooh-analytics.bunkerdb.com';
    }

    // Get css file from Google bucket
    const linkDFavicon = document.createElement('link');
    const linkCss = document.createElement('link');

    linkDFavicon.type = 'image/x-icon';
    linkDFavicon.rel = 'shortcut icon';
    linkCss.type = 'text/css';
    linkCss.rel = 'stylesheet';

    /**
     * For local environment use: npm install -g live-server
     * Run live-server inside of whitelabel folder
     * Uncomment the 3 following lines and comment the 3 subsequent
     */
    // const localWhitelabelServer = 'http://127.0.0.1:8080/dashboard';
    // linkDFavicon.href = `${localWhitelabelServer}/icons/${hostname}.ico`;
    // linkCss.href = `${localWhitelabelServer}/styles/${hostname}.css`;

    /**
     * Comment those lines in case you're using localWhitelabelServer for whitelabel
     */
    const googleCloudStorage = 'https://storage.googleapis.com/assets.admobilize.com/dashboard';
    linkDFavicon.href = `${googleCloudStorage}/icons/${hostname}.ico`;
    linkCss.href = `${googleCloudStorage}/styles/${hostname}.css`;

    document.getElementsByTagName('head')[0].appendChild(linkCss);
    document.getElementsByTagName('head')[0].appendChild(linkDFavicon);
    document.title = this.isAdMobilizeDashboard()
      ? 'AdMobilize Dashboard'
      : hostname.replace(/\.(com.br|com|co|site)/, '').replace(/\./, ' ');
  };

  checkCredentials = async needsSetUser => {
    const { pathname, search } = this.props.location;
    const loginToken = this.getTokenParam(pathname, '/login/');
    const signupToken = this.getTokenParam(search, 'token=');

    // Skip credencial check when a token is passed on URL
    if (loginToken) {
      return this.loginWithToken(loginToken);
    }

    if (signupToken) {
      this.props.setLoading(false);
      return;
    }

    try {
      // Skip credentials check in case the user is doing a successful login
      if (this.loginPaths.includes(pathname)) {
        this.props.setLoading(false);
      } else {
        // TODO Invert the test once we have the checkbox on Login Screen
        const keepSession = window.localStorage.getItem('keepSession') !== 'false';

        // User has flag to keep session
        if (keepSession) {
          let impersonatedUser = localStorage.getItem('userImpersonated');

          const credentials = credentialsStore.get();

          // Tokens can't be get from API for impersonated users bc they don't have refreshToken
          const tokens = credentials.refreshToken
            ? await API.refreshSession(credentials.refreshToken)
            : { accessToken: credentials.accessToken, expiresIn: 3600, refreshToken: null };

          let userInfo = (await API.getUser(credentials.id, tokens.accessToken)) || this.props.user;

          userInfo.permissions = {};
          userInfo.preferences.customWidgets = getCustomWidgets(userInfo.preferences);
          userInfo.name = userInfo.displayName;
          userInfo.accessToken = tokens.accessToken;
          userInfo.refreshToken = tokens.refreshToken;

          if (impersonatedUser) {
            impersonatedUser = JSON.parse(impersonatedUser);
            API.userId = impersonatedUser.id;
            API.isImpersonating = true;
            API.originalToken = tokens.accessToken;
            API.setAccessToken(impersonatedUser.accessToken);
            // Update impersonated accessToken
            await API.refreshTokenWhenNeeded();

            // Reset user permissions (it will be updated on sideFx)
            userInfo = {
              ...impersonatedUser,
              permissions: {}
            };
          }

          if (needsSetUser) {
            this.props.setUser(userInfo);
          } else {
            this.props.updateUser(userInfo);
          }
        } else {
          // Not returning to previous session
          this.props.setLoading(false);

          if (!this.loginPaths.includes(pathname)) {
            this.props.history.replace('/login');
          }
        }
      }
    } catch (error) {
      this.props.setLoading(false);

      if (!this.loginPaths.includes(pathname)) {
        this.props.history.replace('/login');
      }
    }
  };

  getTokenParam = (pathname, dir) => {
    const token = pathname.split(dir)[1];
    return token;
  };

  // Login only with a valid refresh token (skip login screen)
  loginWithToken = async refreshToken => {
    try {
      // Try to call refreshToken and get userInfo
      let userInfo = await API.tokenSignin(refreshToken);

      // Set empty permission set to user
      const user = { ...userInfo, permissions: {} };

      this.props.setUser(user);

      if (user) {
        // Send user information for sentry logs
        Sentry.configureScope(scope => {
          scope.setUser({
            email: user.email,
            id: user.id
          });
        });
      }
      this.props.history.replace('/');
    } catch (error) {
      this.props.setLoading(false);
      this.props.history.replace('/login');
    }
  };

  onAlertDismiss = () => {
    this.props.setAlert({ ...this.props.alert, visible: false });
  };

  stopImpersonating = () => {
    const { impersonator } = this.props.user;
    API.isImpersonating = false;
    localStorage.removeItem('userImpersonated');
    API.setAccessToken(impersonator.accessToken);
    this.props.setUser(impersonator);
    this.props.selectSolution(null);
    this.props.setLoading(true);
    const alert = utils.generateAlert(`Switch back to ${impersonator.email}`, 'success');
    this.props.setAlert(alert);
  };

  setImpersonateModalOpened = show => {
    this.setState({ impersonateModalOpened: show });
  };

  render = () => {
    const { alert, loading, setUser, setLoading, user, location, featureFlags } = this.props;
    const {
      SummaryScreen,
      AnalyticsScreen,
      DevicesScreen,
      ProjectsScreen,
      Profile,
      Authentication,
      AdvancedScreen,
      InsightScreen,
      NotFoundScreen,
      ReportsScreen,
      NotificationsScreen,
      IntegrationsScreen,
      LicensesScreen,
      PresetsScreen,
      SitesScreen,
      SiteGroupsScreen
    } = screens;

    const isPowerUser = user && (user.permissions['support'] || user.impersonator);

    return (
      <div className="app">
        <MainNavigation
          user={user}
          location={this.props.location}
          history={this.props.history}
          openImpersonateModal={() => this.setImpersonateModalOpened(true)}
          stopImpersonating={this.stopImpersonating}
          hideNavigation={this.loginPaths.includes(location.pathname)}
          featureFlags={featureFlags}
        />
        {loading && <div className="app-loading">Loading...</div>}
        <div className="app-container d-flex flex-column">
          <Switch>
            <Route exact path={['/summary', '/index.html', '/']} component={SummaryScreen} />
            <Route exact path="/devices" component={DevicesScreen} />
            <Route exact path="/devices/:deviceId" component={DevicesScreen} />
            <Route exact path="/charts" component={AnalyticsScreen} />
            <Route exact path="/insights" component={InsightScreen} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/reports" component={ReportsScreen} />
            <Route exact path="/integrations" component={IntegrationsScreen} />
            <Route exact path="/notifications" component={NotificationsScreen} />
            {/* <Route exact path="/projects/:projectId" component={ProjectsScreen} /> */}

            {
              /* TODO: REMOVE COMMENT BEFORE DEPLOY TO PRODUCTION */
              /* {featureFlags['dashboard-site-creation'] && ( */
            }
              <Route key="sites" exact path="/sites" component={SitesScreen} />
            {/* )} */}
            {
              /* TODO: REMOVE COMMENT BEFORE DEPLOY TO PRODUCTION */
              /* {featureFlags['dashboard-sitegroup-management'] && ( */
            }
              <Route key="site_groups" exact path="/sitegroups" component={SiteGroupsScreen} />
            {/* )} */}

            {isPowerUser && [
              <Route key="presets" exact path="/presets" component={PresetsScreen} />,
              <Route key="notifications" exact path="/notifications" component={NotificationsScreen} />,
              <Route key="advanced" exact path="/advanced" component={AdvancedScreen} />,
              <Route key="advanced_id" exact path="/advanced/:deviceId" component={AdvancedScreen} />,
              <Route key="licenses" exact path="/licenses" component={LicensesScreen} />
            ]}

            <Route
              exact
              path="/login"
              component={() => <Authentication content={<Login setLoading={setLoading} setUser={setUser} />} />}
            />
            <Route exact path="/signup" component={() => <Authentication content={<Signup />} />} />
            <Route exact path="/forgot" component={() => <Authentication content={<ForgotPassword />} />} />
            <Route exact path="/logout" component={Logout} />

            {/* Render this page in case any of the tried routes isn't valid doesn't exists */}
            <Route component={() => <NotFoundScreen isWhitelabel={!this.isAdMobilizeDashboard()} />} />
          </Switch>
          <ImpersonateFrame
            stopImpersonating={this.stopImpersonating}
            enabled={user && user.impersonator}
            impersonateModalOpened={this.state.impersonateModalOpened}
            setImpersonateModalOpened={this.setImpersonateModalOpened}
            user={user}
          />
          <div className="alert-container">
            <Alert isOpen={alert.visible} toggle={this.onAlertDismiss} color={alert.color}>
              <div>
                <b>{!alert.hideType && `${alert.type.toUpperCase()}:`}</b>
                {`${alert.message}`}
                <b>
                  {alert.callToAction && (
                    <a href={alert.callToAction} target="_blank" rel="noreferrer">
                      Click here
                    </a>
                  )}
                </b>
              </div>
              <img src={icons[`close${utils.capitalize(alert.type)}`]} alt={`close${alert.type}`} />
            </Alert>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = state => {
  return {
    user: getUser(state),
    alert: getAlert(state),
    loading: getLoading(state),
    featureFlags: getFeatureFlags(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setUser: credentials => dispatch(setUser(credentials)),
    updateUser: credentials => dispatch(updateUser(credentials)),
    setAlert: alert => dispatch(setAlert(alert)),
    setLoading: desired => dispatch(setLoading(desired)),
    selectSolution: solution => dispatch(selectSolution(solution))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
