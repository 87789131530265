import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';

import CustomInput from '../../components/customInput';

import Loading from '../../components/loading';
import EditButton from '../../components/editButton';
import PresetCompareModal from '../../components/presetCompareModal';
import DeviceInputs from '../../components/deviceInput';

import utils from '../../utils';
import { parseForTable } from '../../utils/presetModels';
import parse from '../../utils/device/parse';

// Input validations
const validations = {
  'data.displayName': [
    { key: 'required', value: true },
    { key: 'min', value: 4 }
  ]
};

const Details = forwardRef((props, ref) => {
  const { data, onCreate, onChange, onCancel, editMode, setEditMode, isLoading, isNewPreset } = props;

  // Make data copy to avoid overwriting the original one before saving changes
  const [dataCopy, setDataCopy] = useState(utils.deepClone(data));
  const [changedFields, setChangedFields] = useState({});
  const [isEditable, setIsEditable] = useState(editMode);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [oldSettings, setOldSettings] = useState({});
  const [newSettings, setNewSettings] = useState({});

  useEffect(() => {
    setIsEditable(editMode);
  }, [editMode]);

  useEffect(() => {
    const config = parse.presetToDeviceConfig(data, data.solutionId);
    setDataCopy(utils.deepClone(data));
    setOldSettings(config.settings);
  }, [data]);

  useEffect(() => {
    const config = parse.presetToDeviceConfig(dataCopy, dataCopy.solutionId);
    setNewSettings(config.settings);
  }, [dataCopy]);

  useImperativeHandle(
    ref,
    () => ({
      cancelChanges: isEditable ? cancelChanges : null
    }),
    [isEditable]
  );

  const cancelChanges = () => {
    setDataCopy(utils.deepClone(data));
    setChangedFields({});
    setIsEditable(false);
    setEditMode(false);
    setIsModalOpen(false);
    onCancel();
  };

  // Change form inputs mode from read to edit
  const changeEditModeState = newEditState => {
    setIsEditable(newEditState);
    setEditMode(newEditState);
  };

  const saveChanges = () => {
    const changedFieldsPath = Object.keys(changedFields);
    // Remove trailing spaces on data display name
    changedFieldsPath.forEach(field => {
      if (field === 'displayName') {
        dataCopy['displayName'] = String(dataCopy.displayName).trim();
      }
    });

    // Persist changes made on data
    onChange(dataCopy, changedFieldsPath);

    cancelChanges();
  };

  const handleApplyButton = () => {
    if (isNewPreset) {
      delete dataCopy.id;
      onCreate(dataCopy);
      changeEditModeState();
    } else {
      if (!utils.compareObjects(data, dataCopy)) {
        setIsModalOpen(true);
      } else {
        changeEditModeState();
      }
    }
  };

  const rename = value => {
    // Update displayName on currentPreset data
    const newDataCopy = utils.setCopy(dataCopy, 'displayName', value);

    // Add new preset displayName, to be displayed on comparison modal
    newDataCopy.config.presetInfo = newDataCopy.config.presetInfo || {};
    newDataCopy.config.presetInfo.displayName = value;

    setDataCopy(newDataCopy);
    setChangedFields({ ...changedFields, displayName: true });
  };

  const handleChange = (path, val) => {
    const newDataCopy = utils.setCopy(dataCopy, path, val);
    setDataCopy(newDataCopy);
    setChangedFields({ ...changedFields, config: true });
  };

  if (data && !isLoading) {
    const errors = {
      'data-display-name': utils.fieldValidation(dataCopy['displayName'], validations['data.displayName']).join(', ')
    };

    return (
      <>
        <EditButton
          errors={errors}
          isEditable={isEditable}
          saveChanges={handleApplyButton}
          cancelChanges={cancelChanges}
          changeEditModeState={changeEditModeState}
        />
        <div className="form-group">
          <section>
            <div className="section-title">
              <h3>Preset Info</h3>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label>Name</label>
                  <CustomInput
                    id="data-display-name"
                    type="text"
                    value={dataCopy['displayName']}
                    isEditable={isEditable}
                    isCopyButtonEnabled={true}
                    onChange={e => rename(e.target.value)}
                    errorMessage={errors['data-display-name']}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>Solution</label>
                  <CustomInput id="data-solution" type="text" value={dataCopy['solutionId']} isEditable={false} />
                </div>
              </div>
            </div>
          </section>
        </div>
        <DeviceInputs
          device={dataCopy}
          sections={['general', 'advanced']}
          isEditable={isEditable}
          showSectionLabel
          handleChange={handleChange}
          isAdminUser
        />
        <PresetCompareModal
          isOpen={isModalOpen}
          configs={{
            old: { title: 'Current Values' },
            new: { title: 'New Values' },
            content: parseForTable(data['solutionId'], oldSettings, newSettings, 'all')
          }}
          applyChanges={saveChanges}
          handleClose={() => setIsModalOpen(false)}
        />
      </>
    );
  }

  return <Loading />;
});

export default Details;
