import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { isAfter, parseISO } from 'date-fns';
import icons from '../assets/icons';
import { getSolutionData } from './solutions';

const renderIcon = (icon, key, tooltip) => (
  <div className="d-flex align-items-center justify-content-center" key={key}>
    <img src={icons[icon]} id={`icon-${key}`} className="icon" alt="" />
    <UncontrolledTooltip placement="left" target={`#icon-${key}`}>
      <div>
        <span className="d-flex">
          <span>{tooltip || icon}</span>
        </span>
      </div>
    </UncontrolledTooltip>
  </div>
);

const renderStatus = status => {
  const statusClass = {
    'loading...': 'secondary',
    undefined: 'secondary',
    online: 'success',
    detecting: 'success',
    offline: 'danger',
    inactive: 'danger',
    archived: 'danger',
    stopped: 'warning',
    updating: 'warning',
    'no-camera': 'warning',
    'not-configured': 'warning',
    enabled: 'success',
    disabled: 'danger',
    none: 'warning'
  };

  const statusLegend = {
    'loading...': 'Loading...',
    undefined: 'Loading...',
    online: 'Online',
    detecting: 'Online',
    offline: 'Offline',
    inactive: 'Offline',
    archived: 'Archived',
    stopped: 'Stopped',
    updating: 'Updating',
    'no-camera': 'No Camera',
    'not-configured': 'Not Configured',
    enabled: 'Enabled',
    disabled: 'Disabled',
    none: '--'
  };

  const statusKey = status ? status.toLowerCase() : 'none';
  return (
    <span className={`status badge badge-pill badge-outline-${statusClass[statusKey]}`}>{statusLegend[statusKey]}</span>
  );
};

const renderDate = (date, classes = '') => {
  const dateHasExpired = isAfter(new Date(), new Date(parseISO(date)));
  const expired = dateHasExpired ? 'expired' : '';
  const formatedDate = date.split('T')[0]; // Parse datetime string to extract only date

  return <div className={`${classes} ${expired}`}>{formatedDate}</div>;
};

const renderTrimmedValue = (data, fieldName, key) => {
  const value = data[fieldName];

  if (!value) return '--';

  const trimmedValue = value.length > 20 ? `${value.slice(0, 10)}...${value.slice(-5)}` : value;

  const elementId = typeof key !== 'undefined' ? `${fieldName}-${key}` : `${fieldName}-${data[fieldName]}`;

  return (
    <div id={elementId}>
      {trimmedValue}
      <UncontrolledTooltip placement="top" target={`#${elementId}`}>
        {value}
      </UncontrolledTooltip>
    </div>
  );
};

const renderFlag = flag => (
  <div className="flag">
    {flag ? <img src={icons.checked} alt="true" /> : <img src={icons.unchecked} alt="false" />}
  </div>
);

const renderSolution = (solution, id) => {
  const icon = solution;
  const key = id;
  const solutionData = getSolutionData(solution);
  const tooltip = solutionData ? solutionData.name : 'none';
  return renderIcon(icon, key, tooltip);
};

export { renderIcon, renderStatus, renderDate, renderTrimmedValue, renderFlag, renderSolution };
