import React, { useState } from 'react';

import IntervalSelector from './intervalSelector';
import CustomTag from '../customTag';
import Calendar from '../calendar/calendar';
import calendarUtils from '../../utils/calendar';

import './styles.scss';

const DateTimeFilterBar = ({
  appliedFilters={},
  onPeriodSelected,
  hideIntervalSelector
}) => {
  const [filters, setFilters] = useState([]);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [weekDays, setWeekDays] = useState([]);

  const formatTime = time => {
    const formatedTime = time.split(':');
    return `${formatedTime[0]}:${formatedTime[1]}`;
  };

  const storeFilters = ({ filterByTime, filterByWeekday }) => {
    const availableFilters = [];

    if (filterByTime) {
      const startTime = formatTime(filterByTime.start);
      const endTime = formatTime(filterByTime.end);
      const filterText = `${startTime} to ${endTime}`;
      setStartTime(startTime);
      setEndTime(endTime);
      availableFilters.push({ type: 'time', text: filterText });
    }

    if (filterByWeekday) {
      const days = filterByWeekday.map(day => day.id).join(', ');
      const filterText = `Only ${days}`;
      setWeekDays(filterByWeekday);
      availableFilters.push({ type: 'day', text: filterText });
    }

    setFilters(availableFilters);
  };

  const removeFilter = (index, filterType) => {
    const currentFilters = [...filters];

    currentFilters.splice(index, 1);
    setFilters(currentFilters);

    if (filterType === 'day') {
      setWeekDays([]);
    } else if (filterType === 'time') {
      setStartTime('');
      setEndTime('');
    }
  };

  const onSelect = data => {
    storeFilters(data);

    // Get only the week day numbers to send to Query API
    if (data['filterByWeekday']) {
      data['filterByWeekday'] = data['filterByWeekday'].map(day => day.value);
    }

    onPeriodSelected(data);
  };

  return (
    <div className="date-time-filter-bar">
      <Calendar
        onSelect={onSelect}
        startDate={appliedFilters.startDate}
        endDate={appliedFilters.endDate}
        startTime={startTime}
        endTime={endTime}
        weekDays={weekDays}
      />
      {!hideIntervalSelector && (
        <IntervalSelector
          intervalSelected={appliedFilters.interval}
          onIntervalSelected={interval => {
            onPeriodSelected({interval: calendarUtils.getIntervalById(interval)})
          }} />
      )}
      <div className="period-selector-filters">
        {filters.map((filter, index) => (
          <CustomTag key={index} value={filter.text} onClose={() => removeFilter(index, filter.type)} />
        ))}
      </div>
    </div>
  );
};

export default DateTimeFilterBar;
