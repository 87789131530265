/*
 * action types
 */

import types from '../types.js';

/*
 * action creators
 */

export const setLoading = payload => {
  return { type: types.SET_LOADING, payload };
};

export const setUser = payload => {
  return { type: types.SET_USER, payload };
};

export const updateUser = payload => {
  return { type: types.UPDATE_USER, payload };
};

export const updateUserPreferences = (payload) => {
  return { type: types.UPDATE_USER_PREFERENCES, payload }
}

export const setAlert = (payload) => {
  return { type: types.SET_ALERT, payload }
}

export const dismissAlert = () => {
  return { type: types.DISMISS_ALERT, payload: false };
};

export const getUser = state => {
  return state.dashboardReducer.user;
};

export const getAlert = state => {
  return state.dashboardReducer.alert;
};

export const getLoading = state => {
  return state.dashboardReducer.loading;
};

export const clearUser = () => {
  return { type: types.CLEAR_USER };
};

export const getFeatureFlags = state => {
  return state.dashboardReducer.featureFlags;
};

export const setFeatureFlags = payload => {
  return { type: types.SET_FEATURE_FLAGS, payload };
};
