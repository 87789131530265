import React from 'react';
import IconButton from '../iconButton';

import './styles.scss';

export default function Pagination({
  dataLabel,
  itemsCount,
  inferiorLimit,
  superiorLimit,
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage
}) {
  return (
    <div className="table-pagination">
      <div className="table-pagination-info">
        { (itemsCount > 0) &&
          <>
            <span>
              { inferiorLimit }-{ superiorLimit > itemsCount ? itemsCount : superiorLimit }
            </span>
            <span>of</span>
          </>
        }
        <span>{ itemsCount }</span>
        <span>{ dataLabel }</span>
      </div>
      <div className="table-pagination-controls">
        <IconButton
          btnClasses={'outline small'}
          disabled={!canPreviousPage}
          onPress={previousPage}
          icon={'angleLeft'} />
        <IconButton
          btnClasses={'outline small'}
          disabled={!canNextPage}
          onPress={nextPage}
          icon={'angleRight'} />
      </div>
    </div>
  );
}