import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setAlert, getUser } from '../../redux/dashboard/actions';
import {
  getProjects,
  getSelectedProject,
  selectProject,
  updateSelectedProject,
  updateProjects
} from '../../redux/projects/actions';
import types from '../../redux/types';
import { discoverUserPermission } from '../../utils/user/permissions';

import ProjectSettings from './project-settings';
import DropdownSearch from '../dropdownSearch';
import CustomInput from '../customInput';
import utils from '../../utils';
import API from '../../services/api';
import icons from '../../assets/icons';
import { getSelectedCompany } from '../../redux/company/actions';

const Preferences = ({ isAdvertiserUser }) => {
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const company = useSelector(getSelectedCompany);
  const projects = useSelector(getProjects);
  const selectedProject = useSelector(getSelectedProject);

  const [preferences, setPreferences] = useState({});

  useEffect(() => {
    if (selectedProject) {
      setPreferences({ ...selectedProject.preferences });
    }
  }, [selectedProject]);

  const onProjectSelect = async (projectId) => {
    const project = projects.find(proj => proj.id === projectId);
    const permissions = await discoverUserPermission(user, project);

    if (permissions.advertiser) {
      dispatch({
        type: types.SET_ALERT,
        payload: utils.generateAlert(`You cannot see details about this project`, 'error')
      });
    }

    // Change selected project
    dispatch(selectProject(project));
  };

  const onPreferencesChange = async (preferences) => {
    try {
      const project = { ...selectedProject };
      project.preferences = { ...preferences };

      const updatedProjects = projects.map((proj) => {
        if (proj.id === project.id) {
          return project;
        }
        return proj;
      });

      // Send changes to Redux, API and Local Storage
      dispatch(updateSelectedProject(project));
      await API.updateProject(company.id, project, ['preferences']);
      dispatch(updateProjects(updatedProjects));

      const message = `Preferences for Project "${project.displayName}" has changed.`;
      dispatch(setAlert(utils.generateAlert(message, 'success')));
    } catch (error) {
      dispatch(setAlert(utils.generateAlert(error.message, 'error')));
    }
  };

  return (
    <div className="section">
      <div className="section-title">
        Project
      </div>
      <div className="section-content">
        <div className="preference-control project-selector">
          <label>Choose a Project</label>
          <small>To make changes on a Project is important to choose it first, then you&apos;ll be able to see what settings can be modified.</small>
          { (projects && selectedProject) &&
            <div>
              <DropdownSearch
                title={'Search Project'}
                selected={{
                  label: `${selectedProject.displayName} - ${selectedProject.id}`,
                  value: selectedProject.id
                }}
                onSelect={onProjectSelect}
                options={projects.map(proj => ({
                  label: `${proj.displayName} - ${proj.id}`,
                  value: proj.id
                }))} />
                { !isAdvertiserUser &&
                  <>
                    <div className="form-group project-token-container">
                      <label>Project Token</label>
                      <CustomInput
                        id="project-token"
                        type="text"
                        value={selectedProject.provisioningToken}
                        isCopyButtonEnabled />
                    </div>
                    <div className="info-box">
                      <img src={icons.info} alt="info-icon"/>
                      <span>{`If you are looking for more information about a Project, you can click on "Go to Project Page".`}</span>
                    </div>
                  </>
                }
            </div>
          }
        </div>
        { !isAdvertiserUser &&
          <ProjectSettings
            project={selectedProject ? selectedProject.id : null}
            preferences={preferences}
            onChange={onPreferencesChange}
            setAlert={(message) => dispatch(setAlert(message))}/>
        }
      </div>
    </div>
  );
}

export default Preferences;