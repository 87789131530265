import UserProfile from './user-profile';
import HelpfulLinks from './helpful-links';
import Preferences from './preferences';
import Projects from './projects';

import './styles.scss';

const profile = {
  UserProfile,
  HelpfulLinks,
  Projects,
  Preferences
};

export default profile;