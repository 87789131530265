import React from 'react';
import './styles.scss';

const Card = ({ label, icon }) => {
  return (
    <div className="site-card">
      <div className="body">
        <img src={icon} alt={label} />
      </div>
      <div className="card-footer">{label}</div>
    </div>
  );
};

export default Card;
