import React from 'react';
import './styles.scss';

const NotFound = ({ isWhitelabel }) => (
  <div className={`not-found-screen ${isWhitelabel ? '' : 'display-logo'}`}>
    <h1>404</h1>
    <h2>Page not found.</h2>
    <p>Go back to <a href="/">homepage</a></p>
  </div>
);

export default NotFound;
