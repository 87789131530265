import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import components from '../../components';
import CustomButton from '../../components/customButton';
import { getProjects } from '../../redux/projects/actions';
import { setAlert } from '../../redux/dashboard/actions';

import api from '../../services/api';
import utils from '../../utils';

const { DropdownSearch } = components;

const ReassignLicenseModal = ({ isOpen, toggleModal, onReassign, license }) => {
  const [selectedProject, setSelectedProject] = useState({label: 'Choose a Project', value: null});
  const [selectedDevice, setSelectedDevice] = useState({label: 'Choose a Device', value: null});
  const [devices, setDevices] = useState([]);
  const [loadingDevices, setLoadingDevices] = useState(false);

  const projects = useSelector(getProjects);
  const dispatch = useDispatch();

  useEffect(() => {
    if (license.projectId !== '') {
      selectProject(license.projectId);
    }
  },[license]);

  const selectProject = (projectId) => {
    const project = projects.find(dev => dev.id === projectId);

    if (project) {
      setSelectedProject({ value: project.id, label: `${project.displayName} (${project.id})` });

      // Clear Device Selection and fetch device list
      setSelectedDevice({label: 'Choose a Device', value: null});
      setLoadingDevices(true);

      // Fetch devices
      api.getDevices(projectId)
        .then( result => {
          const deviceList = result.devices || [];
          // Filter devices by License solution
          setDevices(deviceList.filter(device => device.solution === license.solution));
        })
        .catch(error => {
          console.error(error);
          dispatch(setAlert(utils.generateAlert('Could not fetch devices. Try again later.', 'error')));
        })
        .finally(() => setLoadingDevices(false));
    }
  };

  const closeModal = () => {
    setSelectedProject({label: 'Choose a Project', value: null});
    setSelectedDevice({label: 'Choose a Device', value: null});
    toggleModal();
  };

  return (
    <Modal className="reassign-license-modal" isOpen={isOpen} fade={false} toggle={toggleModal} centered>
      <ModalHeader className="header-gray" toggle={toggleModal} tag="div">
        <h5>Select a Project, then a Device</h5>
        <div className="modal-subtitle">
          {`You are reassign the license: ${license.id}`}
        </div>
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="row">
            <div className="form-group col">
              <label>Project</label>
              <DropdownSearch
                title={'Search Project'}
                selected={selectedProject}
                onSelect={selectProject}
                options={projects.map(proj => { return { value: proj.id, label: `${proj.displayName} (${proj.id})` }})} />
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label>Device</label>
              {
                loadingDevices
                ? <div><p>loading...</p></div>
                : (
                  <DropdownSearch
                    title={'Search Device'}
                    selected={selectedDevice}
                    disabled={!selectedProject.value && !loadingDevices}
                    onSelect={deviceId => {
                      const device = devices.find(dev => dev.id === deviceId)
                      setSelectedDevice({ value: device.id, label: `${device.displayName} (${device.id})` });
                    }}
                    options={ devices
                      ? devices.map(device => { return { value: device.id, label: `${device.displayName} (${device.id})` }})
                      : []
                    } />
                )
              }
            </div>
          </div>
        </form>
        <div className="button-container">
          <CustomButton title="Cancel" classes="btn-secondary" handleClick={closeModal} />
          <CustomButton disabled={!selectedProject.value || !selectedDevice.value} title="Reassign License" classes="btn-primary" handleClick={() => onReassign(selectedDevice.value, selectedProject.value)} />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ReassignLicenseModal;