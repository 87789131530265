import React, { useState } from 'react';

import containers from '../../containers';
import components from '../../components';
import ReloadButton from '../../components/reloadButton';
import TimezoneInfo from '../../components/timezoneInfo';

const { InsightsContainer } = containers;
const { Header, ProjectSelector, SolutionSelector, NotificationTools } = components;

const supportedSolutions = [
  {type: 'facev2'},
  {type: 'vehicledetectionv1'},
  {type: 'vehiclerecognitionv1'},
  {type: 'crowdv3'},
  {type: 'broadsignfacev1'},
  {type: 'broadsignvehiclev1'},
  {type: 'brightauthorfacev1'},
  {type: 'scalafacev0'},
  {type: 'signagelivefacev1'},
  {type: 'vehiclecrowd'},
];

const InsightScreen = () => {
  const [lastUpdated, setLastUpdated] = useState('');

  return (
    <div className="d-flex flex-column flex-fill">
      <Header
        title={'Analytics'}
        filters={
          <div className="d-flex">
            <ProjectSelector/>
            <SolutionSelector supportedSolutions={supportedSolutions}/>
          </div>
        }
        icon={'analyticsDark'}
        actions={[
          { type: "reactComponent", value: <TimezoneInfo key="timezone-info" /> },
          {
            type: "reactComponent",
            value: <ReloadButton
              key="reload-btn"
              contentName="Insights data"
              lastUpdatedCB={setLastUpdated} />
          },
          { type: "reactComponent", value: <NotificationTools key="notification-btn" /> }
        ]} />
      <InsightsContainer lastUpdated={lastUpdated}/>
    </div>
  );
}

export default InsightScreen;