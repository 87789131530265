import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { formatDistanceToNowStrict } from 'date-fns';
import { renderSolution, renderStatus } from '../../utils/tables';

const tableHeaders = [
  {
    Header: 'Site Name',
    id: 'displayName',
    type: 'string',
    accessor: site => renderName(site.displayName, 'displayName', site.id)
  },
  {
    Header: 'Site Status',
    id: 'status',
    type: 'string',
    accessor: site => (site.status ? renderStatusData(site.status) : '--')
  },
  {
    Header: 'Solution',
    id: 'solution',
    type: 'string',
    accessor: site => (site.solution ? renderSolution(site.solution, site.solution) : '--')
  },
  {
    Header: 'Site Type',
    id: 'type.displayName',
    type: 'string',
    accessor: site => site.type.displayName,
    // disableSortBy: true
  },
  {
    Header: 'Created',
    id: 'createTime',
    type: 'string',
    accessor: site => site.createTime ? (
      <div className="ellipsis">{formatDistanceToNowStrict(new Date(site.createTime))} ago</div>
    ) : (
      '--'
    )
  }
];

const renderName = (name, fieldName, key) => {
  return (
    <div className="ellipsis">
      <div id={`${fieldName}-${key}`}>{name}</div>
      <UncontrolledTooltip placement="top" target={`#${fieldName}-${key}`}>
        {name}
      </UncontrolledTooltip>
    </div>
  );
};

const renderStatusData = status => {
  if (status === 'NOT_CONFIGURED') {
    return renderStatus('not-configured');
  }

  return renderStatus(status);
};
export default tableHeaders;
