import React from 'react';

import Accordion from '../accordion';
import './styles.scss';

const DetailsDrawer = ({
  title,
  isEditing,
  hidden,
  onClose,
  onFocusChange,
  sections,
  customModal
}) => {
  return (
    <div id="drawer" className={`details-drawer side-menu ${hidden ? 'hidden' : ''}`}>
      <div className="details-drawer-header">
        <h3>{title}</h3>
        <button className="btn" onClick={onClose}>
          <i className="uil uil-multiply"/>
        </button>
      </div>
      <div className="details-drawer-body">
        <Accordion
          id={'device-details-accordion'}
          onFocusChange={onFocusChange}
          isEditing={isEditing}
          items={sections || []} />
      </div>
      { customModal }
    </div>
  );
};

export default DetailsDrawer;
