import Details from './details.js';
import Users from './users.js';
import Devices from './devices.js';
// Use the same preferences Form used on Project Drawer sections
import Preferences from '../sections/preferences';

import './styles.scss';

export default {
  Details,
  Users,
  Devices,
  Preferences
};