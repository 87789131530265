import React from 'react';

import containers from '../../containers';
import components from '../../components';
import TimezoneInfo from '../../components/timezoneInfo';

import './styles.scss';

const { ProfileContainer } = containers;
const { Header, NotificationTools } = components;

const Profile = () => (
  <div className="profile-screen d-flex flex-column flex-fill">
    <Header
      title={'Profile'}
      icon={'userDark'}
      actions={[
        { type: "reactComponent", value: <TimezoneInfo key="timezone-info" /> },
        { type: "reactComponent", value: <NotificationTools key="0" /> }
      ]} />
    <ProfileContainer />
  </div>
);

export default Profile;
