const defaultCenter = { lat: 25.7914933, lng: -80.1389693 };

const circleOptions = {
  strokeOppacity: 0.5,
  strokeWeight: 0,
  fillOppacity: 0.05,
  strokeColor: '#8BC34A',
  fillColor: '#8BC34A',
  clickable: false,
  draggable: false,
  editable: false,
  visible: true
};

const getZoom = center => {
  return center ? 14 : 5;
};

const getPosition = center => {
  return center ? center : defaultCenter;
};

const getCenter = center => {
  return center && center.lat && center.lng ? center : defaultCenter;
};

const getMapDefinition = center => ({
  circleOptions,
  defaultCenter,
  center: getCenter(center),
  position: getPosition(center),
  zoom: getZoom(center)
});

export default getMapDefinition;
