// Common
import React, { useEffect, useState } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import { setAlert } from '../../redux/dashboard/actions';
import types from '../../redux/types';

// Components
import components from '../../components';
import DeviceCommands from '../../components/deviceDetails/deviceCommands';
import API from '../../services/api';
import utils from '../../utils';
import parse from '../../utils/device/parse';
import sendCommand from './sendCommand';
import ActionButtons from '../../components/actionButtons';

const { PreviewModal, DropdownMenuList } = components;

const DeviceActionButtons = ({ device, isAdminUser = true, isAdvertiserUser }) => {
  const dispatch = useDispatch();

  const [showPreviewModal, setShowPreviewModal] = useState(false);
  // Status can be: Online, Updating, Offline, Stopped
  const [isCommandRunning, setIsCommandRunning] = useState(false);
  const [enabledCommands, setEnabledCommands] = useState({
    preview: false,
    restart: false,
    start: false,
    stop: false
  });

  useEffect(() => {
    const commandsToCheck = {};

    // Device isn't updating after command run
    Object.keys(enabledCommands).forEach(command => {
      commandsToCheck[command] = canSendCommand(command, device.status);
    });

    setEnabledCommands({ ...commandsToCheck });
  }, [device]);

  //Callback functions definitions.
  const onSuccess = command => {
    if (command === 'start') {
      setDeviceStatus('Online');
    } else if (command === 'stop') {
      setDeviceStatus('Stopped');
    }
    dispatch({
      type: types.SET_ALERT,
      payload: utils.generateAlert(`Command ${command} sent`, 'success')
    });
  };

  const onError = error => {
    dispatch({
      type: types.SET_ALERT,
      payload: utils.generateAlert(error.message, 'error')
    });
  };

  // List of available commands
  const availableCommands = [
    {
      id: `preview-${device.id}`,
      key: 'preview',
      icon: 'cmdPreview',
      tooltip: 'Preview Device Image',
      disabled: isCommandRunning || !enabledCommands['preview'],
      onPress: e => {
        setShowPreviewModal(true);
        e.stopPropagation();
      }
    },
    {
      id: `restart-${device.id}`,
      key: 'restart',
      icon: 'cmdRestart',
      tooltip: 'Restart Detection',
      disabled: isCommandRunning || !enabledCommands['restart'],
      onPress: e => {
        sendCommand('restart', [device], device.companyId, setIsCommandRunning, onSuccess, onError);
        e.stopPropagation();
      }
    },
    {
      id: `start-${device.id}`,
      key: 'start',
      icon: 'cmdStart',
      tooltip: 'Start Detection',
      disabled: isCommandRunning || !enabledCommands['start'],
      onPress: e => {
        sendCommand('start', [device], device.companyId, setIsCommandRunning, onSuccess, onError);
        e.stopPropagation();
      }
    },
    {
      id: `stop-${device.id}`,
      key: 'stop',
      icon: 'cmdStop',
      tooltip: 'Stop Detection',
      disabled: isCommandRunning || !enabledCommands['stop'],
      onPress: e => {
        sendCommand('stop', [device], device.companyId, setIsCommandRunning, onSuccess, onError);
        e.stopPropagation();
      }
    }
  ];

  /**
   * Set device status
   * @param {string} newStatus status to be set
   */
  const setDeviceStatus = newStatus => {
    if (newStatus) {
      dispatch({
        type: types.UPDATE_DEVICE_STATUS,
        payload: { deviceId: device.id, newStatus }
      });
    }
  };

  /**
   * Check if the current commands that is trying to send
   * is possible to run
   *
   * @param {*} command commands that is trying to send
   * @param {*} status device status
   * @returns response about if the wanted command can be sent
   */
  const canSendCommand = (command, status) => {
    switch (command) {
      case 'preview':
        return status === 'Online';
      case 'restart':
      case 'stop':
        return status === 'Online' || status === 'No-Camera';
      case 'start':
        return status === 'Stopped';
      default:
        return false;
    }
  };

  const renderDeviceCommands = async () => {
    try {
      const configResponse = await API.getDeviceConfig(device.companyId, device.id);
      const deviceData = { details: device, config: parse.parseDeviceConfig(configResponse) };

      return <DeviceCommands device={deviceData} companyId={device.companyId} />;
    } catch (e) {
      const errorMessage = 'Something is wrong. We had problems to render this device data.';
      const alert = utils.generateAlert(errorMessage, 'error');
      setAlert(alert);
    }
  };

  return (
    <div className="commands-list">
      <PreviewModal
        className={'modal-image-preview modal-lg'}
        device={device}
        showPreviewModal={showPreviewModal}
        onClose={() => setShowPreviewModal(false)}
        setIsCommandRunning={setIsCommandRunning}
      />
      {!isAdvertiserUser && <ActionButtons buttons={availableCommands} />}
      {device && isAdminUser && (
        <DropdownMenuList key={device.id} onToggle={renderDeviceCommands} disabled={isCommandRunning} alignRight />
      )}
    </div>
  );
};

export default DeviceActionButtons;
