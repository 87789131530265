import config from '../../config';
import API from '../api';

//#region Types
/**
 * @typedef Template
 * @type {Object}
 * @property {"TEMPLATE_COLLECTION_UNSPECIFIED" | "SUMMARY" | "ANALYTICS"[]} collection - Collection of the template.
 * @property {"DATASOURCE_UNSPECIFIED" | "TRAFFIC_M" | "AUDIENCE_M" | "WIFI_M" | "MOBILE_M"[]} compatibleDatasources - Compatible data sources for the template.
 * @property {string} createTime - Creation time of the template.
 * @property {string} updateTime - Update time of the template.
 * @property {string} deleteTime - Deletion time of the template.
 * @property {string} displayName - Display name of the template.
 * @property {boolean} enabled - Indicates if the template is enabled.
 * @property {string} id - ID of the template.
 * @property {string} legacyTable - Legacy table associated with the template.
 * @property {string} name - Name of the template.
 * @property {Object[]} rows - List of rows in the template.
 * @property {Object[]} rows.widgets - List of widgets in each row.
 * @property {string} rows.widgets.id - ID of the widget.
 * @property {string} rows.widgets.url - URL of the widget.
 */
//#endregion

//#region READ
/**
 * List dashboard templates.
 * @param {string} page - page token (first page is defined as empty string).
 * @param {string} filter - Filter expression.
 * @returns {{nextPageToken: string, templates: Template[]}}
 */
const getTemplates = async (page = '', filter = '') => {
  await API.refreshTokenWhenNeeded();

  const url = `${config.coreApi.baseUrl}/templates?pageSize=40&pageToken=${page}&filter=${filter}`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};
//#endregion

export { getTemplates }