const isRowLoaded = (index, itemStatusMap) => {
  return !!itemStatusMap[index];
};

const setItemsStatus = (startIndex, stopIndex, itemStatusMap, status) => {
  for (let index = startIndex; index <= stopIndex; index++) {
    itemStatusMap[index] = status;
  }
};

export default {
  isRowLoaded,
  setItemsStatus
}