import types from '../types.js';

const initialState = {
  notifications: [],
  notificationTypes: [],
  notificationPreferences: [],
};

const projectReducer = (state = initialState, action) => {
  const {
    LOAD_NOTIFICATIONS,
    LOAD_NOTIFICATION_TYPES,
    LOAD_NOTIFICATION_PREFERENCES
  } = types;

  switch (action.type) {
    case LOAD_NOTIFICATIONS:
      return {...state, ...{
        notifications: action.payload
      }};
    case LOAD_NOTIFICATION_TYPES:
      return {...state, ...{
        notificationTypes: action.payload
      }};
    case LOAD_NOTIFICATION_PREFERENCES:
      return {...state, ...{
        notificationPreferences: action.payload
      }};
    default:
      return state;
  }
}

export default projectReducer;