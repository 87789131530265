import logo from './adlogo.svg';
import logo_h from './adlogo_h.svg';
import loading from './loading.svg';
import loading2 from './loading2.svg';
import line from './line.svg';
import bar from './bar.svg';
import donut from './donut.svg';
import table from './table.svg';
import columnLine from './column-line.svg';
import digit from './digit.svg';
import statsList from './stats-list.svg';
import heatmap from './heatmap.svg';

export default {
  logo,
  logo_h,
  loading,
  loading2,
  line,
  bar,
  table,
  'column-line': columnLine,
  'stats-list': statsList,
  digit,
  donut,
  heatmap
};
