import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import types from '../../redux/types';
import utils from '../../utils';
import API from '../../services/api';
import CustomInput from '../customInput';
import CustomButton from '../customButton';

const UserForm = ({ onSave, onCancel }) => {
  const [displayName, setDisplayName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [savingChanges, setSavingChanges] = useState(false);
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

  // Validate fields
  useEffect(() => {
    const validation = {
      'user-display-name': [{ key: 'required', value: true }, { key: 'min', value: 4 }],
      'user-email': [{ key: 'required', value: true }, { key: 'email', value: true }],
      'password': [{ key: 'required', value: true }, { key: 'min', value: 8}],
      'confirm-password': [{ key: 'required', value: true }, { key: 'equals', value: password}]
    };

    const errorList = {
      'user-display-name': utils.fieldValidation(displayName, validation['user-display-name']).join(', '),
      'user-email': utils.fieldValidation(email, validation['user-email']).join(', '),
      'password': utils.fieldValidation(password, validation['password']).join(', '),
      'confirm-password': utils.fieldValidation(confirmPassword, validation['confirm-password']).join(', '),
    };
    setErrors(errorList);
  }, [displayName, email, password, confirmPassword]);

  const saveChanges = () => {
    // Display error message if any of the inputs is empty
    if (!displayName || !email || !password || !confirmPassword) {
      displayErrorMessage();
      return;
    }

    // Display error message if there is something wrong on the inputs
    for (const key in errors) {
      if (errors[key].length > 0){
        displayErrorMessage();
        return;
      }
    }

    // Enable loading and try to create user on server
    setSavingChanges(true);
    createUser();
  };

  const createUser = () => {
    const user = {
      active: true,
      role: 'Advertiser',
      displayName,
      email,
      password
    }
    // Send changes to server
    API.createUser(user)
    .then((res) => {
      dispatch({
        type: types.SET_ALERT,
        payload: utils.generateAlert('User created successfully', 'success')
      });

      // Call on save callback
      onSave({ ...user, id: res.uid });
    }).catch(err => {
      console.error(err);
      dispatch({
        type: types.SET_ALERT,
        payload: utils.generateAlert("User couldn't be saved. Please try again", 'error')
      });
    }).finally(() =>{
      setSavingChanges(false);
    });
  };

  const displayErrorMessage = () => {
    dispatch({
      type: types.SET_ALERT,
      payload: utils.generateAlert('Something wrong on New User inputs.', 'error')
    });
  };

  return (
    <>
      <form>
        <div className="row">
          <div className="form-group col-6">
            <label>Display Name*</label>
            <CustomInput
              id="user-name"
              type="text"
              defaultValue={displayName}
              isEditable={true}
              autoComplete={'displayname'}
              onChange={e => {
                setDisplayName(e.target.value);
              }}
              autoFocus={true}
              errorMessage={errors['user-display-name']} />
          </div>

          <div className="form-group col-6">
            <label>Email*</label>
            <CustomInput
              id="user-email"
              type="text"
              defaultValue={email}
              isEditable={true}
              autoComplete={'email'}
              onChange={e => {
                setEmail(e.target.value);
              }}
              errorMessage={errors['user-email']} />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-6">
            <label>Password*</label>
            <CustomInput
              id="password"
              type="password"
              autoComplete={'new-password'}
              defaultValue={password}
              isEditable={true}
              onChange={e => {
                setPassword(e.target.value);
              }}
              autoFocus={true}
              errorMessage={errors['password']} />
          </div>

          <div className="form-group col-6">
            <label>Confirm Password*</label>
            <CustomInput
              id="confirm-password"
              type="password"
              autoComplete={'new-password'}
              defaultValue={confirmPassword}
              isEditable={true}
              onChange={e => {
                setConfirmPassword(e.target.value);
              }}
              errorMessage={errors['confirm-password']} />
          </div>
        </div>
      </form>

      <div className="row">
        <div className="button-container col-12">
          <CustomButton title="Cancel" classes="btn-danger" handleClick={onCancel} />
          <CustomButton title="Create" classes="btn-primary" loading={savingChanges} handleClick={saveChanges} />
        </div>
      </div>
    </>
  )
};

export default UserForm;