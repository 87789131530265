import React from 'react';
import calendarUtils from '../../utils/calendar';

const DateTimeInput = ({ dateTime, showDate = true, showTime = true }) => {

  if (!dateTime || dateTime === '--') {
    return (
      <div className="datetime-info">
        <div>
          <i className="uil uil-schedule"></i>
          <span>--</span>
        </div>
        <div>
          <i className="uil uil-clock-three"></i> --
        </div>
      </div>
    )
  }

  const date = calendarUtils.getDateTime(dateTime, 'date');
  const time = calendarUtils.getDateTime(dateTime, 'time');

  return (
    <div className="datetime-info">
      { showDate &&
        <div>
          <i className="uil uil-schedule"></i>
          <span>{ date }</span>
        </div>
      }
      { showTime &&
        <div>
          <i className="uil uil-clock-three"></i>
          { time }
        </div>
      }
    </div>
  );
};

export default DateTimeInput;
