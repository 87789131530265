import React from 'react';
import './styles.scss';
import CustomToogle from '../customToogle';
import ModelConfiguration from './modelConfiguration';

const ModelsConfiguration = ({
  models,
  isEditable,
  removeModel,
  modelConfig,
  updateModel,
  updateModelConfig
}) => {
  return (
    <>
      <div className="form-row">
        <div className="col">
          <div className="form-group">
            <CustomToogle
              id="async-mode"
              label="Async mode"
              disabled={!isEditable}
              onChange={e => updateModelConfig('async_mode', e.target.checked)}
              checked={!!modelConfig.async_mode} />
          </div>
        </div>
      </div>
      {
        models.map((model, index) => (
          <ModelConfiguration
            removeModel={removeModel}
            key={index}
            modelIndex={index}
            model={model}
            isEditable={isEditable}
            asyncMode={modelConfig.async_mode}
            updateModel={updateModel} />
        ))
      }
    </>
  );
}

export default ModelsConfiguration;