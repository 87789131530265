import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ManageZones from '../../components/manageZones';

const ManageZoneModal = ({
  selectedDevice,
  solutionConfig,
  defaultConfig,
  isPowerUser,
  zonesModalIsOpen,
  toggleManageZones,
  onConfigChanged
}) => {
  return (
    <Modal size="lg" isOpen={zonesModalIsOpen} fade={false} toggle={toggleManageZones} centered id="manage-zones-modal">
      <ModalHeader toggle={toggleManageZones}>Manage ROIs and Zones</ModalHeader>
      <ModalBody>
        <ManageZones
          device={{
            ...selectedDevice,
            config: solutionConfig,
            defaultConfig: defaultConfig
          }}
          onConfigChanged={onConfigChanged}
          unlimitedZones={isPowerUser}
        />
      </ModalBody>
    </Modal>
  );
};

export default ManageZoneModal;
