import React from 'react';

import DropdownSearch from '../dropdownSearch';
import CustomDropdown from '../customDropdown';
import MultiSelector from '../multiSelector';
import CustomRadioButton from '../customRadioButton';

import filterUtils from '../../utils/filters';

const FilterSections = ({ filters, appliedFilters, onSelect }) => {
  const getSelectedOption = filter => {
    const sectionFilter = filterUtils.getSectionFilter(appliedFilters, filter);
    if (sectionFilter.length === 0) {
      return {
        label: 'Select an option',
        value: 'None'
      };
    }
    return {
      label: sectionFilter[0].label,
      value: sectionFilter[0].value
    };
  };

  const renderSection = filter => {
    let filterBody = '';
    switch (filter.type) {
      case 'customComponent':
        filterBody = filter.renderCustomComponent(onSelect, filter, getSelectedOption(filter));
        break;
      case 'dropdownSearch':
        filterBody = (
          <DropdownSearch
            title={filter.name}
            selected={getSelectedOption(filter)}
            leftAlign
            disabled={false}
            onSelect={selectedValue => onSelect(selectedValue, filter)}
            options={filter.options}
          />
        );
        break;
      case 'dropdown':
        filterBody = (
          <CustomDropdown
            items={filter.options}
            selectedItem={getSelectedOption(filter)}
            displayField="label"
            valueField="value"
            handleSelection={e => onSelect(e.target.value, filter)}
          />
        );
        break;
      case 'radioButton':
        filterBody = (
          <CustomRadioButton
            options={filter.options}
            displayField="label"
            valueField="value"
            onPress={selectedValue => onSelect(selectedValue, filter)}
          />
        );
        break;

      default:
        filterBody = (
          <MultiSelector
            items={filter.options.map(option => ({...option, key: filter.key, path: filter.path}))}
            displayField="label"
            valueField="value"
            renderCheckbox={false}
            renderSearchbox={false}
            listSize={46 * filter.options.length}
            allSelected={false}
            customSelectedItems={filterUtils.getSectionFilter(appliedFilters, filter)}
            onItemSelected={(selectedValue, optionId) => onSelect(selectedValue, filter, optionId)}
            hasCustomSelectedItems
          />
        );
    }

    return filterBody;
  };

  return filters.map(filter => {
    return (
      <div className="section-wrapper" key={`section-${filter.key}`}>
        <div className="multiple-filter-section">
          <div className="filter-section-header"> {filter.name}</div>
          <div className="filter-section-body">{renderSection(filter)}</div>
        </div>
        <div className="section-separator"></div>
      </div>
    );
  });
};

export default FilterSections;
