import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setUser, getUser, setLoading } from '../../redux/dashboard/actions';
import { setAlert } from '../../redux/dashboard/actions';

import components from '../../components';
import API from '../../services/api';
import NewUser from './forms/newUser';
import UpdateUser from './forms/updateUser';

import utils from '../../utils';
import './styles.scss';

const { SearchBox } = components;

class UserContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: '',
      idLookup: '',
      email: '',
      password: '',
      confirmPassword: '',
      displayName: '',
      active: true,
      isSearching: false,
      tryingToImpersonate: false,
      isSaving: false,
      firstTime: {
        displayName: true,
        email: true,
        password: true,
        confirmPassword: true
      },
      showNewUser: true,
      showUpdateUser: false
    };
  }

  searchUser = () => {
    const id = this.state.idLookup;
    this.setState({ isSearching: true });
    API.searchUser(id)
      .then(user => {
        if (user) {
          this.setState({
            email: user.email,
            displayName: user.displayName,
            active: user.active,
            password: '',
            confirmPassword: '',
            userId: user.id,
            showUpdateUser: true,
            showNewUser: false
          });
          const alert = utils.generateAlert('User found.', 'success');
          this.props.setAlert(alert);
        } else {
          this.setState({
            email: '',
            displayName: '',
            active: true,
            userId: '',
            password: '',
            confirmPassword: '',
            showNewUser: true,
            showUpdateUser: false
          });
        }
      })
      .catch(err => {
        this.setState({
          email: '',
          displayName: '',
          active: true,
          userId: '',
          password: '',
          confirmPassword: '',
          showNewUser: true,
          showUpdateUser: false
        });
        const alert = utils.generateAlert('User not found.', 'error');
        this.props.setAlert(alert);
        console.log(err);
      })
      .finally(() => {
        this.setState({ isSearching: false });
      });
  };

  createUser = async errors => {
    const { email, displayName, active, password } = this.state;
    const data = { displayName, email, password, active };

    if (!utils.hasErrorsCheck(errors)) {
      this.setState({ isSaving: true });

      // Test permission before trying to create user
      const permissions = await API.testPermission('users', this.props.user.id, 'authapi.accounts.signUp');
      if (permissions.allAllowed) {
        // Send changes to server
        API.createUser(data)
          .then(() => {
            const alert = utils.generateAlert('User has been saved', 'success');
            this.props.setAlert(alert);
          })
          .catch(err => {
            console.log(err);
            const alert = utils.generateAlert("User couldn't be saved. Please try again", 'error');
            this.props.setAlert(alert);
            this.setState({
              firstTime: {
                displayName: false,
                email: false,
                password: false,
                confirmPassword: false
              }
            });
          })
          .finally(() => {
            this.setState({ isSaving: false });
          });
      } else {
        const alert = utils.generateAlert('You have no permission to create users', 'error');
        this.props.setAlert(alert);
        this.setState({ isSaving: false });
      }
    } else {
      this.props.setAlert(utils.generateAlert('Something is wrong. Please check the fields.', 'error'));
    }
  };

  getUpdateUserErrors = () => {
    const { displayName, email, password, confirmPassword, firstTime } = this.state;
    const validations = {
      'display-name': [
        { key: 'required', value: true },
        { key: 'min', value: 4 }
      ],
      email: [
        { key: 'required', value: true },
        { key: 'email', value: true }
      ],
      password: [{ key: 'min', value: 6 }],
      'confirm-password': [{ key: 'equals', value: password }]
    };
    const passwordErrors = password ? utils.fieldValidation(password, validations['password']) : [];

    if (password && email.includes('@admobilize.com')) {
      passwordErrors.push('Cannot change AdMobilize account password');
    }

    return {
      'display-name': utils.fieldValidation(displayName, validations['display-name']).join(', '),
      email: utils.fieldValidation(email, validations['email']).join(', '),
      password: firstTime.password ? '' : passwordErrors.join(', '),
      'confirm-password': password
        ? ''
        : utils.fieldValidation(confirmPassword, validations['confirm-password']).join(', ')
    };
  };

  getnewUserErrors = () => {
    const { displayName, email, password, confirmPassword, firstTime } = this.state;
    const validations = {
      'display-name': [
        { key: 'required', value: true },
        { key: 'min', value: 4 }
      ],
      email: [
        { key: 'required', value: true },
        { key: 'email', value: true }
      ],
      password: [
        { key: 'required', value: true },
        { key: 'min', value: 8 }
      ],
      'confirm-password': [
        { key: 'required', value: true },
        { key: 'equals', value: password }
      ]
    };

    // Don't show errors on first time
    return {
      'display-name': firstTime.displayName
        ? ''
        : utils.fieldValidation(displayName, validations['display-name']).join(', '),
      email: firstTime.email ? '' : utils.fieldValidation(email, validations['email']).join(', '),
      password: firstTime.password ? '' : utils.fieldValidation(password, validations['password']).join(', '),
      'confirm-password': firstTime.confirmPassword
        ? ''
        : utils.fieldValidation(confirmPassword, validations['confirm-password']).join(', ')
    };
  };

  saveChanges = async errors => {
    const { userId, email, displayName, active, password } = this.state;
    const data = {
      email: email,
      displayName: displayName,
      active: active
    };

    if (password) {
      data.password = password;
      data.currentPassword = password;
    }

    if (!utils.hasErrorsCheck(errors)) {
      this.setState({ isSaving: true });

      // Test permission before trying to update user
      const permissions = await API.testPermission('users', userId, 'authapi.accounts.update');
      if (permissions.allAllowed) {
        // Send changes to server
        API.updateUser(userId, data)
          .then(() => {
            const alert = utils.generateAlert('User has been updated', 'success');
            this.props.setAlert(alert);
            this.setState({ password: '', confirmPassword: '' });
          })
          .catch(err => {
            console.log(err);
            const alert = utils.generateAlert("User couldn't be updated", 'error');
            this.props.setAlert(alert);
          })
          .finally(() => {
            this.setState({ isSaving: false });
          });
      } else {
        const alert = utils.generateAlert('You have no permission to update this user', 'error');
        this.props.setAlert(alert);
        this.setState({ isSaving: false });
      }
    } else {
      this.props.setAlert(utils.generateAlert('Something is wrong. Please check the fields.', 'error'));
    }
  };

  updateValue = (key, value) => {
    const newState = this.state;
    newState[key] = value;
    newState.firstTime[key] = false;
    this.setState(newState);
  };

  render() {
    const {
      showNewUser,
      showUpdateUser,
      email,
      displayName,
      password,
      confirmPassword,
      active,
      isSearching,
      isSaving,
      userId
    } = this.state;

    return (
      <div id="manage-users">
        <div className="search-user">
          <label>Search User</label>
          <SearchBox
            hasSubmit={true}
            className="input-gray"
            placeholder="Email or User ID"
            onChange={value => this.setState({ idLookup: value })}
            onSubmit={this.searchUser}
            disabledButton={isSearching}
          />
        </div>
        {showNewUser && (
          <NewUser
            email={email}
            displayName={displayName}
            active={active}
            password={password}
            confirmPassword={confirmPassword}
            errors={this.getnewUserErrors()}
            isSaving={isSaving}
            saveChanges={this.createUser}
            updateValue={this.updateValue}
          />
        )}
        {showUpdateUser && (
          <UpdateUser
            email={email}
            displayName={displayName}
            userId={userId}
            active={active}
            password={password}
            confirmPassword={confirmPassword}
            errors={this.getUpdateUserErrors()}
            isSaving={isSaving}
            saveChanges={this.saveChanges}
            updateValue={this.updateValue}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUser: user => dispatch(setUser(user)),
    setLoading: loading => dispatch(setLoading(loading)),
    setAlert: payload => {
      dispatch(setAlert(payload));
    }
  };
};

const mapStateToProps = state => {
  return {
    user: getUser(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserContainer);
