import React, { useState } from 'react';
import icons from '../../assets/icons';

import './styles.scss';

/*
  @options - Array of option objects: { label, icon, value }
  @selectedOption - Option selected to initialize component
  @onSelect - Callback function with current option selected
*/
const IconSwitch = ({ options, selectedOption, onSelect }) => {
  const [selected, setSelected] = useState(selectedOption ? selectedOption : options[0]);

  return (
    <div className="icon-switch">
      {
        options.map(option => {
          return (
            <div className={selected.value === option.value ? 'item selected' : 'item'} key={option.value} onClick={() => {
              onSelect(option.value);
              setSelected(option);
            }}>
              { option.label &&
                <label>{option.label}</label>
              }
              <img src={icons[option.icon]} alt={option.value} />
            </div>
          )
        })
      }
    </div>
  );
};

export default IconSwitch;