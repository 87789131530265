import React from 'react';
import IconButton from '../iconButton';

import './styles.scss';

const offcanvasDrawer = ({
  isOpen,
  handleClose,
  title,
  subtitle,
  body
}) => {

  return (
    <div className={`offcanvas-drawer ${isOpen ? "opened" : ""}`}>
      <div className="content">
        <div className="modal-header">
          <h3>{title}</h3>
          <IconButton id="close-modal" icon="close" onPress={handleClose} tooltip="close" />
        </div>
        <p>{subtitle}</p>
        <div className="modal-body">
          {body ? body : null}
        </div>
      </div>
    </div>
  );
}

export default offcanvasDrawer;