import utils from '../index';

/**
 * Generate the custom device models and custom device settings based on customConfig
 * @param {*} customConfig
 */
export const splitCustomConfig = (customConfig) => {
  if (!customConfig) {
    return {};
  }

  const { adm_config = {}, driver_config = {}, desktopui_config, presetInfo } = customConfig;
  const { config = {} } = utils.deepClone(driver_config);
  const modelFlags = ['enable_person_detection', 'use_night_solution', 'use_night_solution_automatic'];
  const configModel = {};

  modelFlags.forEach(key => {
    const value = utils.get(config, key);
    if (!utils.isNullish(value)) {
      utils.set(configModel, key, value);
      utils.remove(config, key);
    }
  });

  const customDeviceModels = driver_config.models
  //Do not initialize custom models as [] or it will overwrite default model
  ? {
      driver_config: {
        models: driver_config.models,
        config: { ...configModel }
      }
    }
  : {
      driver_config: {
        config: { ...configModel }
      }
  };

  const customDeviceSettings = {
    adm_config,
    driver_config: {
      config: { ...config }
    },
    presetInfo
  }

  if (desktopui_config) {
    customDeviceSettings.desktopui_config = desktopui_config;
  }

  return {
    customDeviceModels,
    customDeviceSettings
  };
}