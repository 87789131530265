import React, { useState } from 'react';
import CustomRadioButton from '../../../../components/customRadioButton';
import CustomCheckBox from '../../../../components/customCheckBox';
import Card from './card';
import icons from '../../../../assets/icons';
import IconTooltip from '../../../../components/iconTooltip';
import siteUtils from '../../../../utils/site';

const Solutions = ({ site, updateSite }) => {
  const [solution, setSolution] = useState(site.solution ? site.solution : '');
  const [selectedDataSources, setSelectedDataSources] = useState(site.datasources ? site.datasources : []);
  const [lastSolutionSelected, setLastSolutionSelected] = useState('');

  const options = [
    {
      displayName: <Card label={'Audience Measurement'} icon={icons.AUDIENCE} />,
      value: 'AUDIENCE'
    },
    {
      displayName: <Card label={'Traffic Measurement'} icon={icons.TRAFFIC} />,
      value: 'TRAFFIC'
    },
    {
      displayName: <Card label={'Custom Solution'} icon={icons.CUSTOM} />,
      value: 'CUSTOM'
    }
  ];

  const dataSourcesIds = siteUtils.getDataSourcesIds();
  const dataSources = siteUtils.getDataSources();

  const handleSolution = solution => {
    if (solution !== lastSolutionSelected) {
      setLastSolutionSelected(solution);
      setSolution(solution);
      setSelectedDataSources([]);
      if (solution === 'CUSTOM') {
        updateSite({ ...site, solution: solution, datasources: selectedDataSources });
      } else {
        updateSite({ ...site, solution: solution, datasources: [`${solution}_M`] });
      }
    }
  };

  const handleDataSources = (dataSource, remove) => {
    let localDataSources = selectedDataSources;
    if (remove) {
      localDataSources = selectedDataSources.filter(dS => dS !== dataSource);
    } else {
      localDataSources.push(dataSource);
    }
    updateSite({ ...site, datasources: localDataSources });
    setSelectedDataSources(localDataSources);
  };

  return (
    <>
      <h4 className="section-label">
        Choose one solution between <b>“Audience”</b> and <b>“Traffic”</b> or create a combination in{' '}
        <b>“Custom Solution”</b>.
      </h4>
      <div className={'custom-card-buttom'}>
        <CustomRadioButton
          options={options}
          displayField={'displayName'}
          valueField={'value'}
          value={solution}
          onPress={handleSolution}
        />
      </div>
      <div className="data-sources">
        {solution === 'CUSTOM' && (
          <>
            <h4 className={`${!selectedDataSources || selectedDataSources.length === 0 ? 'input-error' : ''}`}>
              Select the data sources
            </h4>
            <div className="custom-solution-options row">
              {dataSourcesIds.map(sourceId => (
                <div key={sourceId} className="data-source-item">
                  <CustomCheckBox
                    key={sourceId}
                    label={dataSources[sourceId].label}
                    selected={selectedDataSources.includes(sourceId) ? 'all' : 'none'}
                    onClick={e => handleDataSources(sourceId, e)}
                    classList="col-6"
                  />
                  <IconTooltip
                    key={`${sourceId}-info`}
                    id={`${sourceId}-info-id`}
                    icon={'info'}
                    tooltipText={dataSources[sourceId].infoText}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Solutions;
