const mainPallet = [
  '#2F68E5',
  '#BBA8FA',
  '#93B5FF',
  '#9C7EFF',
  '#D5C7FF',
  '#5B8BF4'
];

const audiencePallet = mainPallet;
const vehicleRecognitionPallet = mainPallet;
const vehicleDetectionPallet = mainPallet;
const crowdPallet = mainPallet;

const colors = {
  mainPallet,
  audiencePallet,
  vehicleRecognitionPallet,
  vehicleDetectionPallet,
  crowdPallet
};

export default colors;