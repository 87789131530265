import React from 'react';

const Legend = ({ min, max, unit }) => {
  return (
    <div className='heatmap--legend'>
      <div className='heatmap--legend-bar' />
      <div className='heatmap--legend-values'>
        <div className='heatmap--legend-min'>{min}{unit}</div>
        <div className='heatmap--legend-max'>{max}{unit}</div>
      </div>
    </div>
  );
};

export default Legend;