import React, { useEffect, useState, useRef } from 'react';
import icons from '../../assets/icons';
import './styles.scss';

const SearchBox = React.forwardRef((props, ref) => {
  const {
    onChange,
    placeholder,
    hasSubmit,
    disabledButton,
    searchingBy,
    onSubmit,
    className,
    autoFocus } = props;
  const [value, setValue] = useState(searchingBy || '');
  const inputRef = useRef();

  useEffect(() => {
    setValue(searchingBy || '');
  }, [searchingBy]);

  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, []);

  React.useImperativeHandle(ref, () => ({
    clearInput() {
      setValue('');
    }
  }));

  return (
    <div className={`search-box ${className ? className : "" }`}>
      <input
        ref={inputRef}
        className="flex-fill"
        placeholder={ placeholder }
        value={value}
        onChange={ event => {
          const { value } = event.target;
          setValue(value);
          onChange(value)}
        }
        type="text" />
      {
        hasSubmit
        ? (
          <button disabled={disabledButton} className="btn btn-primary m-0" onClick={onSubmit}>
            {
              disabledButton
              ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                  <span className="sr-only">Loading...</span>
                </>
              )
              : <img src={icons.search} alt="" height="35"/>
            }
          </button>
        )
        : <img src={icons.searchDark} alt="" height="20"/>
      }
    </div>
  );
});

export default SearchBox;