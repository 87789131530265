import types from '../types.js';

export const loadCompanies = () => {
  return { type: types.LOAD_COMPANIES, payload: null };
};

export const selectCompany = payload => {
  return { type: types.SELECT_COMPANY, payload };
};

export const getSelectedCompany = state => {
  return state.companyReducer.selectedCompany;
};

export const getCompanies = state => {
  return state.companyReducer.companies;
};