const setState = (state, node, payload) => {
  return {
    ...state,
    ...{
      [node]: payload
    }
  };
};

const appendState = (state, node, payload) => {
  return {
    ...state,
    ...{
      [node]: [...state[node], payload]
    }
  };
};

export { setState, appendState };