import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { getNotifications } from '../../redux/notification/actions';

import ItemNotification from '../itemNotification';
import icons from '../../assets/icons';

import API from '../../services/api';
import types from '../../redux/types';
import utils from '../../utils';
import './styles.scss';

const NotificationList = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const notifications = useSelector(getNotifications);

  const [unarchivedNotifications, setUnarchivedNotifications] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    setUnarchivedNotifications(notifications.filter(notification => !notification.archived));
  }, [notifications]);

  const archiveNotifications = async (selectedNotifications, archive) => {
    try {
      setIsBusy(true);
      await API.archiveNotification(selectedNotifications.map(notification => notification.id), archive);
      dispatch({
        type: types.LOAD_NOTIFICATIONS,
        payload: notifications.map(notification => {
          if (selectedNotifications.find(currentNotification => currentNotification.id == notification.id)) {
            return { ...notification, archived: archive }
          }
          return notification;
        })
      });
      dispatch({
        type: types.SET_ALERT,
        payload: utils.generateAlert(archive ? "Notification Archived!" : "Notification Sent to Inbox!", "success")
      });
      setIsBusy(false);
    } catch (err) {
      dispatch({
        type: types.SET_ALERT,
        payload: utils.generateAlert("Something went wrong, try again later!", "error")
      });
      setIsBusy(false);
      console.error(err);
    }
  }

  const deleteNotifications = async (selectedNotifications) => {
    try {
      setIsBusy(true);
      await API.deleteNotification(selectedNotifications.map(notification => notification.id));
      dispatch({
        type: types.LOAD_NOTIFICATIONS,
        payload: notifications.filter(notification => !!notifications.find(currentNotification => currentNotification.id !== notification.id))
      });
      dispatch({
        type: types.SET_ALERT,
        payload: utils.generateAlert("Notification Deleted!", "success")
      });
      setIsBusy(false);
    } catch (err) {
      dispatch({
        type: types.SET_ALERT,
        payload: utils.generateAlert("Something went wrong, try again later!", "error")
      });
      setIsBusy(false);
      console.error(err);
    }
  }

  return (
    <div className="notification-list">
      <div className="d-flex flex-fill flex-column">
        {
          unarchivedNotifications.length > 0
            ?
            unarchivedNotifications.slice(0, 10)
              .map((notification, key) => (
                <ItemNotification key={key} notification={notification} onArchive={archiveNotifications} onDelete={deleteNotifications} isBusy={isBusy} />
              ))
            :
            <div className="no-notification">
              <img src={icons.noNotifications} width={150} />
              <h3>There is nothing here yet.</h3>
              <p>You have no notifications that matches your filter(s).</p>
            </div>
        }
      </div>
      <button className="btn btn-outline-primary" onClick={() => history.push("/notifications")}>View All</button>
    </div>
  );
}

export default NotificationList;