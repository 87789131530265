import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from '../../services/api';
import { getUser } from '../../redux/dashboard/actions';
import { getSelectedProject } from '../../redux/projects/actions';
import components from '../../components';
import { AccountsTab, MappingsTab } from './tabs';

import './styles.scss';
import icons from '../../assets/icons';
import { solutionsMeta } from '../../utils/solutions';
import ToolBar from '../../components/toolbar';

const { CustomDropdown, Tabs } = components;

const IntegrationsContainer = ({ lastUpdated }) => {
  const [integrations, setIntegrations] = useState([]);
  const [cmsName, setCmsName] = useState('');
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [credentials, setCredentials] = useState([]);
  const [mappings, setMappings] = useState([]);
  const [loading, setLoading] = useState(false);

  const user = useSelector(getUser);
  const project = useSelector(getSelectedProject);

  useEffect(() => {
    // Go to empty state after changing project/user permission and request content refresh
    setIntegrations([]);
    setCmsName('');
    setSelectedIntegration({});
    // Only fetch content after user is set, to prevent api calls without header
    if (user && project) {
      api.listIntegrations()
        .then(res => {
          // Filter integrations to display only enabled ones
          const filteredIntegrations = res.integrations
          .map(integration => {
            const integrationName = integration.integrationName.toLowerCase();
            const solutionFound = solutionsMeta.find(solution => solution.id === integrationName);
              return { ...integration, name: solutionFound ? solutionFound.name : integrationName }
            })
            .filter( integration => {
              return project.enabledIntegrations
                .some(enabledInt => enabledInt === integration.integrationName)
            });

          // Prevent error while accessing empty integration list
          if (filteredIntegrations.length > 0) {
            setIntegrations(filteredIntegrations);
            // Select first integration
            setCmsName(filteredIntegrations[0].cmsClient);
            setSelectedIntegration(filteredIntegrations[0]);
          }
        })
        .catch(console.error);
    }
  },[user, lastUpdated]);

  useEffect(() => {
    // Load Credential again whenever project o cmsName changes
    loadCredentials();
  }, [cmsName, project]);

  useEffect(() => {
    if (credentials.length > 0) {
      loadMappings();
    }
  }, [credentials]);

  // Load credential can be sent to the children, to update the whole thing after changing mappings or credentials
  const loadCredentials = () => {
    if (project && cmsName !== '') {
      setLoading(true);
      // Load credentials
      api.listCredentials(cmsName, project.id)
        .then(res => {
          // Clear mappings
          setMappings([]);
          const integration = integrations.find(integ => integ.cmsClient === cmsName);
          setSelectedIntegration(integration);
          setCredentials(res.credentials);
        })
        .catch(console.error)
        .finally(() => setLoading(false));
    }
  };

  const loadMappings = async () => {
    // Load mappings from all accounts
    const result = await Promise.all(credentials.map(credential => {
      return api.getMappings(cmsName, project.id, credential.id);
    }));

    const mergeResults = result.reduce((prev, curr) => {
      return {mappings: [...prev.mappings, ...curr.mappings]};
    });

    setMappings(mergeResults.mappings);
  };

  const onIntegrationSelected = (selection) => {
    setCmsName(selection);
  };

  // Render empty state when User doesn't have any Integration
  if (!selectedIntegration || integrations.length === 0) {
    return (
      <div className="integrations-container">
        <div className="filter-bar">
          <div className="title">
            <img src={icons.integrations} alt="Integrations" />
            <h2>Integrations</h2>
          </div>
        </div>
        <div className="no-data-message flex-column">
          <h1>No Integration found for current Project</h1>
          <p>If you do have an active CMS Integration, please contact support </p>
        </div>
      </div>
    );
  }


  // Render denied state when User doesn't have permission
  if (user && user.permissions['advertiser']) {
    return (
      <div className="integrations-container">
        <div className="filter-bar">
          <div className="title">
            <img src={icons.integrations} alt="Integrations" />
            <h2>Integrations</h2>
          </div>
        </div>
        <div className="no-data-message flex-column">
          <h1>{`You don't have permission to manage Integrations`}</h1>
          <p>{`Try a different project where you have admin permission or contact support`}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="integrations-container">
      <ToolBar
        title={'Integrations'}
        icon={'integrations'}
        renderFilters={<CustomDropdown
          items={integrations}
          valueField={'cmsClient'}
          displayField={'name'}
          handleSelection={(e) => onIntegrationSelected(e.target.value)}
          outline={false} />}
      />

      <Tabs
        pages={
          {
            tabs: [
              {
                title: {
                  name: 'Accounts',
                  icon: 'userDark',
                  description: 'Manage your account credentials'
                },
                content: <AccountsTab
                  loading={loading}
                  loadCredentials={loadCredentials}
                  selectedIntegration={selectedIntegration}
                  credentials={credentials} />
              },
              {
                title: {
                  name: 'Device Mappings',
                  icon: 'deviceOutlineDark',
                  description: 'Bind devices to your players'
                },
                content: <MappingsTab
                  credentials={credentials}
                  cmsName={cmsName}
                  loading={loading}
                  loadCredentials={loadCredentials}
                  selectedIntegration={selectedIntegration}
                  mappings={mappings}/>
              }
            ]
          }
        } />
    </div>
  );
};

export default IntegrationsContainer;