import utils from '..';
import preferenceUtils from './preferences';

const defaultPreferences = preferenceUtils.projectPreferences;

// Store timezone offset
const LOCAL_PROJECTS_DATA = 'projectsData';

/**
 * Remove fields that shouldn't be in a project
 * Reference: https://devdocs.admobilize.com/admobilizeapis/doc/devicemanagement/v2/README.html#project
 * @param {*} project
 */
export const cleanUp = project => {
  delete project.tables;
  return project;
};

/**
 * Retrieve Speed Unit object based on a given id
 * @param {string} speedUnit
 */
export const getSpeedUnitOption = speedUnit => {
  const indexFound = defaultPreferences.speedUnitOptions.findIndex(item => item.id === speedUnit);
  return defaultPreferences.speedUnitOptions[indexFound];
};

/**
 * Retrieve multiply value for each vehicle type as formated string
 * @param {object} vehicleMultiplier
 */
export const getMultiplyValues = (vehicleMultiplier) => {
  if (!vehicleMultiplier) return '';

  const factors = Object.keys(vehicleMultiplier);
  return factors.map(factor => `${factor}: <b>${vehicleMultiplier[factor]}</b>`).join('; ');
};

/**
 * Create missing fields and normalize data
 * @param {*} project
 */
export const normalizePreferences = project => {
  let projectPreferences = { ...project.preferences };

  const speedUnits = defaultPreferences.speedUnitOptions;
  const speedUnitDefined = speedUnits.some(su => su.id === projectPreferences.speedUnit);

  if (projectPreferences) {
    // Create preferences attributes in case are invalid or empty
    Object.keys(defaultPreferences).forEach(preference => {
      if (preference === 'speedUnitOptions' || preference === 'speedUnit') {
        if (preference === 'speedUnit' && (!projectPreferences.speedUnit || !speedUnitDefined)) {
          projectPreferences[preference] = defaultPreferences[preference].id;
        }
      } else {
        if (!projectPreferences[preference]) {
          projectPreferences[preference] = defaultPreferences[preference];
        }
      }
    });

    // Initialize vehicleMultiplier with default value, if there is none
    if (!projectPreferences.vehicleMultiplier || Object.keys(projectPreferences.vehicleMultiplier).length === 0) {
      projectPreferences.vehicleMultiplier = defaultPreferences.vehicleMultiplier;
    }
  } else {
    // Set default items inside of project preferences
    projectPreferences = {};

    // Preferences bootstrap
    Object.keys(defaultPreferences).forEach(preference => {
      if (preference === 'speedUnitOptions' || preference === 'speedUnit') {
        if (preference === 'speedUnit') {
          projectPreferences[preference] = defaultPreferences[preference].id;
        }
      } else {
        projectPreferences[preference] = defaultPreferences[preference];
      }
    });
  }

  return projectPreferences;
};

/**
 * Create missing fields and normalize data
 * @param {*} project
 */
export const normalizeProject = (project) => {
  const newProject = utils.deepClone(project);
  const { enabledIntegrations } = newProject;

  // Add field hasCMS and currentCMS on project object
  let currentCMS = '';
  let hasCMS = false;

  if (enabledIntegrations) {
    enabledIntegrations.forEach(integration => {
      currentCMS = integration;
      hasCMS = true;
    });

    newProject.currentCMS = currentCMS;
    newProject.hasCMS = hasCMS;
  }

  // Normalize project preferences
  newProject.preferences = normalizePreferences(project);

  return newProject;
};

export const prepareServerObject = (project) => {
  const newProject = utils.deepClone(project);

  delete newProject.hasCMS;
  delete newProject.currentCMS;

  return newProject;
}

export const computeColumnCount = width => {
  let columnCount = 4;

  if (width < 1440) {
    columnCount = 3;
  }

  if (width < 1200) {
    columnCount = 2;
  }

  if (width < 800) {
    columnCount = 1;
  }

  return columnCount;
};

/**
 * Get list of members, indexed by id, from IAM Policy
 * @param {*} policy
 */
export const getMembers = policy => {
  const parsedMembers = {};

  // Extracting members from different role bindings
  const members = policy.bindings
    .filter(binding => binding.role === 'admobilize.dashboardDataViewer' || binding.role === 'admobilize.dashboardUser')
    .map(binding => {
      return binding.members.map(user => {
        // Adding role info into the object
        return { id: user, role: binding.role };
      });
    })
    // Merge arrays to remove duplicated users
    .reduce((prev, curr) => [...prev, ...curr], []);

  // Create members object, indexed by id
  members.forEach(member => {
    parsedMembers[member.id.slice(5)] = {
      ...member,
      id: member.id.slice(5)
    };
  });

  return parsedMembers;
};

/**
 * Bind new members to project and return updated policy
 * @param {*} policy
 * @param {*} users
 * @param {*} role
 */
export const bindMemberToProject = (policy, users, role) => {
  const members = users.map(user => `user:${user.id}`);
  let policyIndex = policy.bindings.findIndex(binding => binding.role === role);

  // Update policy to add new users, only if it has dashboardUser role
  if (policyIndex > -1) {
    // merge old and current members on an existing binding
    policy.bindings[policyIndex].members = [...policy.bindings[policyIndex].members, ...members];
  } else {
    // Create new binding to add new users
    policy.bindings.push({
      role,
      members
    });
  }

  return policy;
};

/**
 * Update policy bindings with new user's permissions
 * @param {*} policy
 * @param {*} users
 */
export const updatePolicyPermissions = (policy, users) => {
  const newBindings = policy.bindings
    .map(binding => {
      // Remove modified users from original binding
      const members = binding.members.filter(member => {
        return !users.some(selectedUser => `user:${selectedUser.id}` === member);
      });
      return { ...binding, members };
    })
    .map(binding => {
      // Add modified users to binding based on new role
      users.forEach((modifiedUser, index) => {
        if (binding.role === modifiedUser.role) {
          binding.members.push(`user:${modifiedUser.id}`);
          users.splice(index, 1);
        }
      });
      return { ...binding };
    })
    .filter(binding => binding.members.length > 0);

  // Create roles that don't exist in the current policy
  if (users.length > 0) {
    users.forEach(modifiedUser => {
      // Add users to binding if already exists
      const bindingIndex = newBindings.findIndex(binding => binding.role === modifiedUser.role);

      if (bindingIndex > -1) {
        newBindings[bindingIndex].members.push(`user:${modifiedUser.id}`);
      } else {
        // Create new binding
        newBindings.push({
          role: modifiedUser.role,
          members: [`user:${modifiedUser.id}`]
        });
      }
    });
  }

  return newBindings;
};

export const getProjectsData = () => {
  return window.localStorage.getItem(LOCAL_PROJECTS_DATA);
};

export const setProjectsData = projects => {
  window.localStorage.setItem(LOCAL_PROJECTS_DATA, projects);
};

export const clearProjectsData = () => {
  window.localStorage.removeItem(LOCAL_PROJECTS_DATA);
};

export default {
  cleanUp,
  getSpeedUnitOption,
  normalizePreferences,
  getMembers,
  bindMemberToProject,
  updatePolicyPermissions
};
