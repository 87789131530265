import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../redux/dashboard/actions';
import { getSelectedProject } from '../../redux/projects/actions';
import {getSelectedCompany} from '../../redux/company/actions'
import ToolBar from '../../components/toolbar';
import Table from '../../components/table';
import tableHeaders from './tableHeader';
import SidePanel from '../../components/sidepanel';
import MultipleFilters from '../../components/multipleFilters';
import Wizard from '../../components/wizard';
import siteSteps from './siteSteps';
import ManageSitesModal from './manageSitesModal';

import filterUtils from '../../utils/filters';
import utils from '../../utils';
import './styles.scss';
import API from '../../services/api';
import EmptyState from '../../components/emptyState';

const SitesContainer = ({ lastUpdated }) => {
  const [data, setData] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const [totalSize, setTotalSize] = useState(0);

  const [filterBy, setFilterBy] = useState([]);
  const [searchBy, setSearchBy] = useState('');
  const [itemsCount, setItemsCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [newSite, setNewSite] = useState({});
  const [lastFetchTime, setLastFetchTime] = useState(lastUpdated);

  // Flags
  const [isLoading, setIsLoading] = useState(true);
  const [createMode, setCreateMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const siteGroup = useSelector(getSelectedProject);
  const company = useSelector(getSelectedCompany);

  const nextPageToken = useRef('');
  const filters = useRef('');
  const sorting = useRef('displayName, id');
  const sites = useRef([]);
  const oldSiteGroupId = useRef('');
  const oldLastFetchTime = useRef(lastUpdated);
  const allSitesFetched = useRef(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setLastFetchTime(lastUpdated);
  }, [lastUpdated]);

  useEffect(() => {
    if (sites.current.length === 0) {
      fetchData({ pageIndex: 0, pageSize: 15, sortBy: [] });
    }
  }, [siteGroup, filterBy, searchBy]);

  const getFilters = () => {
    let newFilters = [];

    if (searchBy !== '') {
      newFilters.push(`(displayName ILIKE "%${searchBy}%" OR id ILIKE "%${searchBy}%")`)
    }

    filterBy.forEach(filter => {
      const operator = filter.operator ? filter.operator : '=';
      const value = filter.type === 'version' ? `"${filter.value}"` : filter.value;
      newFilters.push(`(${filter.path} ${operator} ${value})`);
    });

    return newFilters.join(" AND ");
  };

  const handleSearch = useDebouncedCallback((term) => {
    setSearchBy(term);
  }, 300);

  const getSorting = (sortBy) => {
    if (sortBy.length > 0 && sortBy[0].id) {
      return `${sortBy[0].id} ${sortBy[0].desc ? 'DESC' : 'ASC'}, id`;
    }

    return sorting.current = 'displayName, id';
  };

  const getSiteList = (newSites, forceUpdate) => {
    // Merge new data with previous data if sorting and filters hasn't change
    if (!forceUpdate) {
      return [...sites.current, ...newSites];
    }

    // Overwrite list with new data
    return newSites;
  };

  const fetchData = useCallback(({ pageIndex, pageSize, sortBy }) => {
    if (!siteGroup) {
      return;
    }
    // Keep old sorting and filters to handle changes
    const oldSorting = sorting.current;
    const oldFilters = filters.current;

    // Apply Sorting and filters
    filters.current = encodeURI(getFilters());
    sorting.current = getSorting(sortBy);

    const forceUpdate = ( oldSorting !== sorting.current
      || oldFilters !== filters.current
      || lastFetchTime !== oldLastFetchTime.current
      || siteGroup.id !== oldSiteGroupId.current
    );

    // Clear pageToken if project, filter or sorting has changed
    if (forceUpdate) {
      nextPageToken.current = '';
      setIsLoading(true);
      allSitesFetched.current = false;
    }

    if (forceUpdate || !allSitesFetched.current) {
      API.getSites(company.id, siteGroup.id, nextPageToken.current, filters.current, sorting.current)
        .then(response => {
          // Update site list
          sites.current = getSiteList(response.sites, forceUpdate);

          if (response.nextPageToken === '') {
            allSitesFetched.current = true;
          }

          nextPageToken.current = response.nextPageToken;
          oldSiteGroupId.current = siteGroup.id;

          // Compute total number of items
          setItemsCount(response.totalSize);

          // Update item count when there is no filters applied
          if (filters.current === '') {
            setTotalSize(response.totalSize);
          }

          // Update table indexes
          const startRow = pageSize * pageIndex;
          const endRow = startRow + pageSize;

          // Slice items from device list
          setData(sites.current.slice(startRow, endRow));

          // Compute total page count
          setPageCount(Math.ceil(response.totalSize / pageSize));
          setIsLoading(false);
        });
    } else {
      // Update table indexes
      const startRow = pageSize * pageIndex;
      const endRow = startRow + pageSize;

      // Slice items from device list
      setData(sites.current.slice(startRow, endRow));
      setIsLoading(false);
    }

    },
    [searchBy, filterBy, siteGroup, lastFetchTime]
  );

  const onCreate = () => {
    const {address, attachedDevices, countryCode, datasources, description, displayName, latitude, longitude, solution, type} = newSite
    const siteApiObject = {
        address,
        attachedDevices: attachedDevices || [],
        countryCode,
        datasources,
        description,
        displayName,
        latitude,
        longitude,
        projectId: siteGroup.id,
        solution,
        status: 'ENABLED',
        type
    }
    API.createSite(company.id, siteApiObject)
      .then((result) =>{
        API.updateAttachedDevices(company.id, {...siteApiObject, id: result.id})
        dispatch(setAlert(utils.generateAlert('Site Created successfully!', 'success')))
      })
      .catch(error => { 
        dispatch(setAlert(utils.generateAlert('Something went wrong. Try again later!', 'error')))
        console.error(error)
      })
      .finally( () =>{
        setLastFetchTime(new Date().toISOString());
        setCreateMode(false);
        setNewSite({})
      })
  };

  const confirmChange = () => {
    const question = 'Are you sure you want to do that? Changes will not be saved.';
    const shouldContinueCancel = window.confirm(question);

    if (shouldContinueCancel) {
      setShowModal(false);
      setSelectedSite(null);
    }
  };

  const handleCloseModal = (shouldConfirm = true) => {
    if (shouldConfirm) {
      confirmChange();
    } else {
      setShowModal(false);
      setSelectedSite(null);
      setLastFetchTime(new Date().toISOString());
    }
  };

  const handleSiteSelection = site => {
    setSelectedSite(site);
    setShowModal(true);
  };


  const handleSiteEdition = () => {
    const newSites = utils.deepClone(sites);
    const siteIndex = newSites.findIndex(site => site.id === selectedSite.id);
    newSites.splice(siteIndex, 1, selectedSite);
    sites.current = newSites;
  };

  if (createMode) {
    return (
      <div className="sites site-creation">
        <Wizard
          title={'Create a new site'}
          icon={'location'}
          steps={siteSteps(newSite, setNewSite,company.id)}
          onSubmit={onCreate}
          onCancel={() => {
            setCreateMode(false);
            setNewSite({});
          }}
        />
      </div>
    );
  }

  return (
    <div className="sites d-flex flex-column h-100">
      <ToolBar
        title="Sites"
        icon="location"
        searchPlaceholder={'Search site'}
        count={totalSize > 0 ? totalSize : null}
        onSearch={handleSearch}
        searchingBy={searchBy}
        hasButton
        buttonTitle="Create Site"
        buttonPress={() => setCreateMode(true)}
      />
      <div className="sites-container d-flex">
        <SidePanel
          content={
            <MultipleFilters
              items={[]}
              filters={filterUtils.getFilters(['Site Type', 'Site Solution', 'Site Status'], false)}
              filterBy={filterBy}
              onFilter={(_filteredItems, appliedFilters) => {
                setFilterBy(appliedFilters);
              }}
            />
          }
          isOpen={showFilters}
        />
        <div className={`d-flex flex-fill  ${!showModal || showFilters ? 'split-view' : ''}`}>
          { sites.current.length === 0 ? (
            <div className="d-flex h-100 w-100 align-items-center justify-content-center">
              <EmptyState
                icon={'location'}
                title={'No Sites found. Create your first site.'}
                details={'If you do have created sites, please contact support.'} />
            </div>
          ) : (
            <Table
              columns={tableHeaders}
              data={data}
              pageCount={pageCount}
              itemsCount={itemsCount}
              fetchData={fetchData}
              searchBy={searchBy}
              filterBy={filterBy}
              loading={isLoading}
              isItemDetailsClosed={showModal}
              resetOnChange={siteGroup} // reset table when siteGroup change
              onItemSelected={site => {
                handleSiteSelection(site);
                setShowFilters(false);
              }}
              dataLabel={'sites'}
            />
          )
          }
        </div>
        {selectedSite && (
          <ManageSitesModal
            title={selectedSite.displayName}
            isOpen={showModal}
            toggle={handleCloseModal}
            onConfirm={handleSiteEdition}
            site={selectedSite}
            companyId={company.id}
          />
        )}
      </div>
    </div>
  );
};

export default SitesContainer;
