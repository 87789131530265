import React from 'react';

import calendarUtils from '../../utils/calendar';

import './styles.scss';

const PanelControl = ({
  startTime,
  endTime,
  startDate,
  endDate,
  weekDays,
  isWeekDaysEnabled,
  isTimeEnabled,
  setCalendarOpen,
  applyChanges
}) => {
  const shouldDisableApplyButton = () => {
    const start = startTime || calendarUtils.placeholders.startTime;
    const end = endTime || calendarUtils.placeholders.endTime;

    const areDatesInvalid = !(startDate && endDate);
    const areTimesInvalid = calendarUtils.isInvalidTime(start) || calendarUtils.isInvalidTime(end) || start > end;
    const areDaysInvalid = weekDays.length === 0;

    return areDatesInvalid || (isTimeEnabled && areTimesInvalid) || (isWeekDaysEnabled && areDaysInvalid);
  };

  return (
    <div className="panel-controls">
      <button type="button" className="btn btn-block btn-link" onClick={() => setCalendarOpen(false)}>
        Close
      </button>
      <button
        type="button"
        className="btn btn-block btn-primary"
        disabled={shouldDisableApplyButton()}
        onClick={applyChanges}
      >
        Apply
      </button>
    </div>
  );
};

export default PanelControl;
