import utils from "..";
import deviceTemplate from "../device/deviceTemplate";

/**
 * Get an array of input names (keys) that belongs to Advanced/Solution section.
 * It should be used to split preset config between GENERAL and SOLUTION config
 * @param {} - none
 * @returns {array} - name of inputs from Advanced/Solution section
 *
 * @example
 * getSolutionKeys(); // returns ['"detectionConfiguration"', 'faceConfiguration', 'triggers', ...]
 */
const getSolutionKeys = () => {
  let solutionKeys = [];

  // Use deviceTemplate to extract advanced/solution fields
  const advancedFields = deviceTemplate.advanced.fields;
  const solutionFields = Object.values(advancedFields);

  // At first the result will be groupped by solution, we need to look inside to get them
  const solutionParams = solutionFields.map(solution => solution.fields);

  solutionParams.forEach(params => {
    Object.keys(params);
    solutionKeys = [...solutionKeys, ...Object.keys(params)]
  });

  // Remove duplicated keys
  solutionKeys = [... new Set(solutionKeys)];

  // Remove 'model' key, they should not be part of advanced/solution section on comparison modal
  return solutionKeys.filter(key => key !== 'models');
};

/**
 * Format table sections (GENERAL, SOLUTION and MODEL) based on two different config objects.
 * @param {string} - solutionType - Solution from both config objects
 * @param {object} - oldSettings - current version of config, it can also be current applied preset
 * @param {object} - newSettings - new version of config to be applied, it can also be a new preset to apply
 * @param {string} - section - desired section to make changes ('all', 'details', 'general' and 'advanced')
 *
 * @returns {array} - list of section objects, each one with their title and inner sections
 *
 * @example
 * parseForTable('FACEV2', OldConfig, NewConfig, 'general'); // returns [{ title: 'GENERAL', sections: { subtitle: 'Render Options', values: {...} }}];
 */
const parseForTable = (solutionType, oldSettings, newSettings, section) => {
  const solutionKeys = getSolutionKeys();

  if (!oldSettings || !newSettings) {
    return null;
  }

  const generalKeys = getGeneralKeys(oldSettings, newSettings, 'models', solutionKeys);

  switch (section) {
    case 'all':
    case 'details':
      return [
        {
          title: 'GENERAL',
          sections: generateSectionBody(oldSettings, newSettings, generalKeys)
        },
        {
          title: solutionType,
          sections: generateSectionBody(oldSettings, newSettings, solutionKeys)
        },
        {
          title: 'MODEL',
          sections: generateModelSection(oldSettings.models, newSettings.models)
        }
      ];
    case 'general':
      return [
        {
          title: 'GENERAL',
          sections: generateSectionBody(oldSettings, newSettings, generalKeys)
        }
      ];
    case 'advanced':
      return [
        {
          title: solutionType,
          sections: generateSectionBody(oldSettings, newSettings, solutionKeys)
        },
        {
          title: 'MODEL',
          sections: generateModelSection(oldSettings.models, newSettings.models)
        }
      ];
  }
}

const generateSectionBody = (oldSettings, newSettings, sectionKeys) => {
  const comparisonMap = sectionKeys.map((subtitle) => {
    const rowKeys = oldSettings[subtitle] ? Object.keys(oldSettings[subtitle]) : [];

    // Add in rowKeys the diff between subtitle oldSettings and subtitle newSettings
    if (newSettings[subtitle]) {
      for (const key2 in newSettings[subtitle]) {
        if (!rowKeys.includes(key2)) {
          rowKeys.push(key2);
        }
      }
    }

    return {
      subtitle: subtitle,
      values: rowKeys.map((rowName) => ({
        title: rowName,
        values: {
          old: oldSettings[subtitle] ? oldSettings[subtitle][rowName] : undefined,
          new: newSettings[subtitle] ? newSettings[subtitle][rowName] : undefined,
        }
      }))
    };
  });

  return removeEqualsValue(comparisonMap);
};

/**
 * Remove repeated values from the table to show only the different values
 * @param {array} sectionBodyArray
 * @returns
 */
const removeEqualsValue = (sectionBodyArray) => {
  return sectionBodyArray.map((sectionBody) => {
    return {
      subtitle: sectionBody.subtitle,
      values: sectionBody.values.filter(item => {
        // Return false if both values are undefined
        if (utils.isNullish(item.values.old) && utils.isNullish(item.values.new)) return false;

        // Return if values are different
        return JSON.stringify(item.values.old) !== JSON.stringify(item.values.new)
      })
    };
  }).filter(value => value.values !== undefined && value.values.length > 0);
}

// generate the union of attributes between oldConfig and newConfig
const getGeneralKeys = (oldConfig, newConfig, modelKeys, solutionKeys) => {
  const generalKeys = Object.keys(oldConfig)
    .filter(settingKey => !modelKeys.includes(settingKey) && !solutionKeys.includes(settingKey));

  // Add in generalKeys the diff between oldConfig and newConfig
  for (const key in newConfig) {
    if (!generalKeys.includes(key) && !modelKeys.includes(key) && !solutionKeys.includes(key)) {
      generalKeys.push(key);
    }
  }
  return generalKeys;
};

const generateModelSection = (oldModels, newModels) => {
  if (!oldModels) return [];

  const oldModelList = oldModels.model || [];
  const newModelList = newModels.model || [];

  const modelIds = oldModelList.map(model => `${model.type}-${model.version}`);

  for (const model of newModelList) {
    if (!modelIds.includes(`${model.type}-${model.version}`)) {
      modelIds.push(`${model.type}-${model.version}`);
    }
  }

  return modelIds.map(modelId => {
    const idPieces = modelId.split('-');
    const type = idPieces[0];
    const version = idPieces[1];

    const rowKeys = [
      {name: 'Type', path: 'type'},
      {name: 'Version', path: 'version'},
      {name: 'Async Queue Size', path: 'config.async_queue_size'},
      {name: 'Input Size Width', path: 'config.input_size.width'},
      {name: 'Input Size Height', path: 'config.input_size.height'},
      {name: 'Backend', path: 'config.backend'},
      {name: 'Target', path: 'config.target'},
    ];
    const oldModel = oldModelList.find((model) => (model.type === type && model.version === version));
    const newModel = newModelList.find((model) => (model.type === type && model.version === version));

    return {
      subtitle: modelId,
      status: getModelStatus(oldModel, newModel),
      values: rowKeys.map((row) => ({
        title: row.name,
        values: {
          old: oldModel ? utils.get(oldModel, row.path) : undefined,
          new: newModel ? utils.get(newModel, row.path) : undefined,
        }
      })).filter(row => {
        return row.values ? row.values.old !== row.values.new : false;
      })
    };
  }).filter(model => model.status !== 'equal');
}

const getModelStatus = (oldModel, newModel) => {
  if (oldModel === undefined && newModel !== undefined) {
    return "added";
  } else if (oldModel !== undefined && newModel === undefined) {
    return "removed";
  } else if (!utils.compareObjects(oldModel, newModel)) {
    return "modified";
  } else {
    return "equal";
  }
}

export {
  parseForTable
};