import types from '../types.js';

export const createDevice = (payload) => {
  return { type: types.CREATE_DEVICE, payload }
}

export const selectDevices = (index) => {
  return { type: types.SELECT_DEVICE, payload: index }
}

export const loadDevices = (devices) => {
  return { type: types.LOAD_DEVICES, payload: devices }
}

export const updateDevice = (device) => {
  return { type: types.UPDATE_DEVICE, payload: device }
}

export const postDeviceUpdate = (device, fieldsPath) => {
  return { type: types.POST_DEVICE_UPDATE, payload: { device, fieldsPath } }
}

export const getDevices = (state) => {
  return state.deviceReducer.devices;
}

export const loadLicenses = (licenses) => {
  return { type: types.LOAD_LICENSES, payload: licenses }
}

export const getLicenses = (state) => {
  return state.deviceReducer.licenses;
}

export const getAllDevices = (state) => {
  return state.deviceReducer.allDevices;
}

export const loadAllDevices = (allDevices) => {
  return { type: types.LOAD_ALL_DEVICES, payload: allDevices }
}