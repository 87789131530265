import React from 'react';
import icons from '../../assets/icons';
import './styles.scss';

const CustomButton = ({ icon, title, handleClick, classes, disabled, loading }) => {
  return (
    <button
      onClick={handleClick}
      className={`btn ${classes}`}
      disabled={disabled || loading}>
      <div className="d-flex align-items-center">
        { loading
          ? <>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
              <span className="sr-only">Loading...</span>
            </>
          : <>
              { icon && <img src={icons[icon]} alt={icon} className="btn-icon"/> }
              { title &&
                <span> {title} </span>
              }
            </>
        }
      </div>
    </button>
  );
}

export default CustomButton;