import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import icons from '../../assets/icons';
import './styles.scss';

const MultiSelectDropDown = ({ items = [], selectedItems = [], onOptionSelected, clearFilters, additionalFilter }) => {

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="custom-dropdown">
      <Dropdown className="multiselect" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
        <DropdownToggle className="dropdown-list">
          Filter by type <img src={isOpen ? icons.angleUp : icons.angleDown} className="arrow-down" alt="arrow-down" />
        </DropdownToggle>
        <DropdownMenu>
          {
            items.map((filter, index) => (
              <DropdownItem key={`button_${index}`} toggle={false}
                onClick={() => onOptionSelected(filter.value)}
                active={selectedItems.includes(filter.value)}>
                <div className="current-value">
                  {filter.label}
                </div>
              </DropdownItem>
            ))
          }
          <div className="dropdown-actions">
            <button onClick={clearFilters} className="btn text-danger clear-button">
              Clear All
            </button>
            {additionalFilter ? additionalFilter : null}
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default MultiSelectDropDown;