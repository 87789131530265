import React from 'react';

import { UncontrolledTooltip } from 'reactstrap';
import SearchBox from '../searchBox';
import ItemSelector from '../itemSelector';

import './styles.scss';

class ItemSelectorSingle extends ItemSelector {
  constructor(props) {
    super(props);
    this.state = {
      filteredItems: [],
      selectedItems: {},

      sortBy: { value: 'displayName', desc: false },
      searchQuery: ''
    };
  }

  componentDidUpdate(prevProps) {
    const { selectedItem } = this.props;
    if (this.props.items !== prevProps.items) {
      // Everytime we change the item list the first element is selected
      if (this.props.firstShouldBeSelected && selectedItem) {
        // Look up for selected item on current list
        const selectedItemExistis = this.props.items.some(item => item.id === this.props.selectedItem.id);

        if (selectedItemExistis) {
          this.select(selectedItem);
        }
      }

      this.populateItemsList();
    }

    if (selectedItem !== prevProps.selectedItem && selectedItem) {
      // Remove all previous selection
      let selectedItems = {};
      selectedItems[selectedItem.id] = true;
      this.setState({ selectedItems });
    }
  }

  selectFirstItem = filteredItems => {
    if (filteredItems.length > 0 && !this.props.selectedItem) {
      const firstItem = filteredItems[0];
      // Select first item
      this.select(firstItem);
    }
  };

  select = item => {
    const { onItemSelected } = this.props;
    // Remove all previous selection
    let selectedItems = {};
    selectedItems[item.id] = true;

    this.setState({ selectedItems });
    onItemSelected(item);
  };

  render() {
    const { items, searchPlaceholder, icon, hideToolbar, hideSearchBox, renderCustomElement } = this.props;
    const { selectedItems, filteredItems, sortBy } = this.state;

    if (items.length === 0) {
      return <span className="no-items">No items to display</span>;
    }

    return (
      <div className="item-selector-container">
        {(!hideSearchBox || hideSearchBox === false) && (
          <SearchBox
            placeholder={searchPlaceholder}
            onChange={value => this.setState({ searchQuery: value }, this.applySortAndFilter)}
          />
        )}
        {(!hideToolbar || hideToolbar === false) && (
          <div className="item-selector-toolbar">
            <div className="sort-button" onClick={this.toggleSort}>
              <i className={sortBy.desc ? 'uil uil-angle-up' : 'uil uil-angle-down'} />
              <label>Name</label>
            </div>
          </div>
        )}
        <ul className="item-selector-list" ref={this.props.forwardedRef}>
          {filteredItems.map(item => {
            const itemClasses = `d-flex align-items-center ${selectedItems[item.id] ? 'selected' : ''}`;

            return renderCustomElement ? (
              renderCustomElement(item, selectedItems[item.id], this.select)
            ) : (
              <li
                key={item.id}
                id={`item-selector-${item.id}`}
                onClick={() => this.select(item)}
                className={itemClasses}
              >
                {icon && <img src={icon} alt="" />}
                <div>{item.displayName}</div>
                <UncontrolledTooltip placement="top" target={`item-selector-${item.id}`}>
                  {item.displayName}
                </UncontrolledTooltip>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => <ItemSelectorSingle {...props} forwardedRef={ref} />);
