import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import components from '../../../../components';
import InfoSection from '../../../../components/infoSection';
import { roles } from '../../../../utils/user/permissions';
import './styles.scss';

const { CustomButton, CustomDropdown} = components;

const UserPermission = ({ toggle, modalOpen, selectedUsers, updateSelectedUserRole, updatePermissions }) => {
  const [infoState, setInfoState] = useState(false);
  const [modifiedUsers, setModifiedUsers] = useState([]);

  return (
    <Modal className="modal-add-user" isOpen={modalOpen} fade={false} toggle={toggle} centered>
      <div className="modal-header">
        <h2>Permissions</h2>
        <h4>{`Update ${selectedUsers.length} user's permissions.`}</h4>
      </div>
      <ModalBody>
        <div className="users-permission-modal-wrapper">
          <div className={`users-permission-modal ${infoState ? 'info-open' : ''}`}>
            {selectedUsers.map(user => {
              return (
                <div className="user-data-row" key={user.id}>
                  <div className="ellipsis">
                    <div className="user-name">{user.displayName}</div>
                    <div className="user-email">{user.email}</div>
                  </div>
                  <div className="user-role">
                    <CustomDropdown
                      items={roles}
                      selectedItem={roles.find(item => item.name === user.roleName)}
                      valueField={'id'}
                      displayField={'name'}
                      handleSelection={event => {
                        const newRoles = modifiedUsers;
                        // Remove user from modified list if value goes back to initial
                        const userIndex = newRoles.findIndex(userRole => userRole.id === user.id);
                        if (userIndex > -1) {
                          newRoles.splice(userIndex, 1);
                        } else {
                          newRoles.push({
                            id: user.id,
                            role: event.target.value
                          });
                        }
                        setModifiedUsers(newRoles);
                        updateSelectedUserRole(user.id, event.target.value);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <InfoSection
            title={'How do roles work:'}
            content={
              <div>
                <p>
                  <b>Dashboard User:</b> Can access/use Profile, Summary, Analytics and Report modules. Also can
                  modify/edit settings (Devices, Projects and Reports)
                </p>
                <p>
                  <b>Data Viewer:</b> Can access/use Profile, Summary, Analytics and Report modules. Cannot modify/edit
                  any settings, only view data.
                </p>
              </div>
            }
            onClickCB={setInfoState}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="button-container">
          <CustomButton classes={'btn-secondary'} title={'Cancel'} handleClick={toggle} />
          <CustomButton
            classes={'btn-primary'}
            title={'Update Permissions'}
            disabled={modifiedUsers.length === 0}
            handleClick={() => {
              updatePermissions(modifiedUsers);
              toggle();
            }}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default UserPermission;
