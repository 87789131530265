import utils from '..';

const dataSources = {
  AUDIENCE_M: {id:'AUDIENCE_M',label:'Audience Measurement', infoText:'Count People. Camera required'},
  TRAFFIC_M:{id:'TRAFFIC_M',label:'Traffic Measurement', infoText:'Count Vehicles. Camera required'},
  MOBILE_M: {id:'MOBILE_M',label:'Mobile Data', infoText:'Data insights from mobile usage. No camera required.'},
  WIFI_M: {id:'WIFI_M',label:'WiFi Data', infoText:'Estimate people by wifi connection. No camera required.'}
}

const hasErrorsCheck = (site, step) => {
  switch (step) {
    case 'settings':
      // Site must have no errors to proceed and Prevent empty value (from before changes were made)
      return utils.hasErrorsCheck(site.errors) || !site.displayName || !site.type;
    case 'solution':
      if (site.solution) {
        if (site.solution === 'customSolution') {
          return site.datasources ? site.datasources.length === 0 : true;
        }
        return false;
      }
      return true;
    case 'location':
      if (!site.latitude || !site.longitude || site.address === '') {
        return true;
      }
      return false;
    default:
      return false;
  }
};

const getSiteErrorsObject = (site, errorArray) => {
  const errors = {};
  errorArray.forEach(error => {
    errors[error] = hasErrorsCheck(site, error);
  });
  return errors;
};

const populateMappingsFields = (site) => {
  const newMappings = {};

  if (site.mappings) {
    return site.mappings;
  }

  site.siteGroup.integration.mappingMetadataFields.forEach(field => {
    const fieldName = field.replaceAll(' ', '_').toLowerCase();

    newMappings[fieldName] = '';
  });

  return newMappings;
}

const getDataSources = () => {
  return dataSources
}

const getDataSourcesIds = () =>  Object.keys(dataSources)

const getCountryCode = (addressArray) => {
  const countryObject = addressArray.filter(address => address.types[0] === 'country')
  return countryObject[0].address_components[0].short_name || ''
}

export default { hasErrorsCheck, getSiteErrorsObject, populateMappingsFields, getDataSources, getDataSourcesIds, getCountryCode };
