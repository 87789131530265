import Konva from 'konva';
import helpers from './helpers';

const colorDefault = 'red';
const colorDefaultSelected = 'blue';
const deafultArrowColor = '#30395a';
const deafultTextColor = '#30395a';

export class Zone extends Konva.Rect {
  constructor(zone) {
    const { A, B, name, real_height, roi_ref, aspectRatio } = zone;
    const height = 20;
    const dX = B.x - A.x;
    const dY = B.y - A.y;

    // Calculate the line angle and remove precision to avoid floating point errors
    const theta = Math.atan2(dY, dX).toFixed(4);

    let x = A.x + (height/2) * Math.sin(theta);
    let y = A.y - (height/2) * Math.cos(theta);
    const dimensions = {
      x,
      y,
      name,
      height,
      width: helpers.diff2Points(A, B),
      rotation: theta * 180 / Math.PI // converting radians to degree
    };
    const defaultParams = {
      fill: colorDefault,
      opacity: 0.2
    }
    super({ ...dimensions, ...defaultParams, aspectRatio });

    this.real_height = real_height;
    this.roi_ref = roi_ref;

    // render arrow
    const arrows = helpers.getArrowPoints(A, B);
    const textPosition = helpers.getTextPosition(A, B);

    this.arrowDirections1  = new Konva.Arrow({
      points: arrows[0],
      pointerLength: 5,
      pointerWidth: 5,
      fill: deafultArrowColor,
      stroke: deafultArrowColor,
      strokeWidth: 2
    });

    this.arrowDirections2  = new Konva.Arrow({
      points: arrows[1],
      pointerLength: 5,
      pointerWidth: 5,
      fill: deafultArrowColor,
      stroke: deafultArrowColor,
      strokeWidth: 2
    });

    this.text = new Konva.Text({
      text: name,
      fill: deafultTextColor,
      fontSize: 14,
      fontStyle: 'bold',
      x: textPosition.x * aspectRatio,
      y: textPosition.y * aspectRatio,
      rotation: theta * 180 / Math.PI
    });

    this.on('dragmove transform', (e) => {
      const { A, B } = e.target.getObj();
      const arrows = helpers.getArrowPoints(A, B);
      const textPosition = helpers.getTextPosition(A, B);
      this.arrowDirections1.points(arrows[0]);
      this.arrowDirections2.points(arrows[1]);
      this.text.position(textPosition);
      this.text.rotation(textPosition.theta * 180 / Math.PI);
    });

    this.updatePositions();
  }

  set parent(newParent) {
    this._parent = newParent;
    if(newParent instanceof Konva.Layer) {
      newParent.add(this.arrowDirections1);
      newParent.add(this.arrowDirections2);
      newParent.add(this.text);
    }
  }

  get parent() {
    return this._parent;
  }

  destroy() {
    this.arrowDirections1.destroy();
    this.arrowDirections2.destroy();
    this.text.destroy();
    this.off('dragmove transform');
    super.destroy();
  }

  edit() {
    this.draggable(true);
    this.fill(colorDefaultSelected);
  }

  finishEdit() {
    this.draggable(false);
    this.fill(colorDefault);
  }

  setAttrs(params) {
    super.setAttrs(params);
    const zone = this.getObj();
    const arrows = helpers.getArrowPoints(zone.A, zone.B);
    const textPosition = helpers.getTextPosition(zone.A, zone.B);
    if (this.arrowDirections1) {
      this.arrowDirections1.points(arrows[0]);
      this.arrowDirections2.points(arrows[1]);
      this.text.position(textPosition);
      this.text.rotation(textPosition.theta * 180 / Math.PI);
      if (params.name) {
        this.text.text(params.name);
      }
    }
  }

  getObj(showRoiRef, aspectRatio) {
    const ratio = aspectRatio || 1;
    const point = {
      width: this.width() / ratio,
      height: this.height() / ratio,
      x: this.x() / ratio,
      y: this.y() / ratio,
      angle: this.rotation(),
      scaleX: this.scaleX(),
      scaleY: this.scaleY()
    }

    const heightFinal = (point.height * point.scaleY);
    const widthFinal = (point.width * point.scaleX);
    const theta = (point.angle * Math.PI / 180).toFixed(4);

    let y = point.y + (Math.cos(theta) * (heightFinal / 2));
    let x = point.x - (Math.sin(theta) * (heightFinal / 2));
    let y2 = y + (Math.sin(theta) * widthFinal);
    let x2 = x + (Math.cos(theta) * widthFinal);
    let obj =  {
      A: { x: Math.round(x), y: Math.round(y) },
      B: { x: Math.round(x2), y: Math.round(y2) },
      height: Math.round(heightFinal),
      name: this.name(),
      real_height: this.real_height
    };
    if(showRoiRef) {
      obj.roi_ref = this.roi_ref;
    }
    return obj;
  }

  // Apply aspect ratio to update positions
  updatePositions() {
    const ratio = this.attrs.aspectRatio;
    this.attrs.x *= ratio;
    this.attrs.y *= ratio;
    this.attrs.width *= ratio;
    this.attrs.height *= ratio;

    // Update Arrows position
    const arrow1 = this.arrowDirections1.points();
    const arrow2 = this.arrowDirections2.points();
    this.arrowDirections1.points(arrow1.map(point => point * ratio));
    this.arrowDirections2.points(arrow2.map(point => point * ratio));
  }
}