import React, { useEffect, useRef, useState } from 'react';
import { FixedSizeList as List } from 'react-window';

import CustomCheckBox from '../customCheckBox';
import SearchBox from '../searchBox';

import utils from '../../utils';
import './styles.scss';

/**
 *
 * @property {Object[]} items Items to be selected (requires at least id)
 * @property {Object[]} customSelectedItems Inject Custom selected items
 * @property {Number} listSize The size of items list
 * @property {Number} itemHeight The size of the item on the list list
 * @property {string} emptyMessage Message when the list is empty
 * @property {string} customSearchPlaceHolder Message on searchbox
 * @property {string} displayField Display (label) field on object
 * @property {string} valueField Value field on object
 * @property {string} sortField Sort items by field
 * @property {boolean} renderCheckbox Display checkbox?
 * @property {boolean} renderSearchbox Display searchbox?
 * @property {boolean} allSelected Set all items selected?
 * @property {boolean} hasCustomSelectedItems is using custom selected items?
 * @property {Function} renderActionButtons Display action buttons?
 * @property {Function} renderBulkActionButtons Display bulk action buttons?
 * @property {Function} onItemSelected Callback when an item is selected
 * @property {Function} onSelect Callback when selected items change
 * @property {Function} customRender Callback to render custom rows
 * @returns {JSX.Element}
 */
const MultiSelector = ({
  items,
  customSelectedItems = [],
  emptyMessage = 'No items to display',
  customSearchPlaceHolder = 'Search',
  listSize,
  itemHeight,
  displayField = 'id',
  valueField = 'id',
  sortField = 'id',
  renderCheckbox = true,
  renderSearchbox = true,
  allSelected = true,
  hasCustomSelectedItems = false,
  renderActionButtons,
  renderBulkActionButtons,
  onItemSelected,
  onSelect,
  customRender,
  filters = null
}) => {
  const [selectedItems, setSelectedItems] = useState(allSelected ? items : []);
  const [availableItems, setAvailableItems] = useState(allSelected ? [] : items);
  const [renderList, setRenderList] = useState(items);
  const [searchingBy, setSearchingBy] = useState('');
  const [searchedItems, setSearchedItems] = useState([]);

  const prevItemsRef = useRef();

  useEffect(() => {
    const sortedItems = items.sort(sortArray);
    if (!utils.compareArrays(prevItemsRef.current, sortedItems)) {
      if (allSelected) {
        setSelectedItems(sortedItems);
        setAvailableItems([]);
      } else {
        setSelectedItems([]);
        setAvailableItems(sortedItems);
      }
      setSearchedItems([]);
      setSearchingBy('');
    }
    prevItemsRef.current = items;
  }, [items]);

  useEffect(() => {
    setRenderList(generateRenderList());
  }, [availableItems, searchedItems]);

  useEffect(() => {
    if (onSelect) {
      onSelect(selectedItems);
    }
    setRenderList([...selectedItems, ...availableItems]);
  }, [selectedItems]);

  useEffect(() => {
    //change if list is different not only on size but on content.
    const selectedHasChanged = !utils.compareArrays(customSelectedItems, selectedItems);
    if (hasCustomSelectedItems && selectedHasChanged) {
      onSelectionChange(selectedItems, customSelectedItems);
    }
  }, [customSelectedItems]);

  /**
   * Check if the user is searching
   * @returns {boolean} is searching?
   */
  const isSearching = () => searchingBy !== '';

  /**
   * Check if item is selected
   * @param {string} itemId id of item to be checked
   * @returns {boolean} is item selected?
   */
  const isSelected = itemId => selectedItems.some(item => item.id === itemId);

  const sortArray = (a, b) => utils.compareStrings(a[sortField], b[sortField]);

  /**
   * Generate items list to be rendered concatenating selected and available items
   * @returns {Array} render list
   */
  const generateRenderList = () => {
    return isSearching()
      ? [...searchedItems.filter(item => isSelected(item.id)), ...searchedItems.filter(item => !isSelected(item.id))]
      : [...selectedItems, ...availableItems];
  };

  /**
   * Change Selected and Available items array when a new item is selected
   * @param {Object[]} currentSelectedItems Current selected items
   * @param {Object[]} newSelectedItems New selected items
   */
  const onSelectionChange = (currentSelectedItems, newSelectedItems) => {
    // When new selected items is bigger than current selected items we add the missing items to selected items
    if (newSelectedItems.length > currentSelectedItems.length) {
      const addArray = newSelectedItems.filter(
        changedItem => !currentSelectedItems.some(item => changedItem.id === item.id)
      );
      toggleSelection(addArray, false);
    }
    // When new selected items is smaller than current selected items we remove the extra items from selected items
    else if (newSelectedItems.length < currentSelectedItems.length) {
      const removeArray = currentSelectedItems.filter(
        item => !newSelectedItems.some(changedItem => changedItem.id === item.id)
      );
      toggleSelection(removeArray, true);
    } else {
      toggleSelection(currentSelectedItems, true);
    }
  };

  /**
   * Toggle item selection moving items from Selected to Available and vice-versa
   * @param {array} itemsToToggle items to be removed or added
   * @param {boolean} remove should remove or add item?
   */
  const toggleSelection = (itemsToToggle, remove) => {
    let newAvailableItems = utils.deepClone(availableItems);
    let newSelectedItems = utils.deepClone(selectedItems);
    itemsToToggle.forEach(itemToToggle => {
      if (remove) {
        //remove items from SELECTED list and add them to AVAILABLE list
        newSelectedItems = newSelectedItems.filter(item => item.id !== itemToToggle.id);
        newAvailableItems.push(items.find(item => item.id === itemToToggle.id));
        newAvailableItems = newAvailableItems.sort(sortArray);
      } else {
        //remove items from AVAILABLE list and add them to SELECTED list
        newAvailableItems = newAvailableItems.filter(item => item.id !== itemToToggle.id);
        newSelectedItems.push(items.find(item => item.id === itemToToggle.id));
        newSelectedItems = newSelectedItems.sort(sortArray);
      }
    });

    setSelectedItems(newSelectedItems);
    setAvailableItems(newAvailableItems);
  };

  /**
   * Select and unselect all items from the list
   * @param {boolean} isAllSelected
   */
  const toggleAll = isAllSelected => {
    if (isAllSelected) {
      setAvailableItems(isSearching() ? [...availableItems, ...searchedItems] : items);
      setSelectedItems(
        isSearching()
          ? selectedItems.filter(
              selectedItem => !searchedItems.some(itemSelected => itemSelected.id === selectedItem.id)
            )
          : []
      );
    } else {
      setSelectedItems(isSearching() ? [...selectedItems, ...searchedItems] : items);
      setAvailableItems(
        isSearching()
          ? availableItems.filter(
              availableItem => !searchedItems.some(itemAvailable => itemAvailable.id === availableItem.id)
            )
          : []
      );
    }
  };

  /**
   * Search items on the list
   * @param {string} searchBy field to be searched
   */
  const onSearch = searchBy => {
    setSearchingBy(searchBy);
    if (searchBy !== '') {
      const localSearchedItems = items.filter(item => {
        const itemHas = option => item[option].toUpperCase().includes(searchBy.toUpperCase());
        return itemHas(displayField) || itemHas('id');
      });
      setSearchedItems(localSearchedItems);
    } else {
      setSearchedItems([]);
    }
  };

  const renderLabel = (item, label) => {
    return customRender ? customRender(item) : label;
  };

  const renderRow = ({ index, style }) => {
    const item = renderList[index];
    const value = item[valueField];
    const selected = isSelected(item.id);
    // If the list of items has only one item no tooltip should be rendered (breaking rendering space).
    const label =
      renderList.length === 1 ? (
        <div id={item.id}>{item[displayField]}</div>
      ) : (
        utils.renderTrimmedString(item[displayField], item.id)
      );

    return (
      <div
        className={`item ${selected ? 'selected' : ''}`}
        key={item.id}
        style={style}
        onClick={() => {
          if (onItemSelected) {
            onItemSelected(value, item.id);
            toggleSelection([item], selected);
          }
        }}
      >
        {renderCheckbox ? (
          <CustomCheckBox
            selected={isSelected(item.id) ? 'all' : 'none'}
            label={renderLabel(item, label)}
            onClick={value => toggleSelection([item], value)}
            key={item.id}
          />
        ) : (
          renderLabel(item, label)
        )}
        {renderActionButtons ? renderActionButtons(item) : null}
      </div>
    );
  };

  const renderEmpty = () => {
    return (
      <div className="item-list no-devices">
        <p>{emptyMessage}</p>
      </div>
    );
  };

  const renderItems = () => {
    return (
      <>
        {renderCheckbox && (
          <div className="toolbar">
            <CustomCheckBox
              multiple
              selectedItems={selectedItems.length}
              totalItems={renderList.length}
              label={`All - ${selectedItems.length} / ${items.length}`}
              onClick={toggleAll}
            />
            {renderBulkActionButtons ? renderBulkActionButtons(selectedItems) : null}
          </div>
        )}
        <div className="item-list">
          {isSearching() && searchedItems.length === 0 && <p>{emptyMessage}</p>}
          <List
            className={'device-virtual-list'}
            height={listSize ? listSize : window.innerHeight - 368}
            itemCount={renderList.length}
            itemSize={itemHeight ? itemHeight : 46}
            width="100%"
          >
            {renderRow}
          </List>
        </div>
      </>
    );
  };

  return (
    <div className="multiselector">
      {renderSearchbox && (
        <div className="search-filter-bar">
          <SearchBox onChange={onSearch} placeholder={customSearchPlaceHolder} searchingBy={searchingBy} />
          {filters}
        </div>
      )}
      {items.length === 0 ? renderEmpty() : renderItems()}
    </div>
  );
};

export default MultiSelector;
