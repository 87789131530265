import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomDropdown from '../customDropdown';
import {
  getSelectedSolution,
  getSolutions,
  selectSolution
} from '../../redux/projects/actions';
import { solutionsMeta } from '../../utils/solutions';

/*
* [solutionsMeta] -> array with all the existing solutions i.e. Broadsign - Audience, Crowd etc.
* [currentSolutions] -> array with solutions within the current project
* [supportedSolutions] -> array with solutions supported by parent component i.e. Compare Screen only
* supports Audience solution at this moment.
*/

const SolutionSelector = ({ supportedSolutions }) => {
  const [filteredSolutions, setFilteredSolutions] = useState([]);

  const currentSolutions = useSelector(getSolutions);
  const selectedSolution = useSelector(getSelectedSolution);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedSolution) {
      // Filter solutions based on the current project
      let filterResult = solutionsMeta.filter(solution => {
        return currentSolutions.some(currSolution => currSolution.type === solution.type);
      });

      // Filter again using supportedSolutions
      if (supportedSolutions) {
        filterResult = filterResult.filter(solution => {
          return supportedSolutions.some(currSolution => currSolution.type === solution.type);
        });
      }

      // If current solution is no long available on supportedSolutions, select another one
      if (!selectedSolution) {
        // selectedSolution is not available on redux just yet
        dispatch(selectSolution(filterResult[0] || null));
      } else if (filterResult.length > 0 && !filterResult.some(solution => solution.id === selectedSolution.id)) {
        // selectedSolution doesn't exist inside filterResult
        dispatch(selectSolution(filterResult[0]));
      }

      // Update dropdown items
      setFilteredSolutions(filterResult);
    }
  }, [currentSolutions, selectedSolution, supportedSolutions]);

  const handleSolutionSelector = (event) => {
    const solutionId = event.target.value;
    const solution = solutionsMeta.find(curSolution => curSolution.id === solutionId);
    dispatch(selectSolution(solution));
  };

  return (
    (selectedSolution) &&
    <div className="solution-selector">
      <CustomDropdown
        hasIcon={false}
        outline={false}
        label="Solution"
        items={filteredSolutions}
        handleSelection={handleSolutionSelector}
        selectedItem={selectedSolution}
        valueField={'id'}
        displayField={'name'} />
    </div>
  );
};

export default SolutionSelector;