import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import CustomInput from '../customInput';
import CustomDropdown from '../customDropdown';
import utils from "../../utils";
import modelUtils from "../../utils/models";

const ModelConfiguration = ({ model, isEditable, asyncMode, modelIndex, removeModel, updateModel}) => {

  const [isOpenModal, setIsOpenModal] = useState(false);

  const renderModelConfig = (type) => {
    if (type.toUpperCase().includes("HAAR")) {
      return (
        <>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>Min Neighbors</label>
                <CustomInput
                  id="min-neighbors"
                  type="number"
                  isEditable={isEditable}
                  value={model.config.min_neighbors || ""}
                  onChange={e => updateModel(modelIndex, 'config.min_neighbors', parseInt(e.target.value))} />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label>Scale Factor</label>
                <CustomInput
                  id="scale-factor"
                  type="number"
                  isEditable={isEditable}
                  value={model.config.scale_factor || ""}
                  onChange={e => updateModel(modelIndex, 'config.scale_factor', parseInt(e.target.value))} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>Min Size</label>
                <CustomInput
                  id="min-size"
                  type="number"
                  isEditable={isEditable}
                  value={model.config.min_size || ""}
                  onChange={e => updateModel(modelIndex, 'config.min_size', parseInt(e.target.value))} />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label>Max Size</label>
                <CustomInput
                  id="max-size"
                  type="number"
                  isEditable={isEditable}
                  value={model.config.max_size || ""}
                  onChange={e => updateModel(modelIndex, 'config.max_size', parseInt(e.target.value))} />
              </div>
            </div>
          </div>
        </>
      );
    } else if (type.toUpperCase().includes("SSD")) {
      return (
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Input Size Height</label>
              <CustomInput
                id="input-size-height"
                type="number"
                isEditable={isEditable}
                defaultValue={model.config.input_size ? model.config.input_size.height : ""}
                onChange={(e) => {
                  return updateModel(modelIndex, 'config.input_size', {
                    ...model.config.input_size,
                    height: e.target.value ? parseInt(e.target.value) : null
                  })
                }
                } />
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Input Size Width</label>
              <CustomInput
                id="input-size-width"
                type="number"
                isEditable={isEditable}
                defaultValue={model.config.input_size ? model.config.input_size.width : ""}
                onChange={e => updateModel(modelIndex, 'config.input_size', {
                  ...model.config.input_size,
                  width: e.target.value ? parseInt(e.target.value) : null
                })} />
            </div>
          </div>
        </div>
      );
    }
  }

  const showSettingsButton = (type) => {
    if (type.toUpperCase().includes("HAAR") || type.toUpperCase().includes("SSD")) {
      return (
        <button disabled={!isEditable} className="btn" onClick={() => setIsOpenModal(true)}>
          <i className="uil uil-cog" />
        </button>
      );
    }
  };

  const getSelectedBackend = () => {
    const backend = utils.get(model, "config.backend");
    return `${backend || 0}`
  };

  return (
    <div className="model-configuration">
      <div className="model-configuration-header">
        <span>{model.type}</span>
        <div>
          {
            model && model.type && showSettingsButton(model.type)
          }
          <button disabled={!isEditable} className="btn" onClick={() => removeModel(modelIndex)}>
            <i className="uil uil-trash" />
          </button>
        </div>
      </div>
      <div className="model-configuration-body">
        <div className="form-group">
          <label>Version</label>
          <CustomInput
            id="model-version"
            type="text"
            value={model.version}
            isEditable={isEditable}
            onChange={e => updateModel(modelIndex, 'version', e.target.value)} />
        </div>
        <div className="form-group">
          <label>Async Queue Size</label>
          <CustomInput
            id="async-queue-size"
            type="number"
            value={model.config.async_queue_size || "3"}
            isEditable={isEditable && asyncMode}
            onChange={e => updateModel(modelIndex, 'config.async_queue_size', e.target.value)} />
        </div>
        <div className="form-group">
          <label>Backend</label>
          <CustomDropdown
            items={modelUtils.backendMapping}
            displayField="label"
            valueField="value"
            disabled={!isEditable}
            selectedItem={getSelectedBackend()}
            handleSelection={(event) => updateModel(modelIndex, 'config.backend', parseInt(event.target.value))}
          />
        </div>
        <div className="form-group">
          <label>Target</label>
          <CustomDropdown
            items={modelUtils.targetMapping}
            displayField="label"
            valueField="value"
            disabled={!isEditable}
            selectedItem={`${utils.get(model, "config.target") || 0}`}
            handleSelection={(event) => updateModel(modelIndex, 'config.target', parseInt(event.target.value))}
          />
        </div>
      </div>
      <Modal isOpen={isOpenModal} fade={false} toggle={() => setIsOpenModal(false)} centered>
        <ModalHeader className="header-gray" toggle={() => setIsOpenModal(false)}>{model.type} Config</ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column">
            { renderModelConfig(model.type) }
            <div className="d-flex justify-content-end">
              <button className="btn btn-primary" onClick={() => setIsOpenModal(false)}>Save</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
export default ModelConfiguration;