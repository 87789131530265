import types from '../types.js';

const initialState = {
  selectedProject: null,
  selectedDashboard: null,
  selectedAnalyticsDashboard: null,
  selectedSolution: null,
  solutions: [],
  projects: [],
  solutionsConfig: {}
};

const projectReducer = (state = initialState, action) => {
  const {
    LOAD_PROJECTS,
    UPDATE_PROJECTS,
    CREATE_PROJECT,
    UPDATE_SELECTED_PROJECT,
    SELECT_PROJECT,
    SET_PROJECT_TABLES,
    LOAD_SOLUTIONS,
    SELECT_SOLUTION,
    SELECT_DASHBOARD,
    SELECT_ANALYTICS_DASHBOARD,
    UPDATE_SELECT_SOLUTION,
    CLEAR_PROJECT,
    LOAD_SOLUTIONS_CONFIG,
    UPDATE_SOLUTION_CONFIG,
    CREATE_SOLUTION_CONFIG
  } = types;

  switch (action.type) {
    case LOAD_PROJECTS:
      return {
        ...state,
        ...{
          projects: action.payload
        }
      };
    case LOAD_SOLUTIONS_CONFIG:
      return {
        ...state,
        ...{
          solutionsConfig: action.payload
        }
      };
    case UPDATE_SOLUTION_CONFIG: {
      const configId = action.payload.id;
      const solutionId = action.payload.solutionId;
      const solutionsConfigByType = [...state.solutionsConfig[solutionId]];
      const configFound = solutionsConfigByType.findIndex(config => config.id === configId);
      solutionsConfigByType[configFound] = { ...action.payload };
      return {
        ...state,
        solutionsConfig: {
          ...state.solutionsConfig,
          [solutionId]: solutionsConfigByType
        }
      };
    }
    case CREATE_SOLUTION_CONFIG: {
      const solutionId = action.payload.solutionId;
      return {
        ...state,
        solutionsConfig: {
          ...state.solutionsConfig,
          [solutionId]: [...state.solutionsConfig[solutionId], action.payload]
        }
      };
    }
    case CREATE_PROJECT:
      return {
        ...state,
        ...{
          projects: [...state.projects, action.payload]
        }
      };
    case SELECT_PROJECT:
    case CLEAR_PROJECT:
      return {
        ...state,
        ...{
          selectedProject: action.payload
        }
      };
    case UPDATE_SELECTED_PROJECT:
    case SET_PROJECT_TABLES:
      return {
        ...state,
        ...{
          selectedProject: { ...action.payload }
        }
      };
    case UPDATE_PROJECTS:
      return {
        ...state,
        ...{
          projects: [...action.payload]
        }
      };

    case LOAD_SOLUTIONS:
      return {
        ...state,
        ...{
          solutions: [...action.payload]
        }
      };
    case SELECT_SOLUTION:
    case UPDATE_SELECT_SOLUTION:
      return {
        ...state,
        ...{
          selectedSolution: action.payload
        }
      };
    case SELECT_DASHBOARD:
      return {
        ...state,
        ...{
          selectedDashboard: action.payload
        }
      };
    case SELECT_ANALYTICS_DASHBOARD:
      return {
        ...state,
        ...{
          selectedAnalyticsDashboard: action.payload
        }
      };
    default:
      return state;
  }
};

export default projectReducer;
