import React from 'react';
import CustomInput from '../customInput';
import CustomRadioButton from '../customRadioButton';
import DateTimeInput from '../dateTimeInput';
import CustomRangeInput from '../customRangeInput';
import CustomCheckBox from '../customCheckBox';
import CustomDropdown from '../customDropdown';
import ChipTags from '../chipTags';
import ModelBox from '../modelBox';

const GeneralInput = ({ props }) => {
  switch (props.type) {
    case 'text':
      return (
        <CustomInput
          id={props.id}
          className={`input-text-ellipsis ${props.classes}`}
          type={props.type}
          value={props.value}
          isEditable={props.isEditable}
          onChange={e => {
            props.onChange(e.target.value);
          }}
          isCopyButtonEnabled={props.isCopyButtonEnabled}
        />
      );
    case 'number':
      return (
        <CustomInput
          id={props.id}
          className={props.classes}
          type={props.type}
          value={props.value}
          isEditable={props.isEditable}
          onChange={e => {
            const newValue = parseInt(e.target.value);
            // Prevent input to have NaN value
            props.onChange(isNaN(newValue) ? 0 : newValue);
          }}
          isCopyButtonEnabled={props.isCopyButtonEnabled}
        />
      );
    case 'radio':
      return (
        <CustomRadioButton
          id={props.id}
          className={props.classes}
          type={props.type}
          value={props.value}
          isEditable={props.isEditable}
          onPress={props.onChange}
          options={props.options}
          valueField={props.valueField}
          displayField={props.displayField}
        />
      );
    case 'datetime':
      return (
        <DateTimeInput label={props.label} dateTime={props.value} showTime={props.showTime} showDate={props.showDate} />
      );
    case 'range':
      return (
        <CustomRangeInput
          id={props.id}
          type={props.type}
          value={props.value}
          isEditable={props.isEditable}
          onChange={props.onChange}
          step={props.step}
          minValue={props.minValue}
          maxValue={props.maxValue}
          suffix={props.suffix}
          valType={props.valType}
        />
      );
    case 'checkbox':
      return (
        <CustomCheckBox
          label={props.label}
          disabled={!props.isEditable}
          selected={props.value === true ? 'all' : 'none'}
          onClick={e => props.onChange(!e)}
        />
      );
    case 'dropdown':
      return (
        <CustomDropdown
          items={props.options}
          selectedItem={props.value}
          displayField={props.displayField}
          valueField={props.valueField}
          disabled={!props.isEditable}
          handleSelection={e => props.onChange(e.target.value)}
        />
      );
    case 'chiptag':
      return (
        <ChipTags
          tags={props.value.map(tag => ({ value: tag }))}
          onChange={props.onChange}
          isEditable={props.isEditable}
        />
      );
    case 'models':
      return (
        <ModelBox
          renderOnSidebar={props.renderOnSidebar}
          models={props.value}
          onChange={props.onChange}
          isEditable={props.isEditable}
        />
      );
    case 'dropdown-search': //TODO:
      return <></>;
    case 'toggle': //TODO:
      return <></>;
    case 'date': //TODO:
      return <></>;
    default: //TODO:
      return <> </>;
  }
};

export default GeneralInput;
