import React, { useEffect, useState } from 'react';
import { sub, startOfDay, endOfDay, format } from 'date-fns';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { getSelectedProject } from '../../redux/projects/actions';

import CustomDateInput from '../customDateInput';
import calendarUtils from '../../utils/calendar';
import CalendarInfo from './calendarInfo';

import './styles.scss';

const SMALL_SCREEN_BREAKING_POINT = 1200;

const Calendar = props => {
  //states
  const [startDate, setStartDate] = useState(
    props.startDate ? new Date(props.startDate) : startOfDay(sub(new Date(), { weeks: 2 }))
  );
  const [endDate, setEndDate] = useState(props.endDate ? new Date(props.endDate) : new Date());
  const [startTime, setStartTime] = useState(props.startTime || '');
  const [endTime, setEndTime] = useState(props.endTime || '');
  const [weekDays, setWeekDays] = useState(props.weekDays || []);
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [isTimeEnabled, setTimeEnabled] = useState(props.endTime !== '');
  const [isWeekDaysEnabled, setWeekDaysEnabled] = useState(props.weekDays && props.weekDays.length > 0);
  const [errors, setErrors] = useState({
    'start-time-invalid': '',
    'end-time-invalid': '',
    'end-time-min-value': ''
  });
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Redux state
  const project = useSelector(getSelectedProject);

  //componentDidUpdate
  useEffect(() => {
    if (project && props.startDate && props.endDate) {
      setStartDate(new Date(props.startDate));
      setEndDate(new Date(props.endDate));
    }
  }, [project]);

  useEffect(() => {
    setWeekDays(props.weekDays);
    setWeekDaysEnabled(props.weekDays && props.weekDays.length !== 0);
  }, [props.weekDays]);

  useEffect(() => {
    setStartTime(props.startTime);
    setEndTime(props.endTime);
    setTimeEnabled(props.startTime !== '' && props.endTime !== '');
  }, [props.startTime, props.endTime]);

  useEffect(() => {
    //Handle change
    const resizePeriodSelector = () => {
      setScreenWidth(window.innerWidth);
    };
    //Subscribe event listener
    window.addEventListener('resize', resizePeriodSelector);

    //Unsubscribe event listener
    return () => window.removeEventListener('resize', resizePeriodSelector);
  });

  const applyChanges = () => {
    const start = startTime || calendarUtils.placeholders.startTime;
    const end = endTime || calendarUtils.placeholders.endTime;

    // Put data in the right UTC format: YYYY-MM-DDTHH:MM:SS-HH:MM
    const startDateStr = format(startOfDay(startDate), "yyyy-MM-dd'T'HH:mm:ssXXX");
    const endDateStr = format(endOfDay(endDate), "yyyy-MM-dd'T'HH:mm:ssXXX");

    const payload = {
      startDate: startDateStr,
      endDate: endDateStr
    };

    if (isTimeEnabled && start && end) {
      // Add seconds and prepare data to go to Query API
      payload['filterByTime'] = {
        start: `${start}:00`,
        end: `${end}:59`
      };
    }

    if (isWeekDaysEnabled && weekDays.length > 0) {
      payload['filterByWeekday'] = [...weekDays];
    }

    // Send data to parent components
    props.onSelect(payload);
    setCalendarOpen(false);
  };

  const onDatesChange = dates => {
    let [start, end] = dates;

    // For endDate get 23:59:59
    if (end) {
      end = endOfDay(end);
    }
    // Save changes
    setStartDate(start);
    setEndDate(end);
  };

  const customCalendarHeader = ({ monthDate, customHeaderCount, decreaseMonth, increaseMonth }) => (
    <div className="custom-calendar-header">
      <div
        className={'nav-arrow left'}
        style={customHeaderCount === 1 ? { visibility: 'hidden' } : null}
        onClick={decreaseMonth}
      >
        <i className={'uil uil-angle-left-b'}></i>
      </div>
      <span className="react-datepicker__current-month">
        {monthDate.toLocaleString('en-US', {
          month: 'long',
          year: 'numeric'
        })}
      </span>
      <div
        className={'nav-arrow right'}
        onClick={increaseMonth}
        style={customHeaderCount === 0 && screenWidth > SMALL_SCREEN_BREAKING_POINT ? { visibility: 'hidden' } : null}
      >
        <i className={'uil uil-angle-right-b'}></i>
      </div>
    </div>
  );

  return (
    <div className={`period-selector ${isCalendarOpen ? 'calendar-open' : ''}`}>
      <DatePicker
        selected={startDate}
        onChange={onDatesChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        monthsShown={screenWidth <= SMALL_SCREEN_BREAKING_POINT ? 1 : 2}
        customInput={
          <CustomDateInput
            startDate={startDate}
            endDate={endDate}
            handleChange={onDatesChange}
            setCalendarOpen={setCalendarOpen}
          />
        }
        renderCustomHeader={customCalendarHeader}
        shouldCloseOnSelect={false}
        open={isCalendarOpen}
        onClickOutside={() => {
          setCalendarOpen(false);
        }}
      >
        <CalendarInfo
          startTime={startTime}
          endTime={endTime}
          startDate={startDate}
          endDate={endDate}
          weekDays={weekDays}
          isWeekDaysEnabled={isWeekDaysEnabled}
          isTimeEnabled={isTimeEnabled}
          setCalendarOpen={setCalendarOpen}
          applyChanges={applyChanges}
          setStartTime={setStartTime}
          setEndTime={setEndTime}
          setTimeEnabled={setTimeEnabled}
          errors={errors}
          setErrors={setErrors}
          setWeekDays={setWeekDays}
          setWeekDaysEnabled={setWeekDaysEnabled}
          onDatesChange={onDatesChange}
        />
      </DatePicker>
    </div>
  );
};

export default Calendar;
