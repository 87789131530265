import React from 'react';
import CustomDropdown from '../customDropdown';
import calendar from '../../utils/calendar';

import './styles.scss';

const IntervalSelector = ({ intervalSelected, onIntervalSelected, disabled, outline, customIntervals }) => {
  // When generating a custom report interval list should include minutes
  // Look into customIntervals to verify this
  const intervals = customIntervals ? customIntervals : calendar.defaultIntervals;

  return (
    <CustomDropdown
      items={intervals}
      handleSelection={({ target }) => onIntervalSelected(target.value)}
      selectedItem={intervalSelected}
      valueField={'id'}
      disabled={disabled}
      outline={outline ? true : false}
      displayField={'name'}
    />
  );
};

export default IntervalSelector;
