import { call, put, takeLatest, select, delay } from 'redux-saga/effects';
import API from '../services/api';
import { normalizeProject, clearProjectsData } from '../utils/project';
import { discoverUserPermission } from '../utils/user/permissions';
import utils from '../utils';
import deviceUtils from '../utils/device';
import types from '../redux/types.js';

const generateErrorAlert = (msg, duration) => {
  return utils.generateAlert(msg, 'error', duration);
};

// Fetch Presets aka Solutions Config from API
function* fetchSolutionsConfig(projects) {
  const allSolutions = [];
  let solutionsConfig = {};

  for (const project of projects) {
    for (const solution of project.enabledSolutions) {
      if (!allSolutions.includes(solution)) {
        allSolutions.push(solution);
      }
    }
  }

  const solutionsConfigsResponse = yield Promise.all(
    allSolutions.map(solutionType => API.getSolutionsConfig(solutionType))
  );

  solutionsConfigsResponse.forEach((solutionConfigResponse, index) => {
    solutionsConfig[allSolutions[index]] = solutionConfigResponse.solutionConfigs;
  });

  return solutionsConfig;
}

// Return default Project from user preferences or first project found
function* getDefaultProject(projects) {
  if (!projects || projects.length === 0 ) {
    return null;
  }
  // Get user preference to load default project
  const user = yield select(state => state.dashboardReducer.user);
  const company = yield select(state => state.companyReducer.selectedCompany)
  let selectedProject = projects[0];

  // Updated selected device based on user preferences
  if (user.preferences && user.preferences.defaultProjectId) {
    // Get default project from API
    let defaultProject = selectedProject;
    try {
      defaultProject = yield call(API.getProject, company.id ,selectedProject.id);
      selectedProject = defaultProject ? defaultProject : selectedProject;
    } catch (e) {
      console.error('Could not fetch default project', e);
    }
  }

  return normalizeProject(selectedProject);
}

function* fetchProjects(company) {
  // Fetch project info from API
  let { projects } = company ? yield call(API.getProjectsByCompany, company.id) : yield call(API.getProjects);
  // Search through projects the one with more devices to select
  const normalizedProjects = projects.map(project => {
    return normalizeProject(project);
  });

  return normalizedProjects;
}

function* loadProjects() {
  try {
    yield call(fetchCompanies)
    const company = yield select(state => state.companyReducer.selectedCompany)
    const projects = yield call(fetchProjects, company);
    const selectedProject = yield call(getDefaultProject, projects);
    // Get solution Presets from API
    const solutionsConfig = yield call(fetchSolutionsConfig, projects);

    // Set Projects on Redux
    yield put({ type: types.LOAD_PROJECTS, payload: projects });
    // Set Selected Project on Redux
    yield put({ type: types.SELECT_PROJECT, payload: selectedProject });
    // Set Presets on Redux
    yield put({ type: types.LOAD_SOLUTIONS_CONFIG, payload: solutionsConfig });
  } catch (e) {
    console.error(e);
    yield put({
      type: types.SET_ALERT,
      payload: generateErrorAlert("Couldn't fetch Projects. Try to refresh this page.")
    });
  }
}

function* fetchLicenses() {
  const user = yield select(state => state.dashboardReducer.user);

  if (user && user.permissions && user.permissions.support) {
    // Fetch License only if user have privillege
    const licensesResponse = yield call(API.listLicenses);
    yield put({ type: types.LOAD_LICENSES, payload: licensesResponse.licenses });
  }
}

function* fetchTemplates() {
  const project = yield select(state => state.projectReducer.selectedProject);
  const company = yield select(state => state.companyReducer.selectedCompany)
  const user = yield select(state => state.dashboardReducer.user);

  // Get datasources from current SiteGroup
  const cid = project && project.companyId ? project.companyId : company.id;
  const pid = project && project.id ? project.id : user.preferences.defaultProjectId;

  // Check if there are any projects in current company
  const { projects } = yield call(API.getProjectsByCompany, cid)
  if(projects && projects.length > 0) {
    const datasourcesResponse = yield call(API.getDatasources, cid, pid);
    // Get filter from datasources
    const filterList = datasourcesResponse.datasources.map((datasource) => {
      return `'${datasource}' = ANY(compatibleDatasources)`;
    });
  
    let templatesResponse = {};
    // Get filter from datasources
    if (user.preferences && user.preferences.defaultSolutionId) {
      templatesResponse = yield call(API.getTemplates, '', `id = '${user.preferences.defaultSolutionId}'`);
    } else {
      templatesResponse = yield call(API.getTemplates, '', (datasourcesResponse.datasources.length > 0) ? filterList.join(' OR ') : '');
    }
  
    let defaultTemplate = templatesResponse.templates[0];
    yield put({ type: types.SELECT_DASHBOARD, payload: defaultTemplate });
    yield put({ type: types.SELECT_ANALYTICS_DASHBOARD, payload: defaultTemplate });
  } else {
    yield put({ type: types.SELECT_DASHBOARD, payload: {} });
    yield put({ type: types.SELECT_ANALYTICS_DASHBOARD, payload: {} });
  }
  

}

function* fetchDevices() {
  const project = yield select(state => state.projectReducer.selectedProject);

  // Stop FX chain if the user doesn't have projects
  if (!project) {
    yield call(finishLoading);
    yield put({ type: types.LOAD_SOLUTIONS, payload: [] });
    yield put({ type: types.SELECT_SOLUTION, payload: null });
    yield put({ type: types.LOAD_DEVICES, payload: [] });
    yield put({
      type: types.SET_ALERT,
      payload: generateErrorAlert("You don't have any sites or site groups. Please contact support@admobilize.com", -1)
    });
    return;
  }

  try {
    const devices = yield call(API.getDevices, project.companyId);

    // Get all possible Solutions/Products by reading all devices
    const { enabledIntegrations, enabledSolutions } = project;

    const sortedSolutions = utils.sortSolutions([
      ...enabledIntegrations.map(integration => integration.toLowerCase()),
      ...enabledSolutions.map(solution => solution.toLowerCase())
    ]);

    let selectedSolution = sortedSolutions.length ? sortedSolutions[0] : null;
    const user = yield select(state => state.dashboardReducer.user);

    if (user.preferences && user.preferences.defaultSolutionId) {
      const solutionFound = sortedSolutions.find(solution => solution.id === user.preferences.defaultSolutionId);
      if (solutionFound) {
        selectedSolution = solutionFound;
      }
    }

    // Remove last param to enable not-malos devices
    let deviceArray = yield call(deviceUtils.addInformation, devices);
    deviceArray = utils.sort(deviceArray, { field: 'displayName', type: 'string', isDescending: false });

    // Wait until devices value is ready and update value on App state
    yield put({ type: types.LOAD_DEVICES, payload: deviceArray });

    yield put({ type: types.LOAD_SOLUTIONS, payload: sortedSolutions });
    yield put({ type: types.SELECT_SOLUTION, payload: selectedSolution });
  } catch (e) {
    console.log(e);
    yield put({
      type: types.SET_ALERT,
      payload: generateErrorAlert("Couldn't fetch devices. Try to refresh this page.")
    });
  }
}

/**
 * Method used to change the device status on reducer
 * and after check the device status using the backend
 * this method is a palliative measure to get the device status when send a command.
 * @param { deviceId, newStatus } param0
 */
function* updateDeviceStatus({ payload }) {
  const currentDevices = yield select(state => state.deviceReducer.devices);
  try {
    const newDevices = currentDevices.map(device => {
      if (device.id === payload.deviceId) {
        device.status = payload.newStatus;
      }
      return { ...device };
    });
    yield put({ type: types.UPDATE_DEVICES, payload: newDevices });
    yield delay(10000);
  } catch (e) {
    yield put({
      type: types.SET_ALERT,
      payload: generateErrorAlert("Couldn't update status. Try to refresh this page.")
    });
  }
}

function* postDeviceUpdate(action) {
  try {
    const updatedDevice = action.payload.device;
    yield call(API.updateDevice,updatedDevice.companyId, updatedDevice, action.payload.fieldsPath);

    // TODO: Test response before updating store
    // console.log(response);

    const devices = yield select(state => state.deviceReducer.devices);
    const newDevices = devices.map(device => {
      if (device.id === updatedDevice.id) {
        const keys = Object.keys(updatedDevice);
        keys.forEach(key => {
          if (key !== 'status') {
            device[key] = updatedDevice[key];
          }
        });
        return device;
      }
      return device;
    });

    yield put({ type: types.UPDATE_DEVICES, payload: newDevices });
    yield put({
      type: types.SET_ALERT,
      payload: utils.generateAlert('Device updated successfully!', 'success')
    });
  } catch (e) {
    yield put({
      type: types.SET_ALERT,
      payload: generateErrorAlert("Couldn't update device. Try again or refresh this page.")
    });
  }
}

function* fetchTables() {
  const selectedProject = yield select(state => state.projectReducer.selectedProject);

  if (!selectedProject) return;

  const projectId = selectedProject.id;

  try {
    const tables = yield call(API.getTables, projectId);
    selectedProject.tables = tables.tables;
    yield put({ type: types.SET_PROJECT_TABLES, payload: selectedProject });
  } catch (e) {
    yield put({
      type: types.SET_ALERT,
      payload: generateErrorAlert("Couldn't load tables. Try again or refresh this page.")
    });
  }
}

function* finishLoading() {
  yield put({ type: types.SET_LOADING, payload: false });
}

function* updateUserDefaultProject({ payload: project }) {
  if (project) {
    try {
      const user = yield select(state => state.dashboardReducer.user);
      const userPermissions = yield call(discoverUserPermission, user, project);
      let updatedUser = { ...user, permissions: userPermissions };
      // Don't allow impersonator user to change default project of other user
      if (!user.impersonator) {
        const userPreferences = { ...user.preferences, defaultProjectId: project.id };
        updatedUser = { ...updatedUser, preferences: userPreferences };
        yield call(API.updateUser, user.id, { preferences: userPreferences });
      }
      yield put({ type: types.UPDATE_USER, payload: updatedUser });
    } catch (e) {
      console.error(e);
      // Clear project data from localStorage to prevent errors when changing env (dev/prod)
      clearProjectsData();
    }
  }
}

// Update user's default solution on Redux and DB
function* updateUserDefaultSolution({ payload: solution }) {
  // Get user and selectetProject from Redux
  const user = yield select(state => state.dashboardReducer.user);
  const selectedProject = yield select(state => state.projectReducer.selectedProject);

  // Don't allow impersonator user to change default solution of other user
  if (solution && !user.impersonator && user.preferences && solution.id !== user.preferences.defaultSolutionId) {
    // Update User preferences on Redux and AuthAPI
    const updatedUserPreferences = {
      ...user.preferences,
      defaultProjectId: selectedProject.id,
      defaultSolutionId: solution.id
    };
    yield put({ type: types.UPDATE_USER, payload: { ...user, preferences: updatedUserPreferences } });
    yield call(API.updateUser, user.id, { preferences: updatedUserPreferences });
  }
}

// Fetch solution Schema and normalize
function* fetchSolutionSchema ({ payload: solution }) {
  const selectedProject = yield select(state => state.projectReducer.selectedProject);

  if (selectedProject && solution) {
    const projectId = selectedProject.id;
    // VDetect doesn't have its own table, use VRecog instead
    const solutionId = solution.table;

    // Load selected solution database schema
    const selectedSolutionSpec = yield call(API.getTableSchema, projectId, solutionId);

    // Columns to be removed from custom report creation requests
    const columnsToRemove = [
      'deviceRegistry',
      'cameraId',
      'insertId',
      'confidenceAge',
      'confidenceEmotion',
      'confidenceGender'
    ];

    // Remove vehicleType column for VDetection solution
    if (solution.id === 'vehicledetectionv1') {
      columnsToRemove.push('vehicleType');
    }

    // Prevent errors when API doesn't find solution table for current project
    if (selectedSolutionSpec.schema) {
      // Remove columns that souldn't be listed form the database schema
      solution.schema = selectedSolutionSpec.schema
        .map(column => column.name)
        .filter(column => columnsToRemove.every(columnToRemove => column !== columnToRemove));

      // Add attentionTime column for face based solutions
      if (solution.id === 'facev2' || solution.cms) {
        solution.schema.push('attentionTime');
      }
    } else {
      solution.schema = [];
    }

    // Update solution on Redux adding schema to it
    yield put({ type: types.UPDATE_SELECT_SOLUTION, payload: { ...solution } });
  }
}

function* dismissAlert({ payload: alert }) {
  // Default alerts don't set duration
  // If an alert should stay on screen until closed, set duration to -1.
  if (!alert.duration) {
    yield delay(5000); // Default alert duration
    yield put({ type: types.DISMISS_ALERT });
  } else if (alert.duration > 0) {
    yield delay(alert.duration);
    yield put({ type: types.DISMISS_ALERT });
  }
}

function* fetchFeatureFlags() {
  try {
    const featureFlags = yield call(API.getFeatureFlags);
    if (featureFlags) {
      yield put({ type: types.SET_FEATURE_FLAGS, payload: featureFlags });
    }
  } catch (error) {
    console.error("Couldn't fetch feature flags");
  }
}

function* fetchCompanies() {
  try {
    const companies = yield call(API.getCompanies);
    if (companies) {
      yield put({ type: types.LOAD_COMPANIES, payload: companies.companies });
      yield put({ type: types.SELECT_COMPANY, payload: companies.companies[0] })
    }
  } catch (error) {
    console.error("Couldn't fetch companies");
  }
}

function* dashboardSideFX() {
  yield takeLatest(types.SET_USER, fetchFeatureFlags);
  yield takeLatest(types.SET_USER, loadProjects);
  yield takeLatest(types.SELECT_PROJECT, updateUserDefaultProject);
  yield takeLatest(types.SELECT_PROJECT, fetchDevices);
  yield takeLatest(types.SELECT_PROJECT, fetchTemplates);
  yield takeLatest(types.UPDATE_USER, fetchLicenses);
  yield takeLatest(types.SELECT_SOLUTION, updateUserDefaultSolution);
  yield takeLatest(types.SELECT_SOLUTION, fetchSolutionSchema);
  yield takeLatest(types.LOAD_SOLUTIONS, fetchTables);
  yield takeLatest(types.UPDATE_DEVICE_STATUS, updateDeviceStatus);
  yield takeLatest(types.POST_DEVICE_UPDATE, postDeviceUpdate);
  // Remove general loading only after getting tables and update selected project
  yield takeLatest(types.SET_PROJECT_TABLES, finishLoading);
  yield takeLatest(types.SET_ALERT, dismissAlert);
}

export default dashboardSideFX;
