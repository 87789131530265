import React, { useEffect, useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import ClipboardJS from 'clipboard';

import icons from '../../assets/icons';
import './styles.scss';

const CopyButton = ({ id, tooltip }) => {
  const isMounted = useRef(null);
  useEffect(() => {
    if (!isMounted.current) {
      const clipboard = new ClipboardJS(`#copy-button-${id}`);
      clipboard.on('success', e => {
        e.clearSelection();
      });
    }
    isMounted.current = true;
  });

  return (
    <div>
      <button type="button" id={`copy-button-${id}`} className="btn icon-button copy" data-clipboard-target={`#${id}`}>
        <img id={`copy-img-${id}`} src={icons.copy} alt="copy icon" />
      </button>
      <UncontrolledTooltip placement="top" target={`copy-button-${id}`} trigger="focus">
        Copied!
      </UncontrolledTooltip>
      {tooltip && (
        <UncontrolledTooltip placement="top" target={`copy-img-${id}`}>
          {tooltip}
        </UncontrolledTooltip>
      )}
    </div>
  );
};

export default CopyButton;
