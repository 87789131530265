import config from '../config';
import API from './api';

const { baseUrl } = config.cmsApi;

const listIntegrations = () => {
  const url = `${baseUrl}/integrations`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};

const listCredentials = (cmsClient, project) => {
  const url = `${baseUrl}/${cmsClient}/projects/${project}/credentials`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};

const checkCredentials = (cmsClient, credentials, credentialFieldName) => {
  const url = `${baseUrl}/${cmsClient}/credentials/-/check`;
  const body = { cms_client: cmsClient, credential: {
    [credentialFieldName]: credentials
  }};

  return fetch(url, {
    headers: API.headers,
    body: JSON.stringify(body),
    method: 'POST'
  }).then(res => API.generateResponse(res));
};

const deleteCredential = (cmsClient, project, credentialId) => {
  const url = `${baseUrl}/${cmsClient}/projects/${project}/credentials/${credentialId}`;
  return fetch(url, {
    headers: API.headers,
    method: 'DELETE'
  }).then(res => API.generateResponse(res));
};

const getMappings = (cmsClient, project, credentialId) => {
  const url = `${baseUrl}/${cmsClient}/projects/${project}/credentials/${credentialId}/mappings`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};

const createCredential = (cmsClient, credential, project) => {
  const body = { ...credential };
  const url = `${baseUrl}/${cmsClient}/projects/${project}/credentials`;
  return fetch(url, {
    headers: API.headers,
    method: 'POST',
    body: JSON.stringify(body)
  }).then(res => API.generateResponse(res));
};

const createMapping = (cmsClient, mapping, credential, project) => {
  const body = { ...mapping };
  const url = `${baseUrl}/${cmsClient}/projects/${project}/credentials/${credential}/mappings`;
  return fetch(url, {
    headers: API.headers,
    method: 'POST',
    body: JSON.stringify(body)
  }).then(res => API.generateResponse(res));
};

const updateCredential = (cmsClient, credential, project, credentialFieldName) => {
  const body = { ...credential };
  const url = `${baseUrl}/${cmsClient}/projects/${project}/credentials/${credential.id}?update_mask=${credentialFieldName}`;
  return fetch(url, {
    headers: API.headers,
    method: 'PATCH',
    body: JSON.stringify(body)
  }).then(res => API.generateResponse(res));
};

const updateMapping = (cmsClient, mapping) => {
  const body = { ...mapping };
  const url = `${baseUrl}/${cmsClient}/${mapping.name}?update_mask=metadata`;
  return fetch(url, {
    headers: API.headers,
    method: 'PATCH',
    body: JSON.stringify(body)
  }).then(res => API.generateResponse(res));
};

const deleteMapping = (cmsClient, mapping) => {
  const url = `${baseUrl}/${cmsClient}/${mapping.name}`;
  return fetch(url, {
    headers: API.headers,
    method: 'DELETE',
  }).then(res => API.generateResponse(res));
};

export default {
  listIntegrations,
  listCredentials,
  checkCredentials,
  getMappings,
  createCredential,
  createMapping,
  updateCredential,
  updateMapping,
  deleteCredential,
  deleteMapping
};