const types = {
  // Dashboard types
  SET_LOADING: 'SET_LOADING',
  SET_USER: 'SET_USER',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_PREFERENCES: 'UPDATE_USER_PREFERENCES',
  SET_ALERT: 'SET_ALERT',
  DISMISS_ALERT: 'DISMISS_ALERT',
  CLEAR_USER: 'CLEAR_USER',
  SET_FEATURE_FLAGS: 'SET_FEATURE_FLAGS',

  // Project types
  LOAD_PROJECTS: 'LOAD_PROJECTS',
  CREATE_PROJECT: 'CREATE_PROJECT',
  SELECT_PROJECT: 'SELECT_PROJECT',
  UPDATE_SELECTED_PROJECT: 'UPDATE_SELECTED_PROJECT',
  UPDATE_PROJECTS: 'UPDATE_PROJECTS',
  SET_PROJECT_TABLES: 'SET_PROJECT_TABLES',
  UPDATE_DEVICES: 'UPDATE_DEVICES',
  CLEAR_PROJECT: 'CLEAR_PROJECT',

  // Device types
  CREATE_DEVICE: 'CREATE_DEVICE',
  SELECT_DEVICE: 'SELECT_DEVICE',
  LOAD_DEVICES: 'LOAD_DEVICES',
  POST_DEVICE_UPDATE: 'POST_DEVICE_UPDATE',
  UPDATE_DEVICE: 'UPDATE_DEVICE',
  UPDATE_DEVICE_STATUS: 'UPDATE_DEVICE_STATUS',
  LOAD_LICENSES: 'LOAD_LICENSES',
  LOAD_ALL_DEVICES: 'LOAD_ALL_DEVICES',

  // Solution types
  LOAD_SOLUTIONS: 'LOAD_SOLUTIONS',
  SELECT_SOLUTION: 'SELECT_SOLUTION',
  UPDATE_SELECT_SOLUTION: 'UPDATE_SELECT_SOLUTION',
  LOAD_SOLUTIONS_CONFIG: 'LOAD_SOLUTIONS_CONFIG', // presets
  UPDATE_SOLUTION_CONFIG: 'UPDATE_SOLUTION_CONFIG', // presets
  CREATE_SOLUTION_CONFIG: 'CREATE_SOLUTION_CONFIG', // presets

  //region DASHBOARD
  SELECT_DASHBOARD: 'SELECT_DASHBOARD',
  SELECT_ANALYTICS_DASHBOARD: 'SELECT_ANALYTICS_DASHBOARD',

  // Login
  LOGIN: 'LOGIN',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',

  // Notifications
  LOAD_NOTIFICATIONS: 'LOAD_NOTIFICATIONS',
  LOAD_NOTIFICATION_TYPES: 'LOAD_NOTIFICATION_TYPES',
  LOAD_NOTIFICATION_PREFERENCES: 'LOAD_NOTIFICATION_PREFERENCES',

  //region Sites
  CREATE_SITE: 'CREATE_SITE',
  SELECT_SITE: 'SELECT_SITE',
  LOAD_SITES: 'LOAD_SITES',
  LOAD_SITE_TYPES: 'LOAD_SITE_TYPES',

  //#region SiteGroups
  CREATE_SITE_GROUP: 'CREATE_SITE_GROUP',
  SELECT_SITE_GROUP: 'SELECT_SITE_GROUP',
  SELECT_SITE_GROUP_DASHBOARD: 'SELECT_SITE_GROUP_DASHBOARD',
  LOAD_SITE_GROUPS: 'LOAD_SITE_GROUPS',
  LOAD_DASHBOARDS: 'LOAD_DASHBOARDS',
  //#endregion
  
  //#region Company
  LOAD_COMPANIES: 'LOAD_COMPANIES',
  SELECT_COMPANY: 'SELECT_COMPANY',
  //#endregion
};

export default types;
