import config from '../config';
import API from './api';

// Used on recurring reports
// TODO: refactor this function and the next one. Both are pretty much the same
const createReport = async (headers, refreshTokenWhenNeeded, projectId, table, data, generateResponse) => {
  await refreshTokenWhenNeeded();
  const url = `${config.queryApi.baseUrl}/projects/${projectId}/tables/${table}/reports`;
  return fetch(url, {
    method: 'POST',
    body: data,
    headers: headers
  }).then(res => generateResponse(res));
}

const generateReport = async (headers, refreshTokenWhenNeeded, projectId, table, data, generateResponse) => {
  await refreshTokenWhenNeeded();
  const url = `${config.queryApi.baseUrl}/projects/${projectId}/tables/${table}/report`;
  return fetch(url, {
    method: 'POST',
    body: data,
    headers: headers
  }).then(res => generateResponse(res));
}

const getCMSData = async (headers, refreshTokenWhenNeeded, projectId, table, data) => {
  await refreshTokenWhenNeeded();
  const url = `${config.queryApi.baseUrl}/projects/${projectId}/tables/${table}/query`;
  return fetch(url, {
    method: 'POST',
    body: data,
    headers: headers
  }).then(res => res.json());
}

const getReports = async (headers, refreshTokenWhenNeeded, projectId) => {
  await refreshTokenWhenNeeded();
  const url = `${config.queryApi.baseUrl}/projects/${projectId}/reports`;
  return fetch(url, {
    headers: headers
  }).then(res => res.json());
};

const getTables = async (headers, refreshTokenWhenNeeded, projectId) => {
  await refreshTokenWhenNeeded();
  const url = `${config.queryApi.baseUrl}/projects/${projectId}/tables`;
  return fetch(url, {
    headers: headers
  }).then(res => res.json());
};

const getDashboard = async (dashboardName) => {
  await API.refreshTokenWhenNeeded();
  const url = `${config.queryApi.baseUrl}/dashboards/${dashboardName}`;
  return fetch(url, {
    headers: API.headers
  }).then(res => res.json());
};

const getHeatmapData = async (device, startTime, endTime, filters) => {
  const projectId = device.projectId;
  const table = device.solution.toLowerCase();

  const filterStr = filters.map(filter => {
    if (filter.operator && filter.operator !== '') {
      return `(${filter.path}${filter.operator}${filter.value})`
    }

    return `(${filter.path}="${filter.value}")`
  }).join(' AND ');

  await API.refreshTokenWhenNeeded();
  const url = `${config.queryApi.baseUrl}/projects/${projectId}/tables/${table}/devices/${device.id}/heatmap?startTime=${startTime}&endTime=${endTime}&filter="${filterStr}"`;
  return fetch(url, {
    headers: API.headers
  }).then(res => res.json());
};

const getTableSchema = async (headers, refreshTokenWhenNeeded, projectId, table) => {
  await refreshTokenWhenNeeded();
  const url = `${config.queryApi.baseUrl}/projects/${projectId}/tables/${table}`;
  return fetch(url, {
    headers: headers
  }).then(res => res.json());
}

const getTableData = async (headers, refreshTokenWhenNeeded, projectId, table, data) => {
  await refreshTokenWhenNeeded();
  const url = `${config.queryApi.baseUrl}/projects/${projectId}/tables/${table}/data`;
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: headers
  }).then(res => res.json());
}

const cancelReport = async (headers, refreshTokenWhenNeeded, projectId, table, report) => {
  await refreshTokenWhenNeeded();
  const url = `${config.queryApi.baseUrl}/projects/${projectId}/tables/${table}/reports/${report}`;
  return fetch(url, {
    method: 'DELETE',
    headers: headers
  }).then(res => res.json());
}

const pauseReport = async (headers, refreshTokenWhenNeeded, projectId, table, report) => {
  await refreshTokenWhenNeeded();
  const url = `${config.queryApi.baseUrl}/projects/${projectId}/tables/${table}/reports/${report}:pause`;
  return fetch(url, {
    method: 'POST',
    headers: headers
  }).then(res => res.json());
}

const updateReport = async (headers, refreshTokenWhenNeeded, projectId, report, data) => {
  await refreshTokenWhenNeeded();
  const url = `${config.queryApi.baseUrl}/projects/${projectId}/reports/${report}`;
  return fetch(url, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: headers
  }).then(res => res.json());
}

const restartReport = async (headers, refreshTokenWhenNeeded, projectId, table, report) => {
  await refreshTokenWhenNeeded();
  const url = `${config.queryApi.baseUrl}/projects/${projectId}/tables/${table}/reports/${report}:restart`;
  return fetch(url, {
    method: 'POST',
    headers: headers
  }).then(res => res.json());
}

export default {
  createReport,
  generateReport,
  getCMSData,
  getReports,
  getDashboard,
  getTables,
  getHeatmapData,
  getTableSchema,
  getTableData,
  cancelReport,
  pauseReport,
  updateReport,
  restartReport
};