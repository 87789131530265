import React from 'react';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../redux/dashboard/actions';
import IconButton from '../iconButton';
import API from '../../services/api';
import utils from '../../utils';


const ReloadButton = ({ onPress, lastUpdatedCB, contentName }) => {
  const dispatch = useDispatch();

  const defautlOnPress = () => {
    API.refreshTokenWhenNeeded()
      .then(() => {
        lastUpdatedCB(new Date().toISOString());
        dispatch(
          setAlert(utils.generateAlert(
            contentName ? `${contentName} was updated` : 'Content is updated.',
            'success'
            ))
        );
      })
      .catch(err => {
        console.error(err);
        dispatch(
          setAlert(utils.generateAlert('Error while refreshing content. Please try again.', 'error'))
        );
      });
  }

  return (
    <IconButton
      id="reload-icon"
      imgClasses="reload-icon"
      icon="reloadDarkOutline"
      onPress={onPress || defautlOnPress}
      tooltip="Refresh Page" />
  );
}

export default ReloadButton;