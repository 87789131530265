import React from 'react';
import CustomCheckBox from '../customCheckBox';
import CustomButton from '../customButton';

import './styles.scss';

export default function Thead({
  headerGroups,
  hasBulkActionButtons,
  hasActionButtons,

  selectedRowsCount,
  dataCount,

  selectAll,
  bulkActionButtons,
  navigation
}) {
  const shouldDisplayBulkActions = hasBulkActionButtons && hasActionButtons && selectedRowsCount > 0;
  const hasNavigation = !!navigation;

  // Get columnCount from headerGroups and set 3 columns as minimum count to render navigation info
  const columnCount = headerGroups[0] ? headerGroups[0].headers.length : 3;
  const renderSort = column => {
    if (column.canSort) {
      if (column.isSorted) {
        return column.isSortedDesc ? <i className="uil uil-angle-up" /> : <i className="uil uil-angle-down" />;
      }
      return <i className="uil uil-sort" />;
    }
    return null;
  };
  return (
    <thead className={shouldDisplayBulkActions ? 'has-bulk-actions' : ''}>
      {hasNavigation && (
        <tr>
          <th className="navigation-wrapper" colSpan={columnCount}>
            <div className="navigation-container">
              <CustomButton
                title="Back"
                icon="angleLeft"
                handleClick={navigation.goBackButton}
                classes="btn-secondary btn-slim"
              />
              <h2>{navigation.title}</h2>
            </div>
          </th>
        </tr>
      )}
      {headerGroups.map((headerGroup, headerIndex) => (
        <tr key={`tr-${headerIndex}`} {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column, indexCol) => (
            <th key={`th-${indexCol}`}>
              <div className="cell-wrapper">
                {hasBulkActionButtons && indexCol === 0 && (
                  <CustomCheckBox
                    multiple
                    selectedItems={selectedRowsCount}
                    totalItems={dataCount}
                    onClick={selectAll}
                  />
                )}
                <div
                  {...column.getHeaderProps(() => {
                    const sortProps = column.getSortByToggleProps();
                    return column.hideTitle ? { ...sortProps, title: undefined } : sortProps;
                  })}
                >
                  <span>{renderSort(column)}</span>
                  <span>{column.render('Header')}</span>
                </div>
              </div>
            </th>
          ))}
          {shouldDisplayBulkActions ? (
            <th className="bulk-actions">{bulkActionButtons()}</th>
          ) : (
            <th className="empty-column"></th>
          )}
        </tr>
      ))}
    </thead>
  );
}
