import types from '../types.js';


const initialState = {
  selectedDevice: null,
  devices: [],
  allDevices: [],
  licenses: []
};

const deviceReducer = (state = initialState, action) => {
  const { CREATE_DEVICE, SELECT_DEVICE, LOAD_DEVICES, UPDATE_DEVICES, LOAD_LICENSES, LOAD_ALL_DEVICES } = types;
  switch (action.type) {
    case LOAD_DEVICES:
    return {...state, ...{
        devices: [...action.payload]
      }};
    case LOAD_ALL_DEVICES:
    return {...state, ...{
        allDevices: [...action.payload]
      }};
    case UPDATE_DEVICES:
      return {...state, ...{
        devices: action.payload
      }};
    case CREATE_DEVICE:
      return {...state, ...{
        devices: [...state.devices, action.payload ]
      }};
    case SELECT_DEVICE:
      return {...state, ...{
        selectedDevice: action.payload
      }};
    case LOAD_LICENSES:
      return {...state, ...{
        licenses: [...action.payload]
        }};

    default:
      return state;
  }
}

export default deviceReducer;
