import React from 'react';

import containers from '../../containers';
import components from '../../components';
import TimezoneInfo from '../../components/timezoneInfo';

import './styles.scss';

const { AdvancedContainer } = containers;
const { Header } = components;

const Advanced = () => (
  <div className="advanced-screen">
    <Header
      title={'Advanced\xa0Settings'}
      icon={'advancedSettingsDark'}
      actions={[
        { type: "reactComponent", value: <TimezoneInfo key="timezone-info" /> },
      ]} />
    <AdvancedContainer />
  </div>
);

export default Advanced;
