import React from 'react';

import Selector from '../selector';
import CustomCheckBox from '../customCheckBox';
import CustomInput from '../customInput';

import calendarUtils from '../../utils/calendar';
import utils from '../../utils';

import './styles.scss';

const CalendarFilters = ({
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  isTimeEnabled,
  setTimeEnabled,
  errors,
  setErrors,
  weekDays,
  setWeekDays,
  isWeekDaysEnabled,
  setWeekDaysEnabled
}) => {
  return (
    <div className="calendar-filters">
      <div className="time-filter">
        <CustomCheckBox
          label="Between"
          selected={(startTime && endTime) || isTimeEnabled ? 'all' : 'none'}
          onClick={value => {
            setTimeEnabled(!value);
            setStartTime(!value ? startTime : '');
            setEndTime(!value ? endTime : '');
          }}
        />
        <div className="time-range-filter form-group">
          <CustomInput
            className="form-control"
            type="text"
            value={startTime}
            mask={calendarUtils.getInputMask(startTime)}
            maskPlaceholder={calendarUtils.placeholders.startTime}
            onChange={e => {
              const data = e.target.value;
              const mask = calendarUtils.getInputMask(data);
              const regex = calendarUtils.transformMaskToRegex(mask);
              setStartTime(data);
              setErrors({
                ...errors,
                'start-time-invalid': regex.test(data) ? '' : 'Invalid value',
                'start-time-max-value': utils
                  .fieldValidation(data, [
                    {
                      key: 'maxTime',
                      value: endTime ? endTime : calendarUtils.placeholders.endTime
                    }
                  ])
                  .join(', ')
              });
            }}
            errorMessage={errors['start-time-invalid'] || errors['start-time-max-value']}
            isEditable={isTimeEnabled}
            showErrorMessage={false}
            alwaysShowMask={true}
          />
          <span className={`${isTimeEnabled ? 'active' : ''}`}>-</span>
          <CustomInput
            className="form-control"
            type="text"
            value={endTime}
            mask={calendarUtils.getInputMask(endTime)}
            maskPlaceholder={calendarUtils.placeholders.endTime}
            onChange={e => {
              const data = e.target.value;
              const mask = calendarUtils.getInputMask(data);
              const regex = calendarUtils.transformMaskToRegex(mask);
              setEndTime(data);
              setErrors({
                ...errors,
                'end-time-invalid': regex.test(data) ? '' : 'Invalid value',
                'end-time-min-value': utils
                  .fieldValidation(data, [
                    {
                      key: 'minTime',
                      value: startTime ? startTime : calendarUtils.placeholders.startTime
                    }
                  ])
                  .join(', ')
              });
            }}
            errorMessage={errors['end-time-invalid'] || errors['end-time-min-value']}
            isEditable={isTimeEnabled}
            showErrorMessage={false}
            alwaysShowMask={true}
          />
        </div>
      </div>
      <div className="day-of-week-filter">
        <CustomCheckBox
          label="Only"
          selected={weekDays.length || isWeekDaysEnabled ? 'all' : 'none'}
          onClick={value => {
            setWeekDaysEnabled(!value);
            setWeekDays(!value ? weekDays : []);
          }}
        />
        <Selector
          items={calendarUtils.weekDaysOptions}
          fields={{ id: 'id', name: 'id', tooltip: 'name' }}
          preSelected={weekDays}
          onSelect={({ selectedItems }) => {
            const chosenDays = calendarUtils.getChosenDays(selectedItems);
            return setWeekDays(chosenDays);
          }}
          disabled={!isWeekDaysEnabled}
          multiple
        />
      </div>
    </div>
  );
};

export default CalendarFilters;
