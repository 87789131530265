import React from 'react';
import { useSelector } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import icons from '../../assets/icons';
import { getProjects } from '../../redux/projects/actions';
import deviceUtils from '../../utils/device';


const DeviceItem = ({ device, selectedDevice, style, onSelect }) => {
  const projects = useSelector(getProjects);

  const renderVersion = (state) => {
    let malosVersion = '-';
    let admProviderVersion = '-';

    if (state) {
      if (state.malosVersion) {
        malosVersion = state.malosVersion;
      }
      if (state.admproviderVersion) {
        admProviderVersion = state.admproviderVersion;
      }
    }

    return (
      <>
        <div className="version">
          Malos Version: {malosVersion}
        </div>
        <div className="version">
          AdProv Version: {admProviderVersion}
        </div>
      </>
    );
  };

  const renderProjectName = (projectId) => {
    const deviceProject = projects.find(project => project.id === projectId);
    const displayName = deviceProject ? deviceProject.displayName : projectId;

    return (
      <div className="project">
        <img src={icons.projectArrow} alt="project" />
        {displayName}
      </div>
    )
  };

  const renderPlatform = (device) => {
    return <img className="platform" src={icons[deviceUtils.getPlatform(device)]} alt="platform" />
  };

  if (!device) {
    return null;
  }

  return (
    <div
      className={`device-item ${deviceUtils.getStatus(device).toLowerCase()} ${selectedDevice.id === device.id ? 'selected' : ''}`}
      style={style}
      onClick={() => onSelect(device)}
      >
        <div id={`device-display-name-${device.id}`} className="display-name">
          {renderPlatform(device)}
          {device.displayName}
          <UncontrolledTooltip placement="left" target={`device-display-name-${device.id}`}>
            {device.displayName}
          </UncontrolledTooltip>
        </div>
        <div className="extra-data">
          {renderProjectName(device.projectId)}
          {renderVersion(device.state)}
        </div>
    </div>
  );
};

export default DeviceItem;