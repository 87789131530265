import React, { useState } from 'react';
import Header from '../../components/header';
import NotificationTools from '../../components/notificationTools';
import TimezoneInfo from '../../components/timezoneInfo';
import ReloadButton from '../../components/reloadButton';
import SiteGroupsContainer from '../../containers/sitegroups';

const SiteGroupsScreen = () => {
  const [lastUpdated, setLastUpdated] = useState('');

  return (
    <div className="d-flex flex-column flex-fill">
      <Header
        title={'Management'}
        icon={'settingsDark'}
        actions={[
          { type: 'reactComponent', value: <TimezoneInfo key="timezone-info" /> },
          {
            type: 'reactComponent',
            value: <ReloadButton key="reload-btn" contentName="Site Groups list" lastUpdatedCB={setLastUpdated} />
          },
          { type: 'reactComponent', value: <NotificationTools key="notification-btn" /> }
        ]}
      />
      <SiteGroupsContainer lastUpdated={lastUpdated} />
    </div>
  );
};

export default SiteGroupsScreen;
