import React from 'react';

import './styles.scss';

const CustomTextArea = (props) => {
  const {
    id,
    className,
    value,
    placeholder,
    onChange,
    autoFocus,
    isEditable,
    tipMessage,
    errorMessage,
    rows,
    cols
  } = props;

  const hasTip = () => {
    if (tipMessage && tipMessage.length > 0) {
      return true;
    }
    return false;
  }

  const hasError = () => {
    if (errorMessage && errorMessage.length > 0) {
      return true;
    }
    return false;
  }

  return (
    <div className="d-flex flex-wrap">
      <div className={`textarea-wrapper ${hasError() ? 'is-invalid' : ''}`}>
        <textarea
          id={id}
          className={`form-control ${className}`}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          autoFocus={autoFocus}
          rows={rows}
          cols={cols}
          disabled={!isEditable} />
      </div>
      { hasTip() && !hasError() && <small className="text-muted">{ tipMessage }</small> }
      { hasError() && <span className="invalid-feedback">{ errorMessage }</span> }
    </div>
  );
}

export default CustomTextArea;