import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import dashboard from './reducers';
import sideFX from '../sagas';

const sagaMiddleware = createSagaMiddleware();
const middleware = process.env.REACT_APP_STAGE === 'prod'
  ? applyMiddleware(sagaMiddleware)
  : applyMiddleware(sagaMiddleware, logger);

const store = createStore(
  dashboard,
  middleware
);

sagaMiddleware.run(sideFX);

export default store;