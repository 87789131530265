import React, { useEffect, useState } from 'react';
import CustomInput from '../customInput';
import Accordion from '../accordion';
import IconButton from '../iconButton';
import CustomDropdown from '../customDropdown';
import modelUtils  from "../../utils/models";

import './styles.scss'
import utils from '../../utils';
import CustomButton from '../customButton';
import AddModel from './addModel';

const ModelBox = ({ models, isEditable, onChange, renderOnSidebar }) => {

  const [currentModels, setCurrentModels] = useState(utils.deepClone(models));
  const [addModelModal, setAddModelModal] = useState(false);

  useEffect( () => {
    // Updating currentModels should not trigger onChange if it is due to model updates
    // This implies the original source has change, so no sidefx should take place.
    setCurrentModels(utils.deepClone(models));
  },[models]);

  const handleChange = (modelIndex, path, value) => {
    const newModel = utils.deepClone(currentModels[modelIndex]);
    utils.set(newModel, path, value);
    currentModels[modelIndex] = newModel;
    setCurrentModels([...currentModels]);
    // Send up new list of models
    onChange(currentModels);
  };

  const addModel = (newModal) => {
    currentModels.push(newModal);
    setCurrentModels([...currentModels]);
    setAddModelModal(false);
    // Send up new list of models
    onChange(currentModels);
  };

  const removeModel = (index) => {
    currentModels.splice(index, 1);
    setCurrentModels([...currentModels]);
    // Send up new list of models
    onChange(currentModels);
  };

  const renderHeader = (toggle, index, item, openedItem) => {
    return (
      <div className="header-wrapper">
        <div className="item-header" onClick={toggle} data-event={index}>
          <span data-event={index}>{item.header}</span>
          <i className={`uil uil-angle-${openedItem === index ? 'down': 'right'}`} data-event={index}></i>
        </div>
        <IconButton icon={'del'} disabled={!isEditable} onPress={() => removeModel(index)}/>
      </div>
    );
  };

  const renderBody = (model, modelIndex) => {
    return (
      <div className="container">
        <div className='row'>
          <div className={`form-group ${renderOnSidebar ? 'col-12' : 'col-6'}`}>
            <label>Version</label>
            <CustomInput
              id={'model-version'}
              type={'text'}
              value={model.version}
              isEditable={isEditable}
              onChange={e => handleChange(modelIndex, 'version', e.target.value)}/>
          </div>
          <div className={`form-group ${renderOnSidebar ? 'col-12' : 'col-6'}`}>
            <label>Async Queue Size</label>
            <CustomInput
              id={'model-async'}
              type={'number'}
              value={model.config ? model.config.async_queue_size : '--'}
              isEditable={isEditable}
              onChange={e => handleChange(modelIndex, 'config.async_queue_size', parseInt(e.target.value))}/>
          </div>
        </div>
        <div className='row'>
          <div className={`form-group ${renderOnSidebar ? 'col-12' : 'col-6'}`}>
            <label>Input Size Width</label>
            <CustomInput
              id={'model-input-size-width'}
              type={'text'}
              value={model.config && model.config.input_size ? model.config.input_size.width : '--'}
              isEditable={isEditable}
              onChange={e => handleChange(modelIndex, 'config.input_size.width', e.target.value)}/>
          </div>
          <div className={`form-group ${renderOnSidebar ? 'col-12' : 'col-6'}`}>
            <label>Input Size Height</label>
            <CustomInput
              id={'model-input-size-height'}
              type={'text'}
              value={model.config && model.config.input_size ? model.config.input_size.height : '--'}
              isEditable={isEditable}
              onChange={e => handleChange(modelIndex, 'config.input_size.height', e.target.value)}/>
          </div>
        </div>
        <div className='row'>
          <div className={`form-group ${renderOnSidebar ? 'col-12' : 'col-6'}`}>
            <label>Backend</label>
              <CustomDropdown
              items={modelUtils.backendMapping}
              selectedItem={model.config && model.config.backend
                ? modelUtils.getObjectFromValue(model.config.backend, modelUtils.backendMapping)
                : modelUtils.backendMapping[0]
              }
              displayField={"label"}
              valueField={"value"}
              handleSelection={e => handleChange(modelIndex, 'config.backend', parseInt(e.target.value))}
              disabled={!isEditable}
              />
          </div>
          <div className={`form-group ${renderOnSidebar ? 'col-12' : 'col-6'}`}>
            <label>Target</label>
              <CustomDropdown
              items={modelUtils.targetMapping}
              selectedItem={model.config && model.config.target
                ? modelUtils.getObjectFromValue(model.config.target, modelUtils.targetMapping)
                : modelUtils.targetMapping[0]
              }
                displayField={"label"}
                valueField={"value"}
                handleSelection={e => handleChange(modelIndex, 'config.target', parseInt(e.target.value))}
                disabled={!isEditable}
              />
          </div>
        </div>
      </div>

    )
  };

  return (
    <div className='model-box'>
      {
      models &&
      <Accordion
        customHeader={renderHeader}
        items={currentModels.map((model, modelIndex) => {
          return {
            header: `${model.type} (${model.version})`,
            body: renderBody(model, modelIndex)
          }
        })}/>
      }
      <CustomButton
        title="Add Model"
        handleClick={() => setAddModelModal(true)}
        disabled={!isEditable}
        classes="btn-secondary add-model-btn" />
      <AddModel
        isOpenModal={addModelModal}
        onAddModel={addModel}
        onClose={() => setAddModelModal(false)} />
    </div>
  );
};

export default ModelBox;