import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAlert } from '../../redux/dashboard/actions';
import { getDevices } from '../../redux/devices/actions';
import icons from '../../assets/icons';
import components from '../../components';
import api from '../../services/api';
import utils from '../../utils';

const { CustomInput, IconButton, DropdownSearch, CustomButton } = components;

const Mapping = ({ mapping, cmsName, selectedIntegration, loadCredentials }) => {
  const [active, setActive] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState({});
  const [values, setValues] = useState(mapping.metadata);

  const devices = useSelector(getDevices);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedDevice(devices.find(device => device.id === mapping.deviceId));
  }, [devices]);

  useEffect(() => {
    setValues(mapping.metadata);
  }, [mapping]);

  const updateMapping = () => {
    api.updateMapping(cmsName, { ...mapping, metadata: values })
      .then(() => {
        dispatch(setAlert(utils.generateAlert('Mapping updated successfuly!','success')));
        loadCredentials();
      })
      .catch((err) => {
        console.error(err);
        dispatch(setAlert(utils.generateAlert('Could not update this mapping. Try again later.', 'error')));
      });
  };

  const updateForm = (field, val) => {
    setValues({...values, [field]: val});
  };

  // Prevent breaking application if mapping field is missing
  if (!mapping) return null;

  return (
    <div className="account-item">
      <div className={`account-accordion-item ${active ? 'active' : ''}`}>
        <div className="account-title" onClick={() => {setActive(!active)}}>
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex align-items-center">
              <img className="user-icon" src={icons.deviceOutlineDark} alt="account" />
              <h3>{mapping.deviceId}</h3>
            </div>
            <div className="d-flex align-items-center">
              <img className="media-icon" src={icons.media} alt="account" />
              <h3>{mapping.playerId}</h3>
            </div>
            <img className="arrow-icon" src={icons.angleRight} alt="arrow" />
          </div>
        </div>

        <div className="account-fields">
          <div className="form-row">
            <div className="col" key="device-id">
              <div className="form-group">
                <label>Device ID</label>
                <DropdownSearch
                  title={'Search Solution Config'}
                  selected={selectedDevice
                    ? { label: selectedDevice.displayName, value: selectedDevice.id }
                    : { label: 'Select device', value: '' }
                  }
                  leftAlign
                  openUp
                  disabled
                  onSelect={value => console.log(value)}
                  options={devices ? devices.map(device => {
                    return { label: device.displayName, value: device.id };
                  }) : []} />
              </div>
            </div>
            {
              selectedIntegration.mappingMetadataFields.map((field) => {
                return (
                  <div className="col" key={field}>
                    <div className="form-group">
                      <label>{field}</label>
                      <CustomInput
                        id={`${mapping.id}-${field}`}
                        type="text"
                        value={values[field]}
                        isEditable
                        onChange={e => {
                          updateForm(field, e.target.value);
                        }}
                        autoFocus />
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className="form-row justify-content-end">
            <CustomButton classes="btn-primary" title="Update" handleClick={updateMapping} />
          </div>
        </div>
      </div>
      <div className="account-buttons">
        <IconButton
          id={`btn-delete-${mapping.id}`}
          btnClasses="account-button"
          tooltip="Remove Account"
          icon="del"
          onPress={() => {
            api.deleteMapping(cmsName, mapping)
              .then(() => {
                loadCredentials();
                dispatch(setAlert(utils.generateAlert('Mapping deleted successfuly!','success')));
              })
              .catch((err) => {
                dispatch(setAlert(utils.generateAlert('Could not delete this mapping. Try again later.', 'error')));
                console.error(err);
              })
          }} />
      </div>
    </div>
  )
};

export default Mapping;