import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDevices } from '../../../redux/devices/actions';
import { setAlert } from '../../../redux/dashboard/actions';
import { getSelectedProject } from '../../../redux/projects/actions';
import components from '../../../components';
import Mapping from '../mapping';
import api from '../../../services/api';
import utils from '../../../utils';
import { initializeMapping } from '../../../utils/integrations';
import ImportModal from '../importModal';

const { SearchBox, DropdownSearch, CustomButton, CustomInput, EmptyState } = components;

const MappingsTab = ({ selectedIntegration, mappings, credentials, cmsName, loadCredentials, loading }) => {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [filteredMappings, setFilteredMappings] = useState(mappings);
  const [selectedDevice, setSelectedDevice] = useState('');
  const [selectedCredential, setSelectedCredential] = useState('');
  const [values, setValues] = useState(initializeMapping(selectedIntegration.mappingMetadataFields));

  const devices = useSelector(getDevices);
  const project = useSelector(getSelectedProject);

  const dispatch = useDispatch();

  useEffect(() => {
    setFilteredMappings(mappings);
    setValues(initializeMapping(selectedIntegration.mappingMetadataFields));
  }, [mappings]);

  useEffect(() => {
    setSelectedDevice('');
  }, [devices]);

  const searchDevices = searchBy => {
    if (searchBy !== '') {
      setFilteredMappings(
        mappings.filter(mapping => {
          const mappingHas = option => mapping[option].toUpperCase().includes(searchBy.toUpperCase());
          return mappingHas('deviceId') || mappingHas('playerId');
        })
      );
    } else {
      setFilteredMappings(mappings);
    }
  };

  const createMapping = () => {
    if (selectedCredential === '' || selectedDevice === '') {
      dispatch(
        setAlert(utils.generateAlert('Could not create Credential! Is something wrong with the form?', 'error'))
      );
      return;
    }

    api
      .createMapping(
        cmsName,
        {
          device_id: selectedDevice.id,
          integration_name: selectedIntegration.integrationName,
          metadata: values
        },
        selectedCredential.id,
        project.id
      )
      .then(() => {
        dispatch(setAlert(utils.generateAlert('Mapping created successfuly!', 'success')));
        loadCredentials();
        setValues(initializeMapping(selectedIntegration.mappingMetadataFields));
        setShowCreateForm(false);
      })
      .catch(err => {
        console.error(err);
        dispatch(setAlert(utils.generateAlert('Could not create Mapping! Please try again.', 'error')));
      });
  };

  const updateForm = (field, val) => {
    setValues({ ...values, [field]: val });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>New Mapping</h2>
      {showCreateForm ? (
        <div className="account-fields">
          <div className="form-row">
            <div className="col" key="device-id">
              <div className="form-group">
                <label>Device ID</label>
                <DropdownSearch
                  title={'Search Solution Config'}
                  selected={
                    selectedDevice
                      ? { label: selectedDevice.displayName, value: selectedDevice.id }
                      : { label: 'Select device', value: '' }
                  }
                  leftAlign
                  onSelect={value => {
                    setSelectedDevice(devices.find(dev => dev.id === value));
                  }}
                  options={
                    devices
                      ? devices.map(device => {
                          return { label: device.displayName, value: device.id };
                        })
                      : []
                  }
                />
              </div>
            </div>

            <div className="col" key="credential-id">
              <div className="form-group">
                <label>Credential ID</label>
                <DropdownSearch
                  title={'Search Solution Config'}
                  selected={
                    selectedCredential
                      ? { label: selectedCredential.displayName, value: selectedCredential.id }
                      : { label: 'Select credential', value: '' }
                  }
                  leftAlign
                  onSelect={value => {
                    setSelectedCredential(credentials.find(cred => cred.id === value));
                  }}
                  options={
                    credentials
                      ? credentials.map(credential => {
                          return { label: credential.displayName, value: credential.id };
                        })
                      : []
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            {selectedIntegration.mappingMetadataFields.map(field => {
              return (
                <div className="col" key={field}>
                  <div className="form-group">
                    <label>{field}</label>
                    <CustomInput
                      id={`new-mappings-${field}`}
                      type="text"
                      value={values[field]}
                      isEditable
                      onChange={e => {
                        updateForm(field, e.target.value);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="form-row justify-content-end">
            <CustomButton
              classes="btn-danger left-button"
              title="Cancel"
              handleClick={() => {
                setShowCreateForm(false);
                setSelectedDevice('');
                setSelectedCredential('');
                setValues(initializeMapping(selectedIntegration.mappingMetadataFields));
              }}
            />
            <CustomButton classes="btn-secondary" title="Create" handleClick={createMapping} />
          </div>
        </div>
      ) : (
        <div>
          <CustomButton
            classes="btn-secondary left-button"
            title="Import CSV"
            handleClick={() => setShowImportModal(true)}
          />
          <CustomButton
            classes="btn-primary"
            title="Add Mapping"
            handleClick={() => {
              setShowCreateForm(!showCreateForm);
            }}
          />
        </div>
      )}
      <div className="horizontal-separator" />
      {mappings.length > 0 ? (
        <>
          <h2>{`List of mapped devices (${filteredMappings.length}/${mappings.length})`}</h2>
          <div>
            <SearchBox placeholder="Search by Device ID or Player ID" className="outline" onChange={searchDevices} />
            {filteredMappings.length > 0 ? (
              <>
                {filteredMappings.map(mapping => (
                  <Mapping
                    key={mapping.id}
                    mapping={mapping}
                    cmsName={cmsName}
                    loadCredentials={loadCredentials}
                    selectedIntegration={selectedIntegration}
                  />
                ))}
              </>
            ) : (
              <div className="no-results-found">
                <p>No mappings were found</p>
              </div>
            )}
          </div>
        </>
      ) : (
        <EmptyState
          icon={'deviceOutlineDark'}
          title={`You don't have any mapping.`}
          details={`Start mapping your devices by clicking "Add new Mapping".`}
        />
      )}
      <ImportModal
        isOpen={showImportModal}
        customColumns={selectedIntegration.mappingMetadataFields.map(field => field)}
        cmsName={cmsName}
        projectId={project.id}
        integrationName={selectedIntegration.integrationName}
        onUpdate={loadCredentials}
        toggleModal={() => setShowImportModal(!showImportModal)}
      />
    </div>
  );
};

export default MappingsTab;
