const reportTypes = [
  { id: 'quick', name: 'Quick CSV' },
  { id: 'custom', name: 'Custom CSV' },
  { id: 'pdf', name: 'Quick PDF' }
];

// Set initial values on reports object
const parse = (reports, projects) => {
  return reports.map(report => {
    const project = projects.find(proj => proj.id === report.projectId);

    return {
      ...report,
      projectName: project ? project.displayName : report.projectId
    };
  });
};

const loadSelectedDay = (frequency, daysToSend) => {
  // Format selectedDay based on frequency
  if (frequency === 'monthly') {
    const day = parseInt(daysToSend[0]);

    if (day >= 31) {
      return '31';
    } else if (day === 1) {
      return '1';
    }

    return 'custom';
  }
  return daysToSend ? daysToSend[0] : 0;
};

const getCustomReportTemplate = (filters) => {
  return {
    email: filters.user.email,

    startDate: filters.startDate,
    endDate: filters.endDate,
    filterByTime: filters.filterByTime,
    filterByWeekday: filters.filterByWeekday,
    timezone: filters.project.preferences.timezone,
    epoch: filters.interval, // Field expected on GenerateReport
    interval: filters.interval, // Field expected on CreateReport (Recurring)

    // CMS fields
    advertisers: filters.selectedAdvertisers.map(advertiser => advertiser.name),
    medias: filters.selectedMedias.map(media => media.name),

    // Recurrency fields
    format: 'default',
    frequency: 'single',
    daysToSend: [],
    fields: [],

    solution: filters.selectedSolution,
    devices: filters.selectedDevices
  };
};

const generateCMSQueryObject = (field, startDate, endDate) => {
  return JSON.stringify({
    metrics: [
      {
        field: field,
        operation: 'distinct',
        name: field
      }
    ],
    aggregateResult: {},
    dimensions: [],
    dimensionsEncoder: false,
    epoch: '',
    hasComparison: false,
    startTime: startDate,
    endTime: endDate
  });
};

export default {
  reportTypes,
  loadSelectedDay,
  parse,
  getCustomReportTemplate,
  generateCMSQueryObject
};
