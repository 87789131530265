import React, { useEffect, useState } from 'react';
import icons from '../../assets/icons';
import './styles.scss';

const SidePanel = ({ content, isOpen = true, onToggle }) => {
  const [isExpanded, setIsExpanded] = useState(isOpen);
  const [isFullOpen, setIsFullOpen] = useState(isOpen);

  useEffect(() => {
    setIsExpanded(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setTimeout(() => {
      setIsFullOpen(isExpanded);
    }, 100);
  }, [isExpanded]);

  return (
    <div className={`side-panel ${isExpanded ? 'expanded' : ''}`}>
      <div className="side-panel-container">
        <div className="side-panel-content">{isFullOpen ? content : null}</div>
      </div>
      <div
        className="side-panel-expand-button"
        onClick={() => {
          setIsExpanded(!isExpanded);
          if (onToggle) {
            onToggle(!isExpanded);
          }
        }}
      >
        <img src={icons.angleRight} alt="angle-button" />
      </div>
    </div>
  );
};

export default SidePanel;
