import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import { setAlert} from '../../redux/dashboard/actions';
import { getProjects } from '../../redux/projects/actions';

import components from '../../components';
import CustomButton from '../../components/customButton';
import api from '../../services/api';
import utils from '../../utils';
import { solutionsMeta, getSolutionData } from '../../utils/solutions';
import CustomCheckBox from '../../components/customCheckBox';

const { CustomInput, CustomDropdown, DropdownSearch } = components;

const CreateLicenseModal = ({ isOpen, toggleModal, onLicensesCreation }) => {
  const [numberOfLicenses, setNumberOfLicenses] = useState(1);
  const [selectedSolution, setSelectedSolution] = useState(solutionsMeta[0]);
  const [accountAdminName, setAccountAdminName] = useState("");
  const [validFrom, setValidFrom] = useState('');
  const [validUntil, setValidUntil] = useState('');
  const [demo, setDemo] = useState(true);
  const [createButtonEnabled, setCreateButtonEnabled] = useState(false);
  const [selectedProject, setSelectedProject] = useState({label: 'Choose a Project', value: null});

  const projects = useSelector(getProjects);
  const dispatch = useDispatch();

  useEffect(() => {
    const isCreateButtonEnabled = (
      accountAdminName !== '' &&
      validFrom !== '' &&
      validUntil !== '' &&
      selectedSolution.id &&
      numberOfLicenses > 0 &&
      selectedProject.value !== null
    );

    setCreateButtonEnabled(isCreateButtonEnabled);
  }, [accountAdminName, validFrom, validUntil, numberOfLicenses, selectedSolution, selectedProject])

  const selectProject = (projectId) => {
    const project = projects.find(dev => dev.id === projectId)
    setSelectedProject({ value: project.id, label: `${project.displayName} (${project.id})` });
  };

  const createLicenses = () => {
    const licenses = {
      solution: selectedSolution.id.toUpperCase(),
      accountAdminName,
      validFrom: new Date(validFrom).toISOString(),
      validUntil: new Date(validUntil).toISOString(),
      numLicenses: numberOfLicenses,
      projectId: selectedProject.value,
      demo: demo
    };

    api.createLicenses(licenses)
      .then(() => {
        dispatch(setAlert(utils.generateAlert('Your lincenses were created.', 'success')));
        toggleModal();
        onLicensesCreation();
      })
      .catch(error => {
        console.error(error);
        dispatch(setAlert(utils.generateAlert('Could not create your licenses. Try again later.', 'error')));
      });
  };

  return (
    <Modal className="create-license-modal" isOpen={isOpen} fade={false} toggle={toggleModal} centered>
      <ModalHeader className="header-gray" toggle={toggleModal} tag="div">
        <h5>Create Licenses</h5>
        <div className="modal-subtitle">
          Here you can create bulk licenses
        </div>
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="row">
            <div className="form-group col-6">
              <label>Project</label>
              <DropdownSearch
                title={'Search Project'}
                selected={selectedProject}
                onSelect={selectProject}
                options={projects.map(proj => { return { value: proj.id, label: `${proj.displayName} (${proj.id})` }})} />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-6">
              <label>Number of Licenses*</label>
              <CustomInput
                id="number-of-licenses"
                type="number"
                value={numberOfLicenses}
                onChange={e => setNumberOfLicenses(e.target.value)}
                min={1}
                isEditable
                autoFocus />
            </div>

            <div className="solution-dropdown form-group col-6">
              <label>Solution</label>
              <CustomDropdown
                hasIcon={false}
                outline
                items={[...solutionsMeta.filter(solution => !solution.cms)]}
                handleSelection={(e) => setSelectedSolution(getSolutionData(e.target.value))}
                valueField={'id'}
                displayField={'name'}
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>Valid From</label>
                <CustomInput
                  id="lincense-valid-from"
                  type="date"
                  value={validFrom}
                  isEditable
                  onChange={e => setValidFrom(e.target.value)} />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label>Valid Until</label>
                <CustomInput
                  id="lincense-valid-until"
                  type="date"
                  value={validUntil}
                  isEditable
                  onChange={e => setValidUntil(e.target.value)} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="form-group col-6">
              <label>Account Admin</label>
              <CustomInput
                id="account-admin"
                type="string"
                placeholder="example@example.com"
                value={accountAdminName}
                onChange={e => setAccountAdminName(e.target.value)}
                isEditable
                autoFocus />
            </div>

            <div className="form-group col-4">
              <label>Demo</label>
              <CustomCheckBox
                label={'Testing Period'}
                selected={demo ? 'all' : 'none'}
                onClick={val => setDemo(!val)}/>
            </div>
          </div>
        </form>
        <div className="button-container">
          <CustomButton title="Cancel" classes="btn-secondary" handleClick={toggleModal} />
          <CustomButton disabled={!createButtonEnabled} title="Create" classes="btn-primary" handleClick={createLicenses} />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CreateLicenseModal;