import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Stage, Layer } from 'react-konva';
import { setAlert } from '../../redux/dashboard/actions';

import Konva from 'konva';
import RoiInput from './roiInput';
import ZoneInput from './zoneInput';
import MultisectionDropdown from '../customDropdown/multisectionDropdown';
import PreviewButton from '../previewButton';
import { Roi, Zone } from './helpers';
import API from '../../services/api';
import utils from '../../utils';
import parse from '../../utils/device/parse';
import './styles.scss';

const { Transformer, Image } = Konva;

// Default value for canvas dimension
let DIMENSIONS = { WIDTH: 0, HEIGHT: 600 };

class ManageZones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zones: [],
      rois: [],
      currentEdition: null,
      models: [],
      hasChanged: false,
      isSaving: false,
      previewUrl: '',
      selectionList: [
        {
          category: 'ROIs',
          values: []
        },
        {
          category: 'Zones',
          values: []
        }
      ],
      aspectRatio: 1,
      dimensions: { width: DIMENSIONS.WIDTH, height: DIMENSIONS.HEIGHT }
    };
    this.bg = new Image({
      scaleX: 1,
      scaleY: 1
    });
    this.layer = {};
  }

  componentDidMount() {
    const { device } = this.props;
    const modalManage = document.getElementsByClassName('modal-manage-zones')[0];

    if (device && device.solution === 'Audience') {
      return;
    }

    if (modalManage) {
      // check if the modal is rendering on modal
      this.resizeCanvasOnModal();
      window.addEventListener('resize', this.resizeCanvasOnModal);
    } else {
      DIMENSIONS = { WIDTH: 800, HEIGHT: 600 };
    }

    this.layer.add(this.bg);
    this.layer.draw();
    this.layer.clearBeforeDraw(true);
    this.stage.on('click tap', this.handleClick);
    this.start();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeCanvasOnModal);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.device.config !== this.props.device.config) {
      this.start();
      this.deselectAll(true);
      this.refreshCanvas();
    }
  }

  resizeCanvasOnModal = () => {
    const modalManage = document.getElementsByClassName('modal-manage-zones')[0];
    if (modalManage) {
      const modalStyle = window.getComputedStyle(modalManage);
      const paddings = 30 * 2 + 20 * 2; // modal-body padding + canvas-container padding
      const totalSpace =
        +modalStyle.marginLeft.replace('px', '') + +modalStyle.marginRight.replace('px', '') + paddings;
      DIMENSIONS = {
        WIDTH: 800 > document.body.clientWidth - totalSpace ? document.body.clientWidth - totalSpace : 800,
        HEIGHT: 600
      };
      this.setState({ dimensions: { width: DIMENSIONS.WIDTH, height: DIMENSIONS.HEIGHT } });
      this.refreshCanvas();
    }
  };

  refreshCanvas = () => {
    this.layer.destroyChildren();
    this.start();
  };

  start = async () => {
    const settings = this.props.device.config ? this.props.device.config.driver_config : null;

    if (!settings || this.props.device.solution === 'Audience') return;

    const drawingSettings = parse.getDrawingSettings(this.props.device.config);

    this.setState({
      // Remove DAY-NIGHT model. Requested by CV Team
      models: settings.models.filter(model => model.type !== 'DAY-NIGHT'),
      // Reset preview background
      previewUrl: ''
    });

    this.updateHeight();
    if (drawingSettings) {
      await this.createRois(drawingSettings.rois);
      await this.createZones(drawingSettings.zones);
      this.renderZoneAndRois();
    }
  };

  handleChange = () => {
    this.setState({ hasChanged: true });
  };

  handleClick = e => {
    const currentRect = e.target;
    const transfs = this.stage.find('Transformer');
    const isRectSelected = transfs[0] && transfs[0]._nodes[0] && transfs[0]._nodes[0]._id === currentRect._id;

    // Early return if try to select a rect already selected
    if (isRectSelected) return;

    // Disable previous selection
    this.deselectAll();

    // Remove all transformers if click on empty area
    if (currentRect === this.stage || currentRect instanceof Image) {
      this.removeTransformers();
      this.layer.draw();
      return;
    }

    // Ignore clicks outside canvas
    if (!(currentRect instanceof Zone) && !(currentRect instanceof Roi)) {
      return;
    }

    // Handle click on Zones/ROIs
    if (currentRect instanceof Zone) {
      this.setState({
        zones: this.state.zones.map(zone => {
          if (zone.name() === currentRect.name()) {
            zone.setAttrs(currentRect.getAttrs());
          }
          return zone;
        })
      });
    } else if (currentRect instanceof Roi) {
      this.setState({
        rois: this.state.rois.map(roi => {
          if (roi.name() === currentRect.name()) {
            roi.setAttrs(currentRect.getAttrs());
          }
          return roi;
        })
      });
    }

    // Handle rect selection
    this.selectRect(currentRect);
  };

  removeTransformers = () => {
    const transfs = this.stage.find('Transformer');
    transfs.forEach(transf => transf.destroy());
  };

  deselectAll = destroyTransforms => {
    // Clear current selection
    if (this.state.currentEdition) {
      this.state.currentEdition.off('dragend transformend');
      this.state.currentEdition.finishEdit();
      this.setState({ currentEdition: null });
    }
    // Remove old transformers from canvas
    if (destroyTransforms) {
      this.removeTransformers();
    }
    this.layer.draw();
  };

  selectRect = currentRect => {
    // Disable previous selection
    this.deselectAll(true);

    // Create new transformer
    const transf = new Transformer();
    if (currentRect instanceof Roi) {
      // Disable rotation for ROIs
      transf.rotateEnabled(false);
    } else if (currentRect instanceof Zone) {
      // Prevent users from change zone's height should be a constant value
      transf.boundBoxFunc((oldBoundBox, newBoundBox) => {
        if (newBoundBox.height !== oldBoundBox.height) {
          return oldBoundBox;
        }
        return newBoundBox;
      });
      transf.enabledAnchors(['middle-right', 'middle-left']);
      const oldUpdate = transf.update;
      transf.update = () => {
        oldUpdate();
        const rotateAnchor = transf.findOne('.rotater');
        rotateAnchor.cornerRadius(50);
      };
    }

    // Bind transformer and draw everything on canvas
    this.layer.add(transf);
    transf.nodes([currentRect]);
    currentRect.edit();
    this.setState({ currentEdition: currentRect });
    this.layer.draw();
  };

  updateBg = url => {
    // Create new Image object with correct dimensions
    const imageObj = new window.Image(this.state.dimensions.width, this.state.dimensions.height);
    imageObj.onload = () => {
      // Update Konva.Image when the image is ready
      this.bg.image(imageObj);
    };

    imageObj.src = url;
    this.setState({ previewUrl: url });

    // Wait until Konva Image source to be updated before refreshing Canvas
    this.bg.on('imageChange', () => {
      this.layer.batchDraw();
    });
  };

  generateName = (type, prefix) => {
    let index = 0;
    let name = '';
    const findCallback = obj => obj.name() === name;
    do {
      index++;
      name = `${prefix} ${index}`;
    } while (this.state[type].findIndex(findCallback) >= 0);
    return name;
  };

  getRectByID = rect => {
    // Rect is not yet a zone or ROI object, so we are going to find it from layer
    const child = this.layer.getChildren(child => child._id === rect._id);
    return child ? child[0] : null;
  };

  create = type => {
    if (type === 'zone') {
      const name = this.generateName('zones', 'zone');
      const currentROI = this.state.currentEdition.attrs;
      const newZone = new Zone({
        A: { x: currentROI.x + 10, y: currentROI.y + 40 },
        B: { x: currentROI.x + 100, y: currentROI.y + 40 },
        name,
        height: 20,
        real_height: 0.7,
        aspectRatio: this.getAspectRatio(),
        roi_ref: this.state.currentEdition.name()
      });
      this.selectRect(newZone);
      this.setState({ zones: [...this.state.zones, newZone] }, () => this.renderZoneAndRois());
    } else if (type === 'roi') {
      const name = this.generateName('rois', 'roi');
      const newROI = new Roi({
        name,
        x: 10,
        y: 10,
        width: 200,
        height: 100,
        aspectRatio: this.getAspectRatio()
      });
      this.selectRect(newROI);
      this.setState({ rois: [...this.state.rois, newROI] }, () => this.renderZoneAndRois());
    }
    this.setState({ hasChanged: true });
  };

  remove = () => {
    const { currentEdition, zones, rois } = this.state;
    this.removeTransformers();
    currentEdition.destroy();
    if (currentEdition instanceof Zone) {
      this.setState({
        currentEdition: null,
        hasChanged: true,
        zones: zones.filter(zone => zone !== currentEdition)
      });
    } else if (currentEdition instanceof Roi) {
      zones.filter(zone => zone.roi_ref === currentEdition.name()).forEach(zone => zone.destroy());
      this.setState({
        hasChanged: true,
        currentEdition: null,
        rois: rois.filter(roi => roi !== currentEdition),
        zones: zones.filter(zone => zone.roi_ref !== currentEdition.name())
      });
    }
    this.layer.draw();
  };

  createZones = zones => {
    return new Promise(res => {
      const aspectRatio = this.getAspectRatio();
      this.setState(
        {
          zones: zones.map(zone => {
            const newZone = { ...zone, aspectRatio };
            return new Zone(newZone);
          })
        },
        () => res()
      );
    });
  };

  createRois = rois => {
    return new Promise(res => {
      const aspectRatio = this.getAspectRatio();
      this.setState(
        {
          rois: rois.map((roi, index) => {
            const name = roi.name || `roi ${index + 1}`;
            const newROI = { ...roi, name, aspectRatio };
            return new Roi(newROI);
          })
        },
        () => res()
      );
    });
  };

  updateROI = params => {
    const { currentEdition, zones } = this.state;
    const oldName = currentEdition.name();

    // Update ROIs list
    const rois = this.state.rois.map(roi => {
      if (roi.name() === currentEdition.name()) {
        roi.setAttrs(currentEdition.getAttrs());
        if (params.model) {
          roi.model = params.model;
        }
        if (params.model_night) {
          roi.model_night = params.model_night;
        }
      }
      return roi;
    });

    // Update Zones with new ROI name
    currentEdition.setAttrs(params);
    if (params.name) {
      this.updateZoneRefs(params.name, oldName);
    }

    // Update app state
    this.setState({
      hasChanged: true,
      selectionList: this.setSelectionList(rois, zones),
      rois
    });

    // Redraw
    this.layer.draw();
  };

  updateZone = params => {
    const { currentEdition } = this.state;
    currentEdition.setAttrs(params);
    this.setState({
      hasChanged: true,
      zones: this.state.zones.map(zone => {
        if (zone.name() === params.oldName) {
          zone.setAttrs(currentEdition.getAttrs());
          if (params.real_height) {
            zone.real_height = params.real_height;
          }
          if (params.roi_ref) {
            zone.roi_ref = params.roi_ref;
          }
        }
        return zone;
      })
    });
    this.layer.draw();
  };

  updateZoneRefs = (name, oldName) => {
    const { zones } = this.state;
    zones.forEach(zone => {
      if (zone.roi_ref === oldName) {
        zone.roi_ref = name;
      }
    });
  };

  renderZoneAndRois = () => {
    const { rois, zones } = this.state;
    rois.forEach(roi => {
      this.layer.add(roi);
    });

    zones.forEach(zone => {
      this.layer.add(zone);
    });

    this.setState({
      selectionList: this.setSelectionList(rois, zones)
    });

    this.layer.draw();
  };

  setSelectionList = (rois, zones) => {
    return [
      {
        category: 'ROIs',
        values: rois.map(roi => ({ ...roi, name: roi.attrs.name }))
      },
      {
        category: 'Zones',
        values: zones.map(zone => ({ ...zone, name: zone.attrs.name }))
      }
    ];
  };

  updateSolutionConfig = deviceCopy => {
    // Parse customConfig to expected format before patch
    const parsedData = parse.parseDeviceToServerConfig(deviceCopy);

    // Send changes to Device Management API
    API.updateDeviceConfig(deviceCopy.companyId,deviceCopy.id, parsedData, ['customConfig'])
      .then(response => {
        this.setState({ hasChanged: false });
        this.props.onConfigChanged(response);
        this.props.setAlert(utils.generateAlert('Device updated successfully!', 'success'));
      })
      .catch(err => {
        this.setState({ hasChanged: true });
        console.error(err);
        this.props.setAlert(utils.generateAlert('Something went wrong. Please try again!', 'error'));
      })
      .finally(() => {
        this.setState({ isSaving: false });
      });
  };

  getNewDrawingSettings = () => {
    const aspectRatio = this.getAspectRatio();
    const drawingSettings = parse.getDrawingSettings(this.props.device.config);
    let newDrawingSettings = utils.get(this.props.device, 'config.driver_config.config');

    // Get Drawing settings changes (old format, Zones and ROIs at same level)
    if (drawingSettings.hasZoneOutside) {
      const rois = this.state.rois.map(roi => {
        const newROI = { ...roi.getObj(aspectRatio), name: roi.attrs.name };
        return { ...newROI };
      });

      const zones = this.state.zones.map(zoneCanvas => zoneCanvas.getObj(true, aspectRatio));

      newDrawingSettings = {
        ...newDrawingSettings,
        zones,
        rois
      };
    } else {
      // Get Drawing settings changes (new format, Zones inside ROIs)
      const rois = this.state.rois.map(roi => {
        const zones = this.state.zones
          .filter(zone => zone.roi_ref === roi.name())
          .map(zoneCanvas => zoneCanvas.getObj(false, aspectRatio));
        const newROI = { ...roi.getObj(aspectRatio), name: roi.attrs.name };
        return { ...newROI, zones };
      });

      newDrawingSettings = {
        ...newDrawingSettings,
        rois
      };
    }

    return newDrawingSettings;
  };

  saveChanges = () => {
    this.setState({ isSaving: true });

    const newDevice = utils.deepClone(this.props.device);

    // Update Drawing Settings
    utils.set(newDevice, 'config.driver_config.config', this.getNewDrawingSettings());

    this.updateSolutionConfig(newDevice);
  };

  // Updates canvas height based on camera aspect ratio
  updateHeight = () => {
    const { device } = this.props;
    const resolution = utils.get(device, 'config.driver_config.config.resolution');
    let newHeight = DIMENSIONS.HEIGHT;
    if (resolution) {
      newHeight = (resolution.height / resolution.width) * DIMENSIONS.WIDTH;
    }
    this.setState({ dimensions: { width: DIMENSIONS.WIDTH, height: newHeight } });
  };

  // Returns aspect ratio factor, used to update dimension and position of Zones and ROIs
  getAspectRatio = () => {
    /* Most common used camera resolution used so far
     * Dimension   Aspect ratio
     * 640x480     4:3
     * 800x600     4:3
     * 1280x720    16:9
     * 1920x1080   16:9
     * 3840x1088   60:80
     */
    const { device } = this.props;
    const resolution = utils.get(device, 'config.driver_config.config.resolution');
    let aspectRatio = 1;
    if (resolution) {
      aspectRatio = DIMENSIONS.WIDTH / resolution.width;
    }
    return aspectRatio;
  };

  /**
   * Method check the max zone and ROI number that the user can create
   */
  canCreate = type => {
    const { device, unlimitedZones } = this.props;

    if (unlimitedZones) return true;

    const { rois, zones } = this.state;
    const currentQuantity = type === 'roi' ? rois.length : zones.length;

    // Max 3 for VD,  2 for VR, 2 for Crowd
    switch (device.solution) {
      case 'Vehicle Detection':
        return currentQuantity < 3;
      case 'Vehicle Recognition':
      case 'Crowd':
        return currentQuantity < 2;
      default:
        return true;
    }
  };

  render() {
    const { device } = this.props;
    const { selectionList, currentEdition, rois, zones, hasChanged, models, isSaving, dimensions, previewUrl } =
      this.state;

    if (device.solution === 'Audience') {
      return (
        <div className="solution-no-zones">
          <h4>There are no Zones for this Solution</h4>
        </div>
      );
    }

    return (
      <div className={`canvas-container ${previewUrl ? '' : 'no-preview'}`}>
        <div className="canvas-header">
          <h3>Manage ROIs and Zones</h3>
          <div>
            <div className="form-group d-flex flex-row">
              <MultisectionDropdown
                label={'Select ROI or Zone:'}
                items={selectionList}
                handleSelection={rect => {
                  this.selectRect(this.getRectByID(rect));
                }}
                selectedItem={currentEdition ? { ...currentEdition, name: currentEdition.attrs.name } : null}
                multipleSections
                nullable
                valueField={'_id'}
                displayField={'name'}
              />
            </div>
          </div>
        </div>
        <Stage ref={ref => (this.stage = ref)} width={dimensions.width} height={dimensions.height}>
          <Layer ref={ref => (this.layer = ref)}></Layer>
        </Stage>
        <div className="d-flex justify-content-between">
          <div className="legends-container">
            <div className="legend rois">ROIs</div>
            <div className="legend zones">Zones</div>
            <div className="legend selected">Selected</div>
          </div>
          <div className="action-buttons">
            {currentEdition && (
              <button onClick={() => this.remove()} className="btn btn-secondary">
                Remove
              </button>
            )}
            <button onClick={() => this.create('roi')} className="btn btn-secondary" disabled={!this.canCreate('roi')}>
              New ROI
            </button>
            {currentEdition && currentEdition instanceof Roi && (
              <button
                onClick={() => this.create('zone')}
                className="btn btn-secondary"
                disabled={!this.canCreate('zone')}
              >
                New Zone
              </button>
            )}
            <PreviewButton
              device={device}
              onPreview={this.updateBg}
              onError={() => this.setState({ previewUrl: '' })}
            />
            <button disabled={!hasChanged || isSaving} onClick={this.saveChanges} className="btn btn-primary">
              {isSaving ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                  <span className="sr-only">Loading...</span>
                </>
              ) : (
                'Save'
              )}
            </button>
          </div>
        </div>
        {currentEdition && (
          <div>
            {currentEdition instanceof Roi ? (
              <RoiInput
                namesUsed={rois.map(roi => roi.name()).filter(name => name !== currentEdition.name())}
                roi={currentEdition}
                updateRoi={newAttr => this.updateROI(newAttr)}
                onChange={this.handleChange}
                models={models.map(model => ({ ...model, displayName: `${model.type} - ${model.version}` }))}
              />
            ) : (
              <ZoneInput
                namesUsed={zones.map(zone => zone.name()).filter(name => name !== currentEdition.name())}
                roisName={rois.map(roi => ({ name: roi.name() }))}
                onChange={this.handleChange}
                zone={currentEdition}
                updateZone={newAttr => this.updateZone(newAttr)}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setAlert: payload => dispatch(setAlert(payload))
  };
};

export default connect(null, mapDispatchToProps)(ManageZones);
