import React from 'react';
import { Info, Devices, Users, Preferences } from './sections';

const projectSections = ({
  project,
  loadProjects,
  projects,
  editMode,
  setEditMode,
  updateProject,
  deleteProject,
  isAdmin,
  counters,
  updateCounters
}) => {
  if (project && project.id) {
    return [
      {
        header: 'Info',
        body: (
          <Info
            selectedProject={project}
            editMode={editMode}
            setEditMode={setEditMode}
            updateProject={updateProject}
            isAdmin={isAdmin}
            deleteProject={deleteProject}
          />
        )
      },
      {
        header: `Devices (${counters.deviceCounter})`,
        body: (
          <Devices
            project={project}
            projects={projects}
            loadProjects={loadProjects}
            counters={counters}
            isAdmin={isAdmin}
            updateCounters={updateCounters}
          />
        )
      },
      {
        // Use AIM API to get info for this section. getPolicy()?
        header: `Users (${counters.userCounter})`,
        body: <Users updateCounters={updateCounters} />
      },
      {
        header: 'Preferences',
        body: (
          <Preferences
            selectedProject={project}
            editMode={editMode}
            setEditMode={setEditMode}
            updateProject={updateProject}
          />
        )
      }
    ];
  }
  return [];
};

export default projectSections;
