import utils from '../index';
import { splitCustomConfig } from './importParse';
import { getCustomConfig } from './exportParse';
import deviceTemplate from '../device/deviceTemplate';

const { get, has, deepClone } = utils;

const extractFieldsFromSection = (section, config) => {
  // Make a copy to avoid rewriting the original reference
  const modifiedSection = deepClone(section);

  Object.keys(section).forEach(key => {
    const inputs = deepClone(section[key].fields);
    if (inputs) {
      Object.keys(inputs).forEach(input => {
        const newValue = get(config, inputs[input].path);
        inputs[input] = (typeof newValue !== 'undefined') ? newValue : inputs[input].value;
      });
      modifiedSection[key] = inputs;
    }
  });

  return modifiedSection;
};

const getDrawingSettings = (config) => {
  const malosConfig = get(config, 'driver_config.config') || {};

  const hasZoneOutside = has(malosConfig, 'zones');
  const rois = malosConfig.rois || [];
  let zones = [];

  // Get a valid ROI index, prevent using a name that already exists
  const getFreeIndex = (currentIndex) => {
    let roiFound = rois.find( roi => roi.name === `roi ${currentIndex}`);
    if (roiFound) {
      return getFreeIndex(currentIndex + 1);
    }
    return currentIndex;
  };

  // Give a name for ROI if none
  rois.forEach( (roi, rIndex) => {
    if (!roi.name) {
      roi.name = `roi ${getFreeIndex(rIndex + 1)}`;
    }
  });

  if (hasZoneOutside) {
    if (rois[0] && !rois[0].name) {
      rois[0].name = 'roi 1';
    }
    const firstROIName = rois[0] ? rois[0].name : 'roi 1';
    // With zones outside ROIs we might not have a roi_ref yet
    // in this case we can assign the name of the first ROI to
    // zones that doesn't have any.
    zones = malosConfig.zones.map(zone => ({
      ...zone,
      roi_ref: zone.roi_ref ? zone.roi_ref : firstROIName,
    }));
  } else {
    // Use ROI's name as reference to zones inside of them
    rois.forEach((roi) => {
      const zonesRois = roi.zones ? roi.zones.map(zone => ({
        ...zone,
        roi_ref: roi.name
      })) : [];
      zones = [...zones, ...zonesRois];
    });
  }

  return { hasZoneOutside, zones, rois };
};

const getSettingsTemplate = (device) => {
  if (!device) return null;

  const solutionName = device.defaultConfig.app;
  const config = { config: device.customConfig };
  const tabs = Object.keys(deviceTemplate);
  const template = deepClone(deviceTemplate);
  const sections = tabs.map(tab => {
    return template[tab].fields;
  });

  const generalSetting = extractFieldsFromSection(sections[1], config);
  const advancedSetting = extractFieldsFromSection(sections[2][solutionName].fields, config);

  const settingsTemplate = {
    ...generalSetting,
    ...advancedSetting,
    drawingSettings: getDrawingSettings(config)
  };

  return settingsTemplate;
};

/**
 * Parse all data and encapsulates the information
 * @param {Object} device
 */
const parseDeviceConfig = (device) => {
  if (!device || !device.name) return {};
  const { customDeviceModels, customDeviceSettings } = splitCustomConfig(device.customConfig);
  const selectedSolutionConfigId = device.defaultSolutionConfigId;
  const malosApp = utils.mergeObjs(device.defaultConfig, customDeviceModels, customDeviceSettings);
  const solution = malosApp.app;
  const resourceName = device.name;

  const solutionsType = {
    "FACEV2": "Audience",
    "VEHICLERECOGNITIONV1": "Vehicle Recognition",
    "VEHICLEDETECTIONV1": "Vehicle Detection",
    "VEHICLECROWD": "Vehicle Crowd",
    "CROWDV3": "Crowd",
    "CROWDV2": "Crowd",
    "--": "None",
  };

  const solutionType = solutionsType[solution];

  return {
    solutionType,
    resourceName,
    solution,
    settings: getSettingsTemplate(device),
    status: 'Loading...',
    malosApp,
    customDeviceModels,
    customDeviceSettings,
    selectedSolutionConfigId,
    defaultConfig: device.defaultConfig
  };
};

/**
 * Convert the device to follow the API standards
 * @param {Object} device
 */
const parseDeviceToServerConfig = (device) => {
  // Extract customConfig from current settings and default
  const customConfig = getCustomConfig(device.defaultConfig, device.config);

   // Server expects an object { name, customConfig } to patch
  const settingsObject =  utils.removeNullValue({
    name: device.name,
    customConfig: customConfig
  });

  return settingsObject;
};

// Convert preset data to a Device like object
const presetToDeviceConfig = (presetData, solution) => parseDeviceConfig({
  defaultConfig: { app: solution},
  customConfig: presetData.config,
  name: presetData.name
});

const parse = {
  parseDeviceConfig,
  getDrawingSettings,
  presetToDeviceConfig,
  parseDeviceToServerConfig
};

export default parse;