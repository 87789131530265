const parseAudienceData = (rows) => {
  const data = rows.map( row => {
    return ({
      ...row,
      gender: genderPercent(row),
      weeklyImpressions: parseWeeklyData(row),
      dailyImpressions: parseDailyData(row),
      growth: growthPercent(row),
      ageGroup: [{
        label: '',
        value: [
          { label: 'young', value: row.young},
          { label: 'young adult', value: row.young_adult},
          { label: 'adult', value: row.adult},
          { label: 'senior', value: row.senior},
        ]
      }]
    })
  });

  return data;
};

const parseVehicleData = (rows) => {
  const data = rows.map( row => {
    return ({
      ...row,
      avg_speed: row.speed ? row.speed.toFixed(2) : 0,
      weeklyImpressions: parseWeeklyData(row, 'detections'),
      dailyImpressions: parseDailyData(row, 'detections'),
      growth: growthPercent(row, 'detections')
    });
  });

  return data;
};

const parseCrowdData = (rows) => {
  const data = rows.map( row => {
    return ({
      ...row,
      weeklyImpressions: parseWeeklyData(row, 'detections'),
      dailyImpressions: parseDailyData(row, 'detections'),
      growth: growthPercent(row, 'detections'),
      direction: directionPercent(row)
    })
  });

  return data;
};

const genderPercent = (data) => {
  if (data.male > data.female) {
    return data.male > 0 ? (100 - 100 * data.female/data.male) : 0;
  }

  return data.female > 0 ? (100 - 100 * data.male/data.female) : 0;
};

const directionPercent = (data) => {
  if (data.forward > data.backward) {
    return data.forward > 0 ? (100 - 100 * data.backward/data.forward) : 0;
  }

  return data.backward > 0 ? (100 - 100 * data.forward/data.backward) : 0;
};

const growthPercent = (data, fieldName = 'impressions') => {
  if (data.previous_impressions !== 0) {
    return 100 * (data[fieldName] - data.previous_impressions)/data.previous_impressions;
  }
  return data[fieldName] > 0 ? 100 : 0;
};

// Use fieldName param to make this function to work with 'detections' and 'impressions'
const parseWeeklyData = (row, fieldName = 'impressions') => {
  const result = [{
    label: 'Impressions By Day of the week',
    value: [
      {label: 'sun', value: 0},
      {label: 'mon', value: 0},
      {label: 'tue', value: 0},
      {label: 'wed', value: 0},
      {label: 'thu', value: 0},
      {label: 'fri', value: 0},
      {label: 'sat', value: 0}
    ]
  }];

  if (row.weekly_impressions && row.weekly_impressions.length > 0) {
    row.weekly_impressions.forEach(day => {
      result[0].value[day.weekday - 1].value = day[fieldName];
    });
  }

  return result;
};

const parseDailyData = (row, fieldName = 'impressions') => {
  const result = [{
    label: '',
    value: [
      {label: '12am', value: 0},
      {label: '01am', value: 0},
      {label: '02am', value: 0},
      {label: '03am', value: 0},
      {label: '04am', value: 0},
      {label: '05am', value: 0},
      {label: '06am', value: 0},
      {label: '07am', value: 0},
      {label: '08am', value: 0},
      {label: '09am', value: 0},
      {label: '10am', value: 0},
      {label: '11am', value: 0},
      {label: '12pm', value: 0},
      {label: '01pm', value: 0},
      {label: '02pm', value: 0},
      {label: '03pm', value: 0},
      {label: '04pm', value: 0},
      {label: '05pm', value: 0},
      {label: '06pm', value: 0},
      {label: '07pm', value: 0},
      {label: '08pm', value: 0},
      {label: '09pm', value: 0},
      {label: '10pm', value: 0},
      {label: '11pm', value: 0}
    ]
  }];

  if (row.daily_impressions && row.daily_impressions.length > 0) {
    row.daily_impressions.forEach(hourData => {
      result[0].value[hourData.hour].value = hourData[fieldName];
    });
  }

  return result;
};

const insightsTableData = {
  'facev2' : parseAudienceData,
  'scalafacev0' : parseAudienceData,
  'signagelivefacev1' : parseAudienceData,
  'broadsignfacev1' : parseAudienceData,
  'brightauthorfacev1' : parseAudienceData,
  'ayudafacev1' : parseAudienceData,
  'vehiclerecognitionv1' : parseVehicleData,
  'broadsignvehiclev1' : parseVehicleData,
  'vehiclecrowd' : parseVehicleData,
  'crowdv3' : parseCrowdData
};

export default insightsTableData;