import React, { useEffect, useRef, useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import CustomCheckBox from '../customCheckBox';
import SearchBox from '../searchBox';

import utils from '../../utils';
import paginationUtils from '../../utils/pagination';
import './styles.scss';
import { useDebouncedCallback } from 'use-debounce';

/**
 *
 * @property {Object[]} items Items to be selected (requires at least id)
 * @property {Object[]} customSelectedItems Inject Custom selected items
 * @property {Number} listSize The size of items list
 * @property {Number} itemHeight The size of the item on the list list
 * @property {string} emptyMessage Message when the list is empty
 * @property {string} customSearchPlaceHolder Message on searchbox
 * @property {string} displayField Display (label) field on object
 * @property {string} valueField Value field on object
 * @property {string} sortField Sort items by field
 * @property {boolean} renderCheckbox Display checkbox?
 * @property {boolean} renderSearchbox Display searchbox?
 * @property {boolean} allSelected Set all items selected?
 * @property {boolean} hasCustomSelectedItems is using custom selected items?
 * @property {Function} renderActionButtons Display action buttons?
 * @property {Function} renderBulkActionButtons Display bulk action buttons?
 * @property {Function} onItemSelected Callback when an item is selected
 * @property {Function} onSelect Callback when selected items change
 * @property {Function} customRender Callback to render custom rows
 * @returns {JSX.Element}
 */
const InfinityLoader = ({
  items,
  emptyMessage = 'No items to display',
  customSearchPlaceHolder = 'Search',
  itemHeight,
  displayField = 'id',
  valueField = 'id',
  renderCheckbox = true,
  renderSearchbox = true,
  allSelected = true,
  renderActionButtons,
  renderBulkActionButtons,
  onItemSelected,
  onSelect,
  customRender,
  filters = null,
  onLoading,
  totalItems = 99999,
  shouldUpdate = false
  // customSelectedItems = [],
  // listSize,
  // sortField = 'id',
  // hasCustomSelectedItems = false,
}) => {
  const [selectedItems, setSelectedItems] = useState(allSelected ? items : []);
  const [searchingBy, setSearchingBy] = useState('');
  const [nextPageToken, setNextPageToken] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [totalSearchedResult, setTotalSearchedResult] = useState(totalItems);

  const infiniteLoaderRef = useRef(null);
  const itemStatusMap = useRef({});
  const LOADING = 1;
  const LOADED = 2;
  const BATCH_SIZE = 40;

  useEffect(() => {
    if (shouldUpdate) {
      loadMoreItems(0, BATCH_SIZE);
    }
  }, [shouldUpdate]);

  useEffect(() => {
    if (onSelect) {
      onSelect(selectedItems);
    }
  }, [selectedItems]);

  const handleSearch = useDebouncedCallback((term) => {
    // Clean up items cache and status everytime searchingBy changes
    setNextPageToken('');
    setSearchingBy(term);
    if (infiniteLoaderRef.current) {
      infiniteLoaderRef.current.resetloadMoreItemsCache();
    }

    itemStatusMap.current = {};

    loadMoreItems(0, BATCH_SIZE,term, true);
  }, 300);

  const loadMoreItems = (startIndex, stopIndex, searchingTerm = '', clearToken = false) => {
    paginationUtils.setItemsStatus(startIndex, stopIndex, itemStatusMap.current, LOADING);
    setIsLoading(true);

    // Perform fetch on parent component
    onLoading(clearToken ? '' : nextPageToken, searchingTerm).then(({nextPageToken, totalSize}) => {
      setTotalSearchedResult(totalSize)
      paginationUtils.setItemsStatus(startIndex, stopIndex, itemStatusMap.current, LOADED);
      setIsLoading(false);
      setNextPageToken(nextPageToken);
    });
  };

  /**
   * Check if item is selected
   * @param {string} itemId id of item to be checked
   * @returns {boolean} is item selected?
   */
  const isSelected = itemId => selectedItems.some(item => item.id === itemId);

  const toggleSelection = (itemsToToggle,remove) => {
    let newSelectedItems = utils.deepClone(selectedItems);
    itemsToToggle.forEach(itemToToggle => {
      if (remove) {
        newSelectedItems = newSelectedItems.filter(item => item.id !== itemToToggle.id);
      } else {
        newSelectedItems.push(items.find(item => item.id === itemToToggle.id));
      }
    })
    setSelectedItems(newSelectedItems);
  }

  const renderLoading = () => {
    if (!isLoading) {
      return <div className="empty-message" />;
    }

    return (
      <div className="empty-message w-100 mt-8 d-flex justify-content-center">
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
      </div>
    )
  };

  const renderLabel = (item, label) => {
    return customRender ? customRender(item) : label;
  };

  const renderRow = ({ index, style }) => {
    const item = items[index];
    const value = item[valueField];
    const selected = isSelected(item.id);
    // If the list of items has only one item no tooltip should be rendered (breaking rendering space).
    const label =
      items.length === 1 ? (
        <div id={item.id}>{`${item[displayField]} - ${item.id}`}</div>
      ) : (
        utils.renderTrimmedString(`${item[displayField]} - ${item.id}`, item.id)
      );

    return (
      <div
        className={`item ${selected ? 'selected' : ''}`}
        key={item.id}
        style={style}
        onClick={() => {
          if (onItemSelected) {
            onItemSelected(value, item.id);
          }
        }}
      >
        {renderCheckbox ? (
          <CustomCheckBox
            selected={isSelected(item.id) ? 'all' : 'none'}
            label={renderLabel(item, label)}
            onClick={value => toggleSelection([item], value)}
            key={item.id}
          />
        ) : (
          renderLabel(item, label)
        )}
        {renderActionButtons ? renderActionButtons(item) : null}
      </div>
    );
  };

  const renderEmpty = () => {
    return (
      <div className="item-list no-devices">
        <p>{emptyMessage}</p>
      </div>
    );
  };

  const renderItems = () => {
    return (
      <>
        {renderCheckbox && (
          <div className="toolbar">
            <CustomCheckBox
              multiple
              selectedItems={selectedItems.length}
              totalItems={items.length}
              label={`All - ${selectedItems.length} / ${totalSearchedResult}`}
              onClick={isAllSelected => setSelectedItems(isAllSelected ? items : [])}
            />
            {renderBulkActionButtons ? renderBulkActionButtons(selectedItems) : null}
          </div>
        )}
        <div className="item-list">
          {items.length === 0 && <p>{emptyMessage}</p>}
          <InfiniteLoader
            ref={infiniteLoaderRef}
            isItemLoaded={params => paginationUtils.isRowLoaded(params, itemStatusMap.current)}
            itemCount={totalItems}
            minimumBatchSize={BATCH_SIZE}
            threshold={5}
            loadMoreItems={(startIndex,stopIndex) => loadMoreItems(startIndex,stopIndex,searchingBy)}
          >
            {({ onItemsRendered, ref }) => (
              <List
                ref={ref}
                className={'infinity-loader-virtual-list'}
                height={window.innerHeight - 563}
                itemCount={items.length}
                itemSize={itemHeight ? itemHeight : 46}
                width="100%"
                onItemsRendered={onItemsRendered}
              >
                {renderRow}
              </List>
            )}
          </InfiniteLoader>
        </div>
      </>
    );
  };

  return (
    <div className="multiselector">
      {renderSearchbox && (
        <div className="search-filter-bar">
          <SearchBox onChange={handleSearch} placeholder={customSearchPlaceHolder} autoFocus />
          {filters}
        </div>
      )}
      {items.length === 0 ? renderEmpty() : renderItems()}
      
      { renderLoading() }
    </div>
  );
};

export default InfinityLoader;
