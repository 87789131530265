import React, { useEffect, useState } from 'react';
import InfinityLoader from '../dropdownSearch/infinityLoader';
import API from '../../services/api';
import utils from '../../utils';

const DeviceSelector = ({ companyId, onSelect, filterObject, selected, disabled }) => {
  const [currentItems, setCurrentItems] = useState([]);
  const [currentFilter, setCurrentFilter] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      loadMoreItems();
    }
  }, [isOpen]);

  const loadMoreItems = (nextPageToken = '', searchingBy = '') => {
    const query = encodeURI(`displayName ILIKE "%${searchingBy}%" OR id ILIKE "%${searchingBy}%"`);
    const filter = searchingBy !== '' ? query : '';

    if (filter !== currentFilter) {
      setCurrentItems([]);
    }

    return API.getDevices(companyId, nextPageToken, filter).then(results => {
      const fetchedData = results.devices.map(result => ({
        label: `${result.displayName || result.id}`,
        value: `"${result.id}"`
      }));

      // Reset list when filter changes
      if (filter !== currentFilter) {
        setCurrentFilter(filter);
        setCurrentItems(fetchedData);
      } else {
        const newSiteTypes = utils.mergeArraysByField(fetchedData, currentItems, 'value');
        setCurrentItems(newSiteTypes);
      }

      return results.nextPageToken;
    });
  };

  return (
    <div className="device-selector">
      <InfinityLoader
        title={'Search Device'}
        leftAlign
        selected={selected}
        disabled={disabled}
        onSelect={(selectedValue) => onSelect(selectedValue, { ...filterObject, options: currentItems })}
        onLoading={loadMoreItems}
        onOpenChange={setIsOpen}
        totalSize={9999}
        options={currentItems} />
    </div>
  );
};

export default DeviceSelector;