import React, { useState, useEffect } from 'react';
import SearchBox from '../../../components/searchBox';
import Tags from '../../../components/tags';
import API from '../../../services/api';
import CustomButton from '../../../components/customButton';
import CustomCheckBox from '../../../components/customCheckBox';
import UserForm from '../../../components/userForm';

const Users = ({ project, updateProject }) => {
  const [users, setUsers] = useState(project.users);
  const [search, setSearch] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [showNewUser, setShowNewUser] = useState(false);
  const [error, setError] = useState({ code: '' });
  const [isDataViewer, setIsDataViewer] = useState(false);

  // Update Users list
  useEffect(() => {
    updateProject({ ...project, users, isDataViewer });
  }, [users, isDataViewer]);

  const onSearch = () => {
    // Do not perform empty search
    if (search === '') return;

    // Clear error message
    setError('');
    setIsSearching(true);

    API.searchUser(search)
      .then(res => {
        setUsers([ ...users, res ]);
      })
      .catch(err => {
        console.error(err);
        setError(err);
      })
      .finally(() => setIsSearching(false));
  };

  const createUser = (createdUser) => {
    setUsers([ ...users, createdUser ]);
  };

  const removeUser = (uid) => {
    setUsers(users.filter(user => {
      return user.id !== uid
    }));
  };

  const toggleShowNewUser = () => {
    setShowNewUser(!showNewUser);
  }

  return (
    <>
      <SearchBox
        placeholder={'User Id or Email'}
        hasSubmit
        disabledButton={isSearching}
        className="input-gray"
        onChange={(word) => setSearch(word)}
        onSubmit={onSearch}/>
      <span className="error-message">{ error.code }</span>
      <div className="tags-container">
        {
          users && users.map(user => (
            <Tags
              key={user.id}
              value={user.displayName}
              hasCloseButton
              onClose={() => removeUser(user.id)} />
          ))
        }
      </div>
      <div className="new-user-box">
        <h2>Register New User</h2>
        { !showNewUser
          ? <div className="new-user-btn-container">
            <CustomButton classes="btn-success btn-new-user" title="+ Add new User" handleClick={toggleShowNewUser} />
            <p>Click the button to add another user</p>
          </div>
          : <div className="new-user-form-container">
            <UserForm onSave={createUser} onCancel={toggleShowNewUser}/>
          </div>
        }
      </div>
      <div className="new-user-box">
        <h2>Permissions</h2>
        <div className="new-user-form-container">
          <CustomCheckBox
            label={`Set User${users.length > 1 ? 's' : ''} as Data Viewer`}
            selected={isDataViewer ? 'all' : 'none'}
            onClick={(value) => setIsDataViewer(!value)} />
        </div>
      </div>
    </>
  )
};

export default Users;