import React, { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import IconSwitch from '../iconSwitch';
import Loading from '../loading';
import SearchBox from '../searchBox';
import DeviceList from './deviceList';
import DeviceGrid from './deviceGrid';
import API from '../../services/api';
import utils from '../../utils';
import paginationUtils from '../../utils/pagination';
import deviceUtils from '../../utils/device';

import './styles.scss';

const DeviceExplorer = ({ currentDevice, appliedFilters, viewMode, customWidth, onSelect, onViewModeChange, onDeviceLoad }) => {
  const [view, setView] = useState(viewMode);
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [searchBy, setSearchBy] = useState('');
  const [currentFilters, setCurrentFilters] = useState('');
  const [nextPageToken, setNextPageToken] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMoreContent, setIsLoadingMoreContent] = useState(true);

  const itemStatusMap = useRef(null);
  const rowStatusMap = useRef(null);
  const infiniteLoaderRef = useRef(null);

  const LOADING = 1;
  const LOADED = 2;
  const BATCH_ROW_SIZE = 10;
  const BATCH_SIZE = 40;
  const VIEW_OPTIONS = [
    { label: 'List', icon: 'list', value: 'list' },
    { label: 'Grid', icon: 'grid', value: 'grid' }
  ];

  useEffect(() => {
    setSelectedDevice(currentDevice);
  }, [currentDevice]);

  useEffect(() => {
    itemStatusMap.current = {};
    rowStatusMap.current = {};
    if (infiniteLoaderRef.current) {
      infiniteLoaderRef.current.resetloadMoreItemsCache();
    }

    const isGrid = view === 'grid';
    loadMoreItems(0, isGrid ? BATCH_SIZE - 1 : BATCH_ROW_SIZE - 1, isGrid, true);
    setIsLoading(true);
  }, [appliedFilters, searchBy]);

  const getQueryFromFilter = (filter) => {
    const operator = filter.operator ? filter.operator : '=';
    const value = filter.type === 'version' ? `"${filter.value}"` : filter.value;
    return `(${filter.path} ${operator} ${value})`;
  }

  const getFilters = () => {
    const filterList = appliedFilters.map(getQueryFromFilter);
    const filterQuery = (appliedFilters.length > 0) ? filterList.join(' AND ') : '';
    const searchQuery = (searchBy !== '') ? `(displayName ILIKE "%${searchBy}%" OR id ILIKE "%${searchBy}%")` : '';

    const hasFilterAndSearch = (searchQuery !== '' && filterQuery !== '') ? ' AND ' : '';

    return `${searchQuery}${hasFilterAndSearch}${filterQuery}`;
  };

  const fetchDevices = async (clearToken = false) => {
    const filter = encodeURI(getFilters());

    if (filter !== currentFilters) {
      setDevices([]);
    }

    return API.getDevices('-', clearToken ? '' : nextPageToken, filter).then(res => {
      const parsedDevices = deviceUtils.addInformation({ devices: res.devices });

      if (filter === '') {
        onDeviceLoad(res);
      }

      // Reset list when filter changes
      if (filter !== currentFilters) {
        setCurrentFilters(filter);
        setDevices(parsedDevices);
      } else {
        const newDevices = utils.mergeArraysByField(parsedDevices, devices, 'id');
        setDevices(newDevices);
        if (!currentDevice) {
          onDeviceSelected(newDevices[0]);
        }
      }

      return res.nextPageToken;
    });
  };

  const loadMoreItems = async (currentStartIndex, currentStopIndex, isGrid = false, clearToken = false) => {
    const startIndex = isGrid ? currentStartIndex * 4 : currentStartIndex;
    const stopIndex = isGrid ? currentStopIndex * 4 : currentStopIndex;

    const rowStartIndex = isGrid ? currentStartIndex : Math.floor(currentStartIndex / 4);
    const rowStopIndex = isGrid ? currentStopIndex : Math.floor(currentStopIndex / 4);

    setIsLoadingMoreContent(true);
    paginationUtils.setItemsStatus(startIndex, stopIndex, itemStatusMap.current, LOADING);
    paginationUtils.setItemsStatus(rowStartIndex, rowStopIndex, rowStatusMap.current, LOADING);

    // Perform fetch on parent component
    return fetchDevices(clearToken).then(newPageToken => {
      paginationUtils.setItemsStatus(startIndex, stopIndex, itemStatusMap.current, LOADED);
      paginationUtils.setItemsStatus(rowStartIndex, rowStopIndex, rowStatusMap.current, LOADED);
      setIsLoading(false);
      setIsLoadingMoreContent(false);
      setNextPageToken(newPageToken);
    });
  };

  const handleSearch = useDebouncedCallback((term) => {
    setIsLoading(true);
    setNextPageToken('');
    setSearchBy(term);
  }, 300);

  const onDeviceSelected = (device) => {
    setSelectedDevice(device);
    onSelect(device);
  };

  if (!currentDevice || isLoading) {
    return <Loading />
  }

  return (
    <div className="device-explorer">
      <div className="toolbar">
        <SearchBox placeholder="Search Device" className="outline" onChange={handleSearch}/>
        <IconSwitch
          onSelect={(view) => {
            setView(view);
            onViewModeChange(view);
          }}
          selectedOption={VIEW_OPTIONS.find(option => view === option.value)}
          options={VIEW_OPTIONS} />
      </div>
      {
        view === 'grid'
        ? (
          <DeviceGrid
            infiniteLoaderRef={infiniteLoaderRef}
            isLoading={isLoading}
            isLoadingMoreContent={isLoadingMoreContent}
            devices={devices}
            selectedDevice={selectedDevice}
            rowStatusMap={rowStatusMap}
            loadMoreItems={loadMoreItems}
            onSelect={onDeviceSelected}
            customWidth={customWidth - 250}/>
        )
        : (
          <DeviceList
            infiniteLoaderRef={infiniteLoaderRef}
            isLoading={isLoading}
            isLoadingMoreContent={isLoadingMoreContent}
            devices={devices}
            selectedDevice={selectedDevice}
            itemStatusMap={itemStatusMap}
            loadMoreItems={loadMoreItems}
            onSelect={onDeviceSelected} />
        )
      }
    </div>
  )
};

export default DeviceExplorer;