import Header from './header';
import DetailsDrawer from './detailsDrawer';
import Table from './table';
import MainNavigation from './mainNavigation';
import Loading from './loading';
import DateTimeFilterBar from './dateTimeFilterBar';
import DeviceDetails from './deviceDetails';
import Profile from './profile';
import CustomButton from './customButton';
import AnalyticsSelector from './analyticsSelector';
import SearchBox from './searchBox';
import ItemSelector from './itemSelector';
import ItemSelectorSingle from './itemSelectorSingle';
import MultiSelectDropDown from './multiSelectDropDown';
import CustomInput from './customInput';
import CustomTextArea from './customTextArea';
import Footer from './footer';
import CustomReport from './reportModal/customReport';
import QuickReport from './reportModal/quickReport';
import Login from './login/login';
import ForgotPassword from './forgotPassword/forgotPassword';
import UserDropdown from './userDropdown';
import CustomDropdown from './customDropdown';
import Logout from './logout';
import Signup from './signup';
import PreviewModal from './previewModal';
import ManageZones from './manageZones';
import Tabs from './tabs';
import CustomToogle from './customToogle';
import ModelsConfiguration from './modelsConfiguration';
import CustomConfirm from './customConfirm';
import CheckBoxModel from './checkBoxModel';
import MultiSelector from './multiSelector';
import ReportDetails from './reportDetails';
import Toolbar from './toolbar';
import Card from './card';
import CustomCheckBox from './customCheckBox';
import IconButton from './iconButton';
import Timezone from './timezone';
import DropdownSearch from './dropdownSearch';
import DropdownMenuList from './dropdownMenuList';
import CopyButton from './copyButton';
import Wizard from './wizard';
import Tags from './tags';
import ProjectSelector from './projectSelector';
import SolutionSelector from './solutionSelector';
import ItemNotification from './itemNotification';
import CustomTag from './customTag';
import OffcanvasDrawer from './offcanvasDrawer';
import NotificationSettings from './notificationSettings';
import NotificationTools from './notificationTools';
import EmptyState from './emptyState';
import TimezoneInfo from './timezoneInfo';
import CheckBoxCard from './checkBoxCard';
import CustomRangeInput from './customRangeInput';
import EditButton from './editButton';
import Widget from './widget';
import ReloadButton from './reloadButton';
import SidePanel from './sidepanel';
import GeneralInput from './generalInput';
import ActionButtons from './actionButtons';
import MultipleFilters from './multipleFilters';
import StatusBar from './statusBar';
import DeviceStatus from './deviceStatus';
import DeviceExplorer from './deviceExplorer';
import IconSwitch from './iconSwitch';
import Comment from './comments';
import IconTooltip from './iconTooltip';

const components = {
  //------------------- JS -Objects {}
  DeviceDetails,
  Profile,
  ReportDetails,
  SearchBox,
  ItemSelectorSingle,
  ManageZones,
  //------------------- Component Objects (class)
  MainNavigation,
  ItemSelector,
  Login,
  ForgotPassword,
  UserDropdown,
  Logout,
  CustomCheckBox,
  //------------------- Functional Components (hooks)
  Signup,
  Timezone,
  DropdownSearch,
  CustomDropdown,
  DropdownMenuList,
  TimezoneInfo,
  Header,
  DetailsDrawer,
  Table,
  Loading,
  DateTimeFilterBar,
  CustomButton,
  AnalyticsSelector,
  CustomInput,
  CustomTextArea,
  Footer,
  CustomReport,
  QuickReport,
  PreviewModal,
  Tabs,
  CustomToogle,
  ModelsConfiguration,
  CustomConfirm,
  CheckBoxModel,
  MultiSelector,
  Toolbar,
  Card,
  IconButton,
  CopyButton,
  Wizard,
  Tags,
  ProjectSelector,
  SolutionSelector,
  ItemNotification,
  MultiSelectDropDown,
  CustomTag,
  OffcanvasDrawer,
  NotificationSettings,
  NotificationTools,
  EmptyState,
  CheckBoxCard,
  CustomRangeInput,
  EditButton,
  Widget,
  ReloadButton,
  SidePanel,
  GeneralInput,
  ActionButtons,
  MultipleFilters,
  StatusBar,
  DeviceStatus,
  DeviceExplorer,
  IconSwitch,
  Comment,
  IconTooltip
};

export default components;
