import React, { useEffect, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import icons from '../../assets/icons';
import './styles.scss';

const Tabs = ({ pages, classes, selectedTab, onTabsChange }) => {
  const [currentTab, setCurrentTab] = useState(selectedTab ? selectedTab : 0);

  useEffect(() => {
    setCurrentTab(selectedTab ? selectedTab : 0);
  }, [selectedTab]);

  const handleClick = tab => {
    if (onTabsChange) {
      onTabsChange(tab);
    }
    setCurrentTab(tab);
  };

  const renderTitle = title => {
    return (
      <div className="tab-title">
        <div className="tab-icon">
          <img src={icons[title.icon]} alt={title.icon} />
        </div>
        <div className="d-flex flex-column align-items-start">
          <h4 className="tab-title-name">{title.name}</h4>
          <h5 className="tab-title-description">{title.description}</h5>
        </div>
      </div>
    );
  };

  if (!pages) {
    return null;
  }

  return (
    <div className={`tabs ${classes}`}>
      <div className="tabs-header">
        <div className="tabs-button-container">
          {pages.tabs.map((page, index) => (
            <button
              key={index}
              onClick={() => handleClick(index)}
              className={`btn btn-tab ${currentTab === index ? 'selected' : ''} ${page.notify ? 'notify' : ''}`}
            >
              {page.title ? renderTitle(page.title) : page.name}
            </button>
          ))}
        </div>
        {pages.buttons && (
          <div className="align-self-center">
            {pages.buttons.map((page, index) => (
              <button
                id={`btn-open-modal-${index}`}
                key={index}
                onClick={() => (page.disabled ? {} : page.handleClick())}
                className={`btn btn-open-modal ${page.disabled ? 'disabled' : ''} ${page.notify ? 'notify' : ''}`}
              >
                <>
                  {page.icon && <i className={page.icon} />} {page.name}
                </>
                {page.disabled && (
                  <UncontrolledTooltip placement="bottom" target={`btn-open-modal-${index}`}>
                    {page.disabledText}
                  </UncontrolledTooltip>
                )}
              </button>
            ))}
          </div>
        )}
      </div>
      <div className="tabs-body">{pages.tabs[currentTab].content}</div>
    </div>
  );
};

export default Tabs;
