import React, { useState } from 'react';
import Tags from '../../../components/tags';
import utils from '../../../utils';
import DeviceSelector from '../../../components/filterSelector/DeviceSelector';

const Devices = ({ companyId, site, updateSite }) => {
  const [selectedDevices, setSelectedDevices] = useState(site.attachedDevices ? site.attachedDevices : []);

  const handleSelection = deviceSelection => {
    const localSelectedDevices = utils.deepClone(selectedDevices);

    if (selectedDevices.some(device => device === deviceSelection)) {
      removeDevice(deviceSelection);
    } else {
      localSelectedDevices.push(deviceSelection);
      setSelectedDevices(localSelectedDevices);
      updateSite({ ...site, attachedDevices: localSelectedDevices });
    }
  };

  const removeDevice = tag => {
    let localSelectedDevices = utils.deepClone(selectedDevices);
    localSelectedDevices = localSelectedDevices.filter(device => device !== tag);
    setSelectedDevices(localSelectedDevices);
    updateSite({ ...site, attachedDevices: localSelectedDevices });
  };

  return (
    <>
      <h4 className="section-label">
        Search existing devices or <a href="/devices">create a new devices</a>
      </h4>
      <div className="form-group site-device-section">
        <label>Devices</label>
        <DeviceSelector
          selected={{ label: 'Select Device'}}
          companyId={companyId}
          onSelect={deviceSelected => handleSelection(deviceSelected.replaceAll('"', ''))}
          filterObject={{}} />
      </div>
      <div className="device-tags">
        {selectedDevices.map(tag => {
          return (
            <Tags
              key={`tag-${utils.toCamelCase(tag)}`}
              value={tag}
              hasCloseButton
              onClose={() => removeDevice(tag)}
            />
          );
        })}
      </div>
    </>
  );
};

export default Devices;
