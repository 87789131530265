import React from 'react';
import icons from '../../assets/icons';

const HelpfulLinks = () => {
  const helpfulLinks = [
    {
      label: 'Support',
      url: 'mailto:support@admobilize.com',
      labelUrl: "support@admobilize.com",
      isExternal: false
    },
    {
      label: 'Documentation',
      url: 'https://documentation.admobilize.com',
      isExternal: true
    }
  ];

  return (
    <div className="profile-cards helpful-links">
      <div className="card">
        <div className="card-header">
          <img src={icons.externalLinkDark} alt="user icon"/>
          <h3>Links</h3>
        </div>
        <div className="card-body">
          {
            helpfulLinks.map((link, i) => (
              <div className="link" key={i}>
                <h4>{link.label}</h4>
                <a
                  href={link.url}
                  target={link.isExternal ? '_blank' : '_self'}
                  rel="noreferrer">
                  {link.labelUrl || link.url}
                </a>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}

export default HelpfulLinks;