import dashboardSideFX from './dashboard';
import notificationSideFX from './notification';
import userSideFX from './user';
import { spawn } from 'redux-saga/effects';

function* sideFX() {
  yield spawn(dashboardSideFX);
  yield spawn(notificationSideFX);
  yield spawn(userSideFX);
}

export default sideFX;