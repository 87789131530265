import React from 'react';
import steps from './newProjectSteps/index.js';

const { Details, Users, Devices, Preferences } = steps;

const newProjectSteps = (newProject, setNewProject) => {
  return [
    {
      title: 'Details',
      description: 'Fill up Project main info',
      required: true,
      validation: () => {
        if (newProject) {
          // New project must have no errors to proceed
          for (const key in newProject.errors) {
            if (newProject.errors[key].length > 0) {
              return false;
            }
          }
          // Prevent empty value (from before changes were made)
          return newProject.displayName;
        }
        // Validation requires newProject to exist
        return false;
      },
      content: <Details project={newProject} updateProject={setNewProject}/>
    },
    {
      title: 'Users',
      description: 'Add users to this project',
      required: false,
      modified: () => (newProject && newProject.users && newProject.users.length > 0),
      content: <Users project={newProject} updateProject={setNewProject}/>
    },
    {
      title: 'Devices',
      description: 'Move devices from another project',
      required: false,
      modified: () => (newProject && newProject.devices && newProject.devices.length > 0),
      content: <Devices
        project={newProject}
        updateProject={setNewProject} />
    },
    {
      title: 'Preferences',
      description: 'Set up this project preferences',
      required: false,
      content: <Preferences
        editMode
        hasButtons={false}
        selectedProject={newProject}
        updateProject={setNewProject}/>
    }
  ];
};

export default newProjectSteps;