import React, { useState, useEffect } from 'react';
import CustomToogle from '../customToogle';

const CheckBoxModel = ({ id, models, isEditable, label, modelDefault, setModels, onChange }) => {

  const [selected, setSelected] = useState(false);

  useEffect(() => {
    const modelFound = models.find(model => model.type === modelDefault.type);
    setSelected(!!modelFound);
  }, [models, modelDefault.type]);

  const onValueChange = () => {
    const newValue = !selected;
    let newModelsList = null;

    if (newValue) {
      newModelsList = [...models, { ...modelDefault }];
    } else {
      newModelsList = models.filter((model) => model.type !== modelDefault.type);
    }

    setModels(newModelsList);
    if (onChange) onChange(newValue, newModelsList);
  }

  return (
    <CustomToogle
      id={id}
      label={label}
      disabled={!isEditable}
      onChange={onValueChange}
      checked={!!selected} />
  )
}

export default CheckBoxModel;
