import React from 'react';

const VirtualRange = ({ position, disabled }) => {
  return (
    <div className={`virtual-range${disabled ? ' disabled' : ''}`}>
      <div className="bar-bg"></div>
      <div className="bar-fill" style={{ width: position }}></div>
      <div className="indicator" style={{ left: `calc(${position} - 12px)` }}></div>
    </div>
  );
};

export default VirtualRange;