import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import CustomButton from '../../components/customButton';
import CustomDropdown from '../../components/customDropdown';
import CustomInput from '../../components/customInput';

const CreatePresetModal = ({ isOpen, toggleModal, onCreate, presets }) => {
  const [presetName, setPresetName] = useState('');
  const [solution, setSolution] = useState('');
  const [copyPreset, setCopyPreset] = useState('');
  const [presetsList, setPresetsList] = useState([]);

  useEffect(() => {
    setCopyPreset('');
    setPresetsList(presets.filter(item => item.solutionId === solution));
  }, [solution]);

  const supportedSolutions = [
    { id: 'FACEV2', name: 'Audience' },
    { id: 'CROWDV3', name: 'Crowd' },
    { id: 'VEHICLECROWD', name: 'Vehicle Crowd' },
    { id: 'VEHICLEDETECTIONV1', name: 'Vehicle Detection' },
    { id: 'VEHICLERECOGNITIONV1', name: 'Vehicle Recognition' }
  ];

  const clonePreset = id => {
    const clonedPreset = presetsList.find(item => item.id === id);
    setCopyPreset({ ...clonedPreset.config });
  };

  const closeModal = () => {
    setPresetName('');
    setSolution('');
    setPresetsList([]);
    setCopyPreset('');
    toggleModal();
  };

  return (
    <Modal className="create-preset-modal" isOpen={isOpen} fade={false} toggle={toggleModal} centered>
      <ModalHeader className="header-gray" toggle={toggleModal} tag="div">
        <h5>Create a New Preset</h5>
        <div className="modal-subtitle">{`Choose a name, a solution and an existing preset to create a new one`}</div>
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>Name</label>
                <CustomInput
                  id="preset-name"
                  type="string"
                  placeholder="Preset Name"
                  value={presetName}
                  onChange={e => setPresetName(e.target.value)}
                  isEditable
                  autoFocus
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="solution-dropdown form-group">
                <label>Solution</label>
                <CustomDropdown
                  items={supportedSolutions}
                  valueField={'id'}
                  displayField={'name'}
                  handleSelection={event => setSolution(event.target.value)}
                  staticTitle={solution ? solution.name : 'Select a Solution'}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="preset-dropdown form-group">
                <label>Copy Preset</label>
                <CustomDropdown
                  items={presetsList}
                  valueField={'id'}
                  displayField={'displayName'}
                  disabled={!solution}
                  handleSelection={event => clonePreset(event.target.value)}
                  staticTitle={copyPreset ? copyPreset.displayName : 'Select a Preset'}
                />
              </div>
            </div>
          </div>
        </form>
        <div className="button-container">
          <CustomButton title="Cancel" classes="btn-secondary" handleClick={closeModal} />
          <CustomButton
            title="Create Preset"
            classes="btn-primary"
            handleClick={() => {
              onCreate(presetName, solution, copyPreset);
              closeModal();
            }}
            disabled={!solution || !presetName || !copyPreset}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CreatePresetModal;
