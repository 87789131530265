import config from '../config';
import API from './api';

const { baseUrl } = config.iamApi;

const getPolicy = (resource) => {
  const url = `${baseUrl}/${resource}:getPolicy`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};
const setPolicy = (resource, body) => {
  const url = `${baseUrl}/${resource}:setPolicy`;
  return fetch(url, {
    headers: API.headers,
    method: 'POST',
    body: JSON.stringify(body)
  }).then(res => API.generateResponse(res));
};

const testPermission = (entity, id, permissions) => {
  const url = `${baseUrl}/resources:testPermissions`;
  return fetch(url, {
    headers: API.headers,
    method: 'POST',
    body: JSON.stringify({
      resource: `${entity}/${id}`,
      permissions
    })
  }).then(res => API.generateResponse(res));
};

export default {
  getPolicy,
  setPolicy,
  testPermission
};