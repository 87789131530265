import React from 'react';

import IconButton from '../iconButton';
import icons from '../../assets/icons';

import './styles.scss';

const Header = ({ title, icon, filters, actions }) => (
  <div id="header" className="d-flex flex-row">
    <div className="header-title">
      <img src={icons[icon]} alt={title} />
      <h1>{title}</h1>
    </div>
    <div className={`header-tools w-100`}>
      <div className={`${filters ? "justify-content-between" : "justify-content-end"} d-flex w-100`}>
        {
          filters &&
          <div className="filters">{filters}</div>
        }
        <div className="d-flex header-actions">
          {
            actions && actions.map((action, key) => {
              return action.type && action.type === "reactComponent"
                ? (action.value)
                : (<IconButton
                    key={key}
                    id={`${action.icon}-${key}`}
                    imgClasses={action.className}
                    icon={action.icon}
                    onPress={action.onPress}
                    tooltip={action.tooltip} />)
            })
          }
        </div>
      </div>
    </div>
  </div>
);

export default Header;