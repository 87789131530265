import React from 'react';
import icons from '../../assets/icons';
import CustomInput from '../customInput';

const Projects = ({ projects }) => {

  return (
    <div className="profile-cards projects">
      <div className="card">
        <div className="card-header">
          <img src={icons.folder} alt="folder icon" /><h3>Projects</h3>
        </div>
        <div className="card-body">
          <div className="sm-table">
            <div className="sm-table-header row">
              <div className="col-3">Name</div>
              <div className="col-4">Id</div>
              <div className="col-5">Provisioning Token</div>
            </div>
            <div className="sm-table-body">
              {
                projects.map((project, index) => (
                  <div className="sm-table-item row" key={index}>
                    <div className="col-3">
                      <div className="form-group ellipsis">
                        <CustomInput
                          id={`project-name-${index}`}
                          type="text"
                          value={project.displayName} />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group ellipsis">
                        <CustomInput
                          id={`project-id-${index}`}
                          type="text"
                          value={project.id}
                          isCopyButtonEnabled />
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="form-group ellipsis">
                        <CustomInput
                          id={`profile-provisioning-token-${index}`}
                          type="text"
                          value={project.provisioningToken || "--"}
                          isCopyButtonEnabled />
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;