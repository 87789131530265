import API from '../../services/api';

/**
 * Run command on API
 *
 * @param {String} command - command to be sent
 * @param {Array} devices - list of  the devices that will receive the command
 * @param {string} companyId - user's company id
 * @param {function} setIsCommandRunning - Function so set a state that command is running
 * @callback onSuccess - Function to excute after Success on sending command
 * @callback onError - Function to excute after Error on sending command
 */
const sendCommand = (command, devices, companyId, setIsCommandRunning, onSuccess, onError) => {
  if (devices.length === 0) {
    if (onError) onError(new Error("No devices to send command to"));
    if (setIsCommandRunning) setIsCommandRunning(false);
    return;
  }

  if (setIsCommandRunning) setIsCommandRunning(true);

  const promises = devices.map(device => 
    API.sendCommand(companyId, device.id, command)
  );

  return Promise.all(promises)
    .then(() => {
      onSuccess(command);
    })
    .catch(error => {
      onError(error);
    })
    .finally(() => {
      if (setIsCommandRunning) setIsCommandRunning(false);
    });
};

export default sendCommand;
