import React from 'react';
import icons from '../../assets/icons';
import components from '../../components';

const { Card } = components;
const widgetList = [
  {
    name: 'Total Licenses',
    type: 'digit',
    key: 'totalLicenses'
  },
  {
    name: 'Assigned Licenses',
    type: 'digit',
    key: 'assignedLicenses'
  },
  {
    name: 'Expired Licenses',
    type: 'digit',
    key: 'expiredLicenses'
  },
  {
    name: 'Total Devices',
    type: 'digit',
    key: 'totalDevices'
  },
  {
    name: 'Devices Status',
    type: 'stats',
    key: 'deviceStatus'
  }
];

const aggregateStatus = (status) => {
  const newStatus = [
    { label: 'online', value: 0 },
    { label: 'offline', value: 0 },
    { label: 'stopped', value: 0 }
  ];

  status.forEach(stat => {
    switch (stat.label) {
      case 'detecting':
        newStatus[0].value += stat.value;
        break;
      case 'offline':
        newStatus[1].value += stat.value;
        break;
      case 'inactive':
        newStatus[2].value += stat.value;
        break;
      case 'no-camera':
        newStatus[2].value += stat.value;
        break;
      default:
        break;
    }
  });

  return newStatus;
};

const renderDigit = (data, widget) => (
  <display-digit
    id={widget.key}
    data={JSON.stringify({
      label: data[widget.name],
      value: data[widget.key]
    })} />
);

const renderStats = (data, widget, isLoading) => {
  if (isLoading) {
    return (<p>Loading...</p>);
  }

  return (
    <display-stats-list
      id={widget.key}
      icon-map={JSON.stringify({
        Online: icons.online,
        Offline: icons.offline,
        Stopped: icons.stopped
      })}
      data={JSON.stringify(aggregateStatus(data[widget.key]))} />
  );
};

const WidgetRow = ({ data, isLoading }) => {
  if (!data) return null;

  return (
    <div className="widgets-row">
      {
        widgetList.map(widget => (
          <Card
            key={widget.key}
            header={(
              <div>
                <h3>{widget.name}</h3>
              </div>
            )}
            body={(
              (widget.type === 'digit')
              ? renderDigit(data, widget)
              : renderStats(data, widget, isLoading)
            )}
          />
        ))
      }
    </div>
  );
};

export default WidgetRow;