import { Buffer } from 'buffer/';

const LOCAL_STORAGE_ITEM_NAME = 'x';
const LOCAL_STORAGE_IMPERSONATOR = 'userImpersonated';
const LOCAL_PROJECTS_DATA = 'projectsData';

const decodeBase64 = (str, encoding) => {
  const unescape = str => {
    return (str + '==='.slice((str.length + 3) % 4))
      .replace(/-/g, '+')
      .replace(/_/g, '/');
  };

  return Buffer.from(unescape(str), 'base64').toString(encoding || 'utf8');
};

const get = () => {
  const localCredentials = window.localStorage.getItem(LOCAL_STORAGE_ITEM_NAME);
  return JSON.parse(decodeBase64(localCredentials));
};

const put = newCredentials => {
  window.localStorage.setItem(LOCAL_STORAGE_ITEM_NAME, window.btoa(JSON.stringify(newCredentials)));
};

const purge = () => {
  window.localStorage.removeItem(LOCAL_STORAGE_ITEM_NAME);
  window.localStorage.removeItem(LOCAL_STORAGE_IMPERSONATOR);
  window.localStorage.removeItem(LOCAL_PROJECTS_DATA);
};

const parseJwtPayload = jwt => JSON.parse(decodeBase64(jwt.split('.')[1]));

export default {
  get,
  put,
  purge,
  parseJwtPayload
};
