import AnalyticsScreen from './analytics';
import DevicesScreen from './devices';
import ProjectsScreen from './projects';
import SummaryScreen from './summary';
import Authentication from './authentication/authentication.js';
import Profile from './profile';
import AdvancedScreen from './advanced';
import ReportsScreen from './reports';
import InsightScreen from './analytics/insightScreen.js';
import NotFoundScreen from './404';
import NotificationsScreen from './notifications';
import IntegrationsScreen from './integrations';
import LicensesScreen from './licenses';
import PresetsScreen from './presets';
import SitesScreen from './sites';
import SiteGroupsScreen from './sitegroups';

export default {
  AnalyticsScreen,
  DevicesScreen,
  ProjectsScreen,
  SummaryScreen,
  Authentication,
  Profile,
  AdvancedScreen,
  InsightScreen,
  NotFoundScreen,
  ReportsScreen,
  NotificationsScreen,
  IntegrationsScreen,
  LicensesScreen,
  PresetsScreen,
  SitesScreen,
  SiteGroupsScreen
};
