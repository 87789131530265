import { format, parseISO, add, formatDistance } from 'date-fns';
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import utils from '../../utils';

const columns = [
  {
    Header: 'Name',
    id: 'name',
    type: 'string',
    accessor: report => renderName(report.name, 'name', report.id)
  },
  {
    Header: 'Email',
    id: 'email',
    type: 'string',
    accessor: report => renderName(report.email, 'email', report.id)
  },
  {
    Header: 'Project',
    id: 'projectName',
    type: 'string',
    accessor: report => renderName(report.projectName, 'project', report.id)
  },
  {
    Header: 'Frequency',
    id: 'frequency',
    type: 'string',
    accessor: report => report.frequency
  },
  {
    Header: 'Status',
    id: 'status',
    type: 'string',
    accessor: report => {
      if (report.status) {
        const statusClass = report.status === 'active' ? 'success' : 'danger';
        return (
          <span className={`status badge badge-pill badge-outline-${statusClass}`}>
            {utils.capitalize(report.status)}
          </span>
        );
      }
      return <span className="status badge badge-pill badge-outline-secondary">Undefined</span>;
    }
  },
  {
    Header: 'Last Sent',
    id: 'lastSent',
    type: 'string',
    accessor: report =>
      report.lastSent
        ? formatDistance(new Date(parseISO(report.lastSent)), new Date(), {
            addSuffix: true
          })
        : '--'
  },
  {
    Header: 'File Expiration Date',
    id: 'expirationDate',
    type: 'string',
    accessor: report =>
      report.lastSent ? format(add(new Date(parseISO(report.lastSent)), { days: 30 }), 'MM/dd/yyyy') : '--'
  }
];

const renderName = (name, fieldName, key) => {
  return (
    <div className="ellipsis">
      <div id={`${fieldName}-${key}`}>{name}</div>
      <UncontrolledTooltip placement="left" target={`#${fieldName}-${key}`}>
        {name}
      </UncontrolledTooltip>
    </div>
  );
};

export default columns;
