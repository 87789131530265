import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getUser, setAlert } from '../../redux/dashboard/actions';
import types from '../../redux/types';

import containers from '../../containers';
import components from '../../components';
import NotificationTools from '../../components/notificationTools';
import ReloadButton from '../../components/reloadButton';
import TimezoneInfo from '../../components/timezoneInfo';
import utils from '../../utils';

import './styles.scss';

const { Header } = components;
const { ProjectsContainer } = containers;

const ProjectsScreen = () => {
  const user = useSelector(getUser);
  const dispatch = useDispatch();

  const reloadOnPress = () => {
    dispatch(setAlert(utils.generateAlert('Project list was updated','success')));
    dispatch({
      type: types.SET_USER,
      payload: user
    });
  }

  return (
    <div>
      <Header
        title={'Management'} icon={'settingsDark'}
        actions={[
          { type: "reactComponent", value: <TimezoneInfo key="timezone-info" /> },
          {
            type: "reactComponent",
            value: <ReloadButton
              key="reload-btn"
              contentName="Project list"
              onPress={reloadOnPress} />
          },
          { type: "reactComponent", value: <NotificationTools key="notification-btn" /> }
        ]} />
      <ProjectsContainer/>
    </div>
  );
}

export default ProjectsScreen;
