import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import components from '../../components';
import API from '../../services/api';
import utils from '../../utils';
import icons from '../../assets/icons';

import './styles.scss';
import types from '../../redux/types';

const {
  ItemNotification,
  CustomButton,
  MultiSelectDropDown,
  CustomToogle,
  CustomTag,
  OffcanvasDrawer,
  NotificationSettings
} = components;

const NotificationContainer = ({ notifications, isOpenNotificationSettings, setIsOpenNotificationSettings }) => {
  const [selectedItem, setSelectedItem] = useState('inbox');
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [onlyUrgent, setOnlyUrgent] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  const dispatch = useDispatch();
  const notificationTypes = useSelector(selector => selector.notificationReducer.notificationTypes);

  useEffect(() => {
    setFilteredNotifications(
      notifications
        .filter(notification => (
            (selectedItem === 'archived' && notification.archived) ||
            (selectedItem === 'inbox' && !notification.archived)
          ))
        .filter(
          notification =>
            selectedFilters.length === 0 ||
            (notification.service && selectedFilters.includes(notification.service.toUpperCase()))
        )
        .filter(notification => (onlyUrgent ? notification.priority == 3 : true))
    );
  }, [notifications, selectedItem, filterItems, selectedFilters, onlyUrgent]);

  useEffect(() => {
    const filter = {};
    notificationTypes.forEach(type => {
      filter[type.id] = 0;
    });

    notifications
      .filter(
        notification =>
          (selectedItem === 'archived' && notification.archived) || (selectedItem === 'inbox' && !notification.archived)
      )
      .filter(notification => !onlyUrgent || notification.priority == 3)
      .forEach(notification => {
        if (notification.service) {
          const serviceName = notification.service.toUpperCase();
          if (filter[serviceName]) {
            filter[serviceName]++;
          } else {
            filter[serviceName] = 1;
          }
        }
      });
    setFilterItems(
      Object.keys(filter).map(item => ({
        label: `${utils.capitalize(item)} (${filter[item]})`,
        value: item
      }))
    );
  }, [notifications, selectedItem, onlyUrgent, notificationTypes]);

  const countNotifications = archived => {
    const total = notifications.filter(n => n.archived == archived).length;
    return total > 0 ? `(${total})` : '';
  };

  const countUrgent = () => {
    const total = filteredNotifications.filter(n => n.priority == 3).length;
    return total > 0 ? `(${total})` : '';
  };

  const onSelectFilter = value => {
    if (selectedFilters.includes(value)) {
      setSelectedFilters(selectedFilters.filter(filterValue => value !== filterValue));
    } else {
      setSelectedFilters([...selectedFilters, value]);
    }
  };

  const archiveNotifications = async (selectedNotifications, archive) => {
    try {
      setIsBusy(true);
      await API.archiveNotification(
        selectedNotifications.map(notification => notification.id),
        archive
      );
      dispatch({
        type: types.LOAD_NOTIFICATIONS,
        payload: notifications.map(notification => {
          if (selectedNotifications.find(currentNotification => currentNotification.id == notification.id)) {
            return { ...notification, archived: archive };
          }
          return notification;
        })
      });
      dispatch({
        type: types.SET_ALERT,
        payload: utils.generateAlert(archive ? 'Notification Archived!' : 'Notification Sent to Inbox!', 'success')
      });
      setIsBusy(false);
    } catch (err) {
      dispatch({
        type: types.SET_ALERT,
        payload: utils.generateAlert('Something went wrong, try again later!', 'error')
      });
      setIsBusy(false);
      console.error(err);
    }
  };

  const deleteNotifications = async selectedNotifications => {
    try {
      setIsBusy(true);
      await API.deleteNotification(selectedNotifications.map(notification => notification.id));
      const payload = notifications.filter(notification => {
        return !selectedNotifications.some(selectedNotifications => selectedNotifications.id === notification.id);
      });
      dispatch({
        type: types.LOAD_NOTIFICATIONS,
        payload
      });
      dispatch({
        type: types.SET_ALERT,
        payload: utils.generateAlert('Notification Deleted!', 'success')
      });
      setIsBusy(false);
    } catch (err) {
      dispatch({
        type: types.SET_ALERT,
        payload: utils.generateAlert('Something went wrong, try again later!', 'error')
      });
      setIsBusy(false);
      console.error(err);
    }
  };

  return (
    <div id="notifications" className="flex-column flex-fill">
      <div className="notification-container h-100">
        <div className="notification-menu">
          <div className="d-flex flex-column justify-content-between flex-fill">
            <div className="d-flex flex-column">
              <div className="d-flex flex-column filter-block">
                <h5 className="menu-title">Status:</h5>
                <div className={`menu-item ${selectedItem === 'inbox' ? 'selected' : ''}`}>
                  <CustomButton
                    title={`Inbox ${countNotifications(false)}`}
                    classes="btn-light d-flex"
                    icon="message"
                    handleClick={() => setSelectedItem('inbox')}
                  />
                </div>
                <div className={`menu-item ${selectedItem === 'archived' ? 'selected' : ''}`}>
                  <CustomButton
                    title={`Archived ${countNotifications(true)}`}
                    classes="btn-light d-flex"
                    icon="archive"
                    handleClick={() => setSelectedItem('archived')}
                  />
                </div>
              </div>

              <div className="d-flex flex-column filter-block">
                <h5 className="menu-title">Filter by:</h5>
                <MultiSelectDropDown
                  isOpen={false}
                  items={filterItems}
                  onOptionSelected={onSelectFilter}
                  selectedItems={selectedFilters}
                  clearFilters={() => setSelectedFilters([])}
                  additionalFilter={
                    <div className="only-urgent-actions">
                      <CustomToogle
                        id="only-urgent"
                        label={`Only Urgents ${countUrgent()}`}
                        className="danger dinamic-label-color"
                        onChange={() => setOnlyUrgent(!onlyUrgent)}
                        checked={onlyUrgent}
                      />
                    </div>
                  }
                />
                <div>
                  {filterItems
                    .filter(item => selectedFilters.includes(item.value))
                    .map((item) => (
                      <CustomTag key={item.value} value={item.label} onClose={() => onSelectFilter(item.value)} />
                    ))}
                  {onlyUrgent && <CustomTag value="Only Urgents" onClose={() => setOnlyUrgent(false)} />}
                </div>
              </div>
            </div>
            <div className="remove-notification">
              {selectedItem === 'inbox' ? (
                <button className="btn btn-warning" onClick={() => archiveNotifications(filteredNotifications, true)}>
                  Archive all ({filteredNotifications.length})
                </button>
              ) : (
                <button className="btn btn-warning" onClick={() => deleteNotifications(filteredNotifications)}>
                  Delete all ({filteredNotifications.length})
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="notifications-list">
          {filteredNotifications.length > 0 ? (
            filteredNotifications.map((notification) => (
              <ItemNotification
                key={notification.id}
                notification={notification}
                onArchive={archiveNotifications}
                onDelete={deleteNotifications}
                isBusy={isBusy}
              />
            ))
          ) : (
            <div className="no-notification">
              <img src={icons.noNotifications} width={150} />
              <h3>There is nothing here yet.</h3>
              <p>You have no notifications that matches your filter(s).</p>
            </div>
          )}
        </div>
      </div>
      <OffcanvasDrawer
        isOpen={isOpenNotificationSettings}
        handleClose={() => setIsOpenNotificationSettings(false)}
        title="Notification Settings"
        body={<NotificationSettings onUpdate={() => setIsOpenNotificationSettings(false)} />}
      />
    </div>
  );
};

export default NotificationContainer;
