// Get the distance between 2 points
const diff2Points = (A, B) => {
  const diffX = A.x - B.x;
  const diffY = A.y - B.y;
  return parseInt(Math.sqrt((diffX * diffX) + (diffY * diffY)));
}

const getTheta = (A, B) => {
  const dX = B.x - A.x;
  const dY = B.y - A.y;
  const theta = Math.atan2(dY, dX);
  return theta;
}

const getArrowPoints = (A, B) => {
  const height = 20;
  const theta = getTheta(A, B);
  const arrows = [];

  const arrow1 = [
    A.x - (height/2) * Math.sin(theta),
    A.y + (height/2) * Math.cos(theta),
    A.x + (height/2) * Math.sin(theta),
    A.y - (height/2) * Math.cos(theta)
  ];
  arrows.push(arrow1);

  const arrow2 = [
    B.x - (height/2) * Math.sin(theta),
    B.y + (height/2) * Math.cos(theta),
    B.x + (height/2) * Math.sin(theta),
    B.y - (height/2) * Math.cos(theta)
  ];
  arrows.push(arrow2);

  return arrows;
}

const getTextPosition = (A, B) => {
  const theta = getTheta(A, B);
  const pos = {
    x: A.x + 10 * Math.sin(theta) + 10 * Math.cos(theta),
    y: A.y - 10 * Math.cos(theta) + 10 * Math.sin(theta),
    theta
  };
  return pos;
}

const helpers = { diff2Points, getArrowPoints, getTextPosition };

export default helpers;