import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import types from '../../../redux/types';
import API from '../../../services/api';
import utils from '../../../utils';
import components from '../../../components';
import './styles.scss';

const {
  CustomCheckBox,
  IconButton,
  SearchBox,
  DropdownSearch,
  CustomButton
} = components;

const Devices = ({ project, projects, loadProjects, updateCounters, isAdmin }) => {
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [intefaceBlocked, setIntefaceBlocked] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [searchedDevices, setSearchedDevices] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;
    if (isMounted && project) {
      setLoading(true);
      const filters = isAdmin ? ['page_size=-1'] : ['page_size=-1', 'filter=archived=false'];
      API.getDevices(
        project.id,
        ['id', 'name', 'display_name', 'archived'],
        filters
      )
      .then(res => {
        const devicelist = res.devices.map(device => {
          return device.archived
            ? { ...device, displayName: `(ARCHIVED) ${device.displayName}`}
            : device
        });
        setDevices(devicelist);
        setSearchedDevices(devicelist);
        setSelectedDevices([]);
        updateCounters('deviceCounter', devicelist.length);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
    }
    return () => { isMounted = false };
  }, [project]);

  const toggleSelection = (deviceId, remove) => {
    if (remove) {
      setSelectedDevices(
        [...selectedDevices.filter((device) => device !== deviceId)]
      );
    } else {
      selectedDevices.push(deviceId);
      setSelectedDevices([ ...selectedDevices ]);
    }
  };

  const selectAll = (value) => {
    // Select all devices
    if (value) {
      setSelectedDevices(searchedDevices.map(device => device.id));
    } else {
      // Deselect all devices
      setSelectedDevices([]);
    }
  };

  const onSearch = (searchBy) => {
    if (searchBy !== '') {
      const searchedDevices = devices.filter(device => {
        const deviceHas = option => device[option].toUpperCase().includes(searchBy.toUpperCase());
        return deviceHas('displayName') || deviceHas('id');
      });
      setSearchedDevices(searchedDevices);
    } else {
      setSearchedDevices(devices);
    }
  };

  const isSelected = (deviceId) => {
    return selectedDevices.some(device => device === deviceId);
  };

  const displayTransferModal = () => {
    if (selectedDevices.length > 0) {
      toggle();
    }
  }

  const moveDevices = () => {
    const data = {
      destination_project_id: selectedProject,
      device_ids: selectedDevices
    };
    setIntefaceBlocked(true);

    API.moveDevices(project.id, data).then(() => {
      dispatch({
        type: types.SET_ALERT,
        payload: utils.generateAlert(`Devices moved to selected Project!`, 'success')
      });
      loadProjects();
    }).catch(err => {
      dispatch({
        type: types.SET_ALERT,
        payload: utils.generateAlert(`Devices couldn't be moved to selected Project!`, 'error')
      });
      console.error(err);
    }).finally(() => {
      setSelectedProject(null);
      setIntefaceBlocked(false);
      toggle();
    });
  }

  const toggle = () => {
    setModalOpen(!modalOpen);
  }

  return loading
  ? (<div>Loading...</div>)
  : (
    <div className="filter-list flex-fill device-section">
      <Modal className="modal-transfer-devices" isOpen={modalOpen} fade={false} toggle={toggle} centered>
        <ModalBody>
          <div className="transfer-devices">
            <h3>Transfer devices to another Project</h3>
            <h4>Select a project to transfer {selectedDevices.length} devices</h4>
            <DropdownSearch
              title={'Search Project'}
              onSelect={(proj) => setSelectedProject(proj)}
              options={projects.filter(proj => (proj.id !== project.id)).map(proj => {
                return { label: proj.displayName, value: proj.id };
              })} />
              <div className="button-group">
                <CustomButton
                  title={'Cancel'}
                  classes={'btn-secondary'}
                  handleClick={() => {
                    setSelectedProject(null);
                    toggle();
                  }} />
                <CustomButton
                  title={'Confirm'}
                  disabled={!selectedProject}
                  loading={intefaceBlocked}
                  classes={'btn-primary'}
                  handleClick={moveDevices} />
              </div>
          </div>
        </ModalBody>
      </Modal>
      { devices.length === 0
        ? <p>No Devices</p>
        : <div className="device-list">
            <SearchBox
              onChange={onSearch}
              placeholder={'Search Device'} />
            <div className="toolbar">
              <CustomCheckBox
                multiple
                selectedItems={selectedDevices.length}
                totalItems={searchedDevices.length}
                label={`All (${searchedDevices.length})`}
                onClick={selectAll} />
              <IconButton
                id={'btn-transfer'}
                icon={'transfer'}
                tooltip={'Transfer'}
                disabled={selectedDevices.length === 0}
                onPress={displayTransferModal} />
            </div>
            {
              searchedDevices.map(device => {
                return (
                  <div className="device-row" key={device.id}>
                    <CustomCheckBox
                      selected={isSelected(device.id) ? 'all' : 'none'}
                      label={device.displayName}
                      onClick={(value) => toggleSelection(device.id, value)} />
                  </div>
                );
              })
            }
          </div>
      }
    </div>
  )
};

export default Devices;