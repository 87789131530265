import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setAlert } from '../../redux/dashboard/actions';
import types from '../../redux/types';

import containers from '../../containers';
import components from '../../components';
import ReloadButton from '../../components/reloadButton';
import TimezoneInfo from '../../components/timezoneInfo';
import API from '../../services/api';
import utils from '../../utils';

import './styles.scss';

const { Header, SearchBox } = components;
const { NotificationsContainer } = containers;

const NotificationsScreen = () => {

  const notifications = useSelector(reducer => reducer.notificationReducer.notifications);

  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [isOpenNotificationSettings, setIsOpenNotificationSettings] = useState(false)
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setFilteredNotifications(notifications.filter(notification =>
      notification.title.toUpperCase().includes(search.toUpperCase()) || notification.message.toUpperCase().includes(search.toUpperCase())
    ))
  }, [search, notifications]);

  const refreshNotifications = () => {
    API.getNotifications().then(notificationResponse => {
      const notifications = notificationResponse.notifications
        .map(notification => {
          if (notification.type && notification.type.id) {
            const { id } = notification.type;
            const [service, status] = id.split("_");
            notification.status = status;
            notification.service = service.toLowerCase();
          }
          return notification;
        });

      dispatch({
        type: types.LOAD_NOTIFICATIONS,
        payload: notifications
      });

      dispatch(setAlert(utils.generateAlert('Notification list was updated','success')));
    }).catch(err => {
      console.error(err);
      dispatch(setAlert(utils.generateAlert('Failed to refresh notification. Please try again','error')));
    });
  }

  return (
    <div className="d-flex flex-column flex-fill">
      <Header
        title="Notifications"
        icon="notificationDarkOutline"
        actions={[
          { type: "reactComponent", value: <TimezoneInfo key="timezone-info" /> },
          {
            type: "reactComponent",
            value: <ReloadButton
              key="reload-btn"
              contentName="Notification list"
              onPress={refreshNotifications} />
          },
          {
            icon: "settingsDark",
            onPress: () => setIsOpenNotificationSettings(true),
            tooltip: "Settings"
          }
        ]}
        filters={
          <div className="d-flex filter-option">
            <SearchBox
              placeholder="Search by Name or ID"
              onChange={value => setSearch(value)} />
          </div>
        }
      />
      <NotificationsContainer
        notifications={filteredNotifications}
        isOpenNotificationSettings={isOpenNotificationSettings}
        setIsOpenNotificationSettings={setIsOpenNotificationSettings} />
    </div>
  );
};

export default NotificationsScreen;
