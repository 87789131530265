import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import icons from '../../assets/icons';

const DeviceStatus = ({ device, styles, tooltip }) => {
  const getStatusIcon = status => {
    switch (status) {
      case 'Archived':
        return 'offline';
      case 'No-Camera':
        return 'stopped';
      case 'Updating':
        return 'reload';
      default:
        return status.toLowerCase();
    }
  };

  return (
    <>
      <img
        id={`device-status-${device.id}`}
        src={icons[getStatusIcon(device.state.status)]}
        alt={device.state.status}
        className={styles}
      />
      {tooltip && (
        <UncontrolledTooltip placement="right" target={`#device-status-${device.id}`}>
          {device.state.status}
        </UncontrolledTooltip>
      )}
    </>
  );
};

export default DeviceStatus;
