import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import icons from '../../assets/icons';
import './styles.scss';

const CustomDropdown = ({
  items, //List of objects
  valueField, //Field from object used to get the value
  displayField, //Field from object to display the selection
  selectedItem, // Item selected outside the component
  label, // Label to render
  disabled,
  nullable, // Dropdown can be empty (no option chosen).
  staticTitle, // First option to be displayed if none is selected
  outline = true, // Style
  handleSelection // Callback
}) => {

  // Return index of selected item, if there is any
  const getSelectedIndex = () => {
    return selectedItem ? items.findIndex(el => el[valueField] === selectedItem) : 0;
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(getSelectedIndex());
  // Copy of selected item used to handle render changes
  const [localSelectedItem, setLocalSelectedItem] = useState(selectedItem);

  useEffect(() => {
    // Update local selected item when selection change outside
    setLocalSelectedItem(selectedItem);
    // Also update the index of the selected item
    setSelectedIndex(getSelectedIndex());
  }, [selectedItem, disabled]);

  const getCurrentSelectedObject = (objectFromIndex) => {
    // Having a valueField defined implies localSelectedItem has to be an object
    // If thats is not the case, we should use objectFromIndex instead of localSelectedItem
    if (valueField && typeof localSelectedItem !== 'object') {
      return objectFromIndex;
    }

    return localSelectedItem;
  };

  const getCurrentLabel = () => {
    const objectFromIndex = items ? items[selectedIndex] : null;

    let currentItem = getCurrentSelectedObject(objectFromIndex);

    if (nullable) {
      currentItem = localSelectedItem ? localSelectedItem : null;
    }

    return currentItem ? currentItem[displayField] : '';
  };

  const onChange = (event, index) => {
    setSelectedIndex(index);
    setLocalSelectedItem(items[index]);
    handleSelection(event, index);
  };

  const renderItems = () =>{
    // Prevent selected index to be out of bound
    let selIndex = selectedIndex >= 0 ? selectedIndex : 0;
    selIndex = (selIndex >= items.length) ? 0 : selIndex;

    // Initialize current selected object as one valid option from items or null
    const objectFromIndex = items ? items[selIndex] : null;

    // Test selected item to see if it should be an object and it is not
    const currentItem = getCurrentSelectedObject(objectFromIndex) || {};

    return (<DropdownMenu>
      {
        items && items.map((item, index) => {
          // Test value field to try to get current selected value
          const currentSelectedValue = (valueField
            ? currentItem[valueField] === item[valueField]
            : currentItem === item
          );

          // Active state should rely on selected items
          const isActive = (localSelectedItem && currentSelectedValue) ? true : false;

          return (
            <DropdownItem
              key={index}
              value={valueField ? item[valueField] : item}
              onClick={event => onChange(event, index)}
              active={isActive}>
              { displayField ? item[displayField] : item }
            </DropdownItem>
          )
        })
      }
    </DropdownMenu>);
  }

  return (
    <div className={`custom-dropdown ${outline ? "outline" : ""}`}>
        { (label && label.length > 0) && <div className="dropdown-label">{ label }</div> }
        <Dropdown
          disabled={disabled}
          className={`dropdown-list-container ${disabled ? "disabled" : ""}`}
          isOpen={dropdownOpen}
          toggle={ ()=>{ setDropdownOpen(!dropdownOpen);}}>
          <DropdownToggle className="dropdown-list">
            <div className="current-value">
              <span>{ staticTitle ? staticTitle : getCurrentLabel() }</span>
            </div>
            <img src={icons.angleDown} className="arrow-down" alt="arrow-down"/>
          </DropdownToggle>
          { renderItems() }
        </Dropdown>
      </div>
  )
}

export default CustomDropdown;
