import React from 'react';
import { FixedSizeList as List } from "react-window";
import { useSelector } from 'react-redux';

import { getAllDevices } from '../../redux/devices/actions';
import { getProjects } from '../../redux/projects/actions';

const DeviceList = ({ presetDevices }) => {
  const devices = useSelector(getAllDevices);
  const projects = useSelector(getProjects);

  const renderRow = ({index, style}) => {
    const deviceId = presetDevices[index].split('/')[3];
    const deviceData = devices.find(dev => dev.id === deviceId);
    const project = deviceData ? projects.find(proj => proj.id === deviceData.projectId) : null;

    let deviceName = '--';
    let projectName = '--';
    let isProjectSelected = false;

    if (deviceData) {
      deviceName = deviceData.displayName;
    }

    if (project) {
      projectName = project.displayName;
    }

    return (
      <div className={`preset-device-list ${isProjectSelected ? 'selected' : ''}`} style={style}>
        <a className="" href={`#/advanced/${deviceId}`} >
          <p>
            <b>{deviceName}</b> ({deviceId}) - {projectName}
          </p>
        </a>
      </div>
    )
  };

  if (!presetDevices || presetDevices.length === 0) {
    return (<p>No devices using this preset</p>)
  }

  return (
    <div className='device-list'>
      <List
        className={"device-virtual-list"}
        height={window.innerHeight - 368}
        itemCount={presetDevices.length}
        itemSize={48}
        width="100%">
          {renderRow}
      </List>
    </div>
  );
};

export default DeviceList;