import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getProjects } from '../../../redux/projects/actions';
import API from '../../../services/api';
import Accordion from '../../../components/accordion';
import CustomCheckBox from '../../../components/customCheckBox';

const Devices = ({ project, updateProject }) => {
  const [items, setItems] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState(project.devices);
  const [loading, setLoading] = useState(false);

  const projects = useSelector(getProjects);

  useEffect(() => {
    projects.forEach(proj => {
      setItems([]);
      fetchDevices(proj);
    });
  }, []);

  const fetchDevices = async (proj) => {
    setLoading(true);
    API.getDevices(
      proj.id,
      ['id', 'name', 'display_name', 'archived'],
      ['page_size=-1']
    )
    .then(res => {
      const devicelist = res.devices;
      const newItem = {
        header: `${proj.displayName} (${proj.devicesCount})`,
        body: renderItemBody(devicelist, proj.id)
      };

      if (proj.devicesCount > 0) {
        items.push(newItem);
        setItems(items);
        setSelectedDevices(devicelist);
      }
    })
    .catch(err => console.error(err))
    .finally(() => setLoading(false));
  };

  const toggleSelection = (deviceId, projectId, remove) => {
    const newSelectedDevices = selectedDevices;

    if (remove) {
      const projectIndex = newSelectedDevices.findIndex(item => Object.keys(item)[0] === projectId);
      // Filter out the device based on the project
      const newDeviceList = Object.values(newSelectedDevices[projectIndex])[0]
        .filter(device => device !== deviceId);

      // Remove project if it doesn't have any device selected
      if (newDeviceList.length === 0) {
        newSelectedDevices.splice(projectIndex);
      } else {
        newSelectedDevices[projectIndex] = {[projectId]: newDeviceList};
      }
      setSelectedDevices([ ...newSelectedDevices ]);
    } else {
      // Add selected device to a list, under the parent project
      const projectIndex = newSelectedDevices.findIndex(dev => Object.keys(dev)[0] === projectId);
      if (projectIndex > -1) {
        newSelectedDevices[projectIndex][projectId].push(deviceId);
      } else {
        newSelectedDevices.push({ [projectId]: [deviceId] });
      }
      setSelectedDevices([ ...newSelectedDevices ]);
    }

    //Update list of projects/devices on the newProject object
    updateProject({ ...project, devices: newSelectedDevices });
  };

  const isSelected = (projectId, deviceId) => {
    return selectedDevices.some(device => {
      return device[projectId] === deviceId;
    }) ? 'all' : 'none';
  }

  const renderItemBody = (devices, projectId) => (
    <div className="device-items">
      {
        devices.map(device => (
          <div key={device.id}>
            <CustomCheckBox
              selected={isSelected(projectId, device.id)}
              label={device.displayName}
              onClick={(value) => toggleSelection(device.id, projectId, value)} />
          </div>
        ))
      }
    </div>
  );

  return (
    <div className="project-devices-container device-section">
      { loading
        ? <div>loading...</div>
        : <Accordion items={items}/>
      }
    </div>
  )
};

export default Devices;