import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../redux/dashboard/actions';
import API from '../../services/api';
import utils from '../../utils';
import CSVTemplate from '../../components/csvTemplate';
import CustomButton from '../../components/customButton';

const ImportModal = ({ isOpen, toggleModal, customColumns, cmsName, projectId, integrationName, onUpdate }) => {
  const [file, setFile] = useState(null);
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const inputRef = useRef(null);

  useEffect(() => {
    setError(null);

    if (file) {
      if (file.type === 'text/csv') {
        loadFile(file, (e) => {
          const data = e.target.result;
          setRows(data.split('\n'));
        });
      } else {
        setError('File choosen is not a CSV!');
        setRows([]);
      }
    } else {
      setRows([]);
    }
  }, [file]);

  useEffect(() => {
    setRows([]);
    setFile(null);
    setError(null);
  }, [isOpen]);

  const loadFile = (intendedFile, callback) => {
    if (!intendedFile) return;
    const reader = new FileReader();
    reader.onload = (evt) => callback(evt);
    reader.readAsText(intendedFile);
  };

  const saveMappings = () => {
    const records = rows.filter((row, index) => index > 0);
    const metadata = {};

    Promise.all(
      records.map(record => {
        const values = record.split(',');

        customColumns.forEach((value, index) => {
          metadata[value] = values[index + 2];
        });

        return API.createMapping(
          cmsName,
          {
            device_id: values[0],
            integration_name: integrationName,
            metadata,
          },
          values[1], // Credential ID
          projectId
        );
      })
    ).then(() => {
      dispatch(setAlert(utils.generateAlert('Mapping created successfuly!', 'success')));
    }).catch(err => {
      dispatch(setAlert(utils.generateAlert(`Couldn't create one or more mappings! Please try again.`, 'error')));
      console.error(err);
    }).finally(onUpdate);
  }

  const renderFileName = () => {
    if (file) {
      if (file.name.length > 25) {
        return `${file.name.substr(0, 25)}...csv`;
      }
      return file.name;
    }

    return '';
  }

  const renderInfo = () => {
    if (rows.length < 2) return null;

    return (
      <div className="file-info">{renderFileName()} - <b>{rows.length - 1} rows found</b></div>
    );
  };

  return (
    <Modal className="import-integration-modal" isOpen={isOpen} fade={false} toggle={toggleModal} centered>
      <ModalHeader className="header-gray" toggle={toggleModal} tag="div">
        <h5>Import CSV mapping file</h5>
        <div className="modal-subtitle">
          Create multiple bindings at once using a CSV file
        </div>
      </ModalHeader>
      <ModalBody>
        <CSVTemplate
          fileName="your mapping file.csv"
          columns={['deviceId', 'credentialId', ...customColumns]} />
        <div className="file-input-container">
          <input
            id="input-file-btn"
            type="file"
            ref={inputRef}
            onChange={e => setFile(e.target.files[0])} />
          <CustomButton
            title="Choose File"
            classes="btn-secondary"
            icon="folder"
            handleClick={() => { inputRef.current.click()}} />
          { renderInfo() }
          { error &&
            <div className="error-message" >{error}</div>
          }
        </div>
        <div className="button-container">
          <CustomButton title="Cancel" classes="btn-secondary" handleClick={toggleModal} />
          <CustomButton disabled={!file || error} title="Save Mappings" classes="btn-primary" handleClick={saveMappings} />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ImportModal;