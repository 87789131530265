import React from 'react';
import './styles.scss';

const CustomToogle = ({ id, onChange, checked, label = "", disabled=false, className="" }) => {
  return (
    <div className={`custom-control custom-switch ${className}`}>
      <input disabled={disabled} type="checkbox" onChange={onChange} checked={checked} className="custom-control-input" id={id} />
      <label className={`custom-control-label label-inline ${checked ? "checked" : ""}`} htmlFor={id}>{label}</label>
    </div>
  );
}

export default CustomToogle;