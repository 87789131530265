import React from 'react';

const Tags = ({ value, hasCloseButton, onClose }) => {
  return (
    <div className="tag" onClick={onClose}>
      <div className="tag-text">{value}</div>
      {hasCloseButton && (
        <button className="tag-close" onClick={onClose}>
          <i className="uil uil-times" />
        </button>
      )}
    </div>
  );
};

export default Tags;
