import utils from '../index';
import filterUtils from '../filters';

import { zonedTimeToUtc } from 'date-fns-tz';
import { differenceInMinutes } from 'date-fns';

/**
 * Add data on device, this method we can add more informations on device obj that we get on request
 */
const addInformation = object => {
  if (!object.devices) {
    return [];
  }

  return object.devices.map(item => {
    item.status = getStatus(item);
    item.platform = getPlatform(item);
    item.displayName = item.displayName ? item.displayName : `device/${item.id}`;

    return item;
  });
};

/**
 * status can be: Online, Updating, Offline, Stopped, No-Camera
 * @param {*} device
 */
const getStatus = device => {
  let status = 'Offline';
  const stateStatus = utils.get(device, 'state.status');
  const updateTime = utils.get(device, 'state.updateTime');

  if (device.archived) {
    status = 'Archived';
  } else if (updateTime) {
    const now = zonedTimeToUtc(new Date());
    const lastUpdate = new Date(updateTime);
    const secondsSinceLastUpdate = differenceInMinutes(now, lastUpdate);

    // Perform same test on status filter: state.updateTime >= CURRENT_TIMESTAMP - INTERVAL '4 minutes'
    if (secondsSinceLastUpdate <= 4) {
      switch (stateStatus) {
        case 'detecting':
          status = 'Online';
          break;
        case 'downloading-models':
        case 'inactive':
          status = 'Stopped';
          break;
        case 'no-camera':
          status = 'No-Camera';
          break;
        case 'offline':
          status = 'Offline';
          break;
        default:
          status = 'Online';
          break;
      }
    }
  }
  return status;
};

const getPlatform = device => {
  const platformString = utils.get(device, 'state.platform');
  const windowsRegex = /^windows/i;
  const linuxRegex = /^linux/i;
  const balenaRegex = /^balena/i;
  const brightsignRegex = /^brightsign/i;

  if (windowsRegex.test(platformString)) {
    return 'windows';
  } else if (linuxRegex.test(platformString)) {
    return 'linux';
  } else if (balenaRegex.test(platformString)) {
    return 'balena';
  } else if (brightsignRegex.test(platformString)) {
    return 'brightsign';
  } else {
    return 'unknown';
  }
};

const getVersions = (devices, path, prefix) => {
  const versions = [];
  const versionFound = {};

  devices.forEach(device => {
    const version = utils.get(device, path);
    if (version) {
      if (!versionFound[version]) {
        versions.push({
          label: `${prefix} v${version}`,
          value: version
        });
      }
      versionFound[version] = true;
    }
  });

  return versions;
};

const getDevicesWithSolution = (devices, solution) => {
  const solutionId = solution ? solution.id.toUpperCase() : '';

  const newFilteredDevices = devices
    .filter(device => {
      const deviceSolution = (device && device.solution) ? device.solution.toUpperCase() : '';

      if (solution && solution.cms) {
        const deviceHasCMS = device.integrations.includes(solutionId);
        return device.integrations && solution.id && deviceHasCMS;
      }

      return deviceSolution.includes(solutionId);
    });

  return newFilteredDevices;
};

const getVersionFilters = (devices, availableFilters, featureFlags) => {
  const malosVersion = deviceUtils.getVersions(devices, 'state.malosVersion', 'Malos');
  const admproviderVersion = deviceUtils.getVersions(devices, 'state.admproviderVersion', 'AdmPro');
  const migrationVersion = featureFlags['dashboard-migrated-filter']
    ? featureFlags['dashboard-migrated-filter']
    : '3.0.0';
  const migratedOptions = [
    {
      id: 'migrated',
      label: 'Migrated',
      operator: '>=',
      value: migrationVersion,
      type: 'version',
      isAdminOnly: true
    },
    {
      id: 'not-migrated',
      label: 'Not Migrated',
      operator: '<',
      value: migrationVersion,
      type: 'version',
      isAdminOnly: true
    }
  ];

  // Add options to filter by Malos Version
  let newFilters = filterUtils.replaceFilterOptions(availableFilters, 'Malos Version', malosVersion);
  // Add options to filter by AdmProvider Version
  newFilters = filterUtils.replaceFilterOptions(newFilters, 'Admprovider Version', admproviderVersion);

  newFilters = filterUtils.replaceFilterOptions(newFilters, 'Migrated', migratedOptions);

  return newFilters;
};

const deviceUtils = {
  getStatus,
  getPlatform,
  addInformation,
  getVersions,
  getDevicesWithSolution,
  getVersionFilters
};

export default deviceUtils;
