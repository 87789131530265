import Konva from 'konva';

const colorDefault = 'green';
const colorDefaultSelected = 'blue';
const deafultTextColor = '#30395a';

export class Roi extends Konva.Rect {
  constructor(roi, params) {
    const defaultParams = {
      fill: colorDefault,
      opacity: 0.2
    }
    super({ ...roi, ...defaultParams, ...params });
    this.model = roi.model;
    this.model_night = roi.model_night;

    this.text = new Konva.Text({
      text: roi.name,
      fill: deafultTextColor,
      fontSize: 14,
      fontStyle: 'bold',
      x: (roi.x + 10) * roi.aspectRatio,
      y: (roi.y + 10) * roi.aspectRatio
    });

    this.on('dragmove transform', (e) => {
      const roi = e.target;
      this.text.position({
        x: roi.attrs.x + 10,
        y: roi.attrs.y + 10,
      });
    });

    // Adjust attributes based on aspect ratio
    this.applyRatio();
  }

  edit() {
    this.draggable(true);
    this.fill(colorDefaultSelected);
  }

  finishEdit() {
    this.draggable(false);
    this.fill(colorDefault);
  }

  destroy() {
    this.text.destroy();
    this.off('dragmove transform');
    super.destroy();
  }

  set parent(newParent) {
    this._parent = newParent;
    if(newParent instanceof Konva.Layer) {
      newParent.add(this.text);
    }
  }

  get parent() {
    return this._parent;
  }

  setAttrs(params) {
    super.setAttrs(params);
    const roi = this.getObj();
    if (this.text && roi) {
      this.text.position({
        x: roi.x + 10,
        y: roi.y + 10,
      });
      if (params.name) {
        this.text.text(params.name);
      }
    }
  }

  getObj(aspectRatio) {
    const ratio = aspectRatio || 1;
    const heightFinal = (this.height() * this.scaleY())
    const widthFinal = (this.width() * this.scaleX())

    return {
      width: parseInt(widthFinal / ratio),
      height: parseInt(heightFinal / ratio),
      x: parseInt(this.x() / ratio),
      y: parseInt(this.y() / ratio),
      model: this.model,
      model_night: this.model_night
    }
  }

  // Apply aspect ratio to update positions
  applyRatio() {
    const ratio = this.attrs.aspectRatio;
    this.attrs.x *= ratio;
    this.attrs.y *= ratio;
    this.attrs.width *= ratio;
    this.attrs.height *= ratio;
  }
}