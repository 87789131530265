import React from 'react';

import DashboardPreferences from './dashboard-preferences';
import ProjectPreferences from './project-preferences';

import icons from '../../assets/icons';

const Preferences = ({ isAdvertiserUser }) => {
  return (
    <div className="profile-cards preferences">
      <div className="card">
        <div className="card-header">
          <img src={icons.settingsDark} alt="user icon"/>
          <h3>Preferences</h3>
        </div>
        <div className="card-body">
          <DashboardPreferences />
          <ProjectPreferences isAdvertiserUser={isAdvertiserUser} />
        </div>
      </div>
    </div>
  );
}

export default Preferences;