import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import utils from '../../utils';
import fieldValidation from '../../utils/fieldValidation';
import CustomCheckBox from '../customCheckBox';
import API from '../../services/api';

import { getUser } from '../../redux/dashboard/actions';

import { setAlert } from '../../redux/dashboard/actions';
import types from '../../redux/types';
import './styles.scss';
import Accordion from '../accordion';
import ChipTags from '../chipTags';
import CustomButton from '../customButton';

const NotificationSettings = ({ onUpdate }) => {
  const dispatch = useDispatch();
  const notificationTypes = useSelector(selector => selector.notificationReducer.notificationTypes);
  const notificationPreferences = useSelector(selector => selector.notificationReducer.notificationPreferences);
  const user = useSelector(getUser);

  const [options, setOptions] = useState([]);
  const [editedOptions, setEditedOptions] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [emails, setEmails] = useState(() => {
    const combinedEmails = [];
    notificationPreferences.map(index => {
      if (index['extraEmails']) {
        combinedEmails.push(index['extraEmails']);
      }
    });
    return [...new Set(combinedEmails.flat())];
  });

  useEffect(() => {
    const enabledOptions = notificationTypes.map(type => {
      const preferenceFound = notificationPreferences.find(notification => notification.type.id == type.id);

      if (preferenceFound) {
        return preferenceFound;
      }

      return { type, preferences: [] };
    });

    setOptions(enabledOptions);
  }, [notificationTypes, notificationPreferences]);

  useEffect(() => {
    setEditedOptions([...options]);
  }, [options]);

  const changePreferenceValue = (index, value) => {
    setEditedOptions(
      editedOptions.map((option, currentIndex) => {
        if (currentIndex == index) {
          if (option.preferences.includes(value)) {
            return {
              ...option,
              preferences: option.preferences.filter(preference => preference !== value)
            };
          } else {
            return { ...option, preferences: [...option.preferences, value] };
          }
        }
        return option;
      })
    );
  };

  const updateSettings = async () => {
    try {
      if (onUpdate) {
        onUpdate();
      }

      const formattedOptions = editedOptions.map(option => ({ ...option, extraEmails: emails }));

      await API.setNotificationPreferences(formattedOptions.filter(option => option.preferences.length > 0));
      dispatch({ type: types.LOAD_NOTIFICATION_PREFERENCES, payload: formattedOptions });
      dispatch(setAlert(utils.generateAlert('Notification Preferences was updated!', 'success')));
    } catch (err) {
      dispatch(setAlert(utils.generateAlert('Something went wrong, try again later!', 'error')));
      console.error(err);
    }
  };

  const isTypeEnabled = (option, type) => {
    return option.preferences.includes(type) ? 'all' : 'none';
  };

  const validateEmails = newEmails => {
    let errorType = [];
    const error = newEmails.some(email => {
      /*
      Check if the email is valid or not
      Expected result from fieldValidation: 
        '' = true
        ['Invalid Email address'] = false
        ['This value is already being used'] = false
      */
      errorType = fieldValidation(email.trim(), [{ key: 'email', value: true }]);
      return errorType.join('') !== '';
    });
    setErrorMessage(errorType);
    setHasError(error);
    setEmails([...new Set(newEmails)]);
  };

  const trimEmail = email => {
    if (email.length <= 25) {
      return email;
    } else {
      return email.slice(0, 10) + '...' + email.slice(-5);
    }
  };

  const renderItems = () => {
    return [
      {
        header: 'Options',
        body: (
          <>
            <div className="d-flex flex-row options option-input-line">
              <div className="col-6"></div>
              <div className="col-3 text-center option-input">Email</div>
              <div className="col-3 text-center option-input">In App</div>
            </div>
            {editedOptions.map((option, index) => (
              <div key={index} className="d-flex flex-row option-input-line">
                <div className="col-6 option-title bold">{utils.capitalize(option.type.id)}</div>
                <div className="col-3 text-center option-input">
                  <CustomCheckBox
                    label=""
                    onClick={() => changePreferenceValue(index, 'email')}
                    selected={isTypeEnabled(option, 'email')}
                  />
                </div>
                <div className="col-3 option-input">
                  <CustomCheckBox
                    label=""
                    onClick={() => changePreferenceValue(index, 'inApp')}
                    selected={isTypeEnabled(option, 'inApp')}
                  />
                </div>
              </div>
            ))}
          </>
        )
      },
      {
        header: 'Emails',
        body: (
          <>
            <div className="d-flex flex-column email-options">
              <p className="email-option-header">
                <b className="email-option-main">{trimEmail(user.email)}</b> will always be notified!
              </p>
              <p className={`email-option-main ${hasError ? 'invalid-feedback' : ''}`}>
                {hasError ? errorMessage.join(', ') : 'Notifications will also be sent to:'}
              </p>
            </div>
            <ChipTags
              tags={emails ? emails.map(tag => ({ value: tag })) : []}
              onChange={validateEmails}
              isEditable
              inverted
            />
          </>
        )
      }
    ];
  };

  if (!user) {
    return null;
  }

  return (
    <div className="notification-settings">
      <div className="accordion d-flex flex-fill flex-column options">
        <Accordion items={renderItems()} />
      </div>
      <CustomButton title="Save and Apply" classes="btn btn-primary" handleClick={updateSettings} disabled={hasError} />
    </div>
  );
};

export default NotificationSettings;
