import types from '../types.js';
import { appendState, setState } from '../../utils/redux.js';

const initialState = {
  selectedSite: null,
  sites: [],
  licenses: [],
  siteTypes: []
};

const siteReducer = (state = initialState, action) => {
  const { CREATE_SITE, SELECT_SITE, LOAD_SITES, LOAD_SITE_TYPES } = types;

  switch (action.type) {
    case CREATE_SITE:
      return appendState(state, 'sites', action.payload);
    case SELECT_SITE:
      return setState(state, 'selectedSite', action.payload);
    case LOAD_SITES:
      return setState(state, 'sites', action.payload);
    case LOAD_SITE_TYPES:
      return setState(state, 'siteTypes', action.payload);
    default:
      return state;
  }
};

export default siteReducer;