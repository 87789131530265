import React from 'react';
import Components from '../../components';

const { ReportInfo } = Components.ReportDetails;

const reportSections = (report, loadReports, solution) => {
  if (report) {
    const devices = report.deviceIds;
    const { media, advertisers } = report;
    return [
      {
        'header': 'Info',
        'body': <ReportInfo
          report={report}
          loadReports={loadReports}
          selectedSolution={solution} />
      },
      {
        'header': `Devices (${devices.length})`,
        'body':
          <div className="filter-list flex-fill">
            { devices.length === 0
              ? <p>No Devices</p>
              : devices.map(device => ( <p key={device}>{device}</p> ))
            }
          </div>
      },
      {
        'header': `Advertisers (${advertisers.length})`,
        'body':
          <div className="filter-list flex-fill">
            { advertisers.length === 0
              ? <p>No Advertisers</p>
              : advertisers.map(advertiser => ( <p key={advertiser}>{advertiser}</p> ))
            }
          </div>
      },
      {
        'header': `Media (${media.length})`,
        'body':
          <div className="filter-list flex-fill">
            { media.length === 0
              ? <p>No Media</p>
              :  media.map(med => ( <p key={med}>{med}</p> ))
            }
          </div>
      }
    ];
  }
  return [];
}

export default reportSections;