import React from 'react';

import IconButton from '../iconButton';

import { useSelector } from 'react-redux';
import { getSelectedProject } from '../../redux/projects/actions';

import './styles.scss';

const TimezoneInfo = () => {
  const selectedProject = useSelector(getSelectedProject);

  if (selectedProject) {
    return (
      <IconButton
        id="timezone-info"
        className="timezone-info"
        icon="timezone"
        tooltip={selectedProject.preferences.timezone}
        onPress={null} />
    );
  }

  return null;
};

export default TimezoneInfo;