import config from '../config';

const getWidget = async (widgetUrl) => {
  const dateTime = new Date().toISOString();
  const url = `${config.widgetApi.baseUrl}/${widgetUrl}?dateTime=${dateTime}`;
  // use dev widgets to test production accounts
  // const url = `https://dev-widgets.admobilize.com/${widgetUrl}?dateTime=${dateTime}`;
  return fetch(url).then(res => res.json());
};

export default {
  getWidget
};