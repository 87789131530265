module.exports = {
  /**
   * Hotjar initialization script
   * @param {*} id Hotjar account Id
   * @param {*} sv Hotjar api Version
   */
  initialize: function(id, sv) {
    (function(h,o,t,j,a,r) {
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:id,hjsv:sv};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  },

  /**
   * Send custom user data to hotjar
   * The Identify API is only available to Business plan customers
   * See: https://help.hotjar.com/hc/en-us/articles/360033640653-Identify-API-Reference
   * @param {*} id userId
   * @param {*} data custom user data
   */
  identify: function(id, data) {
    window.hj('identify', id, data);
  }
};
