import React from 'react';
import steps from './newReportSteps/index.js';
import utils from './../../utils';

const { TimeParameters, SelectData } = steps;

const newReportSteps = (newReportConfig, setNewReportConfig) => {
  return [
    {
      title: 'Time Parameters',
      hideTitle: true,
      hideDescription: true,
      required: true,
      hideCancelButton: true,
      validation: () => !utils.hasErrorsCheck(newReportConfig.errors),
      content: <TimeParameters
        report={newReportConfig}
        updateReport={setNewReportConfig} />
    },
    {
      title: 'Select Data',
      hideTitle: true,
      hideDescription: true,
      required: true,
      submitButtonTitle: 'Generate',
      content: <SelectData
        newReport={newReportConfig}
        updateReport={setNewReportConfig}/>
    }
  ];
};

export default newReportSteps;