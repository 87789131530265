import { sub, startOfDay, startOfYesterday, endOfDay, endOfYesterday, startOfMonth, endOfMonth, format, add } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';

const getOffset = timezone => {
  const offset = getTimezoneOffset(timezone);
  const result = offset / 3600000;
  const absoluteOffset = Math.abs(result);
  const hours = parseInt(absoluteOffset);
  const minutes = absoluteOffset % 1 === 0 ? '00' : `${(absoluteOffset % 1) * 60}`;
  const hasZero = absoluteOffset > 9 ? '' : '0';
  const offsetString = `${hasZero}${hours}:${minutes}`;

  return result > 0 ? `+${offsetString}` : `-${offsetString}`;
};

// Not being used anymore, we are now using local timezone offset
const replaceOffset = (datetimeStr, timezone) => {
  const offset = getOffset(timezone);
  const datePieces = datetimeStr.split('T');
  const date = datePieces[0];
  const time = datePieces[1].substr(0, 8);
  return `${date}T${time}${offset}`;
};

const getDateTime = (datetimeString, valueType) => {
  if (!datetimeString) {
    return '--';
  }

  const datePieces = datetimeString.split('T');
  const date = datePieces[0];
  const time = datePieces[1].substr(0, 8);
  if (valueType === 'date') return convertDate(date);
  if (valueType === 'time') return convertTimeString(time);
};

const convertTimeString = (time, showSeconds) => {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (!showSeconds) delete time[4];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }

  return time.join(''); // return adjusted time or original string
};

const getDaySuffix = day => {
  switch (day) {
    case '1':
    case '21':
    case '31':
      return 'st';
    case '2':
    case '22':
      return 'nd';
    case '23':
      return 'rd';
    default:
      return 'th';
  }
};

const convertDate = dateString => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  dateString = dateString.split('-');
  const day = dateString[2] >= 10 ? dateString[2] : dateString[2][1];
  const month = months[dateString[1] - 1];
  const year = dateString[0];

  return `${month} ${day}${getDaySuffix(day)} ${year}`;
};

const placeholders = {
  startTime: '07:00',
  endTime: '19:00'
};

const weekDaysOptions = [
  { id: 'Sun', name: 'Sunday', value: 1 },
  { id: 'Mon', name: 'Monday', value: 2 },
  { id: 'Tue', name: 'Tuesday', value: 3 },
  { id: 'Wed', name: 'Wednesday', value: 4 },
  { id: 'Thu', name: 'Thursday', value: 5 },
  { id: 'Fri', name: 'Friday', value: 6 },
  { id: 'Sat', name: 'Saturday', value: 7 }
];

const presetsOptions = [
  {
    text: 'Today',
    start: new Date(),
    end: new Date()
  },
  {
    text: 'Yesterday',
    start: startOfYesterday(),
    end: endOfYesterday()
  },
  {
    text: 'Last 7 Days',
    start: startOfDay(sub(new Date(), { weeks: 1 })),
    end: new Date()
  },
  {
    text: 'Last Month',
    start: startOfMonth(sub(new Date(), { months: 1 })),
    end: endOfMonth(sub(new Date(), { months: 1 }))
  },
  {
    text: 'This Month',
    start: startOfMonth(new Date()),
    end: new Date()
  }
];

const isInvalidTime = time => {
  const mask = getInputMask(time);
  const regex = transformMaskToRegex(mask);
  return !time || !regex.test(time);
};

const getInputMask = value => {
  const startsWithTwo = value && value[0] === '2';
  return [/[0-2]/, startsWithTwo ? /[0-3]/ : /\d/, ':', /[0-5]/, /\d/];
};

const transformMaskToRegex = mask => {
  const maskAsString = mask
    .map(el => el)
    .join('')
    .replaceAll('/', '');
  return new RegExp(maskAsString);
};

const getChosenDays = days => {
  return Object.keys(days)
    .filter(key => days[key])
    .map(key => weekDaysOptions.find(day => day.id === key));
};



const addTime = (quantity, period, date) => {
  let timeToAdd = {};
  switch (period) {
    case 'days':
      timeToAdd = { days: quantity };
      break;
    case 'months':
      timeToAdd = { months: quantity };
      break;
    case 'years':
      timeToAdd = { years: quantity };
      break;
  }

  return date ? add(new Date(date), timeToAdd) : add(new Date(), timeToAdd);
};

const defaultIntervals = [
  { id: 'hour', name: 'Hour' },
  { id: 'day', name: 'Day' },
  { id: 'week', name: 'Week' },
  { id: 'monday_week', name: 'Week (Starting on Monday)' },
  { id: 'month', name: 'Month' },
  { id: 'year', name: 'Year' }
];

const getIntervalById = (id, intervalArray) => {
  return intervalArray
    ? intervalArray.find(interval => interval.id === id)
    : defaultIntervals.find(interval => interval.id === id);
};

const getDefaultDate = (date) => {
  const dateString = date === 'start'
    ? format(startOfDay(sub(new Date(), { weeks: 2 })), "yyyy-MM-dd'T'HH:mm:ssXXX")
    : format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ssXXX");

  return dateString;
};

export default {
  placeholders,
  weekDaysOptions,
  presetsOptions,
  defaultIntervals,
  isInvalidTime,
  getInputMask,
  transformMaskToRegex,
  getChosenDays,
  replaceOffset,
  getDateTime,
  addTime,
  getIntervalById,
  getDefaultDate
};
