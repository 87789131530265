import config from '../config';
import credentialsStore from './credentialsStore';
import API from './api';
import { getCustomWidgets } from '../utils/user/preferences';

const { baseUrl } = config.authApi;

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

const signin = async (email, password) => {
  const url = `${baseUrl}/accounts/-/sessions`;
  return fetch(url, {
    headers,
    method: 'POST',
    body: JSON.stringify({
      email,
      password
    })
  }).then(res => validateSigninResponse(res));
};

const refreshSession = async refreshToken => {
  const url = `${baseUrl}/accounts/-/sessions`;
  return fetch(url, {
    headers,
    method: 'PUT',
    body: JSON.stringify({
      refreshToken
    })
  }).then(res => {
    return res
      .json()
      .then(tokens => {
        API.setTokens(tokens);
        return tokens;
      })
      .catch(console.error);
  });
};

const tokenSignin = async refreshToken => {
  const url = `${baseUrl}/accounts/-/sessions`;
  return fetch(url, {
    headers,
    method: 'PUT',
    body: JSON.stringify({
      refreshToken
    })
  }).then(res => validateSigninResponse(res));
};

const getUser = async (uid, accessToken) => {
  const url = `${baseUrl}/accounts/${uid}`;
  const token = API.isImpersonating ? API.originalToken : accessToken;
  return fetch(url, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`
    },
    method: 'GET'
  }).then(API.generateResponse);
};

const setUser = async (uid, data, headers, generateResponse) => {
  const url = `${baseUrl}/accounts/${uid}`;
  return fetch(url, {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(data)
  }).then(generateResponse);
};

const getAccountDetails = async users => {
  await API.refreshTokenWhenNeeded();
  const url = `${baseUrl}/getManyAccountsDetails`;
  const token = API.isImpersonating ? API.originalToken : API.access_token;
  return fetch(url, {
    headers: {
      ...API.headers,
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({ uids: users }),
    method: 'POST'
  }).then(API.generateResponse);
};

const signup = async (data, headers, refreshTokenWhenNeeded, generateResponse) => {
  await refreshTokenWhenNeeded();
  const url = `${baseUrl}/accounts`;
  return fetch(url, {
    headers,
    body: JSON.stringify(data),
    method: 'POST'
  }).then(generateResponse);
};

const inviteUser = async (email, projectId, role, generateResponse) => {
  const url = `${baseUrl}/invites`;
  return fetch(url, {
    headers: API.headers,
    body: JSON.stringify({ email, projectId, role }),
    method: 'POST'
  }).then(generateResponse);
};

const confirmInvite = async (token, body, generateResponse) => {
  const url = `${baseUrl}/invites/${token}`;
  return fetch(url, {
    headers: API.headers,
    body: JSON.stringify(body),
    method: 'POST'
  }).then(generateResponse);
};

const getInviteInfo = async (token, generateResponse) => {
  const url = `${baseUrl}/invites/${token}`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(generateResponse);
};

const resetPassword = async email => {
  const url = `${baseUrl}/accounts/-/oobCodes`;
  return fetch(url, {
    headers,
    body: JSON.stringify(email),
    method: 'POST'
  }).then(res => res.json());
};

const validateSigninResponse = async loginRes => {
  if (!loginRes.ok) {
    let response;

    try {
      response = await loginRes.json();
    } catch (e) {
      throw new Error('Failed to parse login response.');
    }

    if (response.message) {
      throw new Error(response.message);
    }

    throw new Error('Failed to complete login request.');
  }

  const tokens = await loginRes.json();
  const userInfo = credentialsStore.parseJwtPayload(tokens.accessToken);
  const user = await getUser(userInfo.uid, tokens.accessToken);

  const output = {
    ...user,
    name: user.displayName,
    accessToken: tokens.accessToken,
    refreshToken: tokens.refreshToken
  };

  // Add custom widgets after login
  output.preferences.customWidgets = getCustomWidgets(output.preferences);

  API.setTokens(tokens);
  credentialsStore.put(output);

  return output;
};

const getFeatureFlags = async () => {
  const url = `${baseUrl}/accounts/-/featureFlags`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(API.generateResponse);
};

export default {
  getUser,
  getAccountDetails,
  setUser,
  refreshSession,
  resetPassword,
  signin,
  signup,
  inviteUser,
  tokenSignin,
  confirmInvite,
  getInviteInfo,
  getFeatureFlags
};
