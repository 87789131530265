import React from 'react';
import { FixedSizeGrid as Grid } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import DeviceItem from './deviceItem';
import EmptyState from '../emptyState';
import paginationUtils from '../../utils/pagination';

const HEIGHT = window.innerHeight - 485;
const COLUMN_COUNT = 4;
const ROW_HEIGHT = 40;
const BATCH_ROW_SIZE = 10;

// Render device item as a component

const DeviceList = ({ devices, selectedDevice, customWidth, onSelect, loadMoreItems, rowStatusMap, infiniteLoaderRef }) => {

  // useEffect(() => {
  //   scrollSelectedDeviceIntoView();
  // }, [devices, deviceId, selectedDevice]);

  // const getRowIndexFromId = (id) => {
  //   const deviceIndex = devices.findIndex(dev => dev.id === id);
  //   return Math.ceil(deviceIndex / COLUMN_COUNT);
  // };

  // const isDeviceBeingViewed = (scrollTop, rowIndex) => {
  //   const rowsOnScreen = HEIGHT / ROW_HEIGHT;

  //   if ((scrollTop + rowsOnScreen) >= rowIndex && scrollTop <= rowIndex ) {
  //     return true
  //   }
  // };

  /*
    Function to scroll the grid of devices, until the selected is on view
  */
  // const scrollSelectedDeviceIntoView = () => {
  //   const gridRefFound = gridRef && gridRef.current;

  //   if (gridRefFound) {
  //     const rowIndex = getRowIndexFromId(selectedDevice.id);
  //     const scrollTop = gridRef.current.state.scrollTop;

  //     // Only update list scroll position if selected device is the one passed on params
  //     if (!isDeviceBeingViewed(scrollTop/100, rowIndex)) {
  //       gridRef.current.scrollToItem({
  //         rowIndex: rowIndex,
  //         align: "center"
  //       });
  //     }
  //   }
  // };

  const renderCell = ({columnIndex, rowIndex, style}) => {
    const deviceIndex = (COLUMN_COUNT * rowIndex) + columnIndex;

    // use this following code to debug cell addr
    // return <div style={style}> {deviceIndex} - ({rowIndex}, {columnIndex}) </div>

    return (
      <DeviceItem
        device={devices[deviceIndex]}
        style={{
          ...style,
          top: style.top + 8,
          left: style.left + 8,
          height: style.height - 8,
          width: style.width - 8,
          overflow: 'hidden'
        }}
        selectedDevice={selectedDevice}
        onSelect={onSelect} />
    );
  };

  if (devices.length === 0) {
    return (
      <div className="device-list-container">
        <EmptyState icon="device" title="No device found" details="If you do expect results for current filters and seach, please contact support."/>
      </div>
    );
  }

  return (
    <div className="device-list-container">
      <InfiniteLoader
        ref={infiniteLoaderRef}
        isItemLoaded={params => { paginationUtils.isRowLoaded(params, rowStatusMap.current) }}
        itemCount={99999}
        minimumBatchSize={BATCH_ROW_SIZE}
        threshold={5}
        loadMoreItems={(start, stop) => loadMoreItems(start, stop + BATCH_ROW_SIZE, true)} >
          {({ onItemsRendered, ref }) => (
            <Grid
              ref={ref}
              onItemsRendered={props => { onItemsRendered({
                overscanStartIndex: props.overscanRowStartIndex,
                overscanStopIndex: props.overscanRowStopIndex,
                visibleStartIndex: props.visibleRowStartIndex,
                visibleStopIndex: props.visibleRowStopIndex
              }) }}
              className={`device-grid ${devices.length === 0 ? 'empty' : ''}`}
              width={customWidth ? customWidth : 900}
              height={HEIGHT}
              columnWidth={(customWidth / COLUMN_COUNT) - 4}
              columnCount={COLUMN_COUNT}
              rowCount={Math.ceil(devices.length / COLUMN_COUNT)}
              rowHeight={ROW_HEIGHT}>
                {renderCell}
            </Grid>
          )}
      </InfiniteLoader>
    </div>
  );
};

export default DeviceList;