import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Redux
import { getSelectedDashboard, getSelectedProject } from '../../redux/projects/actions';
import { getDevices } from '../../redux/devices/actions';
import { getUser, setAlert } from '../../redux/dashboard/actions';

// Components
import components from '../../components';

// Utilities
import utils from '../../utils';
import API from '../../services/api';
import calendar from '../../utils/calendar';
import deviceUtils from '../../utils/device';

import './styles.scss';

const { DateTimeFilterBar, Widget, Loading } = components;

const SummaryContainer = ({ lastUpdated }) => {
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [currentWidgets, setCurrentWidgets] = useState({});
  const [dashboardData, setDashboardData] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]); // Devices filtered by selected solution

  // Flags
  const [hasTable, setHasTable] = useState(true);
  const [loadingDashboard, setLoadingDashboard] = useState(true);

  // Redux variables
  const user = useSelector(getUser);
  const project = useSelector(getSelectedProject);
  const devices = useSelector(getDevices);
  const selectedDashboard = useSelector(getSelectedDashboard);

  const dispatch = useDispatch();

  const eventHandlers = useRef([]);
  const oldProjectRef = useRef();
  const oldDashboardRef = useRef();

  // Update applied filters when project changes
  useEffect(() => {
    if (project && user) {
      const newFilteredDevices = deviceUtils.getDevicesWithSolution(devices, selectedDashboard);
      const nonArchivedDevices = newFilteredDevices.filter(device => !device.archived);
      setFilteredDevices(nonArchivedDevices);

      // Load Filters
      setAppliedFilters({
        startDate: (!oldProjectRef.current) ? calendar.getDefaultDate('start') : appliedFilters.startDate,
        endDate: (!oldProjectRef.current) ? calendar.getDefaultDate('end') : appliedFilters.endDate,
        timezone: project.preferences.timezone,
        interval: calendar.getIntervalById('day'),
        filterByTime: {},
        filterByWeekday: [],
        user,
        selectedDevices: filteredDevices,
        project,
        projectId: project.id,
        selectedSolution: selectedDashboard
      });

      oldProjectRef.current = project.id;
    }
  }, [project]);

  // Update widgets when applied filters changes
  useEffect(() => {
    if (selectedDashboard && selectedDashboard.id !== oldDashboardRef.current) {
      setLoadingDashboard(true);
      // Load Widgets
      let newDashboard = {};
      setDashboardData(selectedDashboard);
      // Fetch each widget from cloudStorage
      selectedDashboard.rows.forEach(dashboardRow => {
        dashboardRow.widgets.map(widgetData => {
            API.getWidget(widgetData.url)
              .then(res => {
                newDashboard = {...newDashboard, [res.id]: res};
                setCurrentWidgets(newDashboard);
              })
              .catch(onError)
              .finally(() => {
                setLoadingDashboard(false);
              });
          })
      });

      oldDashboardRef.current = selectedDashboard.id;
    }

    API.refreshTokenWhenNeeded();
  }, [selectedDashboard]);

  // Remove listeners on component did unmount
  useEffect(() => {
    return () => {
      eventHandlers.current.forEach(handler => {
        window.removeEventListener('requestResult', handler);
        window.removeEventListener('requestError', handler);
      });
    }
  }, []);

  const onFilterSelect = (payload) => {
    const newAppliedFilters = utils.deepClone(appliedFilters);
    Object.keys(payload).forEach(key => {
      newAppliedFilters[key] = payload[key];
    });

    setAppliedFilters(newAppliedFilters);
  };

  const onError = (e) => {
    const error = e.detail ? e.detail.message : 'No details';
    let errorMessage = error;
    if (error.includes('Not found: Table')) {
      setHasTable(false);
      errorMessage = 'Table not found';
    }

    dispatch(setAlert(utils.generateAlert(errorMessage, 'error')));
  };

  const renderEmptyMessage = () => {
    return (
      <div className="no-data-message flex-column">
        <h1>No data found!</h1>
        <p>Nothing to see here. Try choosing another Project or Solution.</p>
      </div>
    );
  };


  const renderWidgets = () => {
    if (Object.keys(currentWidgets).length === 0) {
      return null;
    }

    return dashboardData.rows.map((dashboardRow, index) => (
      <div key={`row-${index}}`} className="chart-row">
        {dashboardRow.widgets.map(widgetData => {
          return (
            <Widget
              key={`analytics-${widgetData.id}`}
              widget={currentWidgets[widgetData.id]}
              lastUpdated={lastUpdated}
              filters={appliedFilters}
              handleError={onError}
              eventHandlers={eventHandlers.current}
            />
          );
        })}
      </div>
    ));
  };

  const renderTopFilterBar = () => {
    if (!appliedFilters) {
      return null;
    }

    return (
      <div className="filter-bar">
        <DateTimeFilterBar
          appliedFilters={appliedFilters}
          onPeriodSelected={onFilterSelect}
        />
      </div>
    );
  };

  // Prevent rendering when there is no project or when token expires
  // This can happen after logout redirect
  if (!project) {
    return (
      <div className="no-data-message flex-column">
        <h1>No data found!</h1>
        <p>Nothing to see here. Try choosing another site group or creating a new one.</p>
      </div>
    );
  }

  return (
    <div id="summary-container" className="h-100">
      {!hasTable && renderEmptyMessage()}
      {hasTable && (
        <>
          {
            renderTopFilterBar()
          }
          <div className="scrollable-container">
            <div id="summary-chart-container">
              { loadingDashboard
                ? <div className='h-100'><Loading size={8}/></div>
                : renderWidgets()
              }
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SummaryContainer;
