import React from 'react';
import components from '../../../components';

const { CustomInput, CustomToogle } = components;

const UpdateUser = ({
  displayName,
  email,
  active,
  password,
  confirmPassword,
  updateValue,
  saveChanges,
  isSaving,
  errors,
  userId
}) => {
  return (
    <div className="row new-user">
      <div className="col-12">
        <div className="form-group">
          <label>User Id</label>
          <CustomInput id="user-display-id" type="text" value={userId} isEditable={false} />
        </div>
      </div>
      <div className="col-12">
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Display Name</label>
              <CustomInput
                id="user-display-name"
                type="text"
                value={displayName}
                isEditable={true}
                errorMessage={errors['display-name']}
                onChange={e => updateValue('displayName', e.target.value)}
              />
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Email</label>
              <CustomInput
                id="user-email"
                type="text"
                value={email}
                isEditable={true}
                errorMessage={errors['email']}
                onChange={e => updateValue('email', e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>New Password</label>
              <CustomInput
                id="user-password"
                type="password"
                value={password}
                isEditable={true}
                autoComplete={'new-password'}
                errorMessage={errors['password']}
                onChange={e => updateValue('password', e.target.value)}
              />
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Confirm Password</label>
              <CustomInput
                id="user-confirm-password"
                type="password"
                value={confirmPassword}
                autoComplete={'new-password'}
                isEditable={true}
                errorMessage={errors['confirm-password']}
                onChange={e => updateValue('confirmPassword', e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="active-user">Active</label>
              <CustomToogle id="active-user" onChange={e => updateValue('active', e.target.checked)} checked={active} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex justify-content-end">
            <button className="btn btn-primary" onClick={() => saveChanges(errors)} disabled={isSaving}>
              {isSaving ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                  <span className="sr-only">Loading...</span>
                </>
              ) : (
                'Update'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateUser;
