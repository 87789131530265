import { put, takeLatest, select } from 'redux-saga/effects';
import API from '../services/api';
import types from '../redux/types.js';

function* fetchNotificationTypes() {
  try {
    const user = yield select(state => state.dashboardReducer.user);
    if (user) {
      const notificationTypeResponse = yield API.getNotificationTypes();
      yield put({
        type: types.LOAD_NOTIFICATION_TYPES,
        payload: notificationTypeResponse.types
      });
    }
  } catch (err) {
    yield put({
      type: types.SET_ALERT,
      payload: API.generateErrorAlert("Couldn't fetch Notification Types. Try to refresh this page.")
    });
  }
}

function* fetchNotificationPreferences() {
  try {
    const user = yield select(state => state.dashboardReducer.user);
    if (user) {
      const notificationPreferencesResponse = yield API.getNotificationPreferences();
      yield put({
        type: types.LOAD_NOTIFICATION_PREFERENCES,
        payload: notificationPreferencesResponse.preferences
      });
    }
  } catch (err) {
    yield put({
      type: types.SET_ALERT,
      payload: API.generateErrorAlert("Couldn't fetch Notification Types. Try to refresh this page.")
    });
  }
}

function* fetchNotifications() {
  const user = yield select(state => state.dashboardReducer.user);
  if (user) {
    const notificationResponse = yield API.getNotifications();
    yield put({
      type: types.LOAD_NOTIFICATIONS, payload: notificationResponse.notifications.map(notification => {
        if (notification.type && notification.type.id) {
          const { id } = notification.type;
          const [service, status] = id.split("_");
          notification.status = status;
          notification.service = service.toLowerCase();
        }
        return notification;
      })
    });
  }
}

function* notificationSideFX() {
  yield takeLatest(types.UPDATE_USER, fetchNotifications);
  yield takeLatest(types.UPDATE_USER, fetchNotificationTypes);
  yield takeLatest(types.LOAD_NOTIFICATION_TYPES, fetchNotificationPreferences);
}

export default notificationSideFX;