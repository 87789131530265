import React, { useState } from 'react';
import CustomToogle from '../customToogle';

import config from '../../config';
import utils from '../../utils';

const DashboardPreferences = () => {
  const [showMask, setShowMask] = useState(utils.getShowMask());

  const { hostname } = window.location;
  const isLocalEnv = hostname === 'localhost' || hostname === '127.0.0.1';

  return (
    <div className="section">
      <div className="section-title">
        Dashboard
      </div>
      <div className="section-content">
        <div className="preference-control">
          <label>Version</label>
          <div>{`${config.appVersion} #${isLocalEnv ? 'local' : config.commitHash}`}</div>
        </div>
        <div className="preference-control mask-toggle">
          <label>Mask Data</label>
          <CustomToogle
            id="mask-toggle"
            checked={showMask}
            onChange={e => {
              setShowMask(e.target.checked);
              utils.setShowMask(e.target.checked);
            }} />
        </div>
      </div>
    </div>
  );
}

export default DashboardPreferences;