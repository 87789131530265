import React from 'react';
import './styles.scss';

const CustomTag = ({ label, value, onClose, disabled }) => {
  return (
    <div className={`tag${disabled ? ' disabled' : ''}`}>
      <div className="tag-text">
        { label &&
          <span className="tag-label">{label + ":"}</span>
        }
        <span>{value}</span>
      </div>
      {!disabled &&
        <button onClick={() => onClose({value, label})} className="tag-close">
          <i className='uil uil-times'></i>
        </button>
      }
    </div>
  );
}

export default CustomTag;