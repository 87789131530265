import { takeLatest, call } from 'redux-saga/effects';
import credentialsStore from '../services/credentialsStore';
import types from '../redux/types.js';

function* updateLocalStore({ payload }) {
  try {
    yield call(credentialsStore.put, payload);
  } catch (err) {
    console.error(err);
  }
}

function* userSideFX() {
  yield takeLatest(types.UPDATE_USER, updateLocalStore);
}

export default userSideFX;