import DevicesContainer from './devices';
import SummaryContainer from './summary';
import AnalyticsContainer from './analytics';
import ProfileContainer from './profile';
import AdvancedContainer from './advanced';
import PresetsContainer from './presets';
import UserContainer from './user';
import InsightsContainer from './insights';
import ReportsContainer from './reports';
import ProjectsContainer from './projects';
import NotificationsContainer from './notifications';
import IntegrationsContainer from './integrations';

import './styles.scss';

export default {
  DevicesContainer,
  SummaryContainer,
  AnalyticsContainer,
  UserContainer,
  ProfileContainer,
  InsightsContainer,
  AdvancedContainer,
  PresetsContainer,
  ReportsContainer,
  ProjectsContainer,
  NotificationsContainer,
  IntegrationsContainer,
};