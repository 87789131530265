import React, { useEffect, useState } from 'react';
import InfinityLoader from '../dropdownSearch/infinityLoader';
import API from '../../services/api';
import utils from '../../utils';
import { useSelector } from 'react-redux';
import { getSelectedCompany } from '../../redux/company/actions';
import { getSelectedProject } from '../../redux/projects/actions'

const VersionSelector = ({ appName, onSelect, filterObject, selected, disabled }) => {
  const [currentVersions, setCurrentVersions] = useState([]);
  const [currentFilter, setCurrentFilter] = useState('');

  const company = useSelector(getSelectedCompany)
  const project = useSelector(getSelectedProject)

  useEffect(() => {
    onLoadVersions();
  }, [appName]);

  const onLoadVersions = (nextPageToken = '', searchingBy = '') => {
    const query = encodeURI(`applicationVersions ILIKE "%${searchingBy}%"`);
    const filter = searchingBy !== '' ? query : '';

    if (filter !== currentFilter) {
      setCurrentVersions([]);
    }

    return API.getVersion(company.id, project.id, appName, nextPageToken, filter).then(res => {
      const fetchedVersions = res.applicationVersions.map(version => ({
        label: `${appName}: v${version}`,
        value: `"${version}"`
      }));

      // Reset list when filter changes
      if (filter !== currentFilter) {
        setCurrentFilter(filter);
        setCurrentVersions(fetchedVersions);
      } else {
        const newVersions = utils.mergeArraysByField(fetchedVersions, currentVersions, 'value');
        setCurrentVersions(newVersions);
      }

      return res.nextPageToken;
    });
  };

  return (
    <div className="version-selector">
      <InfinityLoader
        title={'Search Version'}
        leftAlign
        selected={selected}
        disabled={disabled}
        onSelect={(selectedValue) => onSelect(selectedValue, { ...filterObject, options: currentVersions })}
        onLoading={onLoadVersions}
        totalSize={9999}
        options={currentVersions} />
    </div>
  );
};

export default VersionSelector;