import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import CustomButton from '../../../../components/customButton';
import UsersModal from '../../newProjectSteps/users';

const AddUserModal = ({
    toggle,
    modalOpen,
    project,
    newUsers,
    addNewUsers,
    bindUsers
  }) => {
  return (
    <Modal className="modal-add-user" isOpen={modalOpen} fade={false} toggle={toggle} centered>
      <div className="modal-header">
        <h2>Add users to the project</h2>
        <h4>Search for users or register a new one</h4>
      </div>
      <ModalBody>
        <UsersModal project={{ ...project, users: newUsers }} updateProject={addNewUsers} />
      </ModalBody>
      <ModalFooter>
        <div className="button-container">
          <CustomButton classes={'btn-secondary'} title={'Cancel'} handleClick={toggle}/>
          <CustomButton classes={'btn-primary'} title={'Save'} handleClick={bindUsers}/>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AddUserModal;