import React, { useEffect, useRef, useState } from 'react';

// Filter components
import FilterHeader from './filterHeader';
import FilterSections from './filterSections';
import FilterTags from './filterTags';
import FilterButton from './filterButton';

//Utilities
import filterUtils from '../../utils/filters';
import utils from '../../utils';
import './style.scss';

const MultipleFilters = ({ items, filters, onFilter, renderAsButton, customAppliedFilters, onlyIcon, applyFiltersOnMount = false }) => {
  const [appliedFilters, setAppliedFilters] = useState(customAppliedFilters ? customAppliedFilters : []);
  const [filteredCounters, setFilteredCounters] = useState({});
  const componentMounted = useRef(false);

  useEffect(() => {
    const filterResult = filterUtils.filterItems(items, appliedFilters);
    setFilteredCounters(filterResult.counters);
    if (componentMounted.current || applyFiltersOnMount) {
      onFilter(filterResult.filteredItems, appliedFilters);
    }
    componentMounted.current = true;
  }, [appliedFilters, items]);

  useEffect(() => {
    if (!utils.compareArrays(customAppliedFilters, appliedFilters)) {
      setAppliedFilters(customAppliedFilters);
    }
  }, [customAppliedFilters]);

  /**
   * Handles selection on a filter
   * @param {String} selectedValue Value selected on a filter
   * @param {Filter} filter that has the value
   */
  const onFilterSelected = (selectedValue, filter, optionId) => {
    const selectedOption = filter.options.find(option => {
      if (optionId) {
        return option.id === optionId;
      }
      return option.value === selectedValue;
    });
    const sectionFilter = filterUtils.getSectionFilter(appliedFilters, filter);

    // If exists already a filter selected in this section
    if (sectionFilter.length > 0) {
      //remove previous option.
      let newAppliedFilter = appliedFilters.filter(remove => remove !== sectionFilter[0]);

      //If the option is not selected, just replace
      if (selectedValue !== sectionFilter[0].value || optionId !== sectionFilter[0].id) {
        newAppliedFilter = [...newAppliedFilter, { ...selectedOption, path: filter.path, key: filter.key }];
      }
      setAppliedFilters(newAppliedFilter);
    }
    // If there are no filters selected for the section
    else {
      // Add on the current section the new filter selected
      if (!appliedFilters.some(appliedFilter => appliedFilter.value === selectedValue)) {
        setAppliedFilters([...appliedFilters, { ...selectedOption, path: filter.path, key: filter.key }]);
      }
      //remove filter if it was clicked again.
      else {
        setAppliedFilters(appliedFilters.filter(remove => remove !== selectedOption));
      }
    }
  };

  if (renderAsButton) {
    return (
      <FilterButton
        appliedFilters={appliedFilters}
        onClear={() => setAppliedFilters([])}
        filters={filters}
        onlyIcon={onlyIcon}
        onFilterSelected={onFilterSelected}
        direction="up"
      />
    );
  }

  return (
    <div className="multiple-filter">
      <FilterHeader renderClearButton={appliedFilters.length > 0} onClear={() => setAppliedFilters([])} />
      <FilterTags
        items={appliedFilters}
        counters={filteredCounters}
        onClose={tag => setAppliedFilters(appliedFilters.filter(remove => remove !== tag))}
      />
      <div className="horizontal-separator"></div>
      <div className="multiple-filter-filters">
        <FilterSections
          filters={filters}
          appliedFilters={appliedFilters}
          onSelect={(option, filter, optionId) => onFilterSelected(option, filter, optionId)}
        />
      </div>
    </div>
  );
};

export default MultipleFilters;
