import config from '../../config';
import API from '../api';

//#region Types
/**
 * @typedef Company
 * @type {Object}
 * @property {string} createTime
 * @property {string} deleteTime
 * @property {string} displayName
 * @property {string} id
 * @property {string} name
 * @property {string} ownerId
 * @property {string} updateTime
 */

/**
 * @typedef Binding
 * @type {Object}
 * @property {string[]} members - Array of member strings.
 * @property {'admobilize.owner'|'admobilize.viewer'|'admobilize.editor'} role - Role assigned to the members.
 */

/**
 * @typedef Policy
 * @type {Object}
 * @property {Binding[]} bindings - Array of bindings.
 * @property {string} id - Policy ID.
 * @property {string} resource - Resource associated with the policy.
 */
//#endregion

//#region READ
/**
 * List all companies of the authenticated user.
 * @param {string} page - page token (first page is defined as empty string).
 * @param {string} filter - Filter expression.
 * @param {string} orderBy - Order expression.
 * @returns {{companies: Company[], nextPageToken: string}} List of companies.
 */
const getCompanies = async (page = '', filter = '', orderBy = 'displayName, id') => {
  await API.refreshTokenWhenNeeded();
  const url = `${config.coreApi.baseUrl}/companies?pageSize=40&pageToken=${page}&filter=${filter}&orderBy=${orderBy}`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
}

/**
 * Gets a single company that the users has the access.
 * @param {string} cid - Company ID.
 * @returns {Company} Company.
 */
const getCompany = async (cid) => {
  await API.refreshTokenWhenNeeded();
  
  const url = `${config.coreApi.baseUrl}/companies/${cid}`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};

/**
 * Gets a company policy.
 * @param {string} cid - Company ID.
 * @returns {Policy} Company policy.
 */
const getCompanyPolicy = async (cid) => {
  await API.refreshTokenWhenNeeded();
  
  const url = `${config.coreApi.baseUrl}/companies/${cid}:getPolicy`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
}
//#endregion

//#region UPDATE
/**
 * Updates a company.
 * @param {Company} company - Company to be updated.
 * @param {string[]} fields - Company fields being updated. Comma separated.
 * @returns {Company} Company updated.
 */
const updateCompany = async (company, fields) => {
  await API.refreshTokenWhenNeeded();
  
  const paths = `updateMask=${fields.join(',')}`;
  const body = {};

  fields.forEach(field => {
    body[field] = company[field];
  });

  const url = `${config.coreApi.baseUrl}/companies/${company.cid}?${paths}`;

  return fetch(url, {
    method: 'PATCH',
    headers: API.headers,
    body: JSON.stringify({ body })
  }).then(res => res.json());
}

/**
 * Sets a company policy
 * @param {string} cid - Company ID.
 * @returns {Policy} Updated policy.
 */
const setCompanyPolicy = async (cid) => {
  await API.refreshTokenWhenNeeded();

  const url = `${config.coreApi.baseUrl}/companies/${cid}:setPolicy`;

  return fetch(url, {
    method: 'PATCH',
    headers: API.headers,
  }).then(res => res.json());
}
//#endregion

//#region DELETE
/**
 * Deletes a single company
 * @param {string} cid - Company ID.
 * @returns {{}}
 */
const deleteCompany = async (cid) => {
  await API.refreshTokenWhenNeeded();
  const url = `${config.coreApi.baseUrl}/companies/${cid}`;
  return fetch(url, {
    method: 'DELETE',
    headers: API.headers,
  }).then(res => res.json());
};
//#endregion

export {
  getCompanies,
  getCompany,
  getCompanyPolicy,
  updateCompany,
  setCompanyPolicy,
  deleteCompany
}