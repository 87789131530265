// Common
import React, { useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedProject } from '../../redux/projects/actions';
import { setAlert, getUser } from '../../redux/dashboard/actions';

// Components
import DevicesTable from './devicesTable';
import SidePanel from '../../components/sidepanel';
import MultipleFilters from '../../components/multipleFilters';
import DetailsDrawer from '../../components/detailsDrawer';
import ToolBar from '../../components/toolbar';
import deviceSections from './deviceSections';
import ManageZoneModal from './manageZoneModal';
import StatusBar from '../../components/statusBar';

// Utilities
import API from '../../services/api';
import utils from '../../utils';
import filterUtils from '../../utils/filters';
import parse from '../../utils/device/parse';

import './styles.scss';
import { getSelectedCompany } from '../../redux/company/actions';

const DevicesContainer = ({ lastUpdated }) => {
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [defaultConfig, setDefaultConfig] = useState(null);
  const [solutionConfig, setSolutionConfig] = useState(null);
  const [licenses, setLicenses] = useState(null);
  const [searchBy, setSearchBy] = useState('');
  const [filterBy, setFilterBy] = useState([]);
  const [countDevices, setCountDevices] = useState(null);
  // Flags
  const [showFilters, setShowFilters] = useState(window.localStorage.getItem('device_filter_is_open') === 'true');
  const [hideDetails, setHideDetails] = useState(true);
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [isAdvertiserUser, setIsAdvertiserUser] = useState(false);
  const [zonesModalIsOpen, setZonesModalIsOpen] = useState(false);


  const project = useSelector(getSelectedProject);
  const company = useSelector(getSelectedCompany);
  const user = useSelector(getUser);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchLicenseInfo();
    setHideDetails(true);
    setSearchBy('');
  }, [project]);

  useEffect(() => {
    setIsAdminUser(user && (user.impersonator || user.permissions['support'] === true));
    setIsAdvertiserUser(user && user.permissions['advertiser'] === true);
  }, [user]);

  const onDeviceSelected = (device) => {
    if (!isAdvertiserUser) {
      // Open Details Drawer with loading animation
      setHideDetails(device === null);
      setShowFilters(false);
      // Get selected device details to render on the DetailsDrawer
      API.getDeviceConfig(device.companyId,device.id)
        .then(configResponse => {
          setSelectedDevice({
            ...device,
            config: parse.parseDeviceConfig(configResponse),
            currentPresetId: configResponse.currentPresetId
          });
          setDefaultConfig(configResponse.defaultConfig);
          setSolutionConfig(configResponse.solutionConfig);
        })
        .catch(() => {
          const alert = utils.generateAlert('Something is wrong. We had problems to render this device data.', 'error');
          setSelectedDevice(null);
          setHideDetails(true);
          setShowFilters(true);

          dispatch(setAlert(alert));
        });
    } else {
      setSelectedDevice({ details: device });
    }
  };

  const onConfigChanged = updatedConfig => {
    setSelectedDevice({
      ...selectedDevice,
      config: parse.parseDeviceConfig(updatedConfig),
      currentPresetId: updatedConfig.currentPresetId
    });
    setDefaultConfig(updatedConfig.defaultConfig);
    setSolutionConfig(updatedConfig.solutionConfig);
  };

  const fetchLicenseInfo = () => {
    if (project) {
      API.getLicensesFromProject(project.id)
        .then(response => {
          setLicenses(response.licenses);
        })
        .catch(console.error);
    }
  };

  const handleSearch = useDebouncedCallback((term) => {
    setSearchBy(term);
  }, 300);

  const toggleManageZones = () => setZonesModalIsOpen(!zonesModalIsOpen);

  const closeDetailsDrawer = () => {
    setHideDetails(true);
    setSelectedDevice(null);
  };

  const openFilterDrawer = (isOpen) => {
    let keepClosed = isOpen;
    if (hideDetails) {
      keepClosed = true;
    }

    setHideDetails(keepClosed);
    setShowFilters(isOpen);

    window.localStorage.setItem('device_filter_is_open', isOpen);
  };

  return (
    <div className="devices">
      <ToolBar
        title={'Devices'}
        icon={'deviceOutlineDark'}
        count={countDevices}
        searchPlaceholder={'Name, Solution, Id'}
        onSearch={handleSearch}
        searchingBy={searchBy}
        customOptions={<StatusBar lastUpdated={lastUpdated} />}
      />
      <div className="devices-container">
        <SidePanel
          content={
            <MultipleFilters
              items={[]}
              filters={filterUtils.getFilters(['Status', 'Solution', 'Archived', 'Malos Version', 'Admprovider Version'], isAdminUser)}
              onFilter={(filteredDevices, appliedFilters) => {
                setFilterBy(appliedFilters);
              }}
            />
          }
          isOpen={showFilters}
          onToggle={openFilterDrawer}
        />
        <div className={`d-flex flex-fill ${!hideDetails || showFilters ? 'split-view' : ''}`}>
          { project 
            ? <DevicesTable
              project={project}
              company={company}
              isAdminUser={isAdminUser}
              isAdvertiserUser={isAdvertiserUser}
              searchBy={searchBy}
              filterBy={filterBy}
              lastUpdated={lastUpdated}
              isItemDetailsClosed={!showFilters && hideDetails}
              onDeviceCountChanged={setCountDevices}
              onItemSelected={onDeviceSelected} />
            : (
              <div className="empty-device-table flex-column">
                <h1>No data found!</h1>
                <p>Nothing to see here. Try choosing another site group or creating a new one.</p>
              </div>
            )
          }
        </div>
        {!isAdvertiserUser && (
          <DetailsDrawer
            title="Device Details"
            sections={deviceSections(
              selectedDevice,
              solutionConfig,
              defaultConfig,
              toggleManageZones,
              onConfigChanged,
              isAdminUser,
              licenses
            )}
            selectedDevice={selectedDevice}
            solutionConfig={solutionConfig}
            defaultConfig={defaultConfig}
            onClose={closeDetailsDrawer}
            hidden={hideDetails}
            customModal={
              <ManageZoneModal
                selectedDevice={selectedDevice}
                solutionConfig={solutionConfig}
                defaultConfig={defaultConfig}
                isPowerUser={isAdminUser}
                zonesModalIsOpen={zonesModalIsOpen}
                toggleManageZones={toggleManageZones}
                onConfigChanged={onConfigChanged}
              />
            }
          />
        )}
      </div>
    </div>
  );
}

export default DevicesContainer;
