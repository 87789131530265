import React, { forwardRef, useEffect, useState } from 'react';
import { format, compareAsc } from 'date-fns';
import utils from '../../utils';
import './style.scss';

const DATE_FORMAT = 'MM/dd/yyyy';

const CustomDateInput = forwardRef(({ startDate, endDate, handleChange, setCalendarOpen }, ref) => {
  const [startDateStr, setStartDateStr] = useState('');
  const [endDateStr, setEndDateStr] = useState('');

  useEffect(() => {
    setStartDateStr(format(startDate, DATE_FORMAT));
  }, [startDate]);

  useEffect(() => {
    if (endDate !== null) {
      const result = format(endDate, DATE_FORMAT);
      setEndDateStr(result);
    }
  }, [endDate]);

  /* 
    Created this function to force selected initial month to show.
    We looked on documentation and forums, there is no solution up to this date.
    https://github.com/Hacker0x01/react-datepicker/issues/2411
  */
  const forceCalendarRedraw = () => {
    setCalendarOpen(false);
    setTimeout(() => setCalendarOpen(true), 0.1);
  };

  const handleStartChange = start => {
    setStartDateStr(start);
    // End date will be always after start Date
    if (utils.isValidDateString(start)) {
      const firstDate = new Date(start);
      let lastDate = endDate;
      const checkDates = compareAsc(firstDate, lastDate);
      if (checkDates === 1 || checkDates === 0) {
        lastDate = firstDate;
      }
      const dates = [new Date(start), lastDate];
      forceCalendarRedraw();
      handleChange(dates);
    }
  };

  const handleEndChange = end => {
    setEndDateStr(end);

    if (utils.isValidDateString(end)) {
      let firstDate = startDate;
      const lastDate = new Date(end);
      const checkDates = compareAsc(firstDate, lastDate);
      if (checkDates === 1 || checkDates === 0) {
        endDate = lastDate;
        handleStartChange(end);
      } else {
        const dates = [startDate, lastDate];
        handleChange(dates);
      }
    }
  };

  return (
    <div
      ref={ref}
      className="DateRangePicker"
      onClick={() => {
        setCalendarOpen(true);
      }}
    >
      <i className="uil uil-schedule"></i>
      <input
        type="text"
        className="react-datepicker-ignore-onclickoutside"
        value={startDateStr}
        onChange={e => {
          handleStartChange(e.target.value);
        }}
      />
      <div className="hifen">-</div>
      <input
        type="text"
        className="react-datepicker-ignore-onclickoutside"
        value={endDateStr}
        onChange={e => {
          handleEndChange(e.target.value);
        }}
      />
    </div>
  );
});

export default CustomDateInput;
