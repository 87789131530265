import React from 'react';
import './styles.scss';

const Card = ({ header, body, footer, status, onPress, classes }) => {
  return (
    <div className={`custom-card ${classes || ''}`} onClick={onPress}>
      { status &&
        <span className="card-status">{status}</span>
      }
      { header &&
        <div className="card-header">
          { header }
        </div>
      }
      { body &&
        <div className="card-body">
          { body }
        </div>
      }
      { footer &&
        <div className="card-footer">
          { footer }
        </div>
      }
    </div>
  )
}

export default Card;