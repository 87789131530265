import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import icons from '../../assets/icons';

const PresetItem = ({ item, isItemSelected, selectCallback, presetDevices }) => {
  const itemClasses = `d-flex align-items-center ${isItemSelected ? 'selected' : ''}`;
  const deviceCount = presetDevices[item.id] ? presetDevices[item.id].length : 0;

  return (
    <li
      key={item.id}
      id={`item-selector-${item.id}`}
      onClick={() => selectCallback(item)}
      className={itemClasses}
    >
      <img src={icons[item.solutionId]} alt={item.displayName} width={24} />
      <div>[{deviceCount}] {item.displayName}</div>

      <UncontrolledTooltip placement="top" target={`item-selector-${item.id}`}>
        {item.displayName}
      </UncontrolledTooltip>
    </li>
  );
};

export default PresetItem;