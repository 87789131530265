import React, { Component } from 'react';

import './styles.scss';

class CustomCheckBox extends Component {
  constructor (props) {
    super(props);
    this.state = {
      selected: props.selected ? props.selected : 'all', // none - some - all
    };
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.selected !== this.props.selected) || (prevProps.disabled !== this.props.disabled)) {
      this.setState({ selected: this.props.selected });
    }
  }

  checkStatus = () => {
    const { selectedItems, totalItems, multiple, filteredSelectedItems } = this.props;
    const { selected } = this.state;

    // Handle multiple selection checkbox status
    if (multiple) {
      let allItemsSelected = false;

      if (filteredSelectedItems !== undefined) {
        allItemsSelected = filteredSelectedItems === totalItems;
      } else {
        allItemsSelected = selectedItems === totalItems;
      }

      if (allItemsSelected) {
        return (
          <span className='custom-checkbox selected all'>
            <i className='uil uil-check' />
          </span>
        );
      } else if (selectedItems > 0) {
        return (
          <span className='custom-checkbox selected some'>
            <i className='uil uil-minus' />
          </span>
        );
      }
    } else {
      // Handle default checkbox use case
      if (selected === 'all') {
        return (
          <span className='custom-checkbox selected all'>
            <i className='uil uil-check' />
          </span>
        );
      }
    }

    return (
      <span className='custom-checkbox' />
    );
  }

  handleClick = (e) => {
    if (this.props.disabled) {
      return;
    }
    e.stopPropagation();
    const { selected } = this.state;
    if (selected !== 'none') {
      this.setState({ selected: 'none' });
      this.props.onClick(true);
    } else {
      this.setState({ selected: 'all' });
      this.props.onClick(false);
    }
  }

  renderLabel = (label) => {
    if (typeof label === 'string') {
      return <label className='custom-checkbox-label'>{label}</label>
    }
    return label;
  }

  render () {
    const { label, labelFirst, disabled } = this.props;
    const { selected } = this.state;

    let classList = `checkbox-wrapper ${selected !== 'none' ? 'selected' : ''}`;
    classList = disabled ? `${classList} disabled` : classList;

    return (
      <div
        className={classList}
        onClick={this.handleClick} >
        { (label && labelFirst) && this.renderLabel(label) }
        { this.checkStatus() }
        { (label && !labelFirst) && this.renderLabel(label) }
      </div>
    );
  }
}

export default CustomCheckBox;