import React, { useEffect, useState } from 'react';
import './styles.scss';

const CustomRadioButton = ({ options, onPress, value, className, valueField, displayField, isEditable = true }) => {
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [value, isEditable]);

  const handleChange = (clickedValue, index) => {
    setSelectedValue(clickedValue);
    onPress(clickedValue, index);
  };

  return (
    <div className={`btn-group ${className || ''}`} role="group">
      {options.map((item, index) => (
        <button
          onClick={() => handleChange(item[valueField], index)}
          key={index}
          type="button"
          className={`btn ${item[valueField] === selectedValue ? 'active' : ''}`}
          disabled={item.disabled || !isEditable}
        >
          {item[displayField]}
        </button>
      ))}
    </div>
  );
};

export default CustomRadioButton;
