import React, { useState } from 'react';

// Components
import Comment from './comment';
import CustomTextArea from '../customTextArea';
import CustomButton from '../customButton';

// Utils
import calendar from '../../utils/calendar';
import utils from '../../utils';

//Styles
import './style.scss';

const Comments = ({ commentsList = [], user, onCreate, onSolve, onDelete }) => {
  const [newComment, setNewComment] = useState('');
  const [localComments, setLocalComments] = useState(commentsList);

  const createComment = comment => {
    const date = calendar.getDefaultDate('now');
    const dateString = `${calendar.getDateTime(date, 'date')} - ${calendar.getDateTime(date, 'time')}`;
    const commentObj = {
      id: date,
      date: dateString,
      message: comment,
      sender: user,
      status: 'Unresolved'
    };
    const newLocalComments = [...localComments, commentObj];

    setLocalComments(newLocalComments);
    setNewComment('');
    onCreate(commentObj, newLocalComments);
  };

  const solveComment = commentToSolve => {
    const comments = utils.deepClone(localComments);
    const localComment = comments.find(comment => commentToSolve.id === comment.id);
    const commentId = comments.findIndex(comment => commentToSolve.id === comment.id);
    const date = calendar.getDefaultDate('now');
    const dateString = `${calendar.getDateTime(date, 'date')} - ${calendar.getDateTime(date, 'time')}`;

    localComment.status = `Solved by ${user} on ${dateString}`;
    setLocalComments(comments);
    onSolve(commentId, localComment, comments);
  };

  const deleteComment = commentToDelete => {
    const newLocalComments = localComments.filter(comment => comment.id !== commentToDelete.id);

    setLocalComments(newLocalComments);
    onDelete(
      localComments.findIndex(comment => comment.id === commentToDelete.id),
      newLocalComments
    );
  };

  return (
    <div className="comments-tab-wrapper">
      <div className="create-comment">
        <div className="create-comment-message">
          <CustomTextArea
            id="new-comment"
            rows={3}
            placeholder="Write a new comment..."
            isEditable
            autoFocus
            value={newComment}
            onChange={e => setNewComment(e.target.value)}
          />
        </div>
        <div className="create-comment-footer">
          <div className="create-buttons">
            <CustomButton
              title="Clear"
              classes="btn-danger btn-slim"
              disabled={newComment === ''}
              handleClick={() => setNewComment('')}
            />

            <CustomButton
              classes="btn-primary btn-slim"
              title="Post"
              disabled={newComment === ''}
              handleClick={() => createComment(newComment)}
            />
          </div>
        </div>
      </div>
      <div className="comments">
        {localComments.map(comment => {
          return (
            <Comment
              key={`comment-${comment.id}-${comment.sender}`}
              comment={comment}
              onDelete={comment => deleteComment(comment)}
              onSolve={comment => solveComment(comment)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Comments;
