import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { getSolutionData } from '../../utils/solutions';
import { renderIcon, renderStatus, renderDate, renderFlag } from '../../utils/tables';

const tableHeaders = projects => [
  {
    Header: 'License ID',
    id: 'id',
    type: 'string',
    accessor: license => renderName(license.id, 'id', license.id)
  },
  {
    Header: 'Project',
    id: 'projectId',
    type: 'string',
    accessor: license => renderIdOnHover(license, projects, 'projectId')
  },
  {
    Header: 'Device',
    id: 'deviceId',
    type: 'string',
    accessor: license => (license.deviceId ? renderDeviceName(license) : '--')
  },
  {
    Header: 'Status',
    id: 'deviceStatus',
    type: 'string',
    hideTitle: true,
    accessor: license => (license.deviceId ? renderStatus(license.deviceStatus) : '--'),
    disableSortBy: true
  },
  {
    Header: 'Solution',
    id: 'solution',
    type: 'string',
    accessor: license => (license.solution ? renderSolution(license) : '--'),
    disableSortBy: true
  },
  {
    Header: 'Admin',
    id: 'accountAdmin',
    type: 'string',
    accessor: license => renderName(license.accountAdminName, 'admin', license.id)
  },
  {
    Header: 'Valid From',
    id: 'validFrom',
    type: 'string',
    accessor: license => renderDate(license.validFrom) || '--'
  },
  {
    Header: 'Valid Until',
    id: 'validUntil',
    type: 'string',
    accessor: license => renderDate(license.validUntil, 'valid-until') || '--'
  },
  {
    Header: 'Demo',
    id: 'demo',
    type: 'boolean',
    accessor: license => renderFlag(license.demo)
  }
];

const renderName = (name, fieldName, key) => {
  return (
    <div className="ellipsis">
      <div id={`${fieldName}-${key}`}>{name}</div>
      <UncontrolledTooltip placement="left" target={`#${fieldName}-${key}`}>
        {name}
      </UncontrolledTooltip>
    </div>
  );
};

const renderSolution = data => {
  const icon = data.solution;
  const key = data.id;
  const solutionData = getSolutionData(data.solution);
  const tooltip = solutionData ? solutionData.name : 'none';
  return renderIcon(icon, key, tooltip);
};

const renderIdOnHover = (license, source, fieldName) => {
  const value = license[fieldName];
  const objectFound = source.find(dev => dev.id === license[fieldName]);

  if (!value) return '--';

  const elementId = `${fieldName}-${value}`;

  return (
    <div className="ellipsis">
      <div id={elementId}>{objectFound ? objectFound.displayName : value}</div>
      <UncontrolledTooltip placement="left" target={`#${elementId}`}>
        {value}
      </UncontrolledTooltip>
    </div>
  );
};

const renderDeviceName = license => {
  return (
    <div className="ellipsis">
      <div id={`device-name-${license.id}`}>{license.deviceName ? license.deviceName : license.deviceId}</div>
      <UncontrolledTooltip placement="left" target={`#device-name-${license.id}`}>
        {license.deviceId}
      </UncontrolledTooltip>
    </div>
  );
};

export default tableHeaders;
