import React, { useEffect, useState } from 'react';
import './styles.scss';

const Accordion = ({ items, isEditing, onFocusChange, customHeader, id }) => {
  const [openedItem, setOpenedItem] = useState(0);

  useEffect(() => {
    // Expand first section if current one is not available anymore
    if (!items[openedItem]) {
      setOpenedItem(0);
    }
  }, [items]);

  const toggle = (e) => {
    const event = e.target.dataset.event;
    const collapsableNumber = openedItem === Number(event) ? 0 : Number(event);
    if (isEditing) {
      onFocusChange(() => setOpenedItem(collapsableNumber));
      return;
    }
    setOpenedItem(collapsableNumber);
  }

  return (
    <div id={id} className="accordion">
      {
        items.map((item, index) => (
          <div key={index} className={`accordion-item ${openedItem === index ? 'is-expanded' : ''}`}>
            { customHeader
              ? customHeader(toggle, index, item, openedItem)
              : (<div className="item-header" onClick={toggle} data-event={index}>
              <span data-event={index}>{ item.header }</span>
              <i className={`uil uil-angle-${openedItem === index ? 'down': 'right'}`} data-event={index}></i>
            </div>)}
            <div className="item-body">{ item.body }</div>
          </div>
        ))
      }
    </div>
  );
}

export default Accordion;
