import React, { useEffect, useState } from 'react';
import CustomInput from '../../../../components/customInput';
import utils from '../../../../utils';
import { initializeCredential } from '../../../../utils/integrations';
import Timezone from '../../../../components/timezone';
import CustomButton from '../../../../components/customButton';
import Credential from './credential';
import IntegrationSelector from '../../../../components/filterSelector/IntegrationSelector';

const Integrations = ({ siteGroup, updateSiteGroup }) => {
  const [selectedCms, setSelectedCms] = useState({ label: 'CMS options', data: null, value: null });
  const [displayName, setDisplayName] = useState('');
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState(selectedCms.data ? initializeCredential(selectedCms.data.credentialFields) : []);
  const [credentials, setCredentials] = useState(siteGroup.credentials ? siteGroup.credentials : [])
  const [usedNames, setUsedNames] = useState([]);

  useEffect(() => {
    const validation = {
      'integration-display-name': [
        { key: 'required', value: true },
        { key: 'min', value: 4 },
        { key: 'unique', value: usedNames, customMessage: 'Integration name is already being used!'}
      ]
    };

    const errorList = {
      'integration-display-name': utils.fieldValidation(displayName, validation['integration-display-name']).join(', ')
    };
    setErrors({...errors, ...errorList});
  }, [displayName]);

  useEffect(() => {
    const enabledIntegrations = credentials.map(item => item.credential.cmsId)
    updateSiteGroup({ ...siteGroup, credentials, enabledIntegrations });
  }, [credentials]);

  useEffect(() => {
    const localErrors = {'integration-display-name': errors['integration-display-name']}
    if(selectedCms.data) {
      setValues(initializeCredential(selectedCms.data.credentialFields))
      selectedCms.data.credentialFields.map(item => localErrors[item] = undefined)
    }
    setErrors(localErrors)
  }, [selectedCms]);

  const clearForm = () => {
    setDisplayName('');
    setValues(initializeCredential(selectedCms.credentialFields));
    setSelectedCms({ label: 'CMS options', data: null, value: null });
  };

  const updateForm = (field, value) => {
    const validation = {
      [field]: [
        { key: 'required', value: true },
        { key: 'min', value: 4 }
      ]
    }

    const errorList = {
      [field]: utils.fieldValidation(value, validation[field]).join(', ')
    };
    setErrors({...errors, ...errorList});
    setValues({ ...values, [field]: value || '' });
  };

  const addCredential = (name, values) => {
    const credentialObject = {
      credential: {
        id: `${selectedCms.data.id}-${name}`,
        cmsId: selectedCms.data.id,
        label: `${selectedCms.data.displayName} - ${name}`,
        cmsClient: selectedCms.data.cmsClient,
        displayName: name,
        credential: {
          [selectedCms.data.cmsClient]: {
            ...values
          }
        }
      }
    }
    const localCredentials = utils.deepClone(credentials);
    localCredentials.push(credentialObject)
    setCredentials(localCredentials)
    setUsedNames(localCredentials.map(item => item.credential.displayName))
  };

  const removeCredential = id => {
    let localCredentials = utils.deepClone(credentials);
    localCredentials = localCredentials.filter(item => item.credential.id !== id);
    setCredentials(localCredentials);
    setUsedNames(localCredentials.map(item => item.credential.displayName))
  };

  const handleValueChange = (id, field, value) =>{
    const localCredentials = utils.deepClone(credentials);
    const foundCredential = localCredentials.filter(item => item.credential.id === id)
    const cmsLabel = foundCredential[0].credential.cmsClient
    foundCredential[0].credential.credential[cmsLabel][field[0]] = value
    setCredentials(localCredentials)
  }

  const renderCustomFields = () => {
    if (selectedCms.data) {
      const hasErrors = Object.values(errors).some(value => value !== "");
      return (
        <>
          <div className="form-row">
            <div className="col" key={selectedCms.data.id}>
              <div className="form-group">
                <label>Name</label>
                <CustomInput
                  id="integration-display-name"
                  placeholder="Type a name"
                  type="text"
                  value={displayName}
                  isEditable={true}
                  onChange={e => {
                    setDisplayName(e.target.value);
                  }}
                  autoFocus={true}
                  errorMessage={errors['integration-display-name']}
                />
              </div>
            </div>
            {
            selectedCms.data.credentialFields.map(field => (
              <div className="col" key={field}>
                <div className="form-group">
                  <label>{field}</label>
                  {field === 'pop_timezone' ? (
                    <Timezone
                      onSelect={value => {
                        updateForm(field, value);
                      }}
                    />
                  ) : (
                    <CustomInput
                      id={`new-mappings-${field}`}
                      type="text"
                      value={values[field]}
                      isEditable
                      onChange={e => {
                        updateForm(field, e.target.value);
                      }}
                      errorMessage={errors[field]}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="form-row justify-content-end">
            <CustomButton
              title={'Cancel'}
              disabled={false}
              classes={'btn-danger left-button'}
              handleClick={() => {
                clearForm();
              }}
            />
            <CustomButton
              title={'Add'}
              disabled={hasErrors}
              classes={'btn-primary'}
              handleClick={() => {
                addCredential(displayName, values);
                clearForm();
              }}
            />
          </div>
        </>
      );
    }
  };

  const renderCredentialsList = () => {
    return (
      <div className="credentials">
        {
          credentials.map(item => 
            {
              const field = item.credential.cmsClient
              const credentialObject = item.credential
              return (
                <Credential
                  key={`credential-${credentialObject.id}`}
                  id={credentialObject.id}
                  label={credentialObject.label}
                  values={credentialObject.credential[field]}
                  onRemove={removeCredential}
                  onValueChange={handleValueChange}
                />
              )
            }
          )
        }
      </div>
    );
  };

  return (
    <div className="integration-section">
      <div className="form-group">
        <label>Choose CMS</label>
        <IntegrationSelector
          selected={selectedCms}
          onSelect={setSelectedCms}
          filterObject={{}}
        />
      </div>
      {renderCustomFields()}
      {renderCredentialsList()}
    </div>
  );
};

export default Integrations;
