// Common
import React from 'react';


// Components
import components from '../../components';
import CustomButton from '../../components/customButton';

const { DropdownMenuList } = components;

const ActionButtons = ({ license, buttons }) => {

  const renderOptions = (callback) => {
    return (
      <>
        { buttons &&
          buttons.map(button => (
            <div key={button.key} className="form-group">
              <CustomButton
                title={button.title}
                handleClick={() => {
                  button.onPress(license);
                  callback();
                }}
                classes={'btn-secondary'} />
            </div>
          ))
        }
      </>
    );
  };

  return (
    <div className="action-buttons">
      <DropdownMenuList
        key={license.id}
        onToggle={renderOptions}
        alignRight/>
    </div>
  );
}

export default ActionButtons;