import React, { useState } from 'react';

// Modules
import containers from '../../containers';
import Header from '../../components/header';
import ProjectSelector from '../../components/projectSelector';
import DashboardSelector from '../../components/dashboardSelector/DashboardSelector';
import NotificationTools from '../../components/notificationTools';
import ReloadButton from '../../components/reloadButton';
import TimezoneInfo from '../../components/timezoneInfo';

import './styles.scss';

const { AnalyticsContainer } = containers;

const AnalyticsScreen = () => {
  const [lastUpdated, setLastUpdated] = useState('');

  return (
    <div className="d-flex flex-column flex-fill">
      <Header
        title={'Analytics'}
        filters={
          <div className="d-flex">
            <ProjectSelector/>
            <DashboardSelector collection={'ANALYTICS'} />
          </div>
        }
        icon={'analyticsDark'}
        actions={[
          { type: "reactComponent", value: <TimezoneInfo key="timezone-info" /> },
          {
            type: "reactComponent",
            value: <ReloadButton
              key="reload-btn"
              contentName="Analytics data"
              lastUpdatedCB={setLastUpdated} />
          },
          { type: "reactComponent", value: <NotificationTools key="notification-btn" /> }
        ]} />
      <AnalyticsContainer lastUpdated={lastUpdated}/>
    </div>
  );
}

export default AnalyticsScreen;