import React, { useState } from 'react';

import containers from '../../containers';
import Header from '../../components/header';
import ProjectSelector from '../../components/projectSelector';
import NotificationTools from '../../components/notificationTools';
import ReloadButton from '../../components/reloadButton';
import TimezoneInfo from '../../components/timezoneInfo';

import './styles.scss';

const { DevicesContainer } = containers;

const DevicesScreen = () => {
  const [lastUpdated, setLastUpdated] = useState('');

  return (
    <div>
      <Header
        title={'Management'}
        filters={<ProjectSelector/>}
        icon={'settingsDark'}
        actions={[
          { type: "reactComponent", value: <TimezoneInfo key="timezone-info" /> },
          {
            type: "reactComponent",
            value: <ReloadButton
              key="reload-btn"
              contentName="Device list"
              lastUpdatedCB={setLastUpdated} />
          },
          { type: "reactComponent", value: <NotificationTools key="notification-btn" /> }
        ]} />
      <DevicesContainer lastUpdated={lastUpdated}/>
    </div>
  );
};

export default DevicesScreen;
