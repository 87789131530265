import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { getUser } from '../../redux/dashboard/actions';
import types from '../../redux/types';

import API from '../../services/api';
import components from '../../components';
import utils from '../../utils';

import './styles.scss';

const { Profile } = components;
const {
  UserProfile,
  HelpfulLinks,
  Preferences
} = Profile;

const ProfileContainer = () => {
  const user = useSelector(getUser);
  const dispatch = useDispatch();

  const updateUser = (userData, cb) => {
    const fieldsToUpdate = { email: user.email, active: user.active, ...userData };
    API.updateUser(user.id, fieldsToUpdate)
      .then(() => {
        cb();
        dispatch({
          type: types.SET_ALERT,
          payload: utils.generateAlert('User has been updated successfuly', 'success')
        });
      })
      .catch((error) => {
        cb(error);
        dispatch({
          type: types.SET_ALERT,
          payload: utils.generateAlert(error.message, 'error')
        });
      });
  }

  return (
    <div className="profile-container">
      <UserProfile user={user || {}} onChange={updateUser} />
      <HelpfulLinks />
      <Preferences isAdvertiserUser={user && user.permissions['advertiser']} />
    </div>
  );
}

export default ProfileContainer;