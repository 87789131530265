import React, { Component } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import './styles.scss';

class Selector extends Component {
  constructor (props) {
    super(props);
    this.state = {
      selectedItemsCount: 0,
      selectedItems: {}, // manually selected by the user
    };
  }

  componentDidMount() {
    this.populateItemsList(this.props.preSelected);
  }

  componentDidUpdate(prevProps) {
    const { items, preSelected } = this.props;

    if (prevProps.items !== items || prevProps.preSelected !== preSelected) {
      if (items.length === 0) {
        this.setState({
          selectedItemsCount: 0,
          selectedItems: {}
        });
      } else {
        this.populateItemsList(preSelected);
      }
    }
  }

  /**
   * Do initial items list population using component props and intial values
   */
  populateItemsList = (preSelected) => {
    const { items, fields, multiple, firstShouldBeSelected } = this.props;

    if (items.length > 0) {
      if (firstShouldBeSelected) {
        this.selectOneByPosition(items, 0);
      } else {
        const itemsToSelect = preSelected && preSelected.length > 0 ? preSelected : [];
        if (multiple) {
          this.selectMany(items, itemsToSelect, true);
        } else {
          this.selectMany(items, [], false);
          if (itemsToSelect[0]) {
            this.select(itemsToSelect[0][fields.id]);
          }
        }
      }
    }
  }

  /**
   * Set an item as selected, in the selectedItems list, based on his key
   * @param {String} key key that represents the item to be selected
   */
  select = (key) => {
    const { selectedItems, selectedItemsCount } = this.state;
    const { multiple } = this.props;

    const newSelectedItems = multiple ? {...selectedItems} : {};
    newSelectedItems[key] = !newSelectedItems[key];

    const selectedCount = multiple
      ? newSelectedItems[key]
        ? selectedItemsCount + 1
        : selectedItemsCount - 1
      : 1;

    const selectedState = {
      selectedItems: newSelectedItems,
      selectedItemsCount: selectedCount
    };

    this.setState({ ...selectedState });
    this.props.onSelect({ ...selectedState });
  }

  /**
   * Select many or all items at the same time
   * @param {Object} items items that will be selected
   * @param {Object} selectList given list with pre-selected items
   * @param {boolean} isSelected case is true, set all items as selected
   */
  selectMany = (items, selectList, isSelected) => {
    const selectedItems = {};
    let list = items;
    let value = false;

    if (selectList && selectList.length > 0) {
      list = selectList;
      value = isSelected;
    }

    list.forEach(key => {
      selectedItems[key[this.props.fields.id]] = value;
    });

    this.setState({
      selectedItems,
      selectedItemsCount: value ? list.length : 0
    });
  }

  /**
   * From a given list, select just one item by its position on the list
   * @param {Array} items list that have items
   * @param {Number} position item position on the list
   */
  selectOneByPosition = (items, position) => {
    if (items.length > 0) {
      this.selectMany(items, [], true); // Restart selected list
      this.select(items[position][this.props.fields.id]); // Select only one item
    }
  }

  render() {
    const { items, icon, fields, disabled } = this.props;
    const { selectedItems } = this.state;
    return (
      <ul className="selector">
        {
          items.map((item) => {
            let classes = '';

            if (selectedItems[item[fields.id]]) {
              classes += 'selected ';
            }

            if (disabled) {
              classes += 'disabled ';
            }

            return (
              <li className={classes} key={item[fields.id]} id={item[fields.id]}
                onClick={() => !disabled && this.select(item[fields.id])}>
                  { icon && <img src={ icon } alt=""/> }
                  <div>{ item[fields.name] }</div>
                  { !disabled &&
                    <UncontrolledTooltip placement="top" target={item[fields.id]}>
                      { item[fields.tooltip] }
                    </UncontrolledTooltip>
                  }
              </li>
            )
          })
        }
      </ul>
    );
  }
}

export default Selector;