import React from 'react';
import icons from '../../assets/icons';

const FilterHeader = ({ renderClearButton, onClear }) => {
  return (
    <div className="multiple-filter-header">
      <div className="icon-title">
        <img src={icons.filter} alt="filter" />
        <h3>Applied Filters</h3>
      </div>
      <button className={`btn btn-danger ${renderClearButton ? '' : 'no-filters'}`} onClick={onClear}>
        Clear
      </button>
    </div>
  );
};

export default FilterHeader;
