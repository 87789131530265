import React, { useState } from 'react';

import containers from '../../containers';
import Header from '../../components/header';
import ProjectSelector from '../../components/projectSelector';
import NotificationTools from '../../components/notificationTools';
import ReloadButton from '../../components/reloadButton';
import TimezoneInfo from '../../components/timezoneInfo';

import './styles.scss';
import DashboardSelector from '../../components/dashboardSelector/DashboardSelector';

const { SummaryContainer } = containers;

const SummaryScreen = () => {
  const [lastUpdated, setLastUpdated] = useState('');

  return (
    <div className="d-flex flex-column flex-fill">
      <Header
        title={'Summary'}
        filters={
          <div className="d-flex">
            <ProjectSelector/>
            <DashboardSelector collection={'SUMMARY'} />
          </div>
        }
        icon={'dashboardDark'}
        actions={[
          { type: "reactComponent", value: <TimezoneInfo key="timezone-info" /> },
          {
            type: "reactComponent",
            value: <ReloadButton
              key="reload-btn"
              contentName="Summary data"
              lastUpdatedCB={setLastUpdated} />
          },
          { type: "reactComponent", value: <NotificationTools key="notification-btn" /> }
        ]}/>
      <SummaryContainer lastUpdated={lastUpdated} />
    </div>
  );
};

export default SummaryScreen;