import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import CustomButton from '../customButton';
import CustomInput from '../customInput';

const AddModel = ({ isOpenModal, onAddModel, onClose }) => {
  const [newModel, setNewModel] = useState({});

  useEffect(() => {
    setNewModel({});
  }, [isOpenModal]);

  return (
    <Modal className="modal-add-model" isOpen={isOpenModal} fade={false} toggle={() => onClose(false)} centered>
      <ModalHeader className="header-gray" toggle={() => onClose(false)}>
        Add Model
      </ModalHeader>
      <ModalBody>
        <div className="d-flex flex-column">
          <div className="form-row">
            <div className="col">
              <div className="form-group">
                <label>Type</label>
                <CustomInput
                  id="type-model"
                  type="text"
                  isEditable={true}
                  value={newModel.type || ""}
                  onChange={e => setNewModel({ ...newModel, type: e.target.value.toUpperCase() })} />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label>Version</label>
                <CustomInput
                  id="version-model"
                  type="text"
                  isEditable={true}
                  value={newModel.version || ""}
                  onChange={e => setNewModel({ ...newModel, version: e.target.value })} />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <CustomButton
              title="Add Model"
              disabled={!newModel.type || !newModel.version}
              classes="btn-primary"
              handleClick={() => onAddModel(newModel)}/>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default AddModel;