import React, { useState } from 'react';
import icons from '../../assets/icons';
import CustomButton from '../customButton';
import './styles.scss';

const Wizard = ({ title, icon, steps = [], onCancel, onSubmit }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [submiting, setSubmiting] = useState(false);

  const next = () => setCurrentStep(currentStep + 1);
  const previous = () => setCurrentStep(currentStep - 1);

  const canGoNext = step => {
    if (step.required) {
      return step.validation();
    }
    return true;
  };

  const renderLeftButton = step => {
    if (currentStep === 0) {
      return step.hideCancelButton ? null : (
        <CustomButton classes={'btn btn-secondary'} title="Cancel" handleClick={onCancel} />
      );
    }
    return <CustomButton classes={'btn btn-secondary'} title="Previous" handleClick={previous} />;
  };

  const renderRightButton = step => {
    const buttonSubmitTitle = step.submitButtonTitle ? step.submitButtonTitle : 'Submit';
    const buttonNextTitle = step.required || (step.modified && step.modified()) ? 'Next' : 'Skip';

    return currentStep === steps.length - 1 ? (
      <CustomButton
        classes={'btn btn-primary'}
        title={buttonSubmitTitle}
        handleClick={() => {
          onSubmit();
          setSubmiting(true);
        }}
        loading={submiting}
      />
    ) : (
      <CustomButton
        classes={'btn btn-primary'}
        title={buttonNextTitle}
        disabled={!canGoNext(step)}
        handleClick={next}
      />
    );
  };

  const renderContent = step => (
    <div className="wizard-content">
      {!step.hideTitle && <h2 className="wizard-content-title">{step.title}</h2>}
      {!step.hideDescription && <h4 className="wizard-content-description">{step.description}</h4>}
      <div className={`step-content ${step.contentClasses ? step.contentClasses : ''}`}>{step.content}</div>
      <div className="buttons">
        {renderLeftButton(step)}
        {renderRightButton(step)}
      </div>
    </div>
  );

  const renderStep = (step, index) => {
    let isBlocked = false;
    for (let i = 0; i < index; i++) {
      if (!canGoNext(steps[i])) {
        isBlocked = true;
      }
    }

    let classes = '';
    if (currentStep === index) {
      classes = 'selected';
    } else if (currentStep > index) {
      classes = 'completed';
    } else {
      classes = isBlocked ? 'blocked' : 'default';
    }

    return (
      <div
        key={`step-${index}`}
        className={`wizard-step ${classes}`}
        onClick={() => {
          // Navigate to the step only if previous steps are not skippable
          if (isBlocked) return;
          setCurrentStep(index);
        }}
      >
        <div className="wizard-step-content">
          <div className="wizard-step-number">{index + 1}</div>
          <div className="wizard-step-info">
            <h3 className="wizard-step-title">{step.title}</h3>
            <h4 className="wizard-step-description">{step.description}</h4>
          </div>
        </div>
        {currentStep > index && <img src={icons.checked} alt={'checked'} />}
      </div>
    );
  };

  if (steps.length === 0) return null;

  return (
    <div className="wizard">
      <div className="wizard-navigation col-4">
        {(title || icon) && (
          <div className="wizard-header">
            <img src={icons[icon]} alt={icon} />
            <h2>{title}</h2>
          </div>
        )}
        {steps.map(renderStep)}
      </div>
      <div className="wizard-content-container col-8">{renderContent(steps[currentStep])}</div>
    </div>
  );
};

export default Wizard;
