import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import messages from './messages';
import './style.scss';

const CustomConfirm = ({ isOpen, cancelCb, confirmCb, messageType, messageContext }) => {
  const message = messages[messageType];

  const cancel = () => {
    if (cancelCb) cancelCb();
  }

  const confirm = () => {
    if (confirmCb) confirmCb();
  }

  return (
    <Modal size="lg" className="custom-confirm" isOpen={isOpen} fade={false} centered>
      <ModalHeader className="header-gray">{ message.title }</ModalHeader>
      <ModalBody>
        <div className="custom-confirm-body">
          { message.actionDetails &&
            <p className="body-action-details">{ message.actionDetails(messageContext) }</p>
          }
          <p className="body-message">
            { message.message }
            {
              (message.link) &&
              <a href={message.link} target="_blank" rel="noopener noreferrer">
                &nbsp;Click here to see more.
              </a>
            }
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="row flex-fill justify-content-end">
          <div className="modal-buttons d-flex">
            <button type="button" className="btn btn-secondary" onClick={() => cancel()}>
              { message.cencelButton ? message.cencelButton : 'Cancel' }
            </button>
            <button type="button" className="btn btn-primary ml-3" onClick={() => confirm()}>
              { message.acceptButton ? message.acceptButton : 'Accept' }
            </button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default CustomConfirm;