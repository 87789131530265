import { combineReducers } from 'redux';
import deviceReducer from './devices/reducer';
import projectReducer from './projects/reducer';
import dashboardReducer from './dashboard/reducer';
import notificationReducer from './notification/reducer';
import siteGroupReducer from './sitegroups/reducer';
import siteReducer from './sites/reducer';
import companyReducer from './company/reducer';

const dashboard = combineReducers({
  deviceReducer,
  projectReducer,
  dashboardReducer,
  notificationReducer,
  siteGroupReducer,
  siteReducer,
  companyReducer
});

export default dashboard;
