import React from 'react';

import containers from '../../containers';
import components from '../../components';

import './styles.scss';

const { PresetsContainer } = containers;
const { Header, TimezoneInfo, NotificationTools } = components;

const Presets = () => (
  <div className="presets-screen">
    <Header
      title={'Advanced\xa0Settings'}
      icon={'advancedSettingsDark'}
      actions={[
        { type: "reactComponent", value: <TimezoneInfo key="timezone-info" /> },
        { type: "reactComponent", value: <NotificationTools key="notification-btn" /> }
      ]} />
    <PresetsContainer />
  </div>
);

export default Presets;