import React from 'react';
import './styles.scss';

const ALPHABET = Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ');

const CSVTemplate = ({ columns, fileName }) => {

  const renderSampleValues = () => {
    const sampleValues = [];
    for (let i=0; i < columns.length; i++) {
      sampleValues.push(`${ALPHABET[i]}${ALPHABET[i]}${ALPHABET[i]}${ALPHABET[i]}${ALPHABET[i]}`);
    }

    return sampleValues.map((sample) => (
      <div key={sample} className="column">{sample}</div>
    ));
  };

  return (
    <div className="template">
      <div className="template-header" >
        <div className="fake-button close-btn"></div>
        <div className="fake-button resize-btn"></div>
        <div className="fake-button minimize-btn"></div>
        {fileName}
      </div>
      <div className="template-first-row" >
        <div className="corner-square first-column">0</div>
        <div className="template-letters" >
          {
            ALPHABET.slice(0, columns.length).map((letter) => (
              <div key={letter} className="column">{letter}</div>
            ))
          }
        </div>
      </div>
      <div className="template-columns" >
        <div className="first-column">1</div>
        <div className="template-column-names" >
          {
            columns.map((column) => (
              <div key={column} className="column">{column}</div>
            ))
          }
        </div>
      </div>
      <div className="template-values" >
        <div className="first-column">2</div>
        {renderSampleValues()}
      </div>
    </div>
  )
};

export default CSVTemplate;