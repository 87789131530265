import types from '../types.js';
import { appendState, setState } from '../../utils/redux.js';

const initialState = {
  selectedSiteGroup: null,
  selectedDashboard: null,
  siteGroups: [],
  dashboards: []
  //presets: {} -> TODO: In what level it would be? Before was related to solutions, now datasources?
};

const siteGroupReducer = (state = initialState, action) => {
  const {
    CREATE_SITE_GROUP,
    SELECT_SITE_GROUP,
    LOAD_SITE_GROUPS,
    SELECT_SITE_GROUP_DASHBOARD, LOAD_DASHBOARDS
  } = types;

  switch (action.type) {
    case CREATE_SITE_GROUP:
      return appendState(state, 'siteGroups', action.payload);
    case SELECT_SITE_GROUP:
      return setState(state, 'selectedSiteGroup', action.payload);
    case LOAD_SITE_GROUPS:
      return setState(state, 'siteGroups', action.payload);
    case SELECT_SITE_GROUP_DASHBOARD:
      return setState(state, 'selectedDashboard', action.payload);
    case LOAD_DASHBOARDS:
      return setState(state, 'dashboards', action.payload);
    default:
      return state;
  }
};

export default siteGroupReducer;