import React from 'react';
import icons from '../../assets/icons';
import SearchBox from '../searchBox';
import CustomButton from '../customButton';
import './styles.scss';

const ToolBar = ({
  title,
  count,
  icon,
  hasButton,
  buttonTitle,
  buttonPress,
  searchPlaceholder,
  onSearch,
  renderFilters,
  searchingBy,
  customOptions
}) => {
  return (
    <div className="toolbar">
      <div className="d-flex flex-fill">
        <div className="title">
          <img src={icons[icon]} alt={title} />
          <h2>{title}</h2>
        </div>
        {count && <div className="count">{count} total</div>}
        {onSearch && (
          <SearchBox
            searchingBy={searchingBy}
            onChange={onSearch}
            placeholder={searchPlaceholder ? searchPlaceholder : 'Search'}
          />
        )}
        {renderFilters}
      </div>
      {customOptions}
      {hasButton && <CustomButton title={buttonTitle} classes={'btn-primary'} handleClick={buttonPress} />}
    </div>
  );
};

export default ToolBar;
