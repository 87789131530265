import React from 'react';
import './styles.scss';

const Footer = () => {

  return (
    <div className="footer">
      All rights reserved | &copy; <span className="current-year">{(new Date()).getFullYear()}</span>
    </div>
  );
};

export default Footer;
