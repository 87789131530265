import React, { useState } from 'react';

// Components
import IconButton from '../iconButton';

import './styles.scss';

const DropdownMenuList = ({ options, disabled, alignRight, onToggle }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [asyncComponent, setAsyncComponent] = useState(null);

  const classes = `${isOpen ? 'show' : ''} ${alignRight ? 'dropdown-menu-right' : ''}`;

  const toggleDropdown = async (e) => {
    // Don't allow to open device details side menu
    e.stopPropagation();
    setIsOpen(!isOpen);
    setAsyncComponent(await onToggle(() => setIsOpen(false)));
  };

  const renderOptions = () => {
    // The option object should have the keys: title, onPress
    return options.map((option) => (
      <button
        key={option.title}
        className="dropdown-item"
        onClick={option.onPress}>
        { option.title }
      </button>
    ))
  };

  return (
    <div className="dropdown-menu-list">
      <div className="dropdown">
        <IconButton
          id="dropdown-menu-button"
          btnClasses={'outline small options-menu'}
          disabled={disabled}
          onPress={toggleDropdown}
          // tooltip={'More'} // Removed because is causing hover conflict
          icon={'more'} />
        <div
          className={`dropdown-menu ${classes}`}
          aria-labelledby="dropdown-menu-button">
          { onToggle
            ? asyncComponent
              ? asyncComponent
              : <p>Loading...</p>
            : renderOptions()
          }
        </div>
      </div>
    </div>
  );
}

export default DropdownMenuList;