import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import IconButton from '../iconButton';
import icons from '../../assets/icons';
import utils from '../../utils';
import './styles.scss';

class UserDropdown extends Component {
  constructor (props) {
    super(props);

    this.state = {
      dropdownOpen: false
    };
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  stopImpersonating = () => {
    this.toggle();
    this.props.stopImpersonating();
  }

  render () {
    const { dropdownOpen } = this.state;
    const { items } = this.props;
    const user = this.props.user || {};

    return (
      <Dropdown isOpen={dropdownOpen} toggle={this.toggle} id="nav-user">
        <DropdownToggle className="user-button">
          <img src={icons.user} alt={user.email}/>
          <span>User</span>
        </DropdownToggle>
        <DropdownMenu>
          <div className="close-btn-container">
            <IconButton
              id="impersonate-close-btn"
              onPress={this.toggle}
              icon="close"/>
          </div>
          <div id="user-info">
            <h2>{`${user.name}`}</h2>
            <span>{user.email}</span>
            { (user.permissions['support'] || user.impersonator) &&
              <h5>{`ID:\xa0${user.id}`}</h5>
            }
          </div>
          <div className="items-container">
            {
              items.map((item, index) => {
                // Do not render adminOnly items unless user has permission
                if (item.isAdminOnly && (!user.permissions['support'] && !user.impersonator)) {
                  return null;
                }

                if (!item.url) {
                  return (
                    <div className={`dropdown-submenu ${item.external ? 'external' : '' }`} key={`name_${index}${item}`}>
                      <div
                        className="dropdown-header"
                        onClick={() => { this.toggle(); item.action();}}>
                        <div>
                          <img src={icons[item.icon]} alt=""/>
                          { utils.capitalize(item.name) }
                        </div>
                        { item.external && <img className="external-icon" src={icons['externalLinkDark']} alt=""/> }
                      </div>
                    </div>
                  );
                }

                return (
                  <div className={`dropdown-submenu ${item.external ? 'external' : '' }`} key={`name_${index}${item}`}>
                    <a href={item.url} className="dropdown-header" target={item.external && '_blank'} onClick={this.toggle}>
                      <div>
                        <img src={icons[item.icon]} alt=""/>
                        { utils.capitalize(item.name) }
                      </div>
                      { item.external && <img className="external-icon" src={icons['externalLinkDark']} alt=""/> }
                    </a>
                  </div>
                );
              })
            }
          </div>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default UserDropdown;
