import React, { useState } from 'react';
import components from '../../components';
import LicensesContainer from '../../containers/licenses';
import ReloadButton from '../../components/reloadButton';

const { Header, NotificationTools, TimezoneInfo } = components;

const LicensesScreen = () => {
  const [lastUpdated, setLastUpdated] = useState('');

  return (
    <div className="d-flex flex-column flex-fill">
      <Header
        title={'Management'}
        icon={'settingsDark'}
        actions={[
          { type: "reactComponent", value: <TimezoneInfo key="timezone-info" /> },
          {
            type: "reactComponent",
            value: <ReloadButton
              key="reload-btn"
              contentName="Device list"
              lastUpdatedCB={setLastUpdated} />
          },
          { type: "reactComponent", value: <NotificationTools key="notification-btn" /> }
        ]} />
      <LicensesContainer lastUpdated={lastUpdated}/>
    </div>
  );
}

export default LicensesScreen;