const deviceTemplate = {
  details: {
    //tab
    label: 'Details',
    fields: {
      info: {
        //section
        label: 'Info',
        fields: {
          name: {
            //inputs
            label: 'Name',
            type: 'text',
            value: 'Default Name',
            isCopyButtonEnabled: true,
            isEditable: true,
            path: 'displayName'
          },
          deviceId: {
            label: 'Device Id',
            type: 'text',
            value: 'Id',
            isCopyButtonEnabled: true,
            path: 'id'
          },
          provisioningToken: {
            label: 'Provisioning Token',
            type: 'text',
            value: 'Token',
            isCopyButtonEnabled: true,
            path: 'provisioningToken'
          },
          createdAt: {
            label: 'Created At',
            type: 'datetime',
            path: 'createTime',
            showDate: true,
            showTime: true
          }
        }
      },
      licenseDate: {
        label: 'License Dates',
        fields: {
          validFrom: {
            label: 'Valid From',
            type: 'datetime',
            value: '--',
            isEditable: true,
            path: 'validFrom',
            showDate: true,
            showTime: false
          },
          validUntil: {
            label: 'Valid Until',
            type: 'datetime',
            value: '--',
            isEditable: true,
            path: 'validUntil',
            showDate: true,
            showTime: false
          }
        }
      },
      versions: {
        label: 'Versions',
        fields: {
          adm: {
            label: 'ADMProvider',
            type: 'text',
            value: '--',
            path: 'state.admproviderVersion'
          },
          malos: {
            label: 'Malos Vision',
            type: 'text',
            value: '--',
            path: 'state.malosVersion'
          }
        }
      }
    }
  },
  general: {
    label: 'General Settings',
    fields: {
      cameraConfiguration: {
        label: 'Camera Configuration',
        fields: {
          width: {
            label: 'Width',
            value: 800,
            type: 'number',
            minValue: 0,
            isEditable: true,
            path: 'config.driver_config.config.resolution.width'
          },
          height: {
            label: 'Height',
            value: 600,
            type: 'number',
            minValue: 0,
            isEditable: true,
            path: 'config.driver_config.config.resolution.height'
          },
          forceResolution: {
            label: 'Force Resolution',
            value: false,
            type: 'checkbox',
            isEditable: true,
            path: 'config.driver_config.config.force_resolution'
          },
          aspectRatio: {
            label: 'Keep aspect ratio',
            value: true,
            type: 'checkbox',
            isEditable: true,
            path: 'config.driver_config.config.keep_aspect'
          },
          cameraRotation: {
            label: 'Camera Rotation',
            value: 0,
            options: [
              { degree: 0, name: '0°' },
              { degree: 90, name: '90°' },
              { degree: 180, name: '180°' },
              { degree: 270, name: '270°' }
            ],
            valueField: 'degree',
            displayField: 'name',
            type: 'radio',
            isEditable: true,
            path: 'config.driver_config.config.camera_rotation'
          },
          videoSource: {
            label: 'Video Source/Path',
            value: '0',
            type: 'text',
            isEditable: true,
            path: 'config.driver_config.config.source'
          }
        }
      },
      renderOptions: {
        label: 'Render Options',
        fields: {
          blur: {
            label: 'Blur',
            value: false,
            type: 'checkbox',
            isEditable: true,
            path: 'config.driver_config.config.blur_preview'
          },
          blurAmount: {
            label: 'Blur Amount',
            value: 75,
            minValue: 0,
            maxValue: 100,
            suffix: '%',
            step: '1',
            type: 'range',
            valType: 'integer',
            isEditable: true,            
            path: 'config.driver_config.config.blur_amount'
          },
          drawings: {
            label: 'Drawings',
            value: true,
            type: 'checkbox',
            isEditable: true,
            path: 'config.driver_config.config.enable_drawings'
          },
          previewQuality: {
            label: 'Preview Quality',
            value: 80,
            minValue: 0,
            maxValue: 100,
            suffix: '%',
            step: '1',
            type: 'range',
            valType: 'integer',
            isEditable: true,
            path: 'config.driver_config.config.preview_quality'
          },
          framerate: {
            label: 'Framerate Limit',
            value: 15,
            minValue: 0,
            type: 'number',
            isEditable: true,
            path: 'config.driver_config.config.max_fps'
          }
        }
      }
    }
  },
  advanced: {
    //tab
    label: 'Advanced',
    fields: {
      FACEV2: {
        label: 'Audience',
        fields: {
          //sections
          detectionConfiguration: {
            label: 'Detection Configuration',
            fields: {
              //inputs
              showGui: {
                label: 'Show GUI',
                value: false,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.show_gui'
              }
            }
          },
          faceConfiguration: {
            label: 'Face Detection Configuration',
            fields: {
              minimunAge: {
                label: 'Minimum Age',
                value: 0,
                type: 'number',
                isEditable: true,
                path: 'config.driver_config.config.min_reported_age'
              }
            }
          },
          triggers: {
            label: 'Triggers',
            fields: {
              personEntry: {
                label: 'Person Entry Event',
                value: false,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.publish_entry_events'
              }
            }
          },
          advancedModelsConfiguration: {
            label: 'Advanced Models Configuration',
            fields: {
              emotionConfidence: {
                label: 'Emotion Confidence',
                value: 0,
                minValue: 0,
                maxValue: 1,
                step: '0.1',
                type: 'range',
                valType: 'float',
                isEditable: true,
                path: 'config.driver_config.config.emotion_confidence_threshold'
              },
              ageConfidence: {
                label: 'Age Confidence',
                value: 0,
                minValue: 0,
                maxValue: 1,
                step: '0.1',
                type: 'range',
                valType: 'float',
                isEditable: true,
                path: 'config.driver_config.config.age_confidence_threshold'
              },
              genderConfidence: {
                label: 'Gender Confidence',
                value: 0,
                minValue: 0,
                maxValue: 1,
                step: '0.1',
                type: 'range',
                valType: 'float',
                isEditable: true,
                path: 'config.driver_config.config.gender_confidence_threshold'
              },
              skipFrames: {
                label: 'Detector Skip Frames',
                value: 0,
                type: 'number',
                isEditable: true,
                path: 'config.driver_config.config.detector_skip_frames'
              },
              faceCorrector: {
                label: 'Face Corrector Threshold',
                value: 0.7,
                minValue: 0,
                maxValue: 1,
                step: '0.1',
                type: 'range',
                valType: 'float',
                isEditable: true,
                path: 'config.driver_config.config.face_corrector_th'
              },
              personDetection: {
                label: 'Person Detection',
                value: false,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.enable_person_detection'
              },
              fullBody: {
                label: 'Full Body Gender',
                value: false,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.full_body_gender'
              },
              async: {
                label: 'Async Mode',
                value: false,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.async_mode'
              }
            }
          },
          models: {
            label: 'Models',
            fields: {
              model: {
                type: 'models',
                isEditable: true,
                path: 'config.driver_config.models'
              }
            }
          }
        }
      },
      CROWDV3: {
        label: 'Crowd',
        fields: {
          detectionConfiguration: {
            label: 'Detection Configuration',
            fields: {
              showGui: {
                label: 'Show GUI',
                value: false,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.show_gui'
              }
            }
          },
          advancedModelsConfiguration: {
            label: 'Advanced Models Configuration',
            fields: {
              async: {
                label: 'Async Mode',
                value: false,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.async_mode'
              }
            }
          },
          models: {
            label: 'Models',
            fields: {
              model: {
                type: 'models',
                isEditable: true,
                path: 'config.driver_config.models'
              }
            }
          }
        }
      },
      VEHICLECROWD: {
        label: 'Vehicle Crowd',
        fields: {
          vehicleOptions: {
            label: 'Vehicle Options',
            fields: {
              momentOfDay: {
                label: 'Draw Moment of day',
                value: false,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.draw_moment_of_day'
              },
              vehicleType: {
                label: 'Draw Vehicle Type',
                value: true,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.draw_vehicle_type'
              },
              peopleZones: {
                label: 'Ignore Zones for People',
                value: true,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.ignore_zones_for_person'
              },
              speedUnit: {
                label: 'Speed Unit',
                value: 'miles',
                options: [
                  { speed: 'km', name: 'km/h' },
                  { speed: 'miles', name: 'mph' }
                ],
                valueField: 'speed',
                displayField: 'name',
                type: 'radio',
                isEditable: true,
                path: 'config.driver_config.config.speed_unit'
              }
            }
          },
          detectionConfiguration: {
            label: 'Detection Configuration',
            fields: {
              showGui: {
                label: 'Show GUI',
                value: false,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.show_gui'
              },
              useFixedInputSize: {
                isAdminOnly: false,
                label: 'Use fixed input size',
                value: true,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.use_fixed_input_size'
              },
              indexesToFilter: {
                label: 'Allowed tagging labels',
                value: ['person', 'car', 'motorcycle', 'bus', 'bicycle', 'truck'],
                type: 'chiptag',
                isEditable: true,
                path: 'config.driver_config.config.indexes_to_filter'
              }
            }
          },
          statsOptions: {
            label: 'Stats Options',
            fields: {
              showAverage: {
                label: 'Show Average',
                value: false,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.stats_options.show_average'
              },
              showCount: {
                label: 'Show Count per Zone',
                value: true,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.stats_options.show_count_per_zone'
              },
              showSpeed: {
                label: 'Show Speed',
                value: true,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.stats_options.show_speed'
              },
              showAverageSpeed: {
                label: 'Show Average Speed',
                value: false,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.stats_options.show_average_speed'
              }
            }
          },
          nightSettings: {
            label: 'Night Solution Settings',
            fields: {
              useSolution: {
                label: 'Use Night Solution',
                value: false,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.use_night_solution'
              },
              automaticNight: {
                label: 'Automatic Night Mode',
                value: true,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.use_night_solution_automatic'
              }
            }
          },
          advancedModelsConfiguration: {
            label: 'Advanced Models Configuration',
            fields: {
              async: {
                label: 'Async mode',
                value: false,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.async_mode'
              }
            }
          },
          models: {           
            label: 'Models',
            fields: {
              model: {
                type: 'models',
                isEditable: true,
                path: 'config.driver_config.models'
              }
            }
          }
        }
      },
      VEHICLEDETECTIONV1: {
        label: 'Vehicle Detection',
        fields: {
          vehicleOptions: {
            label: 'Vehicle Options',
            fields: {
              momentOfDay: {
                label: 'Draw Moment of day',
                value: false,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.draw_moment_of_day'
              },
              speedUnit: {
                label: 'Speed Unit',
                value: 'km',
                options: [
                  { speed: 'km', name: 'km/h' },
                  { speed: 'miles', name: 'mph' }
                ],
                valueField: 'speed',
                displayField: 'name',
                type: 'radio',
                isEditable: true,
                path: 'config.driver_config.config.speed_unit'
              }
            }
          },
          detectionConfiguration: {
            label: 'Detection Configuration',
            fields: {
              showGui: {
                label: 'Show GUI',
                value: false,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.show_gui'
              }
            }
          },
          statsOptions: {
            label: 'Stats Options',
            fields: {
              showAverage: {
                label: 'Show Average',
                value: false,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.stats_options.show_average'
              },
              showCount: {
                label: 'Show Count per Zone',
                value: true,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.stats_options.show_count_per_zone'
              },
              showSpeed: {
                label: 'Show Speed',
                value: true,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.stats_options.show_speed'
              },
              showAverageSpeed: {
                label: 'Show Average Speed',
                value: false,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.stats_options.show_average_speed'
              }
            }
          },
          nightSettings: {
            label: 'Night Solution Settings',
            fields: {
              useSolution: {
                label: 'Use Night Solution',
                value: false,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.use_night_solution'
              }
            }
          },
          advancedModelsConfiguration: {
            label: 'Advanced Models Configuration',
            fields: {
              async: {
                label: 'Async mode',
                value: false,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.async_mode'
              }
            }
          },
          models: {
            label: 'Models',
            fields: {
              model: {
                type: 'models',
                isEditable: true,
                path: 'config.driver_config.models'
              }
            }
          }
        }
      },
      VEHICLERECOGNITIONV1: {
        label: 'Vehicle Recognition',
        fields: {
          vehicleOptions: {
            label: 'Vehicle Options',
            fields: {
              momentOfDay: {
                label: 'Draw Moment of day',
                value: false,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.draw_moment_of_day'
              },
              vehicleType: {
                label: 'Draw Vehicle Type',
                value: true,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.draw_vehicle_type'
              },
              speedUnit: {
                label: 'Speed Unit',
                value: 'km',
                options: [
                  { speed: 'km', name: 'km/h' },
                  { speed: 'miles', name: 'mph' }
                ],
                valueField: 'speed',
                displayField: 'name',
                type: 'radio',
                isEditable: true,
                path: 'config.driver_config.config.speed_unit'
              }
            }
          },
          detectionConfiguration: {
            label: 'Detection Configuration',
            fields: {
              showGui: {
                label: 'Show GUI',
                value: false,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.show_gui'
              },
              indexesToFilter: {
                label: 'Allowed tagging labels',
                value: ['car', 'motorcycle', 'bus', 'bicycle', 'truck'],
                type: 'chiptag',
                isEditable: true,
                path: 'config.driver_config.config.indexes_to_filter'
              }
            }
          },
          statsOptions: {
            label: 'Stats Options',
            fields: {
              showAverage: {
                label: 'Show Average',
                value: false,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.stats_options.show_average'
              },
              showCount: {
                label: 'Show Count per Zone',
                value: true,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.stats_options.show_count_per_zone'
              },
              showSpeed: {
                label: 'Show Speed',
                value: true,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.stats_options.show_speed'
              },
              showAverageSpeed: {
                label: 'Show Average Speed',
                value: false,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.stats_options.show_average_speed'
              }
            }
          },
          nightSettings: {
            label: 'Night Solution Settings',
            fields: {
              useSolution: {
                label: 'Use Night Solution',
                value: false,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.use_night_solution'
              },
              automaticNight: {
                label: 'Automatic Night Mode',
                value: true,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.use_night_solution_automatic'
              }
            }
          },
          advancedModelsConfiguration: {
            label: 'Advanced Models Configuration',
            fields: {
              async: {
                label: 'Async mode',
                value: false,
                type: 'checkbox',
                isEditable: true,
                path: 'config.driver_config.config.async_mode'
              }
            }
          },
          models: {
            label: 'Models',
            fields: {
              model: {
                type: 'models',
                isEditable: true,
                path: 'config.driver_config.models'
              }
            }
          }
        }
      }
    }
  }
};

export default deviceTemplate;
