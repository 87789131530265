import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../redux/dashboard/actions';
import API from '../../../services/api';

import Comments from '../../../components/comments';
import Loading from '../../../components/loading';

import utils from '../../../utils';

const DeviceComments = ({ device, user, onCommentUpdate }) => {
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (device) {
      API.getDeviceComments(device.id, device.projectId)
        .then(result => {
          setComments(result.comments.map(comment => JSON.parse(comment.text)));
        })
        .catch(error => {
          console.error(error);
          dispatch(setAlert(utils.generateAlert('Could not load device comments', 'error')));
        })
        .finally(() => setIsLoading(false));
    }
  }, [device]);

  const createComment = (comment, commentsList) => {
    const newComment = {
      text: JSON.stringify(comment)
    };

    API.createDeviceComment(device.id, device.projectId, newComment)
      .then(() => {
        dispatch(setAlert(utils.generateAlert('Comment created successfully.', 'success')));
        onCommentUpdate(commentsList);
      })
      .catch(error => {
        console.error(error);
        dispatch(setAlert(utils.generateAlert('Could not create the comment.', 'error')));
      });
  };

  const solveComment = (commentId, solvedComment, commentsList) => {
    const newComment = {
      text: JSON.stringify(solvedComment)
    };

    API.deleteDeviceComment(device.id, device.projectId, commentId)
      .then(() => {
        API.createDeviceComment(device.id, device.projectId, newComment)
          .then(() => {
            dispatch(setAlert(utils.generateAlert('Comment solved successfully.', 'success')));
            onCommentUpdate(commentsList);
          })
          .catch(error => {
            console.error(error);
            dispatch(setAlert(utils.generateAlert('Could not create solved comment.', 'error')));
          });
      })
      .catch(error => {
        console.error(error);
        dispatch(setAlert(utils.generateAlert('Could not delete original comment.', 'error')));
      });
  };

  const deleteComment = (commentId, commentsList) => {
    API.deleteDeviceComment(device.id, device.projectId, commentId)
      .then(() => {
        dispatch(setAlert(utils.generateAlert('Comment deleted successfully.', 'success')));
        onCommentUpdate(commentsList);
      })
      .catch(error => {
        console.error(error);
        dispatch(setAlert(utils.generateAlert('Could not delete the comment.', 'error')));
      });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Comments
      commentsList={comments}
      user={user}
      onCreate={createComment}
      onSolve={solveComment}
      onDelete={deleteComment}
    />
  );
};

export default DeviceComments;
