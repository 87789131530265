import React, { useEffect, useState } from 'react';

import Accordion from '../accordion';
import CustomButton from '../customButton';
import MultiSelector from '../multiSelector';
import ActionButtons from '../actionButtons';
import PreviewModal from '../previewModal';
import DeviceStatus from '../deviceStatus';
import MultipleFilters from '../multipleFilters';

import utils from '../../utils';
import filterUtils from '../../utils/filters';
import API from '../../services/api';

import './styles.scss';
import { useSelector } from 'react-redux';
import { getSelectedProject } from '../../redux/projects/actions';
import { availableCMS } from '../../utils/solutions';
import reportUtils from '../../utils/report';

const AnalyticsSelector = ({
  selectedSolution,
  devices,
  appliedFilters,
  onSelection,
  isAdminUser,
  devicesFilter,
  onDevicesFilterChange
}) => {
  const [medias, setMedias] = useState([]);
  const [advertisers, setAdvertisers] = useState([]);
  const [isLoadingMedias, setIsLoadingMedias] = useState(false);
  const [isLoadingAdvertisers, setIsLoadingAdvertisers] = useState(false);

  const [localFilters, setLocalFilters] = useState({
    selectedDevices: devices,
    selectedAdvertisers: [],
    selectedMedias: []
  });

  const [deviceQuickView, setDeviceQuickView] = useState('');
  const [devicePreview, setDevicePreview] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [filteredDevices, setFilteredDevices] = useState(devices);
  const [cms, setCms] = useState(null);

  const project = useSelector(getSelectedProject);

  useEffect(() => {
    setShowPreviewModal(devicePreview !== null);
  }, [devicePreview]);

  useEffect(() => {
    const cmsCandidate = availableCMS[selectedSolution.id];

    if (cmsCandidate) {
      setCms(cmsCandidate);
    } else {
      // Clear media and advertiser data if current solution is not
      setCms(null);
      mediaCallback([]);
      advertisersCallback([]);
    }
  }, [selectedSolution]);

  useEffect(() => {
    if (cms) {
      setIsLoadingAdvertisers(true);
      setIsLoadingMedias(true);
      fetchCMSFilters('advertisers', cms.table, cms.columns, advertisersCallback);
    }
  }, [cms, appliedFilters.startDate, appliedFilters.startDate]);

  useEffect(() => {
    if (cms) {
      fetchCMSFilters('medias', cms.table, cms.columns, mediaCallback);
    }
  }, [advertisers]);

  const isButtonDisabled = (previousItems, currentItems) => {
    return currentItems.length === 0 || utils.compareArrays(previousItems, currentItems);
  };

  const mediaCallback = (newMedias) => {
    onSelection({selectedMedias: newMedias});
    setMedias(newMedias);
    setLocalFilters({...localFilters, selectedMedias: newMedias});
    setIsLoadingMedias(false);
  };

  const advertisersCallback = (newAdvertisers) => {
    onSelection({selectedAdvertisers: newAdvertisers});
    setAdvertisers(newAdvertisers);
    setLocalFilters({...localFilters, selectedAdvertisers: newAdvertisers});
    setIsLoadingAdvertisers(false);
  };

  const fetchCMSFilters = (fieldName, table, columns, callback) => {
    const field = columns[fieldName];
    const data = reportUtils.generateCMSQueryObject(field, appliedFilters.startDate, appliedFilters.endDate);

    API.getCMSData(project.id, table, data)
      .then(res => {
        const datasets = res.datasets;

        if (datasets && datasets.length > 0) {
          let cmsData = [];
          const datasetValue = datasets[0].value || [];
          if (typeof datasetValue === 'string') {
            cmsData = [{ id: `${fieldName}-0`, name: datasetValue }];
          } else {
            cmsData = datasetValue.sort(utils.compareStrings).map((value, index) => {
              return { id: `${fieldName}-${index}`, name: value };
            });
          }

          // TODO: Look for changes on CMS filter
          // utils.getListIntersection(this.state[selectedCMSData], cmsData, fieldName, this.props.setAlert)
          callback(cmsData);
        } else {
          callback([]);
        }
      })
      .catch(err => {
        console.error(err);
        this.onError(err);
      });
  };

  const openPreview = (e, device) => {
    setDevicePreview(device);
    e.stopPropagation();
  };

  const onQuickView = item => {
    if (deviceQuickView === item.id) {
      onSelection(localFilters);
      setDeviceQuickView('');
    } else {
      onSelection({selectedDevices: [item]});
      setDeviceQuickView(item.id);
    }
  };

  const renderActionButtons = device => {
    const styles = `action-buttons ${deviceQuickView === device.id ? 'is-active' : ''}`;
    return (
      <>
        <DeviceStatus device={device} styles={'show-status'} />
        <div className={styles}>
          <ActionButtons
            buttons={[
              {
                id: `get-preview-${device.id}`,
                icon: 'cmdPreview',
                key: 'get-preview',
                tooltip: 'Take a preview',
                onPress: e => openPreview(e, device),
                disabled: device.state.status.toLowerCase() !== 'online'
              },
              {
                id: `quick-data-${device.id}`,
                icon: deviceQuickView === device.id ? 'soloDark' : 'eyeDark',
                key: 'quick-data',
                tooltip: 'See the data',
                onPress: () => onQuickView(device)
              }
            ]}
          />
        </div>
      </>
    );
  };

  let sections = [
    {
      header: `Devices (${
        (appliedFilters.selectedDevices.length > 0)
          ? appliedFilters.selectedDevices.length
          : devices.length}/${devices.length})`,
      body: (
        <>
          <MultiSelector
            items={filteredDevices}
            onSelect={value => {setLocalFilters({...localFilters, selectedDevices: value})}}
            displayField="displayName"
            emptyMessage="No Devices found"
            customSearchPlaceHolder="Search Device"
            sortField="displayName"
            renderActionButtons={renderActionButtons}
            filters={
              <MultipleFilters
                items={devices}
                filters={filterUtils.getFilters(['Status'], isAdminUser)}
                onFilter={(newFilteredDevices, newAppliedFilters) => {
                  setFilteredDevices(newFilteredDevices);
                  onDevicesFilterChange(newAppliedFilters);
                }}
                customAppliedFilters={devicesFilter}
                renderAsButton
                applyFiltersOnMount
                onlyIcon
              />
            }
          />
          {devices.length > 0 && (
            <CustomButton
              handleClick={() => {
                onSelection(localFilters);
                setDeviceQuickView('');
              }}
              classes="btn-primary btn-slim"
              title="Update Analytics"
              disabled={isButtonDisabled(appliedFilters.selectedDevices, localFilters.selectedDevices)}
            />
          )}
        </>
      )
    },
    {
      header: isLoadingAdvertisers ? 'Loading Advertisers...' : `Campaigns/Advertisers (${appliedFilters.selectedAdvertisers.length}/${advertisers.length})`,
      body: (
        <>
          <MultiSelector
            items={advertisers}
            onSelect={value => {setLocalFilters({...localFilters, selectedAdvertisers: value})}}
            displayField="name"
            emptyMessage="No Campaigns found for this period"
            customSearchPlaceHolder="Search Campaign"
            sortField="name"
          />
          {advertisers.length > 0 && (
            <CustomButton
              handleClick={() => onSelection(localFilters)}
              classes="btn-primary btn-slim"
              title="Apply"
              disabled={isButtonDisabled(appliedFilters.selectedAdvertisers, localFilters.selectedAdvertisers)}
            />
          )}
        </>
      )
    },
    {
      header: isLoadingMedias ? 'Loading Medias...' : `Media (${appliedFilters.selectedMedias.length}/${medias.length})`,
      body: (
        <>
          <MultiSelector
            items={medias}
            onSelect={value => {setLocalFilters({...localFilters, selectedMedias: value})}}
            displayField="name"
            emptyMessage="No Medias found for this period"
            customSearchPlaceHolder="Search Media"
            sortField="name"
          />
          {medias.length > 0 && (
            <CustomButton
              handleClick={() => onSelection(localFilters)}
              classes="btn-primary btn-slim"
              title="Apply"
              disabled={isButtonDisabled(appliedFilters.selectedMedias, localFilters.selectedMedias)}
            />
          )}
        </>
      )
    }
  ];

  if (!selectedSolution.cms) {
    // Remove Advertisers/Media section if CMS toggle is disabled
    sections = sections.slice(0, sections.length - 2);
  }

  return (
    <div className={`side-menu ${selectedSolution.cms ? 'cms-mode' : ''}`}>
      <Accordion items={sections}></Accordion>
      <PreviewModal
        className={'modal-image-preview modal-lg'}
        device={devicePreview}
        showPreviewModal={showPreviewModal}
        onClose={() => {
          setDevicePreview(null);
        }}
      />
    </div>
  );
};

export default AnalyticsSelector;
