import React, { useState, useEffect, useCallback, useRef } from 'react';
import CustomInput from '../customInput';
import CustomDropdown from '../customDropdown';
import utils from "../../utils";

const DEFAULT_MODEL = { type: "Select one model", displayName: "Select one model" };

const RoiInput = ({ roi, namesUsed, updateRoi, models, onChange }) => {

  const [name, setName] = useState("");
  const [x, setX] = useState("");
  const [y, setY] = useState("");
  const [width, setWidth] = useState("");
  const [model, setModel] = useState(null);
  const [model_night, setModelNight] = useState(null);
  const [height, setHeight] = useState("");
  const [models_list, setModelsList] = useState(models);
  const [errors, setErrors] = useState({});

  const setInitValues = (roi, modelsList) => {
    const heightFinal = (roi.height() * roi.scaleY());
    const widthFinal = (roi.width() * roi.scaleX());
    // Look for pre selected model and add displayName
    const selectedModel = roi.model
      ? {...roi.model, displayName: `${roi.model.type} - ${roi.model.version}`}
      : { type: "", displayName: "" };

    const selectedNightModel = roi.model_night
      ? {...roi.model_night, displayName: `${roi.model_night.type} - ${roi.model_night.version}`}
      : { type: "", displayName: "" };

    setName(roi.name());
    setX(parseInt(roi.x()));
    setY(parseInt(roi.y()));
    setWidth(parseInt(widthFinal));
    setHeight(parseInt(heightFinal));
    setModel(selectedModel);
    setModelNight(selectedNightModel);

    if (!roi.model || !roi.model_night) {
      setModelsList([DEFAULT_MODEL, ...modelsList]);
    }
  }

  const hasChanged = useCallback(onChange);
  const modelsRef = useRef(models);

  useEffect(() => {
    if (roi) {
      setInitValues(roi, modelsRef.current);
      roi.on('dragend transformend', () => {
        setInitValues(roi, modelsRef.current);
        hasChanged();
      });
    }
  }, [roi, hasChanged]);

  const updateVariable = (value, key, cb) => {
    if ( key !== 'name') {
      if (value !== "") {
        value = parseInt(value);
      }
    }

    cb(value);
    let newValue = {};
    const validations = {
      'name': [{ key: 'required', value: true }, { key: 'unique', value: namesUsed }],
      'x': [{ key: 'required', value: true }, { key: 'min', value: 0 }],
      'y': [{ key: 'required', value: true }, { key: 'min', value: 0 }],
      'width': [{ key: 'required', value: true }, { key: 'min', value: 0 }],
      'height': [{ key: 'required', value: true }, { key: 'min', value: 0 }],
    }
    const errorFound = utils.fieldValidation(value, validations[key]);
    if (errorFound.length === 0) {
      newValue[key] = value;
      updateRoi(newValue);
    }
    setErrors({ ...errors, [key]: errorFound.join('. ') })
  };

  const updateModel = (index, key, cb) => {
    const DEFAULT_MODEL = { type: "Select one model", displayName: "Select one model" };
    const { type, version } = models_list[index];
    const displayName = `${type} - ${version}`;

    if (type === DEFAULT_MODEL.type) {
      setErrors({ ...errors, [key]: "Select a valid model." })
      return;
    }

    cb({ type, version, displayName });
    const newROI = { [key]: { type, version, displayName } };
    updateRoi(newROI);
  }

  return (
    <div>
      <div className="row">
        <h3 className="section-header">ROI Settings</h3>
      </div>
      <div className="roi-form-container">
        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <label>Name</label>
              <CustomInput
                id="zone-name"
                type="text"
                value={name}
                isEditable={true}
                onChange={e => updateVariable(e.target.value, 'name', setName)}
                errorMessage={errors['name']} />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label>X</label>
              <CustomInput
                id="roi-x"
                type="number"
                value={x}
                isEditable={true}
                erro
                onChange={e => updateVariable(e.target.value, 'x', setX)}
                errorMessage={errors['x']} />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label>Y</label>
              <CustomInput
                id="roi-y"
                type="number"
                value={y}
                isEditable={true}
                onChange={e => updateVariable(e.target.value, 'y', setY)}
                errorMessage={errors['y']} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label>Width</label>
              <CustomInput
                id="roi-width"
                type="number"
                value={width}
                isEditable={true}
                onChange={e => updateVariable(e.target.value, 'width', setWidth)}
                errorMessage={errors['width']} />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>Height</label>
              <CustomInput
                id="roi-height"
                type="number"
                value={height}
                isEditable={true}
                onChange={e => updateVariable(e.target.value, 'height', setHeight)}
                errorMessage={errors['height']} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label>Model</label>
              {model && <CustomDropdown
                items={models_list}
                handleSelection={(event, index) => {
                  updateModel(index, 'model', setModel);
                }}
                selectedItem={model ? model.displayName : DEFAULT_MODEL}
                valueField={'displayName'}
                errorMessage={errors['model']}
                displayField={'displayName'} />
              }
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>Model Night</label>
              {model_night && <CustomDropdown
                items={models_list}
                handleSelection={(event, index) => updateModel(index, 'model_night', setModelNight)}
                selectedItem={model_night ? model_night.displayName : DEFAULT_MODEL}
                valueField={'displayName'}
                errorMessage={errors['model_night']}
                displayField={'displayName'} />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoiInput;
