import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import icons from '../../assets/icons';

import './styles.scss';
import CopyButton from '../copyButton';

const CustomInput = props => {
  const {
    id,
    type,
    value,
    defaultValue,
    placeholder,
    autoFocus,
    isEditable,
    isCopyButtonEnabled,
    isRefreshButtonEnabled,
    refreshButtonClicked,
    isInlineEditEnabled,
    onChange,
    onKeyDown,
    toggleInlineEditState,
    saveChanges,
    tipMessage,
    showErrorMessage = true,
    errorMessage,
    min,
    max,
    className,
    isPasswordToolEnabled,
    showingPassword,
    showPasswordClicked,
    step,
    autoComplete,
    mask,
    alwaysShowMask,
    maskPlaceholder,
    icon,
    iconClasses,
    iconPosition
  } = props;

  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const hasTip = () => {
    if (tipMessage && tipMessage.length > 0) {
      return true;
    }
    return false;
  };

  const hasError = () => {
    if (errorMessage && errorMessage.length > 0) {
      return true;
    }
    return false;
  };

  const renderRefreshButton = () => {
    return (
      <>
        <button type="button" onClick={refreshButtonClicked} className="btn">
          <i className="uil uil-refresh" />
        </button>
      </>
    );
  };

  const renderEditButton = () => {
    return (
      <button type="button" onClick={toggleInlineEditState} className="btn">
        <i className="uil uil-pen" />
      </button>
    );
  };

  const renderPasswordToggleButton = () => {
    return (
      <button type="button" onClick={showPasswordClicked} className="btn">
        <img src={!showingPassword ? icons.eyeDark : icons.eyeSlashDark} alt="Toggle password" />
      </button>
    );
  };

  const renderInputTools = () => {
    return (
      <div className="flex-shrink-0">
        {isRefreshButtonEnabled && renderRefreshButton()}
        {isInlineEditEnabled && renderEditButton()}
        {isCopyButtonEnabled && <CopyButton id={id} />}
      </div>
    );
  };

  const renderInlineEditControls = () => {
    return (
      <div className="d-flex">
        <button type="button" onClick={toggleInlineEditState} className="btn">
          <i className="uil uil-multiply" />
        </button>
        <button type="button" onClick={saveChanges} className="btn">
          <i className="uil uil-check" />
        </button>
      </div>
    );
  };

  const renderIcon = icon => {
    return <img src={icons[icon]} alt={icon} className={iconClasses} />;
  };

  if (isEditable) {
    return (
      <div className="d-flex flex-wrap">
        <div className={`input-wrapper ${hasError() ? 'is-invalid' : ''}`}>
          {icon && iconPosition !== 'end' && renderIcon(icon)}
          <InputMask
            className="form-control"
            type={type}
            min={min}
            max={max}
            mask={mask}
            maskPlaceholder={maskPlaceholder}
            alwaysShowMask={alwaysShowMask && mask ? true : false}
            value={localValue}
            defaultValue={defaultValue}
            placeholder={placeholder}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            onChange={e => {
              setLocalValue(e.target.value);
              onChange(e);
            }}
            step={step}
            onKeyDown={onKeyDown}
          />
          {isPasswordToolEnabled && renderPasswordToggleButton()}
          {isInlineEditEnabled && renderInlineEditControls()}
          {iconPosition === 'end' && icon && renderIcon(icon)}
        </div>
        {hasTip() && !hasError() && <small className="text-muted">{tipMessage}</small>}
        {showErrorMessage && hasError() && <span className="invalid-feedback">{errorMessage}</span>}
      </div>
    );
  }

  return (
    <div className="custom-input">
      <div id={id} className={className}>
        {value || defaultValue || maskPlaceholder}
      </div>
      {renderInputTools()}
    </div>
  );
};

export default CustomInput;
