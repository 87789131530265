import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import FilterSections from './filterSections';

import icons from '../../assets/icons';

const FilterButton = ({ appliedFilters, filters, direction, onlyIcon, onClear, onFilterSelected }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <Dropdown
      className={`filter-button${onlyIcon ? ' only-icon' : ''}`}
      isOpen={isDropdownOpen}
      toggle={() => setIsDropdownOpen(!isDropdownOpen)}
      direction={direction}
    >
      <DropdownToggle className={`filter-button-toggle ${appliedFilters.length > 0 ? 'has-filter' : ''}`}>
        <img src={icons.filter} alt="filter" />
        {onlyIcon ? null : <span>Filters</span>}
      </DropdownToggle>
      <DropdownMenu>
        <div className="multiple-filter multiple-filter-slim">
          <div className="multiple-filter-filters">
            <FilterSections
              filters={filters}
              appliedFilters={appliedFilters}
              onSelect={(option, filter) => {
                onFilterSelected(option, filter);
                setIsDropdownOpen(false);
              }}
            />
          </div>
        </div>
        <div className="dropdown-actions">
          <button
            className="btn text-danger"
            onClick={() => {
              onClear();
              setIsDropdownOpen(false);
            }}
          >
            Clear
          </button>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default FilterButton;
